// @mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// component
import PicSwiper from "./PicSwiper";
import FaqTable from "./FaqTable";
import InfoTable from "./InfoTable";
import FloatButtons from "./FloatButtons";

import top from "assets/img/lp-top.svg";
import topMb from "assets/img/lp-top-mb.svg";
import about from "assets/img/lp-about.svg";
import aboutMb from "assets/img/lp-about-mb.svg";
import system from "assets/img/lp-system.svg";
import systemMb from "assets/img/lp-system-mb.svg";
import systemMbSlide from "assets/img/lp-system-mb-slide.svg";
import mail from "assets/img/lp-mail.svg";
import mailMb from "assets/img/lp-mail-mb.svg";
import security from "assets/img/lp-security.svg";
import securityMb from "assets/img/lp-security-mb.svg";
import voice from "assets/img/lp-voice.svg";
import voiceMb from "assets/img/lp-voice-mb.svg";
import flow from "assets/img/lp-flow.svg";
import flowMb from "assets/img/lp-flow-mb.svg";
import faqTitle from "assets/img/lp-faq-title.svg";
import infoTitle from "assets/img/lp-info-title.svg";
import infoTitleMb from "assets/img/lp-info-title-mb.svg";

import "assets/css/landing.scss";

// 仮置き
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";

export default function Landing() {
  // 仮のログアウト処理
  const dispatch = useDispatch();
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  if (loginAccount.isAuthenticated) {
    dispatch({ type: "LOGOUT" });
    console.log("logout");
  }
  console.log(loginAccount);
  // ここまで

  return (
    <>
      <Box
        className="main"
        sx={{
          margin: "0 auto",
          width: { sm: "1280px" },
        }}
      >
        <div className="top">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src={top}
              alt="top 保育園・幼稚園に必要なシステムが1つになった！"
              className="top-img"
            />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={topMb}
              alt="top 保育園・幼稚園に必要なシステムが1つになった！"
              className="top-img"
            />
          </Box>
          <PicSwiper />
        </div>
        <div id="about" className="about">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, paddingBottom: "60%" }}
          >
            <img
              // id="about"
              className="about-img"
              src={about}
              alt="about ニコスケットって？"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "280%" }}
          >
            <img
              // id="about"
              className="about-mb-img"
              src={aboutMb}
              alt="about ニコスケットって？"
            />
          </Box>
        </div>
        <div id="system" className="system">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, paddingBottom: "160%" }}
          >
            <img
              // id="system"
              className="system-img"
              src={system}
              alt="system インターネット写真販売システム"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "830%" }}
          >
            <img
              // id="system"
              className="system-mb-img"
              src={systemMb}
              alt="system インターネット写真販売システム"
            />
            <div className="system-mb-slide-wrapper">
              <img
                className="system-mb-slide"
                src={systemMbSlide}
                alt="system 掲載開始と締切が近づいたら自動メールでお知らせ"
              />
            </div>
          </Box>
        </div>
        <div id="mail" className="mail">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, paddingBottom: "110%" }}
          >
            <img
              // id="mail"
              className="mail-img"
              src={mail}
              alt="mail 保護者連絡メールシステム"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "570%" }}
          >
            <img
              // id="mail"
              className="mail-mb-img"
              src={mailMb}
              alt="mail 保護者連絡メールシステム"
            />
          </Box>
        </div>
        <div id="security" className="security">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, paddingBottom: "85%" }}
          >
            <img
              // id="security"
              className="security-img"
              src={security}
              alt="security 安心万全のセキュリティ"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "420%" }}
          >
            <img
              // id="security"
              className="security-mb-img"
              src={securityMb}
              alt="security 安心万全のセキュリティ"
            />
          </Box>
        </div>
        <div id="voice" className="voice">
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              paddingBottom: "65.3%",
            }}
          >
            <img
              // id="voice"
              className="voice-img"
              src={voice}
              alt="voice ご利用者からの声"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "196%" }}
          >
            <img
              // id="voice"
              className="voice-mb-img"
              src={voiceMb}
              alt="voice ご利用者からの声"
            />
          </Box>
        </div>
        <div id="flow" className="flow">
          <Box
            sx={{ display: { xs: "none", sm: "block" }, paddingBottom: "50%" }}
          >
            <img
              // id="flow"
              className="flow-img"
              src={flow}
              alt="flow 導入までの流れ"
            />
          </Box>
          <Box
            sx={{ display: { xs: "block", sm: "none" }, paddingBottom: "200%" }}
          >
            <img
              // id="flow"
              className="flow-mb-img"
              src={flowMb}
              alt="flow 導入までの流れ"
            />
          </Box>
        </div>
        <div id="faq" className="faq">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={11}
              sm={9}
              sx={{ padding: { xs: "15% 0", sm: "6% 0" } }}
            >
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "none", sm: "block" },
                    textAlign: "center",
                    paddingBottom: "3%",
                  }}
                >
                  <img
                    className="faq-img"
                    src={faqTitle}
                    alt="faq よくあるご質問"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { xs: "block", sm: "none" },
                    textAlign: "center",
                    padding: "10% 0",
                  }}
                >
                  <img
                    className="faq-mb-img"
                    src={faqTitle}
                    alt="faq よくあるご質問"
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FaqTable />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="info">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={11}
              sm={9}
              sx={{ padding: { xs: "15% 0", sm: "6% 0" } }}
            >
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <img
                      className="info-img"
                      src={infoTitle}
                      alt="info お知らせ"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", sm: "none" },
                      textAlign: "center",
                      padding: "10% 0",
                    }}
                  >
                    <img
                      className="info-mb-img"
                      src={infoTitleMb}
                      alt="info お知らせ"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <InfoTable />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <FloatButtons />
      </Box>
    </>
  );
}
