import * as React from "react";

// @mui
import { FormControl, OutlinedInput, Grid } from "@mui/material/";
import { styled } from "@mui/material/styles";

// compornents
import CustomFormLabel from "../InfoEditCustomFormLabel/InfoEditCustomFormLabel";
import CustomFormHelperLabel from "../InfoEditCustomFormHelperLabel/InfoEditCustomFormHelperLabel";
import CustomForm from "../InfoEditCustomForm/InfoEditCustomForm";

const DesignedTextField = styled(OutlinedInput)({
  background: "#FAFAFA",
  border: "1px",
  boxSizing: "border-box",
  borderRadius: "4px",
  // marginTop: "10px",
  // marginBottom: "8px",
  height: "36px",
  width: "104px",
  input: {
    "&::placeholder": {
      textAlign: "right",
    },
  },
});

export default function InfoEditCustomPriceForm(props: any) {
  const {
    labelname,
    placeholder,
    helperlabel,
    inputProps,
    disabled,
    value,
    defaultValue,
    register,
  } = props;
  return (
    <div>
      <Grid container spacing={1} direction="row" alignItems="center">
        <Grid item xs={11}>
          <FormControl>
            <DesignedTextField
              {...inputProps}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              defaultValue={defaultValue}
              {...register}
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <p>円</p>
        </Grid>
      </Grid>
    </div>
  );
}
