import axios from "axios";
import { accountsReturnValue } from "utils/interface/accounts/accountsInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getAccounts = async (params: any) => {
  const response = await axios.get<accountsReturnValue[]>(
    BASE_URL + "accounts",
    {
      params: {
        account_id: params.queryKey[1],
        account_type: params.queryKey[2],
      },
      headers: { "content-type": "application/json" },
    }
  );
  return response.data;
};
