// @mui
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsFooter() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      marginTop="20px"
    >
      <Grid item xs={12} sm={5} md={5}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton type="submit" color="emerald" variant="contained">
              保存
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="emerald"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              キャンセル
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
