// @mui
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsHeader() {
  const navigate = useNavigate();
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6} sm={7} md={7}>
        <h2>会員登録情報</h2>
      </Grid>
      <Grid item xs={6} sm={5} md={5}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CustomButton type="submit" color="emerald" variant="contained">
              保存
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="emerald"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              キャンセル
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
