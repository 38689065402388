import MediaQuery from "react-responsive";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "index";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import "./OrderHistory.scss";

import OrderHistoryConsumerTable from "./organizm/OrderHistoryConsumerTable";
import OrderHistoryConsumerCard from "./organizm/OrderHistoryConsumerCard";
import { TabPanel, a11yProps } from "./organizm/TabPanel";

import { OrderHistoryContext } from "store/OrderHistoryContext";

export default function OrderHistoryConsumer(props: any) {
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // 以下が正しいが、初期値が入らず動かないので静的に宣言
  // const [group_key, setGroup_key] = useState(loginAccount.accountType);
  const [group_key, setGroup_key] = useState("user");

  // Sdummyが入っているため、動作確認したいのであればコメントを変更すること by aska 2022/05/26
  // const [schoolId, setSchoolId] = useState("");
  const [school_id, setSchool_id_key] = useState(loginAccount.schoolId);
  const [user_id, setUser_id_key] = useState(loginAccount.accountId);
  const [album_id, setAlbum_id_key] = useState("");
  const [query, setQuery] = useState([group_key, school_id, album_id, user_id]);

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    setGroup_key(newValue);
    setQuery([group_key, school_id, album_id, user_id]);
  };

  return (
    <OrderHistoryContext.Provider value={{ setGroup_key, setQuery }}>
      <h2>注文一覧</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={group_key}
          onChange={handleChange}
          aria-label="Order Group Tabs"
        >
          <Tab label="団体単位の履歴" {...a11yProps("admin")} disabled />
          <Tab label="アルバム単位の履歴" {...a11yProps("school")} disabled />
          <Tab label="保護者単位の履歴" {...a11yProps("user")} />
        </Tabs>
      </Box>
      <TabPanel group_key={group_key} index="admin">
        ユーザー様の権限では表示できません
      </TabPanel>
      <TabPanel group_key={group_key} index="school">
        ユーザー様の権限では表示できません
      </TabPanel>
      <TabPanel group_key={group_key} index="user">
        <MediaQuery query="(max-width: 600px)">
          <OrderHistoryConsumerCard query={query} />
        </MediaQuery>
        <MediaQuery query="(min-width: 600px)">
          <OrderHistoryConsumerTable query={query} />
        </MediaQuery>
      </TabPanel>
    </OrderHistoryContext.Provider>
  );
}
