import { AnyAction } from "redux";
import { OrderDMActionTypes } from "../actions/OrderDMActions";

export interface orderDM {
  orderId: string;
}

const initialState = {
  orderId: "",
};

export const orderDMReducer = (
  state: orderDM = initialState,
  action: AnyAction
): orderDM => {
  switch (action.type) {
    case OrderDMActionTypes.REGIST_ORDERID:
      state = {
        orderId: action.payload.orderId,
      };
      return state;
    case OrderDMActionTypes.DELETE_ORDERID:
      state = initialState;
      return state;
    default:
      return state;
  }
};
