import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

type Props = {
  register: any;
};

export const GroupRequirementView: React.FC<Props> = ({ register }) => {
  return (
    <Grid container direction="row" alignItems="flex-start">
      {/* <Grid item xs={2}>
        <CustomLabel value="御用件" />
      </Grid> */}
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="資料が欲しい"
              {...register("reasons1")}
              color="emerald"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="導入を検討している"
              {...register("reasons2")}
              color="emerald"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="詳しい説明が聞きたい"
              {...register("reasons3")}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};
