import * as React from "react";
import MediaQuery from "react-responsive";

import { PCNav, SPNav, LandingPCNav, LandingSPNav } from "./genarateNav";
import {
  adminNav,
  schoolNav,
  clientNav,
  landingNav,
  landingPCNav,
} from "./navArray";

export function AdminNav() {
  return (
    <>
      <MediaQuery query="(min-width: 600px)">
        <PCNav children={adminNav} />
      </MediaQuery>
    </>
  );
}

export function SchoolNav() {
  return (
    <>
      <MediaQuery query="(min-width: 600px)">
        <PCNav children={schoolNav} />
      </MediaQuery>
    </>
  );
}

export function ClientNav() {
  return (
    <>
      <MediaQuery query="(max-width: 600px)">
        <SPNav children={clientNav} />
      </MediaQuery>
      <MediaQuery query="(min-width: 600px)">
        <PCNav children={clientNav} />
      </MediaQuery>
    </>
  );
}

export function LandingNav() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <MediaQuery query="(max-width: 600px)">
        <LandingSPNav
          children={landingNav}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </MediaQuery>
      <MediaQuery query="(min-width: 600px)">
        <LandingPCNav children={landingPCNav} />
      </MediaQuery>
    </>
  );
}
