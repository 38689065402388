// 保護者用views
import Cart from "views/Cart/Cart";
import Albums from "views/Albums/Albums";
import Order from "views/Order/Order";
import OrderDetails from "views/Order/OrderDetails";
import OrderHistoryConsumer from "views/OrderHistory/OrderHistoryConsumer";
import OrderConfirm from "views/Order/OrderConfirm";
import PhotoListConsumer from "views/Photo/PhotoListConsumer";
import UserInfoEdit from "views/UserInfoEdit/UserInfoEdit";
import PhotoGroup from "views/PhotoGroup/PhotoGroup";
import PhotoGroupList from "views/PhotoGroupList/PhotoGroupList";
import PhotoGroupList2 from "views/PhotoGroupList/PhotoGroupList2";
import SampleApi from "views/_Sample/SampleApi";
import SampleComponents from "views/_Sample/SampleComponents";
import OrderCompleted from "views/Order/OrderCompleted";
import Logout from "views/Logout/Logout";

export const clientRouteArray = [
  {
    id: "04",
    path: "/cart",
    element: <Cart />,
  },
  {
    id: "05",
    path: "/albums",
    element: <Albums />,
  },
  {
    id: "06",
    path: "/order",
    element: <Order />,
  },
  {
    id: "06A",
    path: "/order/:orderId",
    element: <OrderDetails />,
  },
  {
    id: "08A",
    path: "/order-history/:orderId",
    element: <OrderDetails />,
  },
  {
    id: "08",
    path: "/order-history",
    element: <OrderHistoryConsumer />,
  },
  {
    id: "09",
    path: "/order-confirm",
    element: <OrderConfirm />,
  },
  {
    // info/:userId
    id: "10",
    path: "/info-edit/:userId",
    element: <UserInfoEdit />,
  },
  {
    id: "12",
    path: "/photos/group",
    element: <PhotoGroup />,
  },
  {
    id: "13",
    path: "/photos/group/:albumId",
    element: <PhotoGroupList />,
  },
  {
    id: "14",
    path: "/photos/group/:albumId/:groupName",
    element: <PhotoGroupList2 />,
  },
  {
    id: "11",
    path: "/photos/group/:albumId/:groupName/photos",
    element: <PhotoListConsumer />,
  },
  {
    id: "15",
    path: "/order-completed",
    element: <OrderCompleted />,
  },
  {
    id: "99",
    path: "/logout",
    element: <Logout accountType="user" />,
  },

  // aska add 2022/04/21
  {
    id: "90",
    path: "/sampleapi",
    element: <SampleApi />,
  },
  {
    id: "91",
    path: "/samplecomponents",
    element: <SampleComponents />,
  },
];
