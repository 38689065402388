import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";

// @mui
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Dispatch } from "redux";

const DesignedButton = styled(Button)({
  fontSize: "14px",
  fontWeight: "bold",
  width: "130px",
  height: "36px",
  borderWidth: "2px",
  boxShadow: "none",
});

// 団体用写真一覧画面に遷移
const goToSchoolPhoto = (
  albumId: string,
  albumName: string,
  groupName: string,
  dispatch: Dispatch<any>,
  navigate: NavigateFunction
) => {
  /* storeに入れる場合 */
  // dispatch({
  //   type: "REGIST_PHOTOGROUP",
  //   payload: {
  //     albumId,
  //     albumName,
  //     groupName,
  //   },
  // });
  // navigate("/school-photos");
  // // 下記ではdispatchされずに画面更新される
  // // window.location.href = "/school-photos";
  /* ここまで*/

  /* 2022/5/14 URLに入れる実装に切り替え */
  // 写真一覧画面初期化のため下記で遷移
  window.location.href =
    "/school-photos/" + albumId + "/" + encodeURIComponent(groupName);
};

export default function FolderEditButton(props: any) {
  const { albumId, albumName, groupName } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DesignedButton
      onClick={() =>
        goToSchoolPhoto(albumId, albumName, groupName, dispatch, navigate)
      }
      color="emerald"
      variant="contained"
    >
      写真表示・編集
    </DesignedButton>
  );
}
