import React from 'react';
import { Grid, Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import SquareThumbnail from './SquareThumbnail';
import NumberChangeButtons from './NumberChangeButtons';
import { PhotoSize } from './Cart';

export function numberToWithComma(integer: number): string {
  const numberWithComma = new Intl.NumberFormat();
  return numberWithComma.format(integer);
}

const styles = (theme: Theme) => createStyles({
  picSizeSelector: {
    fontSize: '12px',
    color: '#424242',
  },
  'picSizeRow': {
    'margin-top': '1em',
    'lineHeight': '1.5em',
    '&:last-child': {
      'margin-bottom': '1em',
    }
  }
});

function PicSizeSelector({ ...props }: any) {
  const {
    photo,
    classes
  } = props;
  
  return (
    <div className={classes.picSizeSelector}>
      <SquareThumbnail photoSrc={photo.url} />
      { photo.photoSizes.map((size: PhotoSize) => {
        return (
          <Grid container direction="row" justifyContent="space-around" alignItems="center" className={classes.picSizeRow} key={size.name}>
            <Grid item xs={6} container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item> {size.displayName} </Grid>
              <Grid item> {numberToWithComma(size.unitPrice) + '円'} </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <NumberChangeButtons photoId={photo.id} sizeName={size.name} />
                </Grid>
                <Grid item>枚</Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

export default withStyles(styles)(PicSizeSelector);