import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

const StyledTableContainer = styled(TableContainer)({
  background: "#FFFFFF",
  border: "1px solid #E7E7E7",
  boxSizing: "border-box",
  borderRadius: "16px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
  padding: "0px 40px",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
  padding: "0px 40px",
});

const StyledTableSortLabel = styled(TableSortLabel)({
  "& .MuiTableSortLabel-icon": {
    color: "#23AA91 !important",
  },
});

export default function SchoolDmHistoryTable({ ...props }: any) {
  const { tableHead, tableData, page, rowsPerPage } = props;

  const dispatch = useDispatch();

  const selectDM = (dmHistoryData: any) => {
    dispatch({
      type: "SELECT_DM",
      payload: {
        dmHistoryData,
      },
    });
  };

  // ソートに関する関数
  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("desc");
  const [rows, setRows] = useState(tableData);
  function handleClickSortColumn() {
    const isDesc = order === "desc";
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = rows.sort((a: any, b: any) => {
      if (a.send_date > b.send_date) {
        return sortRule[nextOrder][0];
      } else if (a.send_date < b.send_date) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setOrder(nextOrder);
    setRows(sortedRows);
  }

  const viewTableData = rows.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: "54px" }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align="left">
                    {prop.class_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="left">
                    {prop.title}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sortDirection={order}>
                    {prop.send_date}
                    <StyledTableSortLabel
                      active={true}
                      direction={order}
                      IconComponent={ArrowDropDownRoundedIcon}
                      onClick={() => handleClickSortColumn()}
                    />
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {viewTableData.map((prop: any, key: any) => {
            return (
              <StyledTableRow
                key={key}
                sx={{ "&:hover": { backgroundColor: "#FFFBE7" } }}
              >
                <TableDataCell
                  key={key}
                  onClick={() => {
                    selectDM(prop);
                  }}
                  align="left"
                  sx={{ width: "30%" }}
                >
                  {prop.child_class_name.toString()}
                </TableDataCell>
                <TableDataCell
                  key={key}
                  onClick={() => {
                    selectDM(prop);
                  }}
                  align="left"
                  sx={{ width: "40%" }}
                >
                  {prop.title}
                </TableDataCell>
                <TableDataCell
                  key={key}
                  onClick={() => {
                    selectDM(prop);
                  }}
                  align="center"
                  sx={{ width: "30%" }}
                >
                  {prop.send_date}
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
