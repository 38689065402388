import { useState } from "react";
// @mui
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";

type Props = {
  register: any;
};

export const GroupKeyView: React.FC<Props> = ({ register }) => {
  const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <CustomForm
          labelname="ID(ご登録いただいたメールアドレスまたは文字列)"
          register={register("account_id", { required: true, maxLength: 100 })}
        />
        <CustomForm
          labelname="パスワード"
          type={isRevealPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                edge="end"
              >
                {isRevealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          register={register("password", { required: true, maxLength: 100 })}
        />
      </Grid>
    </Grid>
  );
};
