import * as React from "react";

// @mui
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Select } from "@mui/material/";
import { styled } from "@mui/material/styles";

// compornents
import CustomFormLabel from "../InfoEditCustomFormLabel/InfoEditCustomFormLabel";
import CustomFormHelperLabel from "../InfoEditCustomFormHelperLabel/InfoEditCustomFormHelperLabel";

const DesignedSelector = styled(Select)({
  background: "#FAFAFA",
  border: "1px",
  boxSizing: "border-box",
  borderRadius: "4px",
  marginTop: "10px",
  marginBottom: "8px",
  height: "36px",
});

type item = {
  value: string;
  label: string;
};

type SelectorProps = {
  items: item[];
  labelname?: string;
  helperlabel?: string;
  disabled?: boolean;
  register?: any;
  value?: string;
  defaultValue?: string;
};

export default function InfoEditCustomSelector(props: SelectorProps) {
  const {
    items,
    labelname,
    helperlabel,
    disabled,
    register,
    value,
    defaultValue,
  } = props;
  return (
    <FormControl fullWidth>
      <CustomFormLabel value={labelname} />
      {/* 2/6現在placeholderが表示できない */}
      <DesignedSelector
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        {...register}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </DesignedSelector>
      <CustomFormHelperLabel value={helperlabel} />
    </FormControl>
  );
}
