import { useQuery } from "react-query";

import { getAlbums } from "utils/api/albums/albums";
import { getUsersByUserId } from "utils/api/users/users";
import { getSchools } from "utils/api/schools/schools";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";

export const useAlubms = (props: any): any => {
  // get album data
  const { isLoading, isError, error, data } = useQuery<
    albumsReturnValue[],
    Error
  >(["getAlbums in apis", "", props], getAlbums);

  if (isLoading) {
    return isLoading;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  return data;
};

export const useUsers = (props: any): any => {
  // get album data
  const { isLoading, isError, error, data } = useQuery<any[], Error>(
    ["getUsersByUserId", props],
    getUsersByUserId
  );

  if (isLoading) {
    return isLoading;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  return data;
};

export const useSchools = (props: any): any => {
  // get school data
  const { isLoading, isError, error, data } = useQuery<any[], Error>(
    ["getSchools", props],
    getSchools
  );

  if (isLoading) {
    return isLoading;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  return data;
};
