import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

// other
import PrefMaster from "../../assets/PrefMaster";

const prefectureList = PrefMaster.PREF_OPTIONS.map((pref) => {
  return { value: pref, label: pref };
});

export default function GroupAddressView(props: any) {
  const { data } = props;
  const prefecture = data.address.split(" ")[0]
    ? data.address.split(" ")[0]
    : "";
  const municipalities = data.address.split(" ")[1]
    ? data.address.split(" ")[1]
    : "";
  const houseNumber = data.address.split(" ")[2]
    ? data.address.split(" ")[2]
    : "";
  const buildings = data.address.split(" ")[3]
    ? data.address.split(" ")[3]
    : "";

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="住所" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={3}>
            <CustomForm
              labelname="郵便番号"
              helperlabel="例：000-0000"
              value={data.zip}
              disabled
            />
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="都道府県"
              items={prefectureList}
              value={prefecture}
              disabled
            />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="市区町村"
              placeholder="市区町村を入力してください"
              helperlabel="例：〇〇市〇〇町"
              value={municipalities}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <CustomForm
              labelname="番地"
              placeholder="番地を入力してください"
              helperlabel="例：1-2-3"
              value={houseNumber}
              disabled
            />
          </Grid>
          <Grid item xs={5}>
            <CustomForm
              labelname="建物名・部屋番号"
              placeholder="建物名・部屋番号を入力してください"
              helperlabel="例：〇〇マンション101"
              value={buildings}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
