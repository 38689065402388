// @mui
import Grid from "@mui/material/Grid";

// components
import AlbumSaveButton from "./AlbumSaveButton";
import SalesStartButton from "./SalesStartButton";

export default function ContentsFooter(props: any) {
  const { photoGroupData, isEditable, setIsStartSale } = props;
  return (
    <Grid container alignItems="center" paddingBottom="80px">
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <AlbumSaveButton setIsStartSale={setIsStartSale} />
          </Grid>
          {/* 写真グループがない場合は表示しない */}
          {/* 販売中のアルバムは販売開始ボタンを押せない */}
          {/* 管理者は日付を登録するために押す必要あり */}
          {photoGroupData.length > 0 && (
            <Grid item>
              <SalesStartButton
                isEditable={isEditable}
                setIsStartSale={setIsStartSale}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
