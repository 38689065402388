import * as React from "react";

import Components from "./organizms/Components";

export default function SampleComponents(props: any) {
  return (
    <>
      <Components />
    </>
  );
}
