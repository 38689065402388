import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

const accountTypeList = [
  {
    value: "普通預金",
    label: "普通預金",
  },
  {
    value: "当座預金",
    label: "当座預金",
  },
  {
    value: "無利息型普通預金",
    label: "無利息型普通預金",
  },
];

export default function GroupAccountInfoView(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue("bank_code", data.bank_code);
    schoolUseForm.setValue("bank_name", data.bank_name);
    schoolUseForm.setValue("branch_number", data.branch_number);
    schoolUseForm.setValue("branch_name", data.branch_name);
    schoolUseForm.setValue("bank_type", data.bank_type);
    schoolUseForm.setValue("bank_account", data.bank_account);
    schoolUseForm.setValue("bank_account_name", data.bank_account_name);
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="口座情報" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={2}>
            <CustomForm
              labelname="金融機関コード"
              helperlabel="例：0000"
              register={schoolUseForm.register("bank_code")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="金融機関名"
              placeholder="金融機関名を入力してください"
              helperlabel="例：〇〇銀行"
              register={schoolUseForm.register("bank_name")}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <CustomForm
              labelname="支店番号"
              helperlabel="例：000"
              register={schoolUseForm.register("branch_number")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="支店名"
              placeholder="支店名を入力してください"
              helperlabel="例：〇〇支店"
              register={schoolUseForm.register("branch_name")}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="口座種別"
              items={accountTypeList}
              defaultValue={data.bank_type}
              register={schoolUseForm.register("bank_type")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="口座番号"
              placeholder="口座番号を入力してください"
              helperlabel="例：0000000"
              register={schoolUseForm.register("bank_account")}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="口座名義人"
              placeholder="口座名義人を入力してください"
              helperlabel="例：田中太郎"
              register={schoolUseForm.register("bank_account_name")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
