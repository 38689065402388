import React from 'react';
import { Grid, Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { numberToWithComma } from '../Cart/PicSizeSelector';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: '12px',
    lineHeight: '2em',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '1em',
    marginBottom: '0',
  },
  h3Color: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '1em',
    marginBottom: '0',
    color: '#DE5F5F',
  }
});
function OrderTotalSum({ ...props }: any) {
  const {
    subtotal,
    fee,
    classes
  } = props;
  const totalSum = subtotal + fee;
  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>商品小計</Grid>
        <Grid item>{'¥' + numberToWithComma(subtotal)}</Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>送料・手数料</Grid>
        <Grid item>{'¥' + numberToWithComma(fee)}</Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.totalSum}>
        <Grid item>
          <h3 className={classes.h3}> ご請求額 </h3>
        </Grid>
        <Grid item>
          <h3 className={classes.h3Color}> {'¥' + numberToWithComma(totalSum)} </h3>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(OrderTotalSum);