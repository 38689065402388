import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function UserNameView(props: any) {
  const { register, formState } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <ErrorMessage
          errors={formState.errors}
          name="user_name"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomLabel value={"氏名"} />
        <CustomForm
          placeholder="氏名を入力してください"
          helperlabel="例：山田　太郎"
          register={register("user_name", {
            required: "⚠︎必須項目です",
          })}
        />
        <ErrorMessage
          errors={formState.errors}
          name="user_kana"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomLabel value={"フリガナ"} />
        <CustomForm
          placeholder="フリガナを入力してください"
          helperlabel="例：ヤマダ　タロウ"
          register={register("user_kana", {
            required: "⚠︎必須項目です",
          })}
        />
      </Grid>
    </Grid>
  );
}
