import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// @mui
import { Card, CardContent, Grid, Divider } from "@mui/material";

import {
  StyledTableContainer,
  StyledTableRow,
  TableHeadCell,
  TableDataCell,
  StyledChip,
  StyledTypography,
} from "components/Table/TableComponents";
import { SortTableHead } from "components/Table/SortTable";
import { orderHistory } from "utils/interface/order/orderInterface";

import { OrderHistoryContext } from "store/OrderHistoryContext";

export default function CustomCardList({ ...props }: any) {
  const {
    TABLEHEAD,
    tableData,
    sortKey,
    filterKey,
    urlKey,
    group_key,
    chipFn,
  } = props;

  console.log("CardList");
  console.log(TABLEHEAD);
  console.log(tableData);
  console.log(sortKey);
  console.log(filterKey);
  console.log(urlKey);
  console.log(group_key);
  console.log(chipFn);

  const { setGroup_key, setQuery, setSchoolId, schoolId, setAlbumId, albumId } =
    useContext(OrderHistoryContext);

  // 2022/02/27 西村追記
  // 各テーブル行にpathを追加する関数
  // 2022/04/20 高田追記 /school-album/AlbumIdに変更が必要
  const navigate = useNavigate();
  const goPath = (urlString: string) => {
    navigate("./" + urlString);

    if (group_key) {
      setGroup_key(group_key);

      if (group_key === "school" || group_key === "organization") {
        setSchoolId(urlString);
      } else if (group_key === "user") {
        setAlbumId(urlString);
      }

      setQuery([group_key, schoolId, albumId, ""]);
    }
  };
  // ここまで

  // ソートに関する設定。SortTableHead componentに代入している
  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("desc");
  const [rows, setRows] = useState<orderHistory[]>(tableData);

  useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  return (
    // <Grid item xs={12}>
    <>
      {rows?.map((row: any) => (
        <Grid
          item
          xs={12}
          key={row.order_id}
          onClick={() => {
            if (urlKey) goPath(row[urlKey]);
          }}
        >
          <Card
            sx={{
              padding: "16px",
              borderRadius: "16px",
              boxShadow: "none",
              border: "1px solid #E7E7E7",
            }}
          >
            <Grid
              container
              rowSpacing={1}
              alignItems="baseline"
              sx={{ wordBreak: "break-all", fontSize: "12px" }}
            >
              <Grid item xs={5}>
                {TABLEHEAD[0].order_id + "：　"}
              </Grid>
              <Grid item xs={7}>
                {row.order_id}
              </Grid>
              <Grid item xs={5}>
                {TABLEHEAD[0].user_name}：
              </Grid>
              <Grid item xs={7}>
                {row.user_name}
              </Grid>
              <Grid item xs={5}>
                {TABLEHEAD[0].order_status}：
              </Grid>
              <Grid item xs={7}>
                <StyledChip
                  label={<StyledTypography>{row[filterKey]}</StyledTypography>}
                  color={chipFn(row[filterKey])}
                />
              </Grid>
              <Grid item xs={5}>
                {TABLEHEAD[0].order_date}：
              </Grid>
              <Grid item xs={7}>
                {row.order_date}
              </Grid>
              <Grid item xs={5}>
                {TABLEHEAD[0].amount}：
              </Grid>
              <Grid item xs={7}>
                {Number(row.amount).toLocaleString()}枚
              </Grid>
              <Grid item xs={5}>
                {TABLEHEAD[0].subtotal}：
              </Grid>
              <Grid item xs={7}>
                {Number(row.subtotal).toLocaleString()}円
              </Grid>
              {/* <Grid item xs={5}>
                撮影日
              </Grid>
              <Grid item xs={7}>
                {photo._datetime
                            ? photo._datetime.substring(0, 10)
                            : ""}
              </Grid> */}
            </Grid>
          </Card>
        </Grid>
      ))}
      {/* </Grid> */}
    </>
  );
}
