import * as React from "react";

// @mui
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

// 2022/02/28 modal周りのゴミ、今後どうにかする
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  message: string;
};

export const FormModal: React.FC<Props> = ({ open, message }) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography id="modal-form-title" variant="h6" component="h2">
          {message}
        </Typography>
        <Typography id="modal-form-description" sx={{ mt: 2 }}>
          <a href="/">
            <CustomButton color="emerald" variant="contained">
              TOPへ戻る
            </CustomButton>
          </a>
        </Typography>
      </Box>
    </Modal>
  );
};
