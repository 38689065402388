import { withStyles } from "@mui/styles";
import {
  Grid,
  createStyles,
  Theme,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import OrderCountsByPicSize from "./OrderCountsByPicSize";
import { Photo } from "../Cart/Cart";
import OrderTotalSum from "./OrderTotalSum";
import { GrayButton } from "./GrayButton";
import { Link, useParams } from "react-router-dom";
import { ordersReturnValue } from "utils/interface/orders/ordersInterface";
import { useQueries, useQuery } from "react-query";
import { getOrders } from "utils/api/orders/orders";
import { getUsersByUserId } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getPhotos } from "utils/api/photo/photo";
import { getDisplayNameAndSizeDetail } from "utils/function/getDisplayNameAndSizeDetail";
import formatPhoneNumber from "utils/function/formatPhoneNumber";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { getClasses } from "utils/api/classes/classes";
import { paymentMethodMap } from "./utils";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      marginRight: "20px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    bold: {
      fontWeight: 700,
    },
    ordererUl: {
      padding: 0,
      margin: 0,
    },
    ordererLi: {
      fontWeight: 700,
      listStyleType: "none",
      "& + $ordererLi": {
        marginTop: "1em",
      },
    },
  });

const OrderCard = (props: any) => {
  const { children } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: "20px",
      }}
    >
      <CardContent sx={{ padding: "20px" }}>{children}</CardContent>
    </Card>
  );
};

const Section = (props: any) => {
  const { title, children } = props;
  return (
    <Box>
      <h3>
        <Box sx={{ fontSize: "16px", marginBottom: "12px" }}>{title}</Box>
      </h3>
      <OrderCard>{children}</OrderCard>
    </Box>
  );
};

const getOrderCountsByPicSize = (photos: Photo[]): any => {
  const photoSizes = photos.reduce((keys: any, photo: Photo) => {
    const keysNew = { ...keys };
    for (const photoSize of photo.photoSizes) {
      const key = keysNew[photoSize.name] || { count: 0, subtotal: 0 };
      keysNew[photoSize.name] = {
        name: photoSize.name,
        displayName: photoSize.displayName,
        sizeDetail: photoSize.sizeDetail,
        count: key.count + photoSize.count,
        subtotal: key.subtotal + photoSize.unitPrice * (photoSize.count || 0),
      };
    }
    return keysNew;
  }, {});

  return Object.values(photoSizes);
};

const OrderDetails = (props: any) => {
  const { classes } = props;
  const { orderId } = useParams();

  const ordersQueryResult = useQuery<ordersReturnValue[], Error>(
    [null, null, null, orderId, null, null],
    getOrders
  );
  const order = ordersQueryResult.data ? ordersQueryResult.data[0] : {};
  const orderDetails = order.order_details ? order.order_details : [];

  const fee =
    order.commission && order.postage
      ? parseInt(order.commission) + parseInt(order.postage)
      : undefined;

  // 2022/10/8 takata fee=0の場合に?で判定するとNaNと表示されるためundefined判定にする
  const subtotal =
    order.taxed_price && fee !== undefined
      ? parseInt(order.taxed_price) - fee
      : undefined;

  const usersQueryResult = useQuery<usersReturnValue[], Error>(
    [null, order.user_id],
    getUsersByUserId,
    { enabled: !!order }
  );
  const user = usersQueryResult.data ? usersQueryResult.data[0] : {};

  const photoDetails = useQueries(
    orderDetails.map((orderDetail) => ({
      queryKey: [null, orderDetail.photo_id],
      queryFn: getPhotos,
    }))
  ).map((queryResult) => (queryResult.data ? queryResult.data[0] : {}));
  const photoDetailsMap = new Map(
    photoDetails.map((detail) => [detail.photo_id, detail])
  );
  const photos: any = orderDetails.map((orderDetail) => {
    const photo = photoDetailsMap.get(orderDetail.photo_id);
    const { displayName, sizeDetail } = orderDetail.select_size
      ? getDisplayNameAndSizeDetail(orderDetail.select_size)
      : { displayName: "", sizeDetail: "" };
    return {
      id: orderDetail.photo_id,
      albumId: photo?.album_id,
      groupId: photo?.group_name,
      url: photo?.path,
      photoSizes: [
        {
          count: orderDetail.amount ? parseInt(orderDetail.amount) : undefined,
          name: orderDetail.select_size,
          displayName: displayName,
          sizeDetail: sizeDetail,
          unitPrice: orderDetail.unit_price
            ? parseInt(orderDetail.unit_price)
            : undefined,
        },
      ],
    };
  });
  const classesQueryResult = useQuery<classesReturnValue[], Error>(
    [null, order.delivery_class],
    getClasses
  );
  const child_class = classesQueryResult.data
    ? classesQueryResult.data[0]
    : undefined;
  const orderCountsByPicSize = getOrderCountsByPicSize(photos);

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={"24px"}>
        <Grid item xs={12} md={12}>
          <Section title="ご注文者">
            <ul className={classes.ordererUl}>
              <li className={classes.ordererLi}>{user.user_name}</li>
              <li className={classes.ordererLi}>
                {user.zip} {user.address}
              </li>
              <li className={classes.ordererLi}>
                {formatPhoneNumber(user.phone_number)}{" "}
              </li>
              <li className={classes.ordererLi}>{user.user_id}</li>
            </ul>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section title="配送方法">
            <span className={classes.bold}>
              {order.shipping_method === "自宅配送"
                ? "ご自宅へ配送"
                : "所属の団体様へ配送"}
            </span>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          {order.shipping_method !== "自宅配送" && (
            <Section title="お届け先クラス">
              <span className={classes.bold}>{order.delivery_class}</span>
            </Section>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Section title="お支払い方法">
            <span className={classes.bold}>
              {order.shipping_method === "一括配送集金"
                ? "現金集金"
                : order.payment
                ? paymentMethodMap.get(order.payment)
                : ""}
            </span>
          </Section>
        </Grid>
        <Grid item xs={12} md={12}>
          <Section title="ご注文点数">
            <OrderCountsByPicSize
              orderCountsByPicSize={orderCountsByPicSize}
              downloads={photos}
            />
          </Section>
          <OrderCard>
            <OrderTotalSum subtotal={subtotal} fee={fee} />
          </OrderCard>
        </Grid>
      </Grid>

      {/* linkの振り分けができなかった、ごめん aska 2022/05/31 */}
      <GrayButton component={Link} to="/school-order-history">
        戻る
      </GrayButton>
    </div>
  );
};

export default withStyles(styles)(OrderDetails);
