import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const postAccount = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "account", data, {
    headers: { "content-type": "application/json" },
  });
};

export const patchAccount = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.patch(BASE_URL + "account/" + params.account_id, data, {
    headers: { "content-type": "application/json" },
  });
};
