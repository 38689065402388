import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import folder from "../../assets/img/new_folder_img.png";
import "./folder.scss";

export default function FolderImage(props: any) {
  const { albumId, title, flag, groupName } = props;
  const navigate = useNavigate();

  return (
    <>
      {flag ? (
        <div className="folder">
          <img
            src={folder}
            alt="picture"
            onClick={() => {
              navigate("/photos/group/" + albumId + "/" + title);
            }}
          />
          <p className="bottom-left">{title}</p>
        </div>
      ) : groupName ? (
        <div className="folder">
          <img
            src={folder}
            alt="picture"
            onClick={() => {
              navigate("/photos/group/" + albumId + "/" + groupName + "%2f" + title + "/photos");
            }}
          />
          <p className="bottom-left">{title}</p>
        </div>
      ) : (
        <div className="folder">
          <img
            src={folder}
            alt="picture"
            onClick={() => {
              navigate("/photos/group/" + albumId + "/" + title + "/photos");
            }}
          />
          <p className="bottom-left">{title}</p>
        </div>
      )}
    </>
  );
}
