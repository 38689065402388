import axios from "axios";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getAlbums = async (params: any) => {
  const response = await axios.get<albumsReturnValue[]>(BASE_URL + "albums", {
    params: {
      album_id: params.queryKey[1],
      school_id: params.queryKey[2],
      album_name: params.queryKey[3],
      sales_period_from: params.queryKey[4],
      sales_period_to: params.queryKey[5],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getSchoolAlbums = async (params: any) => {
  const response = await axios.get<albumsReturnValue[]>(BASE_URL + "albums", {
    params: {
      camera_man: params.queryKey[1],
      school_id: params.queryKey[2],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const postAlbum = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "album", data, {
    headers: { "content-type": "application/json" },
  });
};

export const patchAlbum = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.patch(BASE_URL + "album/" + params.album_id, data, {
    headers: { "content-type": "application/json" },
  });
};

export const deleteAlbum = async (params: any) => {
  const response = await axios.delete(BASE_URL + "album/" + params);
  console.log(BASE_URL + "album/" + params)
  await wait(10);
  return response;
};

const wait = (sec:any) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, sec*1000);
    //setTimeout(() => {reject(new Error("エラー！"))}, sec*1000);
  });
};