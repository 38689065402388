import { useState, useEffect } from "react";

// @mui
import {
  Grid,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// components
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomeCheckBox from "../../components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomPriceForm from "../../components/InfoEditCustomPriceForm/InfoEditCustomPriceForm";

// 正規表現 0始まりでない、0-9の繰り返し
const numberRegExp: RegExp = /^[1-9][0-9]+$/;

type Props = {
  register: any;
  dfPriceList: any;
  dfSizeList: any;
  schoolData: any;
};

function createData(
  photoSize: string,
  photoSizeName: string,
  wholePrice: number,
  salesPrice: number,
  placeholderPrice: string,
  tableMargin: any
) {
  return {
    photoSize,
    photoSizeName,
    wholePrice,
    salesPrice,
    placeholderPrice,
    tableMargin,
  };
}

export const PriceSelectView: React.FC<Props> = ({ register, ...props }) => {
  const { dfPriceList, dfSizeList, schoolData } = props;

  // 団体ごとに設定した卸値
  const wholePriceL =
    schoolData && schoolData.wholesale_price_l
      ? schoolData.wholesale_price_l
      : "40";
  const wholePrice2L =
    schoolData && schoolData.wholesale_price_2l
      ? schoolData.wholesale_price_2l
      : "130";
  const wholePriceDL =
    schoolData && schoolData.wholesale_price_dl
      ? schoolData.wholesale_price_dl
      : "140";

  const rows = [
    createData("L", "Lサイズ", wholePriceL, dfPriceList.L, wholePriceL, ""),
    createData(
      "2L",
      "2Lサイズ",
      wholePrice2L,
      dfPriceList["2L"],
      wholePrice2L,
      ""
    ),
    createData(
      "DL",
      "ダウンロード",
      wholePriceDL,
      dfPriceList.DL,
      wholePriceDL,
      ""
    ),
  ];

  // チェックボックスの初期値判定
  const isChecked = (photoSize: string) => {
    if (photoSize === "L") {
      return dfSizeList.length > 0
        ? dfSizeList.includes("L")
        : schoolData.issales_lsize === "True";
    } else if (photoSize === "2L") {
      return dfSizeList.length > 0
        ? dfSizeList.includes("2L")
        : schoolData.issales_2lsize === "True";
    } else if (photoSize == "DL") {
      return dfSizeList.length > 0
        ? dfSizeList.includes("DL")
        : schoolData.issales_dl === "True";
    } else {
      return false;
    }
  };

  const isDisabled = (photoSize: string) => {
    if (photoSize === "L") {
      // console.debug(schoolData.issales_lsize === "False");
      return schoolData.issales_lsize === "False";
    } else if (photoSize === "2L") {
      // console.debug(schoolData.issales_2lsize === "False");
      return schoolData.issales_2lsize === "False";
    } else if (photoSize == "DL") {
      // console.debug(schoolData.issales_dl === "False");
      return schoolData.issales_dl === "False";
    } else {
      return true;
    }
  };

  return (
    <Grid container>
      <Grid item className="row-name" xs={2}>
        <CustomLabel value="販売価格設定" />
      </Grid>
      <Grid item className="row-content" xs={10}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 200 }}>写真サイズ</TableCell>
                <TableCell style={{ width: 200 }} align="right">
                  卸値（税込）
                </TableCell>
                <TableCell style={{ width: 200 }} align="right">
                  販売価格（税込）
                </TableCell>
                <TableCell style={{ width: 50 }} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.photoSize}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <FormControlLabel
                      control={
                        <CustomeCheckBox
                          register={register("size" + row.photoSize)}
                          defaultChecked={isChecked(row.photoSize)}
                          disabled={isDisabled(row.photoSize)}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: 14 }}>
                          {row.photoSizeName}
                        </Typography>
                      }
                    />
                  </TableCell>
                  <TableCell align="right">{row.wholePrice}円</TableCell>
                  <TableCell align="right">
                    <CustomPriceForm
                      placeholder={row.placeholderPrice}
                      defaultValue={row.salesPrice}
                      register={register("price" + row.photoSize, {
                        // required: true,
                        pattern: numberRegExp,
                        min: row.wholePrice,
                      })}
                    />
                  </TableCell>
                  <TableCell align="left">{row.tableMargin}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
