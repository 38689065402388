import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";

import InfoPdf from "../../assets/nicosket-info.pdf";
import SamplePhoto from "../../assets/sample_photo.png";
import { TableRowsSharp } from "@mui/icons-material";

const infoRows = [
  {
    id: 0,
    date: "2022年●月●日",
    info: "「デジタルカメラプレゼント」ご紹介キャンペーン！",
    url: "https://ssl.form-mailer.jp/fms/35fe9461735961",
  },
  {
    id: 1,
    date: "2022年●月●日",
    info: "【募集】撮影カメラマン",
    url: "https://ssl.form-mailer.jp/fms/531852d4735727",
  },
  // {
  //   id: 2,
  //   date: "2022●月●日",
  //   info: "ニコスケットは「ものづくり・商業・サービス生産性向上促進事業」に採択されたサービスです!",
  //   url: "./nicosket-info.pdf",
  // },
  // {
  //   id: 3,
  //   date: "2022.02.20",
  //   info: "年末年始休業日のお知らせ",
  // },
  // {
  //   id: 4,
  //   date: "2022.02.01",
  //   info: "ホームページ開設のお知らせ",
  // },
];

export default function InfoTable() {
  return (
    <div>
      <Table className="info-table">
        <TableBody>
          {infoRows.map((row) => (
            <TableRow key={row.id}>
              {/* <TableCell>
                <b>{row.date}</b>
              </TableCell> */}
              <TableCell>
                <Link
                  href={row.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  sx={{ color: "#000" }}
                >
                  {row.info}
                </Link>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            {/* <TableCell>
                <b>{row.date}</b>
              </TableCell> */}
            <TableCell>
              <Link
                href={InfoPdf}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                sx={{ color: "#000" }}
              >
                ニコスケットは「ものづくり・商業・サービス生産性向上促進事業」に採択されたサービスです!
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
