import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { createTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import AdminPrintingTable1 from './AdminPrintingTable1';
import AdminPrintingTable2 from './AdminPrintingTable2';
import { orderHistory } from "utils/interface/order/orderInterface";
import { getOrderHistory, getOrder } from "utils/api/order/order";
import { patchOrder } from "utils/api/orderhistory/orderhistory";

export const customeTheme = createTheme({
  palette: {
    emerald: {
      main: "#23AA91",
      contrastText: "#fff",
    }
  }
});

declare module "@mui/material/FormControl" {
  interface FormControlPropsColorOverrides {
    emerald: true;
  }
}

export default function AdminPrinting(props: any) {
  const rowsPerPage = 5;
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);

  const [patchList, setPatchList] = useState<any[]>([]);
  const [message, setMessage] = useState<string | undefined>("");

  // 団体絞り込みのためのステータス管理
  const [schoolName, setSchoolName] = useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setSchoolName(event.target.value as string);
  };

  // APIのパッチ用
  const patchOrderMutation = useMutation(patchOrder, {
    onSuccess: (data, variables, context) => {
      if (data.status===200) {
        console.log("Success patchOrderMutation");
        window.location.reload();
      } else {
        setMessage("Error");
      }
    },
    onError: (error, variables, context) => {
      setMessage("Error: " + error);
    },
  });
  const dmUseForm = useForm<orderHistory>({
    mode: "onBlur",
  });
  const onSubmit: SubmitHandler<any> = () => {
    // パッチ処理
    setMessage("");
    patchList.map((item: any) => patchOrderMutation.mutate(item));
  };

  // APIでorderHistory取得
  const getOrderHistoryResponse = useQuery<orderHistory[], Error>(["getOrderHistory", "photo", "", "", "", "", "", ""],getOrderHistory);
  const getOrderResponse = useQuery<orderHistory[], Error>(["getOrder", "", "", "", "", "", "入金済み,印刷待ち"],getOrder);
  if (getOrderHistoryResponse.isLoading || getOrderResponse.isLoading) {return <span>Loading...</span>;}
  if (getOrderHistoryResponse.isError) {return <span>Error: {getOrderHistoryResponse.error?.message}</span>;}
  if (getOrderResponse.isError) {return <span>Error: {getOrderResponse.error?.message}</span>; }
  const orderResponseData: any = getOrderResponse.data;
  const orderHistoryData: any = getOrderHistoryResponse.data;

  // 一括配送のみにフィルター
  const orderData = orderHistoryData.filter((x: any) => ((x.shipping_method === '一括配送前払い')||(x.shipping_method === '一括配送集金')));
  const orderResponseDataTmp: any = orderResponseData.filter((x: any) => ((x.shipping_method === '一括配送前払い')||(x.shipping_method === '一括配送集金')));

  // ステータスで絞り込み
  const paidData: any = orderData.filter((x: any) => (x.order_status === '入金済み'));
  const printWaitData: any = orderData.filter((x: any) => (x.order_status === '印刷待ち'));
  const patchData: any = orderResponseDataTmp.filter((x: any) => (x.order_status === '入金済み'));

  // 団体のリストを取得
  const schoolListTmp: string[] = []
  orderData.map((props: any) => {
    schoolListTmp.push(props.school_name);
  })
  const schoolList = Array.from(new Set(schoolListTmp))

  const HEAD = [{
    'order_status':'ステータス',
    'order_id':'注文番号',
    'order_date':'注文日',
    'user_name':'ご注文者',
    'school_name':'団体名',
    'amount':'注文枚数',
    'delivery_class':'配送クラス',
  }];

  return (
    <>
      <form onSubmit={dmUseForm.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="baseline">
          <Grid item xs={12}>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
              <Stack direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
                <CustomButton color="emerald" variant="contained">
                  一括配送
                </CustomButton>
                <CustomButton color="emerald" variant="outlined" href="/admin-printing-individual-delivery">
                  自宅配送
                </CustomButton>
              </Stack>
              <FormControl size="small" color="emerald">
                <InputLabel id="label" sx={{color:"#23AA91"}}>団体名</InputLabel>
                <Select
                  value={schoolName}
                  label="団体名"
                  labelId="label"
                  onChange={handleChange}
                  sx={{
                    width:'500px',
                    background: "#FFFFFF",
                    select: {
                      '&:before': {
                        backgroundColor: "#23AA91"
                      },
                      '&:after': {
                        backgroundColor: "#23AA91"
                      }
                    },
                    "&:hover": {
                      "&& fieldset": { border: "2px solid #23AA91" }
                    }
                  }}
                >
                  {schoolList.map((prop: any) => {
                    return (
                      <MenuItem value={prop}>
                        {prop}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <h2>注文履歴の未印刷一覧</h2>
            <AdminPrintingTable1
              schoolName={schoolName}
              tableHead={HEAD}
              tableData={paidData.filter((x: any) => (x.school_name === schoolName))}
              page={page1}
              rowsPerPage={rowsPerPage}
              patchList={patchList}
              setPatchList={setPatchList}
              patchData={patchData}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
              <Stack direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
                { paidData.filter((x: any) => (x.school_name === schoolName)).length ? (
                    <div>
                      全{paidData.filter((x: any) => (x.school_name === schoolName)).length}件中{(page1 - 1) * rowsPerPage + 1}件〜{Math.min(paidData.filter((x: any) => (x.school_name === schoolName)).length, (page1 - 1) * rowsPerPage + rowsPerPage)}件を表示
                    </div>
                  ) : (
                    <div>
                      全0件中0件〜0件を表示
                    </div>
                )}
                <Pagination
                  page={page1}
                  count={Math.ceil(paidData.filter((x: any) => (x.school_name === schoolName)).length/rowsPerPage)}
                  shape='circular'
                  color='emerald'
                  onChange={(e, page) => setPage1(page)}
                />
              </Stack>
              <CustomButton color="emerald" variant="contained" type="submit">
                印刷
              </CustomButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <span>{message}</span>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <h2>印刷待ち一覧</h2>
            <AdminPrintingTable2
              tableHead={HEAD}
              tableData={printWaitData.filter((x: any) => (x.school_name === schoolName))}
              page={page2}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
          <Grid item>
            { printWaitData.filter((x: any) => (x.school_name === schoolName)).length ? (
                <div>
                  全{printWaitData.filter((x: any) => (x.school_name === schoolName)).length}件中{(page2 - 1) * rowsPerPage + 1}件〜{Math.min(printWaitData.filter((x: any) => (x.school_name === schoolName)).length, (page2 - 1) * rowsPerPage + rowsPerPage)}件を表示
                </div>
              ) : (
                <div>
                  全0件中0件〜0件を表示
                </div>
            )}
          </Grid>
          <Grid item>
            <Pagination
              page={page2}
              count={Math.ceil(printWaitData.filter((x: any) => (x.school_name === schoolName)).length/rowsPerPage)}
              shape='circular'
              color='emerald'
              onChange={(e, page) => setPage2(page)}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}