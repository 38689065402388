import { Suspense } from "react";
import { useSelector } from "react-redux";
import { RootState } from "index";
import Grid from "@mui/material/Grid";

import GetOrderHistorySP from "components/CustomTableOrderHistory/GetOrderHistorySP";
import {
  CONSUMERHEAD,
  ORDERSTATUS,
  getChipColorByOrderstate,
} from "./OrderHistoryDataSet";

export default function OrderHistoryConsumerCard(props: any) {
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // ここを編集
  const filterKey: string = "order_status";
  const sortKey: string = "";
  const filter: string = "ステータス";
  const urlKey: string = "order_id";
  const filterArray: string[] = ORDERSTATUS;
  const group_key: string =
    loginAccount.accountType === "organization" ? "organization" : "user";
  const { query } = props;

  console.log("query: " + query);
  console.log("query2: " + query);
  console.log("group_key: " + group_key);

  return (
    <Grid
      container
      rowSpacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
    >
      <Suspense fallback={<p>Loading...</p>}>
        <GetOrderHistorySP
          filterKey={filterKey}
          sortKey={sortKey}
          filter={filter}
          urlKey={urlKey}
          filterArray={filterArray}
          TABLEHEAD={CONSUMERHEAD}
          query={query}
          group_key={group_key}
          chipFn={getChipColorByOrderstate}
        />
      </Suspense>
    </Grid>
  );
}
