// 保育園用views + アルファ
import AdminUsers from "views/AdminUsers/AdminUsers";
import AdminSchools from "views/AdminSchools/AdminSchools";
import AdminPrinting from "views/AdminPrinting/AdminPrinting";
import AdminPrintingIndividualDelivery from "views/AdminPrinting/AdminPrintingIndividualDelivery";
import OrderHistoryAdmin from "views/OrderHistory/OrderHistoryAdmin";
import OrderDetailsAdmin from "views/Order/OrderDetailsAdmin";
import Schools from "views/Schools/Schools";
import Admin from "views/Admin/Admin";
import DownloadPaymentInfo from "views/DownloadPaymentInfo/DownloadPaymentInfo";
import Logout from "views/Logout/Logout";
import AlbumEdit from "views/AlbumEdit/AlbumEdit";
import UserRegistFlyer from "views/UserRegistFlyer/UserRegistFlyer";

export const adminRouteArray = [
  {
    id: "31",
    path: "/admin",
    element: <Admin />,
  },
  {
    id: "32",
    path: "/admin-order-history",
    element: <OrderHistoryAdmin />,
  },
  {
    id: "33",
    path: "/admin-printing",
    element: <AdminPrinting />,
  },
  {
    id: "34",
    path: "/admin-users",
    element: <AdminUsers />,
  },
  {
    id: "35A",
    path: "/admin-schools",
    element: <AdminSchools />,
  },
  {
    id: "35B",
    path: "/admin-schools/:SchoolId",
    element: <Schools />,
  },
  {
    id: "36",
    path: "/admin-printing-individual-delivery",
    element: <AdminPrintingIndividualDelivery />,
  },
  {
    id: "40A",
    path: "/admin-order-history",
    element: <OrderHistoryAdmin />,
  },
  {
    id: "40B",
    path: "/admin-order-history/:school_id",
    element: <OrderHistoryAdmin />,
  },
  {
    id: "40C",
    path: "/admin-order-history/:school_id/:album_id",
    element: <OrderHistoryAdmin />,
  },
  {
    id: "40D",
    path: "/admin-order-history/:school_id/:album_id/:orderId",
    element: <OrderDetailsAdmin />,
  },
  {
    id: "42",
    path: "/admin-camera-albums",
    element: <AdminSchools />,
  },
  {
    id: "43",
    path: "/admin-camera-albums/:SchoolId",
    element: <AlbumEdit />,
  },
  {
    id: "45",
    path: "/admin-download-payment-info",
    element: <DownloadPaymentInfo />,
  },
  {
    id: "46",
    path: "/user-regist-flyer/:SchoolId",
    element: <UserRegistFlyer />,
  },
  {
    id: "47",
    path: "/admin-order/:orderId",
    element: <OrderDetailsAdmin />,
  },
  {
    id: "97",
    path: "/admin-logout",
    element: <Logout accountType="admin" />,
  },
];
