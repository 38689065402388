import React from "react";
import { Dialog, DialogContent, Button, Grid, Box } from "@mui/material";
import SquareThumbnail from "../Cart/SquareThumbnail";
import CloseIcon from "@mui/icons-material/Close";
import Watermark from "react-awesome-watermark";
import cover from "../../assets/img/cover2.png";

export const PhotoMordal = (props: any) => {
  const { url, thumbnail, alt } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onClick = (e: any, isRight?: boolean) => {
    e.preventDefault();
    return false;
  };
  const useWindowDimensions = () => {
    const getWindowDimensions = () => {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    };

    const [windowDimensions, setWindowDimensions] = React.useState(
      getWindowDimensions()
    );
    React.useEffect(() => {
      const onResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }, []);
    return windowDimensions;
  };
  const window_width = useWindowDimensions().width;
  const watermark_width = window_width >= 500 ? 500 : window_width - 100;
  const watermark_height =
    window_width >= 500 ? 350 : ((watermark_width - 25) * 350) / 500;
  // console.debug(
  //   `watermark_width: ${watermark_width}, watermark_height: ${watermark_height}`
  // );
  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          ":hover": {
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
            transition: "box-shadow .15s",
          },
        }}
      >
        <SquareThumbnail photoSrc={thumbnail} />
      </Box>
      <Dialog onClose={handleClose} open={open}>
        <DialogContent>
          <Grid container justifyContent="center">
            <img
              src={url}
              alt={alt}
              width="100%"
              style={{ zIndex: 2 }}
              className="photoGuard"
            />
            <img
              src={cover}
              alt={alt}
              height="65%"
              onContextMenu={(e) => onClick(e, true)}
              style={{ zIndex: 3, position: "absolute" }}
            />
            <Button
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
              sx={{ marginTop: "1em" }}
            >
              画像を閉じる
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
