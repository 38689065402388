import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import AdminSchoolsTable from './AdminSchoolsTable';
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getSchools, deleteSchool } from "utils/api/schools/adminSchools";
import { DeleteDialog, DeleteDialogProps } from 'components/DeleteDialog/DeleteDialog'

export default function AdminSchools(props: any) {
  const rowsPerPage = 10
  const [page, setPage] = useState(1)
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [message, setMessage] = useState<string | undefined>("");
  const [modalConfig, setModalConfig] = useState<DeleteDialogProps | undefined>()
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  // API:DELETE
  const deleteListMutation = useMutation(deleteSchool, {
    onSuccess: (data, variables, context) => {
      if (data.status===200) {
        console.log("Success deleteListMutation");
        window.location.reload();
      } else {
        setMessage("Error");
      }
    },
    onError: (error, variables, context) => {
      setMessage("Error: " + error);
    },
  });

  // API:GET
  const query: schoolsReturnValue = {};
  const { isLoading, isError, data, error } = useQuery<schoolsReturnValue[], Error>(
    ["getSchools",
      query.school_id,
      query.school_name,
      query.phone_number,
      query.zip,
      query.address,
      query.update_date
    ],
    getSchools
  );
  if (isLoading) { return <span>Loading...</span>; }
  if (isError) { return <span>Error: {error?.message}</span>; }
  const userData: any = data;

  // 削除ボタンが押された時の関数
  const handleDeleteClick = async () => {
    const ret = await new Promise<string>((resolve) => {
      setModalConfig({
        onClose: resolve,
        title: '削除します。よろしいですか?',
        message: '削除すると元に戻せません。'
      })
    })
    setModalConfig(undefined)
    if (ret==="ok"){
      deleteList?.map((item: any) => deleteListMutation.mutate(item));
    }
  }

  // 検索欄への入力値をハンドリング
  const searchHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSearchResult(search(e.target.value, userData));
    setIsFirstTime(false);
    setPage(1);
  };

  // 検索する関数
  type schoolInfo = {
    school_id: string;
    school_name: string;
    phone_number: string;
    zip: string;
    address: string;
    update_date: string;
  };
  const search = (value: string, data:any[]) => {
    if (value !== "") {
      const filteredList = data?.filter((userInfoList: schoolInfo) =>
        Object.values(userInfoList).some((item: string) =>
          item !== undefined &&
          item !== null &&
          item?.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1)
      );
      return filteredList;
    }
    return data;
  };

  // テーブルヘッドの定義
  const HEAD = [{
    'school_name':'団体名',
    'phone_number':'電話番号',
    'zip':'郵便番号',
    'address':'住所',
    'update_date':'更新日時',
  }];

  return (
    <>
      <Grid container rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={12}>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
            <h2>団体一覧</h2>
            <CustomButton color="emerald" variant="contained" onClick={handleDeleteClick}>
              削除
            </CustomButton>
          </Stack>
          {modalConfig && <DeleteDialog {...modalConfig} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="検索"
            variant="outlined"
            size="small"
            color="emerald"
            value={searchValue}
            onChange={searchHandleChange}
            sx={{background:"#FFFFFF", select:{'&:before':{backgroundColor:"#23AA91" },'&:after':{backgroundColor:"#23AA91"}},"&:hover":{"&& fieldset":{border:"2px solid #23AA91"}}}}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <span>{message}</span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isFirstTime
            ? <AdminSchoolsTable tableHead={HEAD} tableData={userData} page={page} rowsPerPage={rowsPerPage} deleteList={deleteList} setDeleteList={setDeleteList}/>
            : <AdminSchoolsTable tableHead={HEAD} tableData={searchResult} page={page} rowsPerPage={rowsPerPage} deleteList={deleteList} setDeleteList={setDeleteList}/>
          }
        </Grid>
        <Grid item>
          {isFirstTime
            ? (userData.length
              ? ( <div>全{userData.length}件中{(page - 1) * rowsPerPage + 1}件〜{Math.min(userData.length, (page - 1) * rowsPerPage + rowsPerPage)}件を表示</div>)
              : (<div>全0件中0件〜0件を表示</div>)
            )
            : (searchResult.length
              ? ( <div>全{searchResult.length}件中{(page - 1) * rowsPerPage + 1}件〜{Math.min(searchResult.length, (page - 1) * rowsPerPage + rowsPerPage)}件を表示</div>)
              : (<div>全0件中0件〜0件を表示</div>)
            )
          }
        </Grid>
        <Grid item>
          {isFirstTime
            ? <Pagination page={page} count={Math.ceil(userData.length/rowsPerPage)} shape='circular' color='emerald' onChange={(e, page) => setPage(page)}/>
            : <Pagination page={page} count={Math.ceil(searchResult.length/rowsPerPage)} shape='circular' color='emerald' onChange={(e, page) => setPage(page)}/>
          }

        </Grid>
      </Grid>
    </>
  );
}