import axios from "axios";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getSchools = async (params: any) => {
  const response = await axios.get<schoolsReturnValue[]>(BASE_URL + "schools", {
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const deleteSchool = async (params: any) => {
  const response = await axios.delete(BASE_URL + "school/" + params);
  return response;
};