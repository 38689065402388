import { AnyAction } from "redux";
import { DMHistoryActionTypes } from "../actions/DMHistoryActions";

export interface dmHistory {
  mailId: string;
  schoolId: string;
  className: string;
  title: string;
  letterBody: string;
  sendDate: string;
}

const initialState = {
  mailId: "",
  schoolId: "",
  className: "",
  title: "",
  letterBody: "",
  sendDate: "",
};

export const dmHistoryReducer = (
  state: dmHistory = initialState,
  action: AnyAction
): dmHistory => {
  switch (action.type) {
    case DMHistoryActionTypes.SELECT_DM:
      state = {
        mailId: action.payload.dmHistoryData.mail_id,
        schoolId: action.payload.dmHistoryData.school_id,
        className: action.payload.dmHistoryData.child_class_name[0],
        title: action.payload.dmHistoryData.title,
        letterBody: action.payload.dmHistoryData.letter_body,
        sendDate: action.payload.dmHistoryData.send_date,
      };
      return state;
    case DMHistoryActionTypes.DESELECT_DM:
      state = initialState;
      return state;
    default:
      return state;
  }
};
