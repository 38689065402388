import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export const StyledButton = styled(Button)({
  padding: "5px 10px",
  width: "82px",
  height: "22px",
  borderRadius: "12px",
  margin: "0px 4px",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#23AA91",
    color: "white",
  },
});

export const StyledTableContainer = styled(TableContainer)({
  background: "#FFFFFF",
  border: "1px solid #E7E7E7",
  boxSizing: "border-box",
  borderRadius: "16px",
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

export const StyledTableRowNoPointer = styled(TableRow)(({ theme }) => ({
  height: "54px",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

export const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
  textAlign: "center",
});

export const TableDataCell = styled(TableCell)({
  color: "#37474F",
  width: "10%",
  textAlign: "center",
});

export const StyledChip = styled(Chip)({
  width: "82px",
  height: "22px",
  borderRadius: "12px",
});

export const StyledTypography = styled(Typography)({
  width: "62px",
  height: "12px",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  color: "#FFFFFF",
  flex: "none",
});

export const StyledTableSortLabel = styled(TableSortLabel)({
  "& .MuiTableSortLabel-icon": {
    color: "#23AA91 !important",
  },
});
