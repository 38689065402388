import { VFC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { Navigate } from "react-router-dom";

const USER = "user";
const ORG = "organization";
const ADMIN = "admin";

type Props = {
  children: ReactNode;
  routeType: string;
};

const PrivateRoute: VFC<Props> = (props) => {
  const { children, routeType } = props;

  // ログイン状態、権限情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // ログインしていない場合はランディングページにリダイレクト
  if (
    loginAccount &&
    loginAccount.isAuthenticated &&
    loginAccount.accountType
  ) {
    // console.log("route login");
    // console.log("loginAccount.accountType:" + loginAccount.accountType);
    // console.log("routeType:" + routeType);

    // logoutの場合はページを表示
    if (window.location.pathname === "/logout") {
      return <>{children}</>;
      // routeTypeで判定、権限がない場合はログイン後ページにリダイレクト
      // 管理者は全ページを見られる
    } else if (
      loginAccount.accountType === ADMIN ||
      loginAccount.accountType === routeType
    ) {
      return <>{children}</>;
    } else if (loginAccount.accountType === ORG) {
      return <Navigate to="/school-albums" />;
    } else if (loginAccount.accountType === USER) {
      return <Navigate to="/albums" />;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
