// template
import {
  LandingTemplate,
  LandingNonheaderTemplate,
} from "components/_Template/_Template";

// ログイン前views
import LoginUser from "views/Login/LoginUser";
import LoginOrg from "views/Login/LoginOrg";
import LoginAdmin from "views/Login/LoginAdmin";
import Logout from "views/Logout/Logout";
import Landing from "views/Landing/Landing";
import Terms from "views/Terms/Terms";
import Form from "views/Form/Form";
import UserAccountRegist from "views/UserAccoutRegist/UserAccountRegist";
import Partition from "views/Partition/Partition";
import DeliverySlip from "views/DeliverySlip/DeliverySlip";
import ResetPassword from "views/ResetPassword/ResetPassword";

export const logoutRouteArray = [
  {
    id: "01",
    path: "/login",
    element: <LoginUser />,
  },
  {
    id: "02",
    path: "/school/login",
    element: <LoginOrg />,
  },
  {
    id: "03",
    path: "/admin/login",
    element: <LoginAdmin />,
  },
  {
    id: "04",
    path: "/",
    element: <LandingTemplate children={<Landing />} />,
  },
  {
    id: "05",
    path: "/form",
    element: <LandingNonheaderTemplate children={<Form />} />,
  },
  {
    id: "06",
    path: "/terms",
    element: <Terms />,
  },
  // 5/5 西村追加
  {
    id: "07",
    path: "/user-account-regist/:SchoolId",
    element: <UserAccountRegist />,
  },
  {
    id: "09",
    path: "/partition/:OrderId",
    element: <Partition />,
  },
  {
    id: "10",
    path: "/delivery-slip/:OrderId",
    element: <DeliverySlip />,
  },

  {
    id: "11",
    path: "/partation",
    element: <DeliverySlip />,
  },
  {
    id: "12",
    path: "/resetpassword",
    element: <ResetPassword />,
  },
];
