import * as React from "react";

import logo from "assets/logo.svg";
import "./nav.scss";

import ContactButton from "views/Landing/ContactButton";
import LoginButton from "views/Landing/LoginButton";

// @material-ui/cores
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

type Props = {
  children?: React.ReactNode;
};

export const PCNav: React.FC<Props> = ({ children }) => {
  return (
    <Box className="nav">
      <img className="logo" src={logo} alt="NICOSKET" />
      <nav aria-label="global nav" className="globalNav">
        {children}
      </nav>
    </Box>
  );
};

export const SPNav: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box className="appBar">
      <img className="logo center" src={logo} alt="NICOSKET" />

      <IconButton onClick={handleOpen} className="spButton">
        <MenuIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box className="nav">
          <nav aria-label="global nav" className="globalNav">
            {children}
          </nav>
        </Box>
      </Modal>
    </Box>
  );
};

export const LandingPCNav: React.FC<Props> = ({ children }) => {
  return (
    <Box className="landing-nav">
      {children}
      <ContactButton />
      <LoginButton />
    </Box>
  );
};

export const LandingSPNav: any = (props: any) => {
  return (
    <>
      <Box className="appBarLP">
        <IconButton
          onClick={props.handleOpen}
          className="spButton"
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Modal open={props.open} onClose={props.handleClose}>
          <Box className="nav" onClick={props.handleClose}>
            <nav aria-label="global nav">{props.children}</nav>
          </Box>
        </Modal>
      </Box>
    </>
  );
};
