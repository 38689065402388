import Button from "@mui/material/Button";

const folderEdit = (event: React.MouseEvent<HTMLElement>) => {
  alert("edit folder");
};

export default function FolderViewButton() {
  return (
    <Button
      // onClick={(e) => folderEdit(e)}
      href="/photos" //デモ用リンク
      color="emerald"
      variant="contained"
      sx={{
        borderRadius: "4px",
        padding: "5px 12px",
        width: "125px",
        fontWeight: "bold",
      }}
    >
      写真表示
    </Button>
  );
}
