import * as React from "react";

// components
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomChip from "components/CustomChip/CustomChip";

import { Link } from "react-router-dom";

export default function BreadCrumbs(props: any) {
  const breadcrumbsNew = [
    <CustomChip
      label="TOP"
      color="emerald"
      component={Link}
      to="/school-albums"
      clickable
    />,
    <CustomChip label="新規アルバム作成" />,
  ];

  const breadcrumbsEdit = [
    <CustomChip
      label="TOP"
      color="emerald"
      component={Link}
      to="/school-albums"
      clickable
    />,
    <CustomChip label={props.dfAlbumValue.albumName || "アルバム編集"} />,
  ];

  return (
    <>
      <CustomBreadcrumbs>
        {props.isNewAlbum ? breadcrumbsNew : breadcrumbsEdit}
      </CustomBreadcrumbs>
    </>
  );
}
