import Grid from "@mui/material/Grid";
import { StyledButton } from "components/Table/TableComponents";

export default function CustomTableFilter(props: any) {
  const { filterArray, filterStatus, setFilterStatus, setPage, filter } = props;

  const getVariant = (onSelected: string) =>
    filterStatus === onSelected ? "contained" : "text";

  return (
    <>
      <Grid item xs="auto">
        <div>{filter}で絞り込み：</div>
      </Grid>
      {filterArray.map((filterState: string, key: number) => {
        return (
          <Grid item key={key}>
            <StyledButton
              disableElevation
              variant={getVariant(filterState)}
              color="emerald"
              onClick={() => {
                setFilterStatus(filterState);
                setPage(1);
              }}
            >
              {filterState}
            </StyledButton>
          </Grid>
        );
      })}
    </>
  );
}
