export const getTodayDate = (now: Date): Date => {
  const yyyy = now.getFullYear();
  const mm = ("00" + (now.getMonth() + 1)).slice(-2);
  const dd = ("00" + now.getDate()).slice(-2);
  return new Date(yyyy + "/" + mm + "/" + dd);
};

export const getInOneYear = (now: Date): Date => {
  now.setFullYear(now.getFullYear() + 1);
  return now;
};

// 高田さんのalbumEdt/getDatetime.tsxをパクってきた
const date: Date = new Date();

const OneMonthLaterDate: Date = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  date.getDate()
);

// format date to YYYY-MM-DD, YYYY/MM/DD
export const formatDate = (date: Date, delimiter: string): string => {
  const formattedDate: string =
    date.getFullYear() +
    delimiter +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    delimiter +
    ("00" + date.getDate()).slice(-2);
  return formattedDate;
};

export const getCurrentDate = (): string => {
  return formatDate(date, "-");
};

export const getOneMonthLaterDate = (): string => {
  return formatDate(OneMonthLaterDate, "-");
};
