import * as React from "react";

import "./Terms.scss";

export default function Terms() {
  return (
    <div>
      <p className="strong">
        <strong>「特定商取引に関する法律」に基づく表記</strong>
      </p>
      <table className="tokuteisyou">
        <caption>
          株式会社Nico＆Peaceは、お客様が安心してお買い物いただけるよう特定商取引に基づき以下に明示します。
          ご理解いただいたうえ、ご利用お願いいたします。
        </caption>
        <tbody>
          <tr>
            <th>販売業者</th>
            <td>株式会社Nico＆Peace</td>
          </tr>
          <tr>
            <th>運営統括責任者名</th>
            <td>大和田 陽介</td>
          </tr>
          <tr>
            <th>郵便番号</th>
            <td>350-1131</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>埼玉県川越市岸町1-46-30</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>049-277-5363</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>hello@nicoandpeace.net</td>
          </tr>
          <tr>
            <th>商品販売価格</th>
            <td>販売画面に記載</td>
          </tr>
          <tr>
            <th>商品代金以外の料金の説明</th>
            <td>
              【消費税】10%
              <br />
              支払い手数料
              <br />
              ・クレジットカード: 無料
              <br />
              ・コンビニ払い: 200円
              <br />
              【配送料】全国一律: 250円
              <br />
            </td>
          </tr>
          <tr>
            <th>引き渡し時期</th>
            <td>
              ご注文日より10日間前後での発送
              <br />
              一括配送の場合、販売期間終了後10日間前後での発送
              <br />
              写真ダウンロードの場合、お支払い手続き完了後、マイページ注文履歴より直ちにご利用いただけます。
            </td>
          </tr>
          <tr>
            <th>お支払い方法（支払い期限）</th>
            <td>
              ■クレジットカード決済（VISA/MASTER/DINERS/JCB/AMEX）
              <br />
              　支払い時期: ご利用のカード会社ごとに異なります。
              <br />
              ■コンビニ決済
              <br />
              　支払い時期: ご注文日から1週間以内にお支払いください。
              <br />
              　全国の主要なコンビニエンスストア（セブンイレブン/ファミリーマート/ローソン/セイコーマート/ミニストップ）
              ​
            </td>
          </tr>
          <tr>
            <th>返品・交換など</th>
            <td>
              ■サービスの性質上、返品・返金はお受けしておりません。
              <br />
              ・商品に明らかな品質問題、注文内容と違う場合に限り、お取り替えをさせていただきます。
              <br />
              ・実物のプリント仕上がり画像は、ご購入の際の端末（スマートフォン、タブレット、パソコン）でご覧に
              <br />
              　なるイメージと異なる場合がありますので、予めご了承ください。この理由による返品・交換には応じか
              <br />
              　ねますのでご容赦ください。
              <br />
              　※お客様都合の理由に理由による返品は対応いたしかねます。
              <br />
              　※原則返金には応じかねます。
            </td>
          </tr>
          <tr>
            <th>返品期限</th>
            <td>商品出荷より7日以内にご連絡下さい。</td>
          </tr>
          <tr>
            <th>返品送料</th>
            <td>
              不良品の場合は弊社が負担いたします。
              <br />
              それ以外はお客様のご負担となります。
            </td>
          </tr>
          <tr>
            <th>キャンセルについて</th>
            <td>
              完全受注生産のため、ご注文確定後のキャンセルには応じかねます。予めご了承ください。
            </td>
          </tr>
          <tr>
            <th>返品/交換の連絡先</th>
            <td>
              株式会社Nico＆Peace
              <br />
              〒350-1131　埼玉県川越市岸町1-46-30
              <br />
              TEL: 049-277-5363　平日10:00 - 17:00
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
}
