import React from "react";
import SamplePhoto from "../../assets/sample_photo.png";
import { withStyles } from "@mui/styles";
import { Button, Grid, createStyles, Divider, Theme, Box } from "@mui/material";
import styled from "@mui/styled-engine";
import PicSizeSelector from "./PicSizeSelector";
import OrderCountsByPicSize from "./OrderCountsByPicSize";
import OrderTotalSum from "./OrderTotalSum";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { GrayButton } from "../Order/GrayButton";
import { EmptyCartCard } from "./EmptyCartCard";
import { useNavigate } from "react-router-dom";

export interface PhotoSize {
  name: string;
  displayName: string;
  sizeDetail: string;
  unitPrice: number;
  wholesalePrice?: number;
  maxCount?: number;
  count?: number;
}

export interface Photo {
  id: string;
  albumId: string;
  groupId: string;
  url: string;
  photoSizes: PhotoSize[];
}

const styles = (theme: Theme) =>
  createStyles({
    stickyButtonWrapper: {
      position: "sticky",
      bottom: "8px",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "16px",
      margin: "0 0 12px",
    },
    card: {
      background: "#FFFFFF",
      border: "1px solid #E7E7E7",
      boxSizing: "border-box",
      borderRadius: "8px",
      padding: 0,
      marginBottom: "24px",
    },
  });

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const getTotal = (photos: Photo[]) => {
  return photos.reduce(
    (total: number, photo) =>
      total +
      photo.photoSizes.reduce(
        (subtotal: number, size) => subtotal + (size.count ? size.count : 0),
        0
      ),
    0
  );
};

const Cart = (props: any) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const cart: any = useSelector<RootState>((state) => state.cartReducer);
  const deleteButtonStyle = {
    backgroundColor: "#F5F5F5",
    fontSize: "12px",
    color: "#DE5F5F",
    fontWeight: 700,
  };
  const handleDeleteButtonClick = (photoId: string) => {
    dispatch({
      type: "DELETE_PHOTO",
      payload: {
        photoId,
      },
    });
  };
  const navigate = useNavigate();

  // URLを取得
  let url = new URL(window.location.href);
  // URLSearchParamsオブジェクトを取得
  let params = url.searchParams;
  const album_id = params.get("album_id");

  return (
    <div className={classes.wrapper}>
      {cart.length > 0 ? (
        <>
          <Grid container columnSpacing={"24px"}>
            <Grid item xs={12} sm={12} md={4} order={{ xs: 1, sm: 2 }}>
              <h3 className={classes.h3}>ご注文点数</h3>
              <div className={classes.card}>
                <OrderTable />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} order={{ xs: 2, sm: 1 }}>
              <h3 className={classes.h3}>写真サイズ</h3>
              <div className={classes.card}>
                <Grid
                  container
                  columnSpacing={"20px"}
                  justifyContent="flex-start"
                  sx={{ padding: "20px 20px 0" }}
                >
                  {cart.map((photo: Photo) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{ marginBottom: "20px" }}
                      key={photo.id}
                    >
                      <PicSizeSelector photo={photo} />
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button
                            style={deleteButtonStyle}
                            size="small"
                            onClick={(event) =>
                              handleDeleteButtonClick(photo.id)
                            }
                          >
                            削除
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div className={classes.stickyButtonWrapper}>
            <NextButton
              fullWidth
              disabled={getTotal(cart) === 0}
              component={Link}
              to={`/order?album_id=${album_id}`}
            >
              ご注文手続きに進む
            </NextButton>
          </div>
        </>
      ) : (
        <EmptyCartCard />
      )}
      <Grid container sx={{ marginTop: "20px" }} justifyContent="space-between">
        <GrayButton component={Link} to="/albums">
          他のアルバムも見る
        </GrayButton>
        <GrayButton nextArrow component={Link} to={`/photos/group/${album_id}`}>
          他の写真も見る
        </GrayButton>
      </Grid>
    </div>
  );
};

const getOrderCountsByPicSize = (photos: Photo[]): any => {
  const photoSizes = photos.reduce((keys: any, photo: Photo) => {
    const keysNew = { ...keys };
    for (const photoSize of photo.photoSizes) {
      // keysNewにphotoSize.nameが入ってなかった場合も動くように書いている
      const key = keysNew[photoSize.name] || { count: 0, subtotal: 0 };
      keysNew[photoSize.name] = {
        name: photoSize.name,
        displayName: photoSize.displayName,
        sizeDetail: photoSize.sizeDetail,
        count: key.count + photoSize.count,
        subtotal: key.subtotal + photoSize.unitPrice * (photoSize.count || 0),
      };
    }
    return keysNew;
  }, {});

  return Object.values(photoSizes);
};

const OrderTable = ({ ...props }: any) => {
  const cart: any = useSelector<RootState>((state) => state.cartReducer);
  const orderCountsByPicSize = getOrderCountsByPicSize(cart);
  const subtotal = orderCountsByPicSize.reduce((sum: number, prop: any) => {
    return sum + prop.subtotal;
  }, 0);

  const cardBodyStyle = {
    padding: "1.25rem",
  };
  return (
    <>
      <div style={cardBodyStyle}>
        <OrderCountsByPicSize orderCountsByPicSize={orderCountsByPicSize} />
      </div>
      <Divider sx={{ borderBottomWidth: "2px" }} />
      <div style={cardBodyStyle}>
        <OrderTotalSum subtotal={subtotal} fee={0} />
      </div>
    </>
  );
};

export default withStyles(styles)(Cart);
