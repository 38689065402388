import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import {
  Button,
  Grid,
  createStyles,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  Theme,
  Card,
  CardContent,
  Box,
  CircularProgress,
} from "@mui/material";
import OrderCountsByPicSize from "../Cart/OrderCountsByPicSize";
import { Photo, PhotoSize } from "../Cart/Cart";
import OrderTotalSum from "./OrderTotalSum";
import { Link, useNavigate } from "react-router-dom";
import styled from "@mui/styled-engine";
import { GrayButton } from "./GrayButton";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../..";
import { useMutation, useQuery } from "react-query";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getUsersByUserId } from "utils/api/users/users";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getSchools } from "utils/api/schools/schools";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { getClasses } from "utils/api/classes/classes";
import { ordersPostValue } from "utils/interface/orders/ordersInterface";
import {
  calculateFee,
  containCommission,
  containPostage,
  formatDate,
  getOrderDetails,
  getTotal,
  paymentMethodMap,
} from "./utils";
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from "components/ConfirmDialog/ConfirmDialog";
import { postOrder } from "utils/api/orders/orders";
import formatPhoneNumber from "utils/function/formatPhoneNumber";
import { GMOEpsilonPostValue } from "utils/interface/GMOEpsilon/GMOEpsilonInterface";
import { postGMOEpsilonPayment } from "utils/api/GMOEpsilon/GMOEpsilon";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      marginRight: "48px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    bold: {
      fontWeight: 700,
    },
    ordererUl: {
      padding: 0,
      margin: 0,
    },
    ordererLi: {
      fontWeight: 700,
      listStyleType: "none",
      "& + $ordererLi": {
        marginTop: "1em",
      },
    },
    formControlLabel: {
      fontSize: "14px",
      "& label": { fontSize: "14px" },
    },
  });

const OrderCard = (props: any) => {
  const { children } = props;
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: "20px",
      }}
    >
      <CardContent sx={{ padding: "20px" }}>{children}</CardContent>
    </Card>
  );
};

const Section = (props: any) => {
  const { title, children } = props;
  return (
    <Box>
      <h3>
        <Box sx={{ fontSize: "16px", marginBottom: "12px" }}>{title}</Box>
      </h3>
      <OrderCard>{children}</OrderCard>
    </Box>
  );
};

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const isNotOnlyDL = (photos: Photo[]): boolean => {
  const photoSizes = photos.reduce((keys: any, photo: Photo) => {
    const keysNew = { ...keys };
    for (const photoSize of photo.photoSizes) {
      const key = keysNew[photoSize.name] || { count: 0 };
      keysNew[photoSize.name] = {
        count: key.count + photoSize.count,
      };
    }
    return keysNew;
  }, {});
  if (photoSizes.L && photoSizes["2L"]) {
    return photoSizes.L.count + photoSizes["2L"].count > 0;
  } else if (photoSizes.L) {
    return photoSizes.L.count > 0;
  } else if (photoSizes["2L"]) {
    return photoSizes["2L"].count > 0;
  } else {
    return false;
  }
};

const getOrderCountsByPicSize = (photos: Photo[]): any => {
  const photoSizes = photos.reduce((keys: any, photo: Photo) => {
    const keysNew = { ...keys };
    for (const photoSize of photo.photoSizes) {
      const key = keysNew[photoSize.name] || { count: 0, subtotal: 0 };
      keysNew[photoSize.name] = {
        name: photoSize.name,
        displayName: photoSize.displayName,
        sizeDetail: photoSize.sizeDetail,
        count: key.count + photoSize.count,
        subtotal: key.subtotal + photoSize.unitPrice * (photoSize.count || 0),
      };
    }
    return keysNew;
  }, {});

  return Object.values(photoSizes);
};

const OrderConfirm = (props: any) => {
  const { classes } = props;

  const dispatch = useDispatch();
  const registDmTargetOrderId = (orderId: any) => {
    dispatch({
      type: "REGIST_ORDERID",
      payload: {
        orderId,
      },
    });
  };

  const navigate = useNavigate();

  const cart: any = useSelector<RootState>((state) => state.cartReducer);
  const orderCountsByPicSize = getOrderCountsByPicSize(cart);

  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const usersQueryResult = useQuery<usersReturnValue[], Error>(
    [null, loginAccount.accountId],
    getUsersByUserId
  );
  const user = usersQueryResult.data ? usersQueryResult.data[0] : {};

  const schoolsQueryResult = useQuery<schoolsReturnValue[], Error>(
    [null, user.school_id],
    getSchools
  );
  const school = schoolsQueryResult.data ? schoolsQueryResult.data[0] : {};
  const subtotal = orderCountsByPicSize.reduce((sum: number, prop: any) => {
    return sum + prop.subtotal;
  }, 0);
  const order: any = useSelector<RootState>((state) => state.orderReducer);
  const fee = calculateFee(
    school.commission,
    school.postage,
    order.paymentMethod,
    isNotOnlyDL(cart),
    school.shipping_method
  );

  const postOrderMutation = useMutation(postOrder, {
    onSuccess: async (res) => {
      // console.log("Success");
      // console.log(res.data);
      if (res.data.result === "1" || res.data.result === 1) {
        // 注文完了メール送信対象とする
        registDmTargetOrderId(res.data.order_id);

        const id = encodeURIComponent(loginAccount.accountId).replace(
          ".",
          "%2E"
        );

        if (res.data.conveni_code) {
          //コンビニ払い
          navigate(
            `/order-completed?conveni_nextstep=${res.data.nextstep}'&conveni_code=${res.data.conveni_code}&user_id=${id}&order_number=${res.data.order_id}&receipt_no=${res.data.receipt_no}&kigyou_code=${res.data.kigyou_code}`
          );
        } else if (res.data.nextstep) {
          //クレジット払い
          window.location.href = decodeURIComponent(res.data.nextstep);
        } else {
          //現金集金
          navigate(
            `/order-completed?user_id=${id}&order_number=${res.data.order_id}&collect_flag=1`
          );
        }
      } else {
        console.log("Failed Request");
      }
    },
    onError: async (res) => {
      console.log("Failure");
    },
  });
  const [modalConfig, setModalConfig] = useState<
    ConfirmDialogProps | undefined
  >();
  const onSubmit = async () => {
    var ret = "ok";
    if (
      order.paymentMethod == "card" &&
      school.shipping_method != "一括配送集金"
    ) {
      ret = await new Promise<string>((resolve) => {
        setModalConfig({
          onClose: resolve,
          title: "別サイトのクレジットカード入力画面に遷移します。",
          message:
            "別サイトのクレジットカード入力画面に遷移します。入力前に画面を閉じないようにお願い致します。",
        });
      });
      setModalConfig(undefined);
    }
    if (ret === "ok") {
      const data = {
        user_id: user.user_id,
        school_id: user.school_id,
        order_status:
          school.shipping_method === "一括配送集金"
            ? isNotOnlyDL(cart)
              ? "入金済み"
              : "入金済みDL"
            : "手続き中",
        taxed_price: (subtotal + fee).toString(),
        order_price: (subtotal + fee).toString(),
        amount: getTotal(cart).toString(),
        shipping_method: school.shipping_method,
        commission: containCommission(
          order.paymentMethod,
          school.shipping_method
        )
          ? school.commission
          : 0,
        postage: containPostage(isNotOnlyDL(cart), school.shipping_method)
          ? school.postage
          : 0,
        delivery_class: order.deliveryClassName,
        order_date: formatDate(new Date()),
        order_details: getOrderDetails(cart),
        del_flg: "0",
        payment:
          school.shipping_method !== "一括配送集金" ? order.paymentMethod : "",
        conveni_code:
          school.shipping_method !== "一括配送集金" &&
          order.paymentMethod === "conveni"
            ? order.conveniCode
            : "",
      };
      await postOrderMutation.mutate(data);
    }
  };

  // URLを取得
  let url = new URL(window.location.href);
  // URLSearchParamsオブジェクトを取得
  let params = url.searchParams;
  const album_id = params.get("album_id");

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={"24px"}>
        <Grid item xs={12} md={12}>
          <Section title="ご注文者">
            <ul className={classes.ordererUl}>
              <li className={classes.ordererLi}>{user.user_name}</li>
              <li className={classes.ordererLi}>{user.address}</li>
              <li className={classes.ordererLi}>
                {formatPhoneNumber(user.phone_number)}
              </li>
              <li className={classes.ordererLi}>{user.user_id}</li>
            </ul>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section title="配送方法">
            <span className={classes.bold}>
              {isNotOnlyDL(cart)
                ? school.shipping_method === "自宅配送"
                  ? "ご自宅へ配送します。"
                  : "所属の団体様へ配送します。"
                : "注文詳細画面からダウンロード"}
            </span>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          {school.shipping_method !== "自宅配送" && (
            <Section title="お届け先クラス">
              <span className={classes.bold}>{order.deliveryClassName}</span>
            </Section>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Section title="お支払い方法">
            {school.shipping_method !== "一括配送集金" ? (
              <span className={classes.bold}>
                {paymentMethodMap.get(order.paymentMethod)}
              </span>
            ) : (
              <span className={classes.bold}>
                現金で団体様にお支払いください。
              </span>
            )}
          </Section>
        </Grid>
        {order.paymentMethod === "conveni" && (
          <Grid item xs={12} md={12}>
            <Section title="お支払いのコンビニ">
              <span className={classes.bold}>{order.conveniName}</span>
            </Section>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Section title="ご注文点数">
            <OrderCountsByPicSize orderCountsByPicSize={orderCountsByPicSize} />
          </Section>
          <OrderCard>
            <OrderTotalSum subtotal={subtotal} fee={fee} />
            <span>
              <br />
              ※現像時に上下左右が数ミリカットされます。
              <br />
              　ハイビジョン等、細長い比率のお写真は両サイドが大きくカットされます。予めご了承ください。
              <br />
            </span>
          </OrderCard>
        </Grid>
      </Grid>
      <NextButton
        fullWidth
        onClick={onSubmit}
        disabled={postOrderMutation.isLoading}
      >
        {postOrderMutation.isLoading && (
          <CircularProgress sx={{ marginRgiht: "0.3em" }} size="1em" />
        )}
        注文を確定する
      </NextButton>
      {modalConfig && <ConfirmDialog {...modalConfig} />}
      <Box sx={{ marginTop: "20px" }}>
        <GrayButton component={Link} to={`/order?album_id=${album_id}`}>
          戻る
        </GrayButton>
      </Box>
    </div>
  );
};

export default withStyles(styles)(OrderConfirm);
