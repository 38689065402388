import { AnyAction } from "redux";
import { PhotoGroupActionTypes } from "../actions/PhotoGroupActions";

export interface PhotoGroup {
  albumId: string;
  albumName: string;
  groupName: string;
}

const initialState = {
  albumId: "",
  albumName: "",
  groupName: "",
};

export const photoGroupReducer = (
  state: PhotoGroup = initialState,
  action: AnyAction
): PhotoGroup => {
  switch (action.type) {
    case PhotoGroupActionTypes.REGIST_PHOTOGROUP:
      state = {
        albumId: action.payload.albumId,
        albumName: action.payload.albumName,
        groupName: action.payload.groupName,
      };
      return state;
    default:
      return state;
  }
};
