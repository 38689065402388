import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useState } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CustomCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";

const StyledTableContainer = styled(TableContainer)({
  background: "#FFFFFF",
  border: "1px solid #E7E7E7",
  boxSizing: "border-box",
  borderRadius: "16px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
});

const StyledChip = styled(Chip)({
  width: "82px",
  height: "22px",
  borderRadius: "12px",
});

const StyledTypography = styled(Typography)({
  width: "62px",
  height: "12px",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  color: "#FFFFFF",
  flex: "none",
});

const getChipColor = (status: string): any => {
    if (status === "入金済み") {
      return "emerald";
    } else if (status === "印刷待ち") {
      return "lime";
    }
  };

export default function AdminPrintingTable({ ...props }: any) {
  const { tableHead, tableData, page, rowsPerPage, patchList, setPatchList, patchData } = props;

  // patchListを初期化
  const initialPachData:any = [];
  for (var i=0; i<patchData.length; i++) {
    let orderItem: any = Object.assign({}, patchData[i]);
    orderItem.order_status = "印刷待ち";
    initialPachData.push(orderItem);
  }
  useEffect(() => { setPatchList(initialPachData) }, [])

  // 表示するデータ
  const viewTableData = tableData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

  // CheckBoxにチェックされた行データを保持するための関数
  const useRowSelect = (rowIds: string[], initialSelectedRowIds: string[]): {
    selectedRowIds: string[];
    isSelected: (rowId: string) => boolean;
    toggleSelected: (id: string) => void;
  } => {
      const [selectedRowIds, setSelectedRowIds] = useState<string[]>(initialSelectedRowIds);
      const isSelected = (rowId: string) => selectedRowIds.includes(rowId);
      const toggleSelected = (rowId: string) => {
        if (isSelected(rowId)){
          setSelectedRowIds(selectedRowIds.filter((selectedId) => selectedId !== rowId))
          setPatchList(patchList.filter((item: any) => item.order_id !== rowId));
        }
        else{
          setSelectedRowIds([...selectedRowIds, rowId]);
          let patchDataFilter: any = patchData.filter((item: any) => item.order_id === rowId);
          let orderItem: any = Object.assign({}, patchDataFilter[0]);
          orderItem.order_status = "印刷待ち";
          setPatchList([...patchList, orderItem]);
        }
      };
      return { selectedRowIds, isSelected, toggleSelected };
  };
  const {selectedRowIds, isSelected, toggleSelected} = useRowSelect(patchData.map((row: any) => row.order_id), patchData.map((row: any) => row.order_id));



  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: "54px" }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align="center">
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.order_status}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.order_id}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.order_date}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.user_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.school_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.amount}
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {viewTableData.map((prop: any, key: any) => {
            const isItemSelected = isSelected(prop.order_id);
            return (
              <StyledTableRow
                role="checkbox"
                key={prop.order_id}
                onClick={() => toggleSelected(prop.order_id)}
                selected={isItemSelected}
              >
                <TableDataCell key={key} align="center">
                  <CustomCheckBox checked={isItemSelected}/>
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  <StyledChip
                    label={<StyledTypography>{prop.order_status}</StyledTypography>}
                    color={getChipColor(prop.order_status)}
                  />
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  {prop.order_id}
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  {prop.order_date}
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  {prop.user_name}
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  {prop.school_name}
                </TableDataCell>
                <TableDataCell key={key} align="right">
                  {prop.amount}枚
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}