import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

type Props = {
  register: any;
};

export const GroupPhoneNumberView: React.FC<Props> = ({ register }) => {
  return (
    <Grid container direction="row" alignItems="flex-start">
      {/* <Grid item xs={2}>
        <CustomLabel value="連絡先" />
      </Grid> */}
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <CustomForm
            labelname="電話番号"
            placeholder="電話番号を入力してください"
            helperlabel="例：00-1234-5678"
            register={register("phone", { required: true })}
          />
        </Grid>
        <Grid container direction="row" alignItems="flex-start">
          <CustomForm
            labelname="メールアドレス"
            placeholder="メールアドレスを入力してください"
            helperlabel="例：xxx@example.com"
            register={register("mail", { required: true })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
