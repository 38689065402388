import { useState, useEffect } from "react";

// @mui
import { Grid, FormControlLabel } from "@mui/material";

// components
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomeCheckBox from "../../components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";

// apis
import { classesReturnValue } from "utils/interface/classes/classesInterface";

type Props = {
  register: any;
  dfAlbumValue: any;
  classData: classesReturnValue[];
};

export const TargetClassView: React.FC<Props> = ({ register, ...props }) => {
  const { dfAlbumValue, classData } = props;

  // チェックボックスの初期値判定
  const isChecked = (classId: string) => {
    if (!dfAlbumValue.salesClasses) {
      return false;
    } else if (dfAlbumValue.salesClasses.includes(classId)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container>
      <Grid item className="row-name" xs={2}>
        <CustomLabel value="販売するクラス" />
      </Grid>
      <Grid item className="row-content-narrow" xs={10}>
        {classData.map((prop: any, key: any) => {
          return (
            <FormControlLabel
              control={
                <CustomeCheckBox
                  register={register("salesClasses." + prop.class_id)}
                  defaultChecked={isChecked(prop.class_id)}
                />
              }
              label={prop.class_name ? prop.class_name : ""}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
