import React, { useState, useEffect } from "react";
import SamplePhoto from "../../assets/sample_photo.png";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  createStyles,
  Chip,
  Divider,
  Theme,
  Card,
  CardContent,
  CardMedia,
  Badge,
  Box,
  Typography,
} from "@mui/material";
import { Photo, PhotoSize } from "../Cart/Cart";
import { withStyles } from "@mui/styles";
import PhotoAlbumOutlinedIcon from "@mui/icons-material/PhotoAlbumOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import styled from "@mui/styled-engine";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "./BreadCrumbs";
import { useSelector } from "react-redux";
import { PhotoGrid } from "./PhotoGrid";
import { RootState } from "../..";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  photosReturnValue,
  photosSearchValues,
} from "utils/interface/photos/photosInterface";
import { getPhotos } from "utils/api/photos/photos";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";
import { getAlbums } from "utils/api/albums/albums";
import { getDisplayNameAndSizeDetail } from "utils/function/getDisplayNameAndSizeDetail";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getSchools } from "utils/api/schools/schools";

// Added by Nishimura at 2022/05/29
import { useAlubms, useUsers } from "../Albums/AlubmsApis";
import {
  removePreparingAlbums,
  filterChildclass,
  addAlbumStatus,
} from "utils/function/shapeAlbumData";
import CustomTablePagenation from "components/CustomTable/CustomTablePagenation";
const getPriceCoefficient = (sales_period_to: string): number => {
  const today = new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
  );
  const sales_period_to_date = new Date(sales_period_to);
  sales_period_to_date.setDate(sales_period_to_date.getDate() + 1);
  if (today > sales_period_to_date) {
    return 1.15;
  } else {
    return 1;
  }
};
// ここまで

const ROWSPERPAGE = 30;

const getDayCountingText = (
  sales_period_from?: string,
  sales_period_to?: string,
  shipping_method?: string
): string => {
  const today = new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
  );
  if (!sales_period_from || !sales_period_to) {
    return "";
  }
  const sales_period_from_date = new Date(sales_period_from);
  const sales_period_to_date = new Date(sales_period_to);
  sales_period_to_date.setDate(sales_period_to_date.getDate() + 1);

  if (shipping_method === "自宅配送") {
    const sales_period_to_date_extended = new Date(sales_period_to);
    sales_period_to_date_extended.setDate(
      sales_period_to_date_extended.getDate() + 1
    );
    sales_period_to_date_extended.setFullYear(
      sales_period_to_date_extended.getFullYear() + 1
    );

    if (today < sales_period_from_date) {
      return "販売開始前";
    } else if (today > sales_period_to_date_extended) {
      return "販売終了済み";
    } else if (today > sales_period_to_date) {
      const diff = sales_period_to_date_extended.getTime() - today.getTime();
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      return "延長販売中　販売終了まで" + diffDays + "日";
    }
    const diff = sales_period_to_date.getTime() - today.getTime();
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    return "販売終了まで" + diffDays + "日";
  } else {
    if (today < sales_period_from_date) {
      return "販売開始前";
    } else if (today > sales_period_to_date) {
      return "販売終了済み";
    }
    const diff = sales_period_to_date.getTime() - today.getTime();
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    return "販売終了まで" + diffDays + "日";
  }
};

const isSalesEligibility = (
  sales_period_from?: string,
  sales_period_to?: string,
  shipping_method?: string
): boolean => {
  const today = new Date(
    Date.now() + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
  );
  if (!sales_period_from || !sales_period_to) {
    return false;
  }
  const sales_period_from_date = new Date(sales_period_from);
  const sales_period_to_date = new Date(sales_period_to);
  sales_period_to_date.setDate(sales_period_to_date.getDate() + 1);

  if (shipping_method === "自宅配送") {
    sales_period_to_date.setFullYear(sales_period_to_date.getFullYear() + 1);
  }

  if (today < sales_period_from_date) {
    return false;
  } else if (today > sales_period_to_date) {
    return false;
  }
  return true;
};

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      fontSize: "12px",
      minWidth: "876px",
      [theme.breakpoints.down("md")]: {
        minWidth: "576px",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "unset",
      },
    },
    albumTitle: {
      margin: 0,
    },
    stickyButtonWrapper: {
      position: "sticky",
      marginBottom: "40px",
      bottom: "8px",
    },
    badge: {
      backgroundColor: theme.palette.ruby.main,
    },
  });

const StyledChip = styled(Chip)({
  width: "80px",
  height: "20px",
  borderRadius: "12px",
  marginBottom: "8px",
});

const StyledTypography = styled(Typography)({
  fontSize: "12px",
  color: "#FFFFFF",
});

const getChipColor = (status: any): any => {
  if (status === "販売中") {
    return "emerald";
  } else if (status === "延長販売") {
    return "lime";
  } else {
    return "neutral";
  }
};

const PhotoListConsumer = (props: any) => {
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const useInfo = useUsers(loginAccount.accountId);
  const school_id: string = useInfo === true ? "loading" : useInfo[0].school_id;

  const { classes } = props;
  const { albumId, groupName } = useParams();
  const groupPath = groupName?.split("/");
  const groupPathClamb: any = groupPath
    ? groupPath.map((path: string) => ({ label: path, color: "default" }))
    : [];

  const albumsQueryResult = useQuery<albumsReturnValue[], Error>(
    [null, albumId, null, null, null, null],
    getAlbums
  );
  let album = albumsQueryResult.data ? albumsQueryResult.data[0] : {};

  // Added by Nishimura at 2022/05/29
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", school_id],
    getSchools
  );
  const schoolInfo = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};
  // ここまで

  const schoolsQueryResult = useQuery<schoolsReturnValue[], Error>(
    [null, album.school_id],
    getSchools
  );
  const school = schoolsQueryResult.data ? schoolsQueryResult.data[0] : {};
  const wholesale_prices = {
    wholesale_price_l: school.wholesale_price_l,
    wholesale_price_2l: school.wholesale_price_2l,
    wholesale_price_dl: school.wholesale_price_dl,
  };
  console.log(album, school);
  const size_list = album.size_list ? JSON.parse(album.size_list) : [];
  const price_list = JSON.parse(album.price_list ? album.price_list : "{}");
  console.log(size_list, price_list);

  const photoSizes = size_list.map((size: string) => {
    const { displayName, sizeDetail, maxCount, wholesalePrice } =
      getDisplayNameAndSizeDetail(size, wholesale_prices);
    // 2022/10/8 takata 延長販売15%の計算後に端数を切り上げ
    return {
      name: size,
      displayName: displayName,
      sizeDetail: sizeDetail,
      unitPrice: Math.ceil(
        price_list[size] *
          getPriceCoefficient(
            album.sales_period_to ? album.sales_period_to : "2099/12/31"
          )
      ),
      wholesalePrice: wholesalePrice,
      maxCount: maxCount,
    };
  });

  const photosQueryResult = useQuery<photosReturnValue[], Error>(
    [null, null, albumId],
    getPhotos
  );

  const hasL = photoSizes.some((photoSize: any) => photoSize.name === "L");
  const has2L = photoSizes.some((photoSize: any) => photoSize.name === "2L");
  const photos = photosQueryResult.data
    ?.filter((p: photosReturnValue) => p.group_name === groupName)
    .map((p: photosReturnValue) => ({
      id: p.photo_id,
      albumId: p.album_id,
      groupId: p.group_name,
      thumbnail: p.thumbnail,
      url: p.path,
      photoSizes: photoSizes.map((photoSize: any) => ({
        ...photoSize,
        count: hasL ? (photoSize.name === "L" ? 1 : 0)
             : has2L ? (photoSize.name === "2L" ? 1 : 0)
             : 1,
      })),
    }));

  const cart: any = useSelector<RootState>((state) => state.cartReducer);

  // Added by Nishimura at 2022/05/29
  // get albumdata from api
  const albumData = useAlubms(school_id);

  let OTHER_ALBUMS = [];
  const OTHER_STATUS = ["販売中", "延長販売"];

  if (useInfo !== true && albumData !== true) {
    const useInfoArray =
      useInfo && useInfo[0]
        ? JSON.parse(useInfo[0].child_class.replaceAll("'", '"'))
        : [];

    OTHER_ALBUMS = removePreparingAlbums(albumData);
    OTHER_ALBUMS = filterChildclass(OTHER_ALBUMS, useInfoArray);
    for (let i = 0; i < OTHER_ALBUMS.length; i++) {
      OTHER_ALBUMS[i].shipping_method = school.shipping_method;
    }
    OTHER_ALBUMS = addAlbumStatus(OTHER_ALBUMS);
    OTHER_ALBUMS = OTHER_ALBUMS.filter((album: albumsReturnValue) => {
      return (
        album.sales_status &&
        OTHER_STATUS.includes(album.sales_status) &&
        album.album_id !== albumId
      );
    });
    // console.debug(OTHER_ALBUMS);
  }
  const navigate = useNavigate();
  // ここまで

  const [page, setPage] = useState(1);
  // ページ切替時にスクロール位置を画面トップにする
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const photosPagenized = photos?.slice(
    (page - 1) * ROWSPERPAGE,
    Math.min(photos?.length, page * ROWSPERPAGE)
  );

  return (
    <div className={classes.wrapper}>
      <BreadCrumbs
        breadcrumbs={[
          {
            label: "TOP",
            component: Link,
            color: "emerald",
            to: "/albums",
            clickable: true,
          },
          {
            label: album.album_name,
            component: Link,
            color: "emerald",
            to: `/photos/group/${albumId}`,
            clickable: true,
          },
        ].concat(groupPathClamb)}
      />
      <Card
        variant="outlined"
        sx={{
          marginBottom: "20px",
          borderRadius: "16px",
        }}
      >
        <CardContent sx={{ padding: "20px" }}>
          <h2 className={classes.albumTitle}>
            <Grid
              container
              rowSpacing="12px"
              columnSpacing="12px"
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid item>
                <Grid container alignItems="center">
                  <PhotoAlbumOutlinedIcon sx={{ color: "emerald.main" }} />
                  {album.album_name}
                </Grid>
              </Grid>
              {groupPath?.map((path: string) => (
                <>
                  <Grid item key={path}>
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: "16px",
                        color: "rgba(0,0,0,.4)",
                        marginLeft: "12px",
                      }}
                    />
                  </Grid>
                  <Grid item key={path}>
                    <Grid container alignItems="center">
                      <FolderIcon sx={{ color: "orange.main" }} />
                      {path}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          </h2>
        </CardContent>
        <Divider />
        <CardContent sx={{ padding: "20px" }}>
          <Grid container columnSpacing="40px" sx={{ lineHeight: "1.6em" }}>
            <Grid item>
              <Grid container columnSpacing="8px">
                <Grid item>写真枚数：</Grid>
                <Grid item>{photos ? photos.length + "枚" : ""}</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing="8px">
                <Grid item> 販売期間：</Grid>
                <Grid item>
                  {" "}
                  {album.sales_period_from}〜{album.sales_period_to}
                </Grid>
                <Grid item sx={{ color: "ruby.main" }}>
                  {" "}
                  {getDayCountingText(
                    album.sales_period_from,
                    album.sales_period_to,
                    schoolInfo.shipping_method
                  )}{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent sx={{ padding: "20px" }}>
          {isSalesEligibility(
            album.sales_period_from,
            album.sales_period_to,
            schoolInfo.shipping_method
          ) ? (
            <>
              <Grid
                container
                rowSpacing="36px"
                columnSpacing={{ xs: "16px", lg: "16px" }}
              >
                {photosPagenized &&
                  photosPagenized.map((photo) => <PhotoGrid photo={photo} />)}
              </Grid>
              <Box
                sx={{
                  marginTop: "2em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "1em",
                  rowGap: "1em",
                  flexDirection: { xs: "column", md: "row" },
                  marginLeft: { xs: "-20px", md: "0" },
                  marginRight: { xs: "-20px", md: "0" },
                }}
              >
                <CustomTablePagenation
                  tableData={photos ? photos : []}
                  page={page}
                  setPage={setPage}
                  ROWSPERPAGE={ROWSPERPAGE}
                  filterStatus={true}
                  filterArray={[true]}
                  size="small"
                />
              </Box>
            </>
          ) : (
            <span> 現在、このアルバムは販売しておりません。</span>
          )}
        </CardContent>
      </Card>
      <div className={classes.stickyButtonWrapper}>
        <NextButton
          fullWidth
          component={Link}
          to={"/cart?album_id=" + album.album_id}
          startIcon={<ShoppingCartIcon />}
          endIcon={
            <Badge
              badgeContent={cart.length}
              sx={{ marginLeft: ".5em" }}
              classes={{ badge: classes.badge }}
            ></Badge>
          }
        >
          カートに進む
        </NextButton>
      </div>
      <h2>販売中の他のアルバム</h2>
      <Grid container columnSpacing="40px" rowSpacing="20px">
        {OTHER_ALBUMS.map((album: albumsReturnValue) => (
          <Grid item xs={12} md={4}>
            <Card
              sx={{ height: "100%", minHeight: 408 }}
              onClick={() => {
                navigate("/photos/group/" + album.album_id);
              }}
            >
              <CardMedia
                component="img"
                height="219"
                image={album.thumbnail}
                alt="photo"
                className="photoGuard"
              />
              <CardContent>
                <StyledChip
                  label={
                    <StyledTypography>{album.sales_status}</StyledTypography>
                  }
                  color={getChipColor(album.sales_status)}
                />
                <h2>{album.album_name}</h2>
                <Divider sx={{ margin: "16px 0" }} />
                <Grid
                  container
                  justifyContent="space-between"
                  direction="row-reverse"
                  sx={{ lineHeight: "1.5em" }}
                >
                  <Grid item>
                    {" "}
                    {album.sales_period_from}〜{album.sales_period_to}
                  </Grid>
                  <Grid item> 販売期間：</Grid>
                  <Grid
                    item
                    sx={{ color: "ruby.main" }}
                    justifyContent="flex-end"
                  >
                    {" "}
                    {getDayCountingText(
                      album.sales_period_from,
                      album.sales_period_to,
                      schoolInfo.shipping_method
                    )}{" "}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(PhotoListConsumer);
