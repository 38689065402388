import * as React from "react";

// @mui
import { FormControl, OutlinedInput, TextField } from "@mui/material/";
import { styled } from "@mui/material/styles";

// compornents
import CustomFormLabel from "../InfoEditCustomFormLabel/InfoEditCustomFormLabel";
import CustomFormHelperLabel from "../InfoEditCustomFormHelperLabel/InfoEditCustomFormHelperLabel";

const DesignedTextField = styled(OutlinedInput)({
  background: "#FAFAFA",
  border: "1px",
  boxSizing: "border-box",
  borderRadius: "4px",
  marginTop: "10px",
  marginBottom: "8px",
});

export default function InfoEditCustomMailForm(props: any) {
  const {
    labelname,
    placeholder,
    helperlabel,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    disabled,
    type,

    // aska add 2022/03/06 for react-hook-form
    register,

    // nishimura add 2022/04/27
    value,
    onChange,
    defaultValue,
    minRows,
  } = props;
  return (
    <FormControl {...formControlProps} fullWidth>
      <CustomFormLabel value={labelname} />
      <DesignedTextField
        {...inputProps}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        type={type}
        multiline
        defaultValue={defaultValue}
        minRows={minRows}
        {...register}
      />
      <CustomFormHelperLabel value={helperlabel} />
    </FormControl>
  );
}
