import {
  TableHeadCell,
  StyledTableSortLabel,
} from "components/Table/TableComponents";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// 並べ替えロジック
type Order = "asc" | "desc";
const sortColumnsByKey = (
  o: Order,
  r: any,
  sortKey: string,
  setOrder: any,
  setRows: any
) => {
  console.debug("call!!!");
  const nextOrder = o === "desc" ? "asc" : "desc";
  const sortRule = { asc: [1, -1], desc: [-1, 1] };
  const sortedRows = r.sort((a: any, b: any) => {
    if (a[sortKey] > b[sortKey]) {
      return sortRule[nextOrder][0];
    } else if (a[sortKey] < b[sortKey]) {
      return sortRule[nextOrder][1];
    } else {
      return 0;
    }
  });
  setOrder(nextOrder);
  console.debug(setRows);
  setRows(sortedRows);
};

export const SortTableHead = ({ ...props }: any) => {
  const { headline, order, rows, sortKey, setOrder, setRows } = props;
  return (
    <>
      <TableHeadCell key={headline}>
        {headline}
        <StyledTableSortLabel
          key={sortKey}
          active={true}
          direction={order}
          IconComponent={ArrowDropDownRoundedIcon}
          onClick={() => {
            sortColumnsByKey(order, rows, sortKey, setOrder, setRows);
          }}
        />
      </TableHeadCell>
    </>
  );
};
