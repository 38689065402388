import * as React from "react";

// @mui
import { Modal, Box, Typography, LinearProgress } from "@mui/material";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean;
  message: string;
  dispCount: number;
};

export const PhotoUploadModal: React.FC<Props> = ({
  open,
  message,
  dispCount,
}) => {
  return (
    <Modal open={open} sx={{ zIndex: "9000" }}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {message}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              color="emerald"
              value={dispCount}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
              dispCount
            )}%`}</Typography>
          </Box>
        </Box>
        {/* <Typography id="modal-form-description" sx={{ mt: 2 }}>
          <a href="/school-albums">
            <CustomButton
              color="emerald"
              variant="contained"
              sx={{ width: "150px" }}
            >
              アルバム一覧へ
            </CustomButton>
          </a>
        </Typography> */}
      </Box>
    </Modal>
  );
};
