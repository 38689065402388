import * as React from "react";

export default function InfoEditCustomFormLabel(props: any) {
  const { value } = props;
  return (
    <div
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        color: "#424242",
        lineHeight: "16px",
      }}
    >
      {value}
    </div>
  );
}
