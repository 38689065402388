import PropTypes from "prop-types";
import Box from "@mui/material/Box";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  group_key: PropTypes.string.isRequired,
};

export function TabPanel(props: any) {
  const { children, group_key, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={group_key !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {group_key === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export function a11yProps(group_key: string) {
  return {
    id: `simple-tab-${group_key}`,
    "aria-controls": `simple-tabpanel-${group_key}`,
    value: group_key,
  };
}
