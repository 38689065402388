import React from "react";
import SamplePhoto from "../../assets/sample_photo.png";
import {
  Button,
  Grid,
  createStyles,
  Chip,
  Divider,
  Theme,
  Card,
  CardContent,
  CardMedia,
  Badge,
} from "@mui/material";
import { Photo, PhotoSize } from "../Cart/Cart";
import { withStyles } from "@mui/styles";
import PhotoAlbumOutlinedIcon from "@mui/icons-material/PhotoAlbumOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import { PhotoMordal } from "./PhotoMordal";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import styled from "@mui/styled-engine";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "./BreadCrumbs";
import UploadedFolderTable from "./UploadedFolderTable";

const photoSizes: PhotoSize[] = [
  {
    name: "L",
    displayName: "Lサイズ",
    sizeDetail: "89×127mm",
    unitPrice: 40,
  },
  {
    name: "2L",
    displayName: "2Lサイズ",
    sizeDetail: "127×178mm",
    unitPrice: 130,
  },
  {
    name: "download",
    displayName: "ダウンロード",
    sizeDetail: "2400×1600px",
    unitPrice: 140,
    maxCount: 1,
  },
];
const photos: Photo[] = Array(14).fill({
  id: "0",
  albumId: "1",
  groupId: "2",
  url: SamplePhoto,
  photoSizes: photoSizes.map((photoSize) => ({
    ...photoSize,
    count: 1,
  })),
});

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      marginRight: "48px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    albumTitle: {
      margin: 0,
    },
    stickyButtonWrapper: {
      position: "sticky",
      marginBottom: "40px",
      bottom: "8px",
    },
    badge: {
      backgroundColor: theme.palette.ruby.main,
    },
  });

const PhotoGroup = (props: any) => {
  const { classes } = props;
  return (
    <div className={classes.wrapper}>
      <BreadCrumbs
        breadcrumbs={[
          {
            label: "TOP",
            component: Link,
            color: "emerald",
            to: "/",
            clickable: true,
          },
          {
            label: "運動会",
            color: "default",
          },
        ]}
      />
      <Card
        variant="outlined"
        sx={{
          marginBottom: "20px",
          borderRadius: "16px",
        }}
      >
        <CardContent sx={{ padding: "20px" }}>
          <h2 className={classes.albumTitle}>
            <Grid
              container
              rowSpacing="12px"
              columnSpacing="12px"
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid item>
                <Grid container alignItems="center">
                  <PhotoAlbumOutlinedIcon sx={{ color: "emerald.main" }} />
                  運動会
                </Grid>
              </Grid>
            </Grid>
          </h2>
        </CardContent>
        <Divider />
        <CardContent sx={{ padding: "20px" }}>
          <Grid container columnSpacing="40px" sx={{ lineHeight: "1.6em" }}>
            <Grid item>
              <Grid container columnSpacing="8px">
                <Grid item>写真枚数：</Grid>
                <Grid item>{photos.length}枚</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing="8px">
                <Grid item> 販売期間：</Grid>
                <Grid item> 2021/10/31〜2021/10/31</Grid>
                <Grid item sx={{ color: "ruby.main" }}>
                  {" "}
                  終了まであと20日{" "}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent sx={{ padding: "20px" }}>
          <Grid
            container
            rowSpacing="36px"
            columnSpacing={{ xs: "16px", lg: "16px" }}
          >
            <Grid item xs={12} sx={{ paddingTop: "8px" }}>
              <Card variant="outlined" sx={{ borderRadius: "16px" }}>
                <UploadedFolderTable />
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(PhotoGroup);
