// components
import ContentsHeader from "./ContentsHeader";
import ContentsViewContainer from "./ContentsViewContainer";

import "./form.scss";

export default function Form3() {
  return (
    <>
      <ContentsHeader />
      <p>
        ニコスケットにご興味頂きありがとうございます。下記のフォームにご入力の上、送信ボタンを押してください。
        <br />
        内容確認後、担当者よりご連絡させていただきます。
        <br />
      </p>
      <ContentsViewContainer />
    </>
  );
}
