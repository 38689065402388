import { Photo } from "views/Cart/Cart";

export const paymentMethodMap = new Map([['conveni', 'コンビニ払い'], ['card', 'クレジット払い']]);

export const formatDate = (dt: Date) => {
  const y = dt.getFullYear();
  const m = ('00' + (dt.getMonth()+1)).slice(-2);
  const d = ('00' + dt.getDate()).slice(-2);
  return (y + '/' + m + '/' + d);
}

export const getTotal = (photos: Photo[]) => {
  return photos.reduce((total: number, photo) => (
    total + photo.photoSizes.reduce((subtotal: number, size) => (
      subtotal + (size.count ? size.count : 0)
    ), 0)
  ), 0);
}

export const getOrderDetails = (photos: Photo[]) => {
  const details = [];
  for (const photo of photos) {
    const {id, photoSizes} = photo;
    for (const photoSize of photoSizes) {
      if (photoSize.count && photoSize.count > 0) {
        details.push({
          photo_id: id,
          select_size: photoSize.name,
          amount: photoSize.count?.toString(),
          wholesale_price: photoSize.wholesalePrice,
          unit_price: photoSize.unitPrice,
        });
      }
    }
  }
  return details;
}

export const containCommission = (paymentMethod: any, shippingMethod : string | undefined) => {
  return paymentMethod === 'conveni' && shippingMethod !== '一括配送集金';
};

export const containPostage = (isNotOnlyDL: boolean, shippingMethod : string | undefined) => {
  return isNotOnlyDL && shippingMethod !== '一括配送前払い' && shippingMethod !== '一括配送集金';
};

export const calculateFee = (commission: string | undefined, postage: string | undefined, paymentMethod: any, isNotOnlyDL: boolean, shippingMethod : string | undefined): number | undefined => {
  let fee: number | undefined = 0;
  if (commission && postage && shippingMethod) {
    if (containCommission(paymentMethod, shippingMethod)) {
      fee += parseInt(commission);
    }
    if (containPostage(isNotOnlyDL, shippingMethod)) {
      fee += parseInt(postage);
    }
  } else {
    fee = undefined;
  }
  return fee;
};