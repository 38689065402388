import React, { useEffect } from "react";
import SamplePhoto from "../../assets/sample_photo.png";
import { withStyles } from "@mui/styles";
import {
  Button,
  Grid,
  createStyles,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  Theme,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import OrderCountsByPicSize from "../Cart/OrderCountsByPicSize";
import { Photo, PhotoSize } from "../Cart/Cart";
import OrderTotalSum from "./OrderTotalSum";
import { Link } from "react-router-dom";
import styled from "@mui/styled-engine";
import { GrayButton } from "./GrayButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { useQuery } from "react-query";
import { getUsersByUserId } from "utils/api/users/users";
import formatPhoneNumber from "utils/function/formatPhoneNumber";
import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { OrderActionTypes } from "redux/actions/OrderActions";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getSchools } from "utils/api/schools/schools";
import { calculateFee } from "./utils";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      marginRight: "48px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    bold: {
      fontWeight: 700,
    },
    ordererUl: {
      padding: 0,
      margin: 0,
    },
    ordererLi: {
      fontWeight: 700,
      listStyleType: "none",
      "& + $ordererLi": {
        marginTop: "1em",
      },
    },
    formControlLabel: {
      fontSize: "14px",
      "& label": { fontSize: "14px" },
    },
  });

const OrderCard = (props: any) => {
  const { children } = props;
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: "20px",
      }}
    >
      <CardContent sx={{ padding: "20px" }}>{children}</CardContent>
    </Card>
  );
};

const Section = (props: any) => {
  const { title, children } = props;
  return (
    <Box>
      <h3>
        <Box sx={{ fontSize: "16px", marginBottom: "12px" }}>{title}</Box>
      </h3>
      <OrderCard>{children}</OrderCard>
    </Box>
  );
};

const EditButton = (props: any) => {
  const { children, ...others } = props;
  return (
    <Button
      sx={{
        fontWeight: 700,
        color: "#424242",
        border: "1px solid #E5E5E5",
        boxSizing: "border-box",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
        marginTop: "20px",
      }}
      fullWidth
      {...others}
    >
      {children}
    </Button>
  );
};

const SelectLabel = (props: any) => {
  const { className, value, label, ...others } = props;
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            "&.Mui-checked": {
              color: "emerald.main",
            },
          }}
        />
      }
      label={<Typography className={className}>{label}</Typography>}
    />
  );
};

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const isNotOnlyDL = (photos: Photo[]): boolean => {
  const photoSizes = photos.reduce(
    (keys: any, photo: Photo) => {
      const keysNew = { ...keys };
      for (const photoSize of photo.photoSizes) {
        const key = keysNew[photoSize.name] || { count: 0 };
        keysNew[photoSize.name] = {
          count: key.count + photoSize.count,
        };
      }
      return keysNew;
    },
    { L: { count: 1 }, "2L": { count: 0 } }
  );
  return photoSizes.L.count + photoSizes["2L"].count > 0;
};

const hasAnyDL = (photos: Photo[]): boolean => {
  const photoSizes = photos.reduce(
    (keys: any, photo: Photo) => {
      const keysNew = { ...keys };
      for (const photoSize of photo.photoSizes) {
        const key = keysNew[photoSize.name] || { count: 0 };
        keysNew[photoSize.name] = {
          count: key.count + photoSize.count,
        };
      }
      return keysNew;
    },
    { DL: { count: 0 } }
  );
  return photoSizes.DL.count > 0;
};

const getOrderCountsByPicSize = (photos: Photo[]): any => {
  const photoSizes = photos.reduce((keys: any, photo: Photo) => {
    const keysNew = { ...keys };
    for (const photoSize of photo.photoSizes) {
      const key = keysNew[photoSize.name] || { count: 0, subtotal: 0 };
      keysNew[photoSize.name] = {
        name: photoSize.name,
        displayName: photoSize.displayName,
        sizeDetail: photoSize.sizeDetail,
        count: key.count + photoSize.count,
        subtotal: key.subtotal + photoSize.unitPrice * (photoSize.count || 0),
      };
    }
    return keysNew;
  }, {});

  return Object.values(photoSizes);
};

const Order = (props: any) => {
  const { classes } = props;
  const dispatch = useDispatch();

  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const usersQueryResult = useQuery<usersReturnValue[], Error>(
    [null, loginAccount.accountId],
    getUsersByUserId
  );
  const user = usersQueryResult.data ? usersQueryResult.data[0] : {};

  const schoolsQueryResult = useQuery<schoolsReturnValue[], Error>(
    [null, user.school_id],
    getSchools
  );
  const school = schoolsQueryResult.data ? schoolsQueryResult.data[0] : {};

  user.child_class = user.child_class
    ? user.child_class.replace(/'/g, '"')
    : undefined;
  let child_class_ids = user.child_class ? JSON.parse(user.child_class) : [];
  child_class_ids = new Map(
    child_class_ids.map((child_class_id: string) => [child_class_id, null])
  );

  const classesQueryResult = useQuery<classesReturnValue[], Error>(
    [null, null, user.school_id],
    getClasses
  );
  const child_classes = classesQueryResult.data
    ? classesQueryResult.data.filter((school_class: any) =>
        child_class_ids.has(school_class.class_id)
      )
    : [];
  for (const child_class of child_classes) {
    child_class_ids.set(child_class.class_id, child_class.class_name);
  }

  const order: any = useSelector<RootState>((state) => state.orderReducer);

  const cart: any = useSelector<RootState>((state) => state.cartReducer);
  const orderCountsByPicSize = getOrderCountsByPicSize(cart);
  const fee = calculateFee(
    school.commission,
    school.postage,
    order.paymentMethod,
    isNotOnlyDL(cart),
    school.shipping_method
  );

  const subtotal = orderCountsByPicSize.reduce((sum: number, prop: any) => {
    return sum + prop.subtotal;
  }, 0);
  const onClassSelect = (event: any) => {
    dispatch({
      type: OrderActionTypes.SELECT_DELIVERY_CLASS,
      payload: {
        deliveryClassId: event.target.value,
        deliveryClassName: child_class_ids.get(event.target.value),
      },
    });
  };
  const onPaymentMethodSelect = (event: any) => {
    dispatch({
      type: OrderActionTypes.SELECT_PAYMENT_METHOD,
      payload: {
        paymentMethod: event.target.value,
      },
    });
  };
  const onConveniSelect = (event: any) => {
    const conveniMap = new Map([
      ["11", "セブンイレブン"],
      ["21", "ファミリーマート"],
      ["31", "ローソン"],
      ["32", "セイコーマート"],
      ["33", "ミニストップ"],
    ]);
    dispatch({
      type: OrderActionTypes.SELECT_CONVENI,
      payload: {
        conveniCode: event.target.value,
        conveniName: conveniMap.get(event.target.value),
      },
    });
  };
  useEffect(() => {
    dispatch({
      type: OrderActionTypes.INIT_ORDER,
    });
  }, []);

  // URLを取得
  let url = new URL(window.location.href);
  // URLSearchParamsオブジェクトを取得
  let params = url.searchParams;
  const album_id = params.get("album_id");

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={"24px"}>
        <Grid item xs={12} md={12}>
          <Section title="ご注文者">
            <ul className={classes.ordererUl}>
              <li className={classes.ordererLi}>{user.user_name}</li>
              <li className={classes.ordererLi}>{user.address}</li>
              <li className={classes.ordererLi}>
                {formatPhoneNumber(user.phone_number)}
              </li>
              <li className={classes.ordererLi}>{user.user_id}</li>
            </ul>
            <EditButton component={Link} to={"/info-edit/me"}>
              編集する
            </EditButton>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section title="配送方法">
            <span className={classes.bold}>
              {isNotOnlyDL(cart)
                ? school.shipping_method === "自宅配送"
                  ? "ご自宅へ配送します。"
                  : "所属の団体様へ配送します。"
                : "注文詳細画面からダウンロード"}
            </span>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          {school.shipping_method !== "自宅配送" && (
            <Section title="お届け先クラス">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={onClassSelect}
                >
                  {child_classes.map((child_class) => (
                    <FormControlLabel
                      value={child_class.class_id}
                      key={child_class.class_id}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "emerald.main",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography className={classes.formControlLabel}>
                          {child_class.class_name}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Section>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Section title="お支払い方法">
            {school.shipping_method !== "一括配送集金" ? (
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="card"
                  name="radio-buttons-group"
                  onChange={onPaymentMethodSelect}
                >
                  <SelectLabel
                    value="card"
                    label="クレジット払い"
                    className={classes.formControlLabel}
                  />
                  {!hasAnyDL(cart) && (
                    <SelectLabel
                      value="conveni"
                      label="コンビニ払い"
                      className={classes.formControlLabel}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            ) : (
              <span> 現金で団体様にお支払いください。 </span>
            )}
          </Section>
        </Grid>
        {order.paymentMethod === "conveni" && (
          <Grid item xs={12} md={12}>
            <Section title="お支払いのコンビニ">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={order.conveniCode}
                  name="radio-buttons-group"
                  onChange={onConveniSelect}
                >
                  {/* <SelectLabel
                    value="11"
                    label="セブンイレブン"
                    className={classes.formControlLabel}
                  /> */}
                  <SelectLabel
                    value="21"
                    label="ファミリーマート"
                    className={classes.formControlLabel}
                  />
                  <SelectLabel
                    value="31"
                    label="ローソン"
                    className={classes.formControlLabel}
                  />
                  <SelectLabel
                    value="32"
                    label="セイコーマート"
                    className={classes.formControlLabel}
                  />
                  <SelectLabel
                    value="33"
                    label="ミニストップ"
                    className={classes.formControlLabel}
                  />
                </RadioGroup>
              </FormControl>
            </Section>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Section title="ご注文点数">
            <OrderCountsByPicSize orderCountsByPicSize={orderCountsByPicSize} />
            <EditButton component={Link} to={`/cart?album_id=${album_id}`}>
              前の画面に戻って編集する
            </EditButton>
          </Section>
          <OrderCard>
            <OrderTotalSum subtotal={subtotal} fee={fee} />
          </OrderCard>
        </Grid>
      </Grid>
      <NextButton
        fullWidth
        component={Link}
        to={`/order-confirm?album_id=${album_id}`}
        disabled={
          (order.paymentMethod === "conveni" && !order.conveniCode) ||
          (school.shipping_method !== "自宅配送" && !order.deliveryClassName)
        }
      >
        注文確認に進む
      </NextButton>
      <Box sx={{ marginTop: "20px" }}>
        <GrayButton component={Link} to={`/cart?album_id=${album_id}`}>
          戻る
        </GrayButton>
      </Box>
    </div>
  );
};

export default withStyles(styles)(Order);
