import * as React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CustomCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";

const StyledTableContainer = styled(TableContainer)({
  background: '#FFFFFF',
  border: '1px solid #E7E7E7',
  boxSizing: 'border-box',
  borderRadius: '16px',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#FFFBE7",
  },
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
  padding: "0px 40px",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
  padding: "0px 40px",
});

const StyledTableSortLabel = styled(TableSortLabel)({
  '& .MuiTableSortLabel-icon': {
    color: '#23AA91 !important',
  },
});

export default function AdminUserTable({ ...props }: any) {
  const { tableHead, tableData, page, rowsPerPage, deleteList, setDeleteList } = props;

  const navigate = useNavigate();

  // CheckBoxにチェックされた行データを保持するための関数
  const useRowSelect = (rowIds: string[], initialSelectedRowIds: string[] = []): {
    selectedRowIds: string[];
    isSelected: (rowId: string) => boolean;
    toggleSelected: (id: string) => void;
  } => {
      const [selectedRowIds, setSelectedRowIds] = useState<string[]>(initialSelectedRowIds);
      const isSelected = (rowId: string) => selectedRowIds.includes(rowId);
      const toggleSelected = (rowId: string) => {
        if (isSelected(rowId)){
          setSelectedRowIds(selectedRowIds.filter((selectedId) => selectedId !== rowId))
          setDeleteList(deleteList.filter((item: any) => item !== rowId));
        }
        else{
          setSelectedRowIds([...selectedRowIds, rowId]);
          let tableDataFilter: any = tableData.filter((item: any) => item.user_id === rowId);
          let orderItem: any = tableDataFilter[0].user_id;
          setDeleteList([...deleteList, orderItem]);
        }
      };
      return { selectedRowIds, isSelected, toggleSelected };
  };
  const {selectedRowIds, isSelected, toggleSelected} = useRowSelect(tableData.map((row: any) => row.user_id));

  // ソートに関する関数
  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [rows, setRows] = useState(tableData);
  useEffect(() => { setRows(tableData); }, [tableData]);
  function handleClickSortColumn() {
    const isDesc = order === 'desc';
    const nextOrder = isDesc ? 'asc' : 'desc';
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = rows.sort((a: any, b: any) => {
      if (a.update_date > b.update_date) {
        return sortRule[nextOrder][0];
      } else if (a.update_date < b.update_date) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setOrder(nextOrder);
    setRows(sortedRows)
  }

  const viewTableData = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)



  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: '54px' }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align='center'>
                    {prop.user_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center'>
                    {prop.phone_number}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center'>
                    {prop.zip}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center'>
                    {prop.address}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center'>
                    {prop.school_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center' sortDirection={order}>
                      {prop.update_date}
                    <StyledTableSortLabel
                      active={true}
                      direction={order}
                      IconComponent={ArrowDropDownRoundedIcon}
                      onClick={() => handleClickSortColumn()}
                    />
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    削除
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {viewTableData.map((prop: any, key: any) => {
            const isItemSelected = isSelected(prop.user_id);
            return (
              <StyledTableRow>
                <TableDataCell key={key} align='center' sx={{ width:"15%" }} onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.user_name}
                </TableDataCell>
                <TableDataCell key={key} align='center' sx={{ width:"15%" }} onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.phone_number}
                </TableDataCell>
                <TableDataCell key={key} align='center' sx={{ width:"15%" }} onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.zip}
                </TableDataCell>
                <TableDataCell key={key} align='center' onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.address}
                </TableDataCell>
                <TableDataCell key={key} align='center' sx={{ width:"15%" }} onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.school_name}
                </TableDataCell>
                <TableDataCell key={key} align='center' sx={{ width:"10%" }} onClick={() => { navigate("/info-edit/" + prop.user_id); }}>
                  {prop.update_date}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "5%" }}>
                  <CustomCheckBox checked={isItemSelected} onClick={() => { toggleSelected(prop.user_id); }}/>
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}