import React from 'react';
import { Grid, Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';

import { numberToWithComma } from './PicSizeSelector';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: '12px',
    lineHeight: '2em',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '0',
    marginBottom: '0.5em',
  },
  h3Color: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '0',
    marginBottom: '0.5em',
    color: '#DE5F5F',
  }
});
function OrderTotalSum({ ...props }: any) {
  const {
    subtotal,
    classes
  } = props;
  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.totalSum}>
        <Grid item>
          <h3 className={classes.h3}> 商品小計 </h3>
        </Grid>
        <Grid item>
          <h3 className={classes.h3Color}> {'¥' + numberToWithComma(subtotal)} </h3>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(OrderTotalSum);