import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function UserPhoneNumberView(props: any) {
  const { register, formState } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12}>
            <ErrorMessage
              errors={formState.errors}
              name="phone_number"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <CustomLabel value={"電話番号"} />
            <CustomForm
              placeholder="電話番号を入力してください"
              helperlabel="例：00-1234-5678"
              register={register("phone_number", {
                required: "⚠︎必須項目です",
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
