import { useEffect } from "react";

// @mui
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

// compornents
import CustomeCheckBox from "../../components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

// APIs
import { classesReturnValue } from "utils/interface/classes/classesInterface";

// functions
import setChildrenClassList from "utils/function/setChildrenClassList";

export default function UserClassView(props: any) {
  const { data, classes, existClassList, setExistClassList } = props;

  useEffect(() => {
    const child_classes: string[] =
      // data && data.child_class ? data.child_class.split(",") : [];
      data && data.child_class
        ? JSON.parse(data.child_class.replaceAll("'", '"'))
        : [];
    const existClass = classes.map((existClass: classesReturnValue) => {
      return {
        ...existClass,
        child_flg: child_classes.includes(existClass.class_id),
      };
    });
    setExistClassList(existClass);
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <CustomLabel
          value={"お子様のクラス（兄弟姉妹がいる場合は複数選択可）"}
        />
        <Grid container direction="row" alignItems="flex-start">
          {existClassList.map((row: classesReturnValue, index: Number) => (
            <FormControlLabel
              control={
                <CustomeCheckBox
                  defaultChecked={row.child_flg}
                  onChange={function (e: any) {
                    setChildrenClassList(
                      index,
                      existClassList,
                      setExistClassList
                    );
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>{row.class_name}</Typography>
              }
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
