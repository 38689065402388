import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Amplify, { Auth } from "aws-amplify";
import awsmobile from "../../aws-exports";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

// compornents
import { resetPasswordInterface } from "./resetPasswordInterface";
import RenderRequestCodeForm from "./RenderRequestCodeForm";
import RenderConfirmationForm from "./RenderConfirmationForm";

export default function ResetPassword() {
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  // Amplifyの設定を行う
  Amplify.configure(awsmobile);

  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  const { register, handleSubmit, getValues, trigger, formState } =
    useForm<resetPasswordInterface>({ mode: "onBlur" });

  const handleSendCodeClick: SubmitHandler<resetPasswordInterface> = async (
    data
  ) => {
    try {
      setMessage("");
      console.debug(data.email);
      await Auth.forgotPassword(data.email ? data.email : "");
      setCodeSent(true);
      console.debug("forgotPassword");
    } catch (error) {
      console.debug(error);
    }
  };

  const handleConfirmClick: SubmitHandler<resetPasswordInterface> = async (
    data
  ) => {
    try {
      setMessage("");
      await Auth.forgotPasswordSubmit(
        data.email ? data.email : "",
        data.code ? data.code : "",
        data.password ? data.password : ""
      );
      console.debug(data);
      setConfirmed(true);
    } catch (error) {
      console.debug(error);
      setMessage("入力値が正しいか確認してください");
    }
  };

  function renderSuccessMessage() {
    return (
      <div className="success">
        <p>パスワードリセットが完了しました。</p>
        <p>
          <Link to="/login">
            こちらをクリックしてログイン画面に戻ってください
          </Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <Box
        sx={{
          margin: { xs: "10vh auto", sm: "20vh auto" },
          padding: { xs: "20px", sm: "50px" },
          width: { xs: "90%", sm: "600px" },
          backgroundColor: "#fff",
        }}
      >
        <h2 className="center">パスワードリセット</h2>
        <p>{message}</p>
        {!codeSent ? (
          <RenderRequestCodeForm
            handleSubmit={handleSubmit}
            handleSendCodeClick={handleSendCodeClick}
            register={register}
            formState={formState}
          />
        ) : !confirmed ? (
          <RenderConfirmationForm
            handleSubmit={handleSubmit}
            handleConfirmClick={handleConfirmClick}
            formState={formState}
            isRevealPassword={isRevealPassword}
            togglePassword={togglePassword}
            register={register}
            getValues={getValues}
            trigger={trigger}
          />
        ) : (
          renderSuccessMessage()
        )}
      </Box>
    </>
  );
}
