import * as React from "react";

// @mui
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// compornents
import { GroupNameView } from "./GroupNameView";
import { GroupAddressView } from "./GroupAddressView";
import { GroupPhoneNumberView } from "./GroupPhoneNumberView";
import { GroupRequirementView } from "./GroupRequirementView";
import ContentsFooter from "./ContentsFooter";
import { FormModal } from "./FormModal";

// 3rd-party library (EmailJS)
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";

import { useForm, SubmitHandler } from "react-hook-form";

// interface
import { IFormInput } from "./INTERFACES";

const CustomDivider = styled(Divider)({
  marginBottom: "20px",
});

export default function ContentsViewContainer() {
  const { register, handleSubmit } = useForm<IFormInput>();
  const [open, setOpen] = React.useState(false);
  let message =
    "この度は、ニコスケットの資料請求・お問い合わせをいただき誠にありがとうございます。後ほど、担当者から確認のご連絡を致しますので、しばらくお待ちくださいますようお願いいたします。";

  init("a6t9o_FuiNv6BuFHA");

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data);
    setOpen(true);
    emailjs.send("service_wujul48", "template_hzow9mp", data).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        message = "送信失敗:" + error.text;
      }
    );
  };

  // init(process.env.REACT_APP_USER_ID || "");

  // const onSubmit: SubmitHandler<IFormInput> = (data) => {
  //   console.log(data);
  //   setOpen(true);
  //   emailjs
  //     .send(
  //       process.env.REACT_APP_SERVICE_ID || "",
  //       process.env.REACT_APP_TEMPLATE_ID || "",
  //       data
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         message = "送信失敗:" + error.text;
  //       }
  //     );
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div id="myForm" className="content">
        <GroupRequirementView register={register} />
        <CustomDivider />
        <GroupNameView register={register} />
        <CustomDivider />
        <GroupAddressView register={register} />
        <CustomDivider />
        <GroupPhoneNumberView register={register} />
      </div>
      <ContentsFooter />
      <FormModal open={open} message={message} />
    </form>
  );
}
