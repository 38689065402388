import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { RootState } from "index";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { GetSchoolAlbums } from "components/CustomTableAlbums/GetAlbums";
import { SALES_STATUS } from "utils/function/shapeAlbumData";
import { TABLEHEAD } from "./SchoolAlbumsDataSet";
import { OrderHistoryContext } from "store/OrderHistoryContext";
import { getChipColor } from "utils/function/shapeAlbumData";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import { DeleteDialog, DeleteDialogProps } from 'components/DeleteDialog/DeleteDialog';
import { deleteAlbum } from "utils/api/albums/albums";
import { getSchoolAlbums } from "utils/api/albums/albums";
import { getSchools } from "utils/api/schools/adminSchools";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { addAlbumStatus } from "utils/function/shapeAlbumData";

export default function SchoolAlbums(props: any) {

  const loginAccount: any = useSelector<RootState>( (state) => state.accountReducer );

  const filterKey: string = "sales_status";
  const sortKey: string = "sales_period_to";
  const filter: string = "販売状況";
  const urlKey: string = "album_id";
  const filterArray: string[] = SALES_STATUS;

  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(filterArray[0]);
  const [modalConfig, setModalConfig] = useState<DeleteDialogProps | undefined>()
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [message, setMessage] = useState<string | undefined>("");
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  let query: string[] = [];
  if (loginAccount.accountType === "organization") { query = ["getAlbums", "0", loginAccount.schoolId]; }
  else { query = ["getAlbums", , loginAccount.schoolId];}

  // API:DELETE
  const deleteListMutation = useMutation(deleteAlbum, {
  onSuccess: (data, variables, context) => {
    if (data.status===200) {
      console.log("Success deleteListMutation");
      window.location.reload();
    } else {
      setMessage("Error");
    }
  },
  onError: (error, variables, context) => {
    setMessage("Error: " + error);
  },
  });

  // API:GET
  const getAlbumsResponse = useQuery<albumsReturnValue[], Error>( query, getSchoolAlbums);
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(["getSchools", "", "", "", "", "", ""], getSchools);
  if (getAlbumsResponse.isLoading || getSchoolsResponse.isLoading) { return <span>Loading...</span>;}
  if (getAlbumsResponse.isError) { return <span>Error: {getAlbumsResponse.error?.message}</span>; }
  if (getSchoolsResponse.isError) { return <span>Error: {getSchoolsResponse.error?.message}</span>; }
  const schoolsData: any = getSchoolsResponse.data;
  const getAlbumsData: any = getAlbumsResponse.data;

  // アルバム情報に団体情報を追加
  for (var i = 0; i < getAlbumsData.length; i++) {
    for (var j = 0; j < schoolsData.length; j++) {
      if (getAlbumsData[i].school_id === schoolsData[j].school_id) {
        getAlbumsData[i].school_name = schoolsData[j].school_name;
        getAlbumsData[i].shipping_method = schoolsData[j].shipping_method;
      }
    }
  }

  // 必要な情報のみ残す
  var displayData: any[] = [];
  for (var k = 0; k < getAlbumsData.length; k++) {
    displayData.push({
      album_id: getAlbumsData[k].album_id,
      album_name: getAlbumsData[k].album_name,
      school_name: getAlbumsData[k].school_name,
      sales_status: getAlbumsData[k].sales_status,
      sales_period_from: getAlbumsData[k].sales_period_from,
      sales_period_to: getAlbumsData[k].sales_period_to,
      photo_counts: getAlbumsData[k].photo_counts,
    });
  }

  // アルバム情報にステータスを追加
  const albumsData = addAlbumStatus(displayData);

  // 削除ボタンが押された時の関数
  const handleDeleteClick = async () => {
    const ret = await new Promise<string>((resolve) => {
      setModalConfig({
        onClose: resolve,
        title: '削除します。よろしいですか?',
        message: '削除すると元に戻せません。'
      })
    })
    setModalConfig(undefined)
    if (ret==="ok"){
      deleteList?.map((item: any) => deleteListMutation.mutate(item));
    }
  }

  // 検索欄への入力値をハンドリング
  const searchHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSearchResult(search(e.target.value, albumsData));
    setIsFirstTime(false);
    setPage(1);
  };

  // 検索する関数
  type albumInfo = {
    album_id: string;
    album_name: string;
    school_name: string;
    sales_status: string;
    sales_period_from: string;
    sales_period_to: string;
    photo_counts: number | string;
  };
  const search = (value: string, data:any[]) => {
    if (value !== "") {
      const filteredList = data?.filter((albumInfoList: albumInfo) =>
        Object.values(albumInfoList).some((item: any) =>
          item !== undefined &&
          item !== null &&
          typeof item === 'string' &&
          item?.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1)
      );
      return filteredList;
    }
    return data;
  };

  return (
    <OrderHistoryContext.Provider value={{}}>
      <Grid container rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={12}>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
            <h2>アルバム一覧</h2>
            <CustomButton color="emerald" variant="contained" onClick={handleDeleteClick}>
              削除
            </CustomButton>
          </Stack>
          {modalConfig && <DeleteDialog {...modalConfig} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="検索"
            variant="outlined"
            size="small"
            color="emerald"
            value={searchValue}
            onChange={searchHandleChange}
            sx={{background:"#FFFFFF", select:{'&:before':{backgroundColor:"#23AA91" },'&:after':{backgroundColor:"#23AA91"}},"&:hover":{"&& fieldset":{border:"2px solid #23AA91"}}}}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Suspense fallback={<p>Loading...</p>}>
          {isFirstTime
            ? <GetSchoolAlbums
                filterKey={filterKey}
                sortKey={sortKey}
                filter={filter}
                urlKey={urlKey}
                filterArray={filterArray}
                TABLEHEAD={TABLEHEAD}
                chipFn={getChipColor}
                deleteList={deleteList}
                setDeleteList={setDeleteList}
                page={page}
                setPage={setPage}
                albumsData={albumsData}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
              />
            : <GetSchoolAlbums
                filterKey={filterKey}
                sortKey={sortKey}
                filter={filter}
                urlKey={urlKey}
                filterArray={filterArray}
                TABLEHEAD={TABLEHEAD}
                chipFn={getChipColor}
                deleteList={deleteList}
                setDeleteList={setDeleteList}
                page={page}
                setPage={setPage}
                albumsData={searchResult}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
              />
          }

        </Suspense>
      </Grid>
    </OrderHistoryContext.Provider>
  );
}
