import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

// other
import PrefMaster from "../../assets/PrefMaster";

const prefectureList = PrefMaster.PREF_OPTIONS.map((pref) => {
  return { value: pref, label: pref };
});

type Props = {
  register: any;
};

export const GroupAddressView: React.FC<Props> = ({ register }) => {
  return (
    <Grid container direction="row" alignItems="flex-start">
      {/* <Grid item xs={2}>
        <CustomLabel value="住所" />
      </Grid> */}
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12} sm={4}>
            <CustomForm
              labelname="郵便番号"
              helperlabel="例：000-0000"
              register={register("zip")}
            />
          </Grid>
          <Grid item xs={0} sm={8}></Grid>
          <Grid item xs={12} sm={4}>
            <CustomSelector
              labelname="都道府県"
              items={prefectureList}
              register={register("prefecture")}
            />
          </Grid>
          <Grid item xs={0} sm={8}></Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CustomForm
                labelname="市区町村"
                placeholder="市区町村を入力してください"
                helperlabel="例：〇〇市〇〇町"
                register={register("address1")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomForm
                labelname="番地"
                placeholder="番地を入力してください"
                helperlabel="例：1-2-3"
                register={register("address2")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomForm
                labelname="建物名・部屋番号"
                placeholder="建物名・部屋番号を入力してください"
                helperlabel="例：〇〇マンション101"
                register={register("address3")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
