import * as React from "react";
import { useParams, Link } from "react-router-dom";

// @mui
import { Divider, Grid, Card, CardContent } from "@mui/material";
import PhotoAlbumOutlinedIcon from "@mui/icons-material/PhotoAlbumOutlined";

// components
import { BreadCrumbs } from "./BreadCrumbs";
import FolderList from "./FolderList";

// apis
import { useQuery } from "react-query";
import { getAlbums } from "utils/api/albums/albums";
import { getPhotoGroups } from "utils/api/photoGroup/photoGroup";
import { photoGroupReturnValue } from "utils/interface/photoGroup/photoGroupInterface";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";

export default function PhotoListSchool() {

  // URLからalbum_idを取得
  const albumId = useParams().albumId;
  const groupName = useParams().groupName;

  // albumIdからアルバム情報と写真グループを取得
  const getAlbumsResponse = useQuery<albumsReturnValue[], Error>(
    ["getAlbums", albumId],
    getAlbums
  );
  const getPhotoGroupsResponse = useQuery<photoGroupReturnValue[], Error>(
    ["getPhotoGroups", albumId],
    getPhotoGroups
  );
  if (getAlbumsResponse.isLoading || getPhotoGroupsResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getAlbumsResponse.isError) {
    return <span>Error: {getAlbumsResponse.error?.message}</span>;
  }
  if (getPhotoGroupsResponse.isError) {
    return <span>Error: {getPhotoGroupsResponse.error?.message}</span>;
  }

  // レスポンスからデータを抜き出し
  const albumData = getAlbumsResponse.data ? getAlbumsResponse.data[0] : {};
  const photoGroupResponseData = getPhotoGroupsResponse.data ? getPhotoGroupsResponse.data : [];

  // フォルダ名のリストを作成
  const photoGroupData = photoGroupResponseData.map((item: any) => item["group_name"])

  // 重複を削除してフォルダ名の一覧を取得
  const folderListTmp: string[] = []
  photoGroupData.map((props: any) => {
    if ((props.split("/")[0] === groupName)&&(props.indexOf('/') !== -1)){
      folderListTmp.push(props.split("/")[1]);
    };
  })
  const folderList = Array.from(new Set(folderListTmp));

  // フォルダが1段か2段かを判定するフラグを作成
  const folderFlag: boolean[] = [];
  folderList.map((props: string) => {
    if (photoGroupData.find((value: any) => (value.indexOf(props + "/")) !== -1)) {
      folderFlag.push(true);
    }
    else {
      folderFlag.push(false);
    };
  })

  return (
    <>
    <BreadCrumbs
      breadcrumbs={[
        {label: "TOP", component: Link, color: "emerald", to: "/albums", clickable: true},
        {label: albumData.album_name, component: Link, color: "emerald", to: "/photos/group/" + albumId, clickable: true},
        {label: groupName, color: "default"},
      ]}
    />
    <Grid container sx={{ paddingTop: "24px" }}>
      <Grid item xs={12}>
        <Card variant="outlined" sx={{ borderRadius: "16px", paddingBottom: "24px" }}>
          <CardContent sx={{ padding: "20px" }}>
            <h2>
              <Grid item>
                <Grid container alignItems="center">
                  <PhotoAlbumOutlinedIcon sx={{ color: "emerald.main" }} />
                    {albumData.album_name}
                </Grid>
              </Grid>
            </h2>
          </CardContent>
          <Divider />
          <CardContent sx={{ padding: "20px" }}>
            <Grid container columnSpacing="40px" sx={{ lineHeight: "1.6em" }}>
              <Grid item>
                <Grid container columnSpacing="8px">
                  <Grid item>写真枚数：</Grid>
                  <Grid item>{albumData.photo_counts}枚</Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container columnSpacing="8px">
                  <Grid item> 販売期間：</Grid>
                  <Grid item> {albumData.sales_period_from}〜{albumData.sales_period_to}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Grid container  sx={{ paddingTop: "24px", paddingLeft: "10px", paddingBottom: "24px" }}>
            <Grid item xs={12}>
              <FolderList albumId={albumId} PhotoList={folderList} folderFlag={folderFlag} groupName={groupName}/>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
    </>
  );
}