import * as React from "react";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

const StyledButton = styled(Button)({
  padding: "5px 10px",
  width: "82px",
  height: "22px",
  borderRadius: "12px",
  margin: "0px 4px",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#23AA91",
    color: "white",
  },
});

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
});

export default function CustomTable({ ...props }: any) {
  const { tableHead, tableData, filterData, filterColumnNum } = props;
  const columnAlign =
    props.columnAlign || Array(tableHead.length).fill("center");
  const [page, setPage] = useState(1);
  const [state, setState] = useState(0);
  const getVariant = (onSelectStateNumber: number) =>
    state === onSelectStateNumber ? "contained" : "text";

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={12}>
          <Stack direction="row">
            {filterData?.map((prop: any, key: any) => {
              return (
                <StyledButton
                  disableElevation
                  variant={getVariant(key)}
                  color="emerald"
                  onClick={() => {
                    setState(key);
                    setPage(1);
                  }}
                >
                  {prop}
                </StyledButton>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            variant="outlined"
            sx={{ borderRadius: "16px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {tableHead.map((prop: any, key: any) => {
                    return (
                      <TableHeadCell key={key} align="center">
                        {prop}
                      </TableHeadCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .filter(
                    (x: any) => state === 0 || x[filterColumnNum] === state
                  )
                  .map((prop: any, key: any) => {
                    return (
                      <StyledTableRow key={key}>
                        {prop.map((p: any, k: any) => {
                          return (
                            <TableCell key={k} align={columnAlign[k]}>
                              {p}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
