import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// components
import Box from "@mui/material/Box";

// img
import partition from "assets/img/Partition.png";

// APIs
import { useQuery } from "react-query";
import { getOrder } from "utils/api/order/order";
import {
  orderReturnValue,
  orderDetail,
} from "utils/interface/order/orderInterface";
import { getUsersByUserId } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";

export default function UserRegistFlyer() {
  const order_id = useParams().OrderId;

  const [schoolId, setSchoolId] = useState<string | undefined>("");
  const [userId, setUserId] = useState<string | undefined>("");
  const [updFlg, setUpdFlg] = useState<boolean>(false);

  const getOrderResponse = useQuery<orderReturnValue[], Error>(
    ["getOrder", "", "", order_id, "", "", ""],
    getOrder
  );
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", schoolId, ""],
    getSchools
  );
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsers", userId],
    getUsersByUserId
  );
  useEffect(() => {
    getSchoolsResponse.refetch();
    getUsersResponse.refetch();
  }, [userId]);

  if (
    getOrderResponse.isLoading ||
    getUsersResponse.isLoading ||
    getSchoolsResponse.isLoading
  ) {
    return <span>Loading...</span>;
  }
  if (getOrderResponse.isError) {
    return <span>Error: {getOrderResponse.error?.message}</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  if (getSchoolsResponse.isError) {
    return <span>Error: {getSchoolsResponse.error?.message}</span>;
  }
  const orderData = getOrderResponse.data ? getOrderResponse.data[0] : {};
  const school_id = orderData.school_id;
  const user_id = orderData.user_id;
  if (!updFlg) {
    setSchoolId(school_id);
    setUserId(user_id);
    setUpdFlg(true);
  }
  const userData = getUsersResponse.data ? getUsersResponse.data[0] : {};
  const schoolData = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};

  let countL = 0;
  let count2L = 0;
  let countDL = 0;
  if (orderData.order_details) {
    orderData.order_details.map((order: orderDetail) => {
      if (order.select_size == "L") {
        countL += Number(order.amount);
      } else if (order.select_size == "2L") {
        count2L += Number(order.amount);
      } else if (order.select_size == "DL") {
        countDL += 1;
      }
    });
  }

  return (
    <>
      <Box
        className="main"
        sx={{
          width: { sm: "89mm" },
          height: { sm: "127mm" },
        }}
      >
        <div className="top">
          <img
            src={partition}
            style={{
              position: "absolute",
              width: "89mm",
              height: "127mm",
              top: "0px",
              left: "0px",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "19.6mm",
            left: "21mm",
          }}
        >
          <h3>{order_id}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "26.6mm",
            left: "21mm",
          }}
        >
          <h3>{userData.user_name}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "34.6mm",
            left: "21mm",
          }}
        >
          <h3>{orderData.order_date}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "42.6mm",
            left: "26.5mm",
          }}
        >
          <h3>{countL}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "42.6mm",
            left: "49mm",
          }}
        >
          <h3>{count2L}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "54mm",
            left: "23mm",
          }}
        >
          <h3>{schoolData.school_name}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "63mm",
            left: "23mm",
          }}
        >
          <h3>{userData.child_name}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "73mm",
            left: "23mm",
          }}
        >
          <h3>{orderData.delivery_class}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "93.5mm",
            left: "17mm",
          }}
        >
          <h3>{schoolData.shipping_method}</h3>
        </div>
      </Box>
    </>
  );
}
