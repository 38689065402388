import * as React from "react";

import GetSample from "./organizms/GetSample";
import PostSample from "./organizms/PostSample";
import GMOSample from "./organizms/GMOSample";

export default function SampleApi(props: any) {
  return (
    <>
      <PostSample />
      <GetSample />
      <GMOSample />
    </>
  );
}
