// @mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// compornents
import CustomPriceForm from "../../components/InfoEditCustomPriceForm/InfoEditCustomPriceForm";

function createData(photoSize: any, wholePrice: any, tableMargin: any) {
  return { photoSize, wholePrice, tableMargin };
}

export default function PhotoPriceSetting(props: any) {
  const { data } = props;

  const rows = [
    createData(
      "Lサイズ",
      <CustomPriceForm
        value={data.wholesale_price_l}
        placeholder="40"
        disabled
      />,
      ""
    ),
    createData(
      "2Lサイズ",
      <CustomPriceForm
        value={data.wholesale_price_2l}
        placeholder="130"
        disabled
      />,
      ""
    ),
    createData(
      "ダウンロード",
      <CustomPriceForm
        value={data.wholesale_price_dl}
        placeholder="140"
        disabled
      />,
      ""
    ),
  ];

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 150 }}>写真サイズ</TableCell>
            <TableCell style={{ width: 90 }} align="right">
              卸値（税込）
            </TableCell>
            <TableCell style={{ width: 490 }} align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.photoSize}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.photoSize}
              </TableCell>
              <TableCell align="right">{row.wholePrice}</TableCell>
              <TableCell align="left">{row.tableMargin}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
