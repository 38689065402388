import * as React from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const StyledTableContainer = styled(TableContainer)({
  background: '#FFFFFF',
  border: '1px solid #E7E7E7',
  boxSizing: 'border-box',
  borderRadius: '16px',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#FFFBE7"
  }
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
  padding: "0px 40px",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
  padding: "0px 40px",
});

export default function MonthTable({ ...props }: any) {
  const { tableHead, tableData, setDay, setIsSetDay } = props;

  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: '54px' }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align='center'>
                    {prop.day}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center' sx={{ width:"33%" }}>
                    {prop.sales}
                  </TableHeadCell>
                  <TableHeadCell key={key} align='center' sx={{ width:"33%" }}>
                    {prop.records}
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop: any, key: any) => {
            return (
              <StyledTableRow key={key} onClick={() => { setDay(prop.order_date.replace( '-', '/' ).replace( '-', '/' )); setIsSetDay(true); }}>
                <TableDataCell key={key} align='right'>
                  {prop.order_date.replace( '-', '/' ).replace( '-', '/' )}
                </TableDataCell>
                <TableDataCell key={key} align='right' sx={{ width:"33%" }}>
                  {Number(prop.subtotal).toLocaleString()}円
                </TableDataCell>
                <TableDataCell key={key} align='right' sx={{ width:"33%" }}>
                  {Number(prop.records).toLocaleString()}件
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}