import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useMutation } from "react-query";

// @mui
import {
  Button,
  Card,
  CardContent,
  Grid,
  Divider,
  FormControlLabel,
} from "@mui/material";

// components
import CustomeCheckBox from "../../components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import { PhotoMordal } from "./PhotoMordal";

// interface
import { IFormInputPhoto } from "./INTERFACES";

// api
import { deletePhoto } from "utils/api/photo/photo";

// APIで取得した写真のpathからfilenameを切り出す処理
// N文字以上の場合は先頭N文字...を返す
const getPhotoName = (path: string) => {
  const filePathAry = path.split("/");
  const fileName = filePathAry[filePathAry.length - 1];
  return fileName.length > 40 ? fileName.substring(0, 40) + "..." : fileName;
};

export default function PhotoGrid(props: any) {
  const { photos, page } = props;
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([""]);
  const photoUseForm = useForm<IFormInputPhoto>();
  const [open, setOpen] = useState(false);
  const [photoUrl, setPhotoUrl] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //ページネーション用
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // ページ切替時の動作
    // 写真のスクロール位置をリセット
    if (ref.current !== null) {
      ref.current.scrollTop = 0;
    }

    // 全て選択のcheckを空にする
    setIsCheckAll(false);
    // 個別の選択状態をリセットする
    setIsCheck([]);
    photoUseForm.setValue("photoIds", []);
  }, [page]);

  // console.log("photoUseForm.getValues");
  // console.log(photoUseForm.getValues("photoIds"));

  let count = 0;
  let targetCount = 0;

  // 全ての写真に対する削除処理（成功/エラー問わず）完了後にリロードする
  const checkDeleteStatus = async () => {
    // console.log("checkcount: " + count);
    // console.log("checktargetCount: " + targetCount);
    if (count >= targetCount) {
      // console.log("finished: " + count);
      // リロード
      window.location.reload();
    }
  };

  // 写真削除API用
  const deletePhotoMutation = useMutation(deletePhoto, {
    onSuccess: async (res) => {
      // console.log("Success deletePhotoMutation");
      // console.log(res.data);
      count++;
      checkDeleteStatus();
    },
    onError: async (error) => {
      // console.log("error deletePhotoMutation");
      // console.log(error);
      count++;
      checkDeleteStatus();
    },
  });

  // 個別削除ボタンの処理
  const handlePhotoDelete = async (photoId: any) => {
    // console.log("handlePhotoDelete");
    // console.log(photoId);
    count = 0;
    targetCount = 1;
    await deletePhotoMutation.mutate({ photo_id: photoId });
  };

  // 全選択チェックを切り替えた時の処理
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("handleSelectAll");
    // 全てを選択チェックの制御
    setIsCheckAll(!isCheckAll);

    // 個別チェックの制御
    if (isCheckAll) {
      // 全てのcheck, valueを空にする
      setIsCheck([]);
      photoUseForm.setValue("photoIds", []);
      // console.log("isCheck ");
      // console.log(isCheck);
    } else {
      // 全てのcheck, valueをセットする
      const checkedVals = new Array<string>();
      photos.map((photo: any) => checkedVals.push(photo.photo_id));
      setIsCheck(checkedVals);
      photoUseForm.setValue("photoIds", checkedVals);
      // console.log("isCheck ");
      // console.log(isCheck);
    }
  };

  // 個別チェックを切り替えた時の処理
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    // console.log("handleClick");
    // console.log("event.target.value ");
    // console.log(value);
    // console.log("event.target.checked ");
    // console.log(checked);
    // console.log(photoUseForm.getValues("photoIds"));

    if (checked) {
      // 個別のcheck, valueをセットする
      setIsCheck([...isCheck, value]);
      photoUseForm.setValue("photoIds", isCheck);
      // console.log("checked ");
      // console.log(checked);
      // console.log("isCheck ");
      // console.log(isCheck);
    } else {
      // 個別のcheck, valueを空にする
      setIsCheck(isCheck.filter((item) => item !== value));
      photoUseForm.setValue("photoIds", isCheck);
      // console.log("checked ");
      // console.log(checked);
      // console.log("isCheck ");
      // console.log(isCheck);
    }
  };

  // 一括削除ボタンの処理
  const onSubmit: SubmitHandler<IFormInputPhoto> = async (data) => {
    // console.log("submit");
    // console.log(data);

    // チェックが1つ以上ある場合のみ実行
    if (data.photoIds) {
      count = 0;
      targetCount = data.photoIds.length;

      data.photoIds.map(async (photoId: any) => {
        if (photoId) {
          await deletePhotoMutation.mutate({ photo_id: photoId });
        }
      });
    }
  };

  return (
    <>
      <PhotoMordal url={photoUrl} open={open} handleClose={handleClose} />
      <FormProvider {...photoUseForm}>
        <form onSubmit={photoUseForm.handleSubmit(onSubmit)}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ padding: "24px" }}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <CustomeCheckBox
                    checked={isCheckAll}
                    onChange={handleSelectAll}
                  />
                }
                label="すべて選択"
              />
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item sx={{ fontSize: "14px" }}>
                  <b>選択した画像を</b>
                </Grid>
                <Grid item>
                  <CustomButton
                    type="submit"
                    color="error"
                    variant="outlined"
                    sx={{ margin: "0" }}
                  >
                    削除
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ borderBottomWidth: "2px" }} />
          <Grid
            container
            sx={{
              padding: "24px",
              maxHeight: "567px",
              overflowY: "scroll",
            }}
            ref={ref}
          >
            <Grid container spacing="16px">
              {photos.map((photo: any) => (
                <Grid item xs={3} key={photo.photo_id}>
                  <Card className="photo-card" sx={{ borderRadius: "16px" }}>
                    <CardContent
                      className="photo-card-background"
                      sx={{ padding: "0px" }}
                    >
                      <CustomeCheckBox
                        register={photoUseForm.register("photoIds")}
                        value={photo.photo_id}
                        checked={isCheck.includes(photo.photo_id)}
                        onClick={handleClick}
                      />
                      <div
                        className="thumbnail-wrapper"
                        onClick={() => {
                          setPhotoUrl(photo.path);
                          handleOpen();
                        }}
                      >
                        <img
                          src={photo.thumbnail}
                          width="100%"
                          className="thumbnail"
                          alt={photo.photo_id}
                        />
                      </div>
                    </CardContent>
                    <CardContent sx={{ padding: "20px" }}>
                      <Grid
                        container
                        rowSpacing={1}
                        sx={{ wordBreak: "break-all", fontSize: "12px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{ fontWeight: "bold", height: "40px" }}
                        >
                          {getPhotoName(photo.path)}
                        </Grid>
                        <Grid item xs={4} sx={{ height: "24px" }}>
                          写真番号
                        </Grid>
                        <Grid item xs={8}>
                          {photo.photo_id}
                        </Grid>
                        <Grid item xs={4} sx={{ height: "24px" }}>
                          撮影日
                        </Grid>
                        <Grid item xs={8}>
                          {photo._datetime
                            ? photo._datetime.substring(0, 10)
                            : ""}
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="flex-end">
                            <Button
                              size="small"
                              sx={{
                                backgroundColor: "#FAFAFA",
                                borderRadius: "4px",
                                fontSize: "12px",
                                color: "#DE5F5F",
                                fontWeight: 700,
                              }}
                              onClick={() => handlePhotoDelete(photo.photo_id)}
                            >
                              削除
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
