import * as React from "react";

// components
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomChip from "components/CustomChip/CustomChip";

import { Link } from "react-router-dom";

export default function BreadCrumbs(props: any) {
  const { albumId, albumName, groupName1 } = props;

  const breadcrumbs = [
    <CustomChip
      label="TOP"
      color="emerald"
      component={Link}
      to="/school-albums"
      clickable
    />,
    <CustomChip
      label={albumName || "写真一覧"}
      color="emerald"
      component={Link}
      to={"/school-album/" + albumId}
      clickable
    />,
    <CustomChip label={groupName1} />,
  ];

  return (
    <>
      <CustomBreadcrumbs>{breadcrumbs}</CustomBreadcrumbs>
    </>
  );
}
