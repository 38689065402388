import React from 'react';
import { Button, Grid } from '@mui/material';
import { PhotoMordal } from './PhotoMordal';
import CheckIcon from '@mui/icons-material/Check';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Photo } from '../Cart/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';

export const PhotoGrid = (props: any) => {
  const {photo} = props;
  const dispatch = useDispatch();
  const wasAdded: any = useSelector<RootState>(state => state.cartReducer.filter(p => p.id === photo.id).length > 0);
  const handlePhotoAdd = () => {
    dispatch({
      type: 'ADD_PHOTO',
      payload: {
        photo,
      }
    });
  };
  const handlePhotoDelete = () => {
    dispatch({
      type: 'DELETE_PHOTO',
      payload: {
        photoId: photo.id,
      }
    });
  };
  return (
    <Grid item xs={6} sm={3} md={2} key={photo.id}>
      <PhotoMordal url={photo.url} thumbnail={photo.thumbnail} alt={photo.id}/>
      { wasAdded ?
      <Button startIcon={<CheckIcon sx={{marginRight: '-0.3em'}}/>} sx={{
        fontSize: "12px",
        marginTop: '8px',
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3)), #23AA91',
        width: {xs: '100%', sm: 'unset'},
        height: {xs: '28px', sm: '24px'},
        color: '#FFFFFF',
        ':hover': {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.3)), #23AA91',
        },
      }} onClick={event => handlePhotoDelete()}>カートから削除</Button> :
      <Button startIcon={<ShoppingCartIcon sx={{marginRight: '-0.3em'}}/>} sx={{
        fontSize: "12px",
        marginTop: '8px',
        backgroundColor: 'emerald.main',
        width: {xs: '100%', sm: 'unset'},
        height: {xs: '28px', sm: '24px'},
        color: '#FFFFFF',
        transition: '.15s ease',
        ':hover': {
          backgroundColor: '#59c4b0',
        },
      }} onClick={event => handlePhotoAdd()}>カートに追加</Button>
    }
    </Grid>
)};