// @material-ui/cores
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// @material-ui/icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PhotoAlbumOutlinedIcon from "@mui/icons-material/PhotoAlbumOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SecurityIcon from "@mui/icons-material/Security";
import InfoIcon from "@mui/icons-material/Info";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";

export const adminNavArray = [
  {
    path: "/admin",
    icon: <DashboardCustomizeOutlinedIcon />,
    text: "ダッシュボード",
  },
  {
    path: "/admin-printing",
    icon: <LocalPrintshopOutlinedIcon />,
    text: "現像機印刷",
  },
  {
    path: "/admin-camera-albums",
    icon: <AddAPhotoOutlinedIcon />,
    text: "アルバム作成",
  },
  {
    path: "/schools-edit/new",
    icon: <AddOutlinedIcon />,
    text: "新規団体登録",
  },
  {
    path: "/school-albums",
    icon: <PhotoAlbumOutlinedIcon />,
    text: "アルバム一覧",
  },
  {
    path: "/admin-schools",
    icon: <SchoolOutlinedIcon />,
    text: "団体一覧",
  },
  {
    path: "/admin-users",
    icon: <AccountBoxOutlinedIcon />,
    text: "会員一覧",
  },
  {
    path: "/admin-download-payment-info",
    icon: <AccountBalanceOutlinedIcon />,
    text: "振込先出力",
  },
  // {
  // 後で直す : id みたいな形に
  //   path: "/school-album/new",
  //   icon: <AddOutlinedIcon />,
  //   text: "アルバム新規作成",
  // },
  // {
  //   path: "/school-dm",
  //   icon: <EmailOutlinedIcon />,
  //   text: "新規メール作成",
  // },
  // {
  //   path: "/schools",
  //   icon: <PersonIcon />,
  //   text: "アカウント設定",
  // },
  {
    path: "/admin-order-history",
    icon: <FormatListBulletedIcon />,
    text: "注文履歴",
  },
  {
    path: "/admin-logout",
    icon: <LogoutOutlinedIcon />,
    text: "ログアウト",
  },
];

export const schoolNavArray = [
  {
    path: "/school-albums",
    icon: <PhotoAlbumOutlinedIcon />,
    text: "アルバム一覧",
  },
  {
    path: "/school-users",
    icon: <AccountBoxOutlinedIcon />,
    text: "会員一覧",
  },
  {
    // 後で直す : id みたいな形に
    path: "/school-album/new",
    icon: <AddOutlinedIcon />,
    text: "アルバム新規作成",
  },
  {
    path: "/school-dm",
    icon: <EmailOutlinedIcon />,
    text: "新規メール作成",
  },
  {
    path: "/schools",
    icon: <PersonIcon />,
    text: "アカウント設定",
  },
  {
    path: "/school-order-history",
    icon: <FormatListBulletedIcon />,
    text: "注文履歴",
  },
  {
    path: "/school-logout",
    icon: <LogoutOutlinedIcon />,
    text: "ログアウト",
  },
];

export const clientNavArray = [
  {
    path: "/albums",
    icon: <PhotoAlbumOutlinedIcon />,
    text: "アルバム一覧",
  },
  {
    path: "/order-history",
    icon: <FormatListBulletedIcon />,
    text: "注文履歴",
  },
  {
    path: "/info-edit/me",
    icon: <PersonIcon />,
    text: "アカウント設定",
  },
  {
    path: "/logout",
    icon: <LogoutOutlinedIcon />,
    text: "ログアウト",
  },
];

export const landingNavArray = [
  {
    path: "/#about",
    icon: <InfoIcon sx={{ color: "#23aa91" }} />,
    text: "ニコスケットって？",
  },
  {
    path: "/#system",
    icon: <PhotoAlbumOutlinedIcon sx={{ color: "#23aa91" }} />,
    text: "写真販売機能",
  },
  {
    path: "/#mail",
    icon: <EmailOutlinedIcon sx={{ color: "#23aa91" }} />,
    text: "メール機能",
  },
  {
    path: "/#security",
    icon: <SecurityIcon sx={{ color: "#23aa91" }} />,
    text: "安心のセキュリティ",
  },
  {
    path: "/#faq",
    icon: <QuestionMarkIcon sx={{ color: "#23aa91" }} />,
    text: "よくあるご質問",
  },
];

export const adminNav = (
  <List>
    {adminNavArray.map((prop, key) => {
      return (
        <ListItem disablePadding>
          <a href={prop.path}>
            <ListItemButton>
              <ListItemIcon>{prop.icon}</ListItemIcon>
              <ListItemText primary={prop.text} />
            </ListItemButton>
          </a>
        </ListItem>
      );
    })}
  </List>
);

export const schoolNav = (
  <List>
    {schoolNavArray.map((prop, key) => {
      return (
        <ListItem disablePadding>
          <a href={prop.path}>
            <ListItemButton>
              <ListItemIcon>{prop.icon}</ListItemIcon>
              <ListItemText primary={prop.text} />
            </ListItemButton>
          </a>
        </ListItem>
      );
    })}
  </List>
);

export const clientNav = (
  <List>
    {clientNavArray.map((prop, key) => {
      return (
        <ListItem disablePadding>
          <a href={prop.path}>
            <ListItemButton>
              <ListItemIcon>{prop.icon}</ListItemIcon>
              <ListItemText primary={prop.text} />
            </ListItemButton>
          </a>
        </ListItem>
      );
    })}
  </List>
);

export const landingNav = (
  <List>
    {landingNavArray.map((prop, key) => {
      return (
        <>
          <a href={prop.path}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>{prop.icon}</ListItemIcon>
                <ListItemText primary={prop.text} />
              </ListItemButton>
            </ListItem>
          </a>
          {landingNavArray.length - 1 === key ? <></> : <Divider />}
        </>
      );
    })}
  </List>
);

export const landingPCNav = (
  <>
    {landingNavArray.map((prop, key) => {
      return <a href={prop.path}>{prop.text}</a>;
    })}
  </>
);
