import SamplePhoto from '../../assets/sample_photo.png';
import { AnyAction } from 'redux';
import { CartActionTypes } from "../actions/CartActions";

export interface PhotoSize {
  name: string;
  displayName: string;
  sizeDetail: string;
  unitPrice: number;
  count?: number;
  maxCount?: number;
};

export interface Photo {
  id: string;
  albumId: string;
  groupId: string;
  url: string;
  photoSizes: PhotoSize[];
};

const getCount = (photo: Photo, sizeName: string): {sizeIndex: number|null, count: number|null} => {
  let index = 0;
  for (const photoSize of photo.photoSizes) {
    if (photoSize.name === sizeName && typeof photoSize.count === 'number') {
      return {
        sizeIndex: index,
        count: photoSize.count
      };
    }
    index++;
  }
  return {
    sizeIndex: null,
    count: null
  };
};

const getStateAppliedCountHook = (state: Photo[], countHook: (count: number) => number, photoId: string, sizeName: string): Photo[]|null => {
  let index = 0;
  for (const photo of state) {
    if (photo.id === photoId) {
      const {sizeIndex, count} = getCount(photo, sizeName);
      if (typeof sizeIndex === 'number' && typeof count === 'number') {
        let countNew = Math.max(countHook(count), 0);
        const maxCount = state[index].photoSizes[sizeIndex].maxCount;
        if (typeof maxCount !== 'undefined') {
          countNew = Math.min(countNew, maxCount);
        }
        return [
          ...state.slice(0, index),
          Object.assign({}, 
            state[index],
            {
              photoSizes: [
                ...state[index].photoSizes.slice(0, sizeIndex),
                Object.assign({}, 
                  state[index].photoSizes[sizeIndex],
                  {
                    count: countNew,
                  }),
                ...state[index].photoSizes.slice(sizeIndex + 1),
              ]
            }),
          ...state.slice(index + 1),
        ];
      }
    }
    index++;
  }
  return null;
}
export const cartReducer = (state: Photo[] = [], action: AnyAction): Photo[] => {
  switch (action.type) {
    case CartActionTypes.DELETE_PHOTO:
      return state.filter(photo => photo.id !== action.payload.photoId);
    case CartActionTypes.ADD_PHOTO:
      if (state.filter(photo => photo.id === action.payload.photo.id).length === 0)
        return state.concat([action.payload.photo]);
      else
        return state;
    case CartActionTypes.INCREMENT_COUNT:
      const stateIncremented = getStateAppliedCountHook(state, (count: number) => (count + 1), action.payload.photoId, action.payload.sizeName);
      return stateIncremented !== null? stateIncremented : state;
    case CartActionTypes.DECREMENT_COUNT:
      const stateDecremented = getStateAppliedCountHook(state, (count: number) => (count - 1), action.payload.photoId, action.payload.sizeName);
      return stateDecremented !== null? stateDecremented : state;
    case CartActionTypes.INIT_CART:
      return [];
    default:
      return state;
  }
};