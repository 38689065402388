import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import {
  Button,
  Grid,
  createStyles,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  Theme,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@mui/styled-engine";
import OrderCountsByPicSize from "../Cart/OrderCountsByPicSize";
import { Photo, PhotoSize } from "../Cart/Cart";
import OrderTotalSum from "./OrderTotalSum";
import { Link, useSearchParams } from "react-router-dom";
import { GrayButton } from "./GrayButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { useMutation, useQuery } from "react-query";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getUsersByUserIdMutation } from "utils/api/users/users";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getSchools } from "utils/api/schools/schools";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { getClasses } from "utils/api/classes/classes";
import {
  ordersPostValue,
  ordersReturnValue,
} from "utils/interface/orders/ordersInterface";
import {
  formatDate,
  getOrderDetails,
  getTotal,
  paymentMethodMap,
} from "./utils";
import { getOrdersMutation, patchOrder } from "utils/api/orders/orders";
import formatPhoneNumber from "utils/function/formatPhoneNumber";
import { GMOEpsilonPostValue } from "utils/interface/GMOEpsilon/GMOEpsilonInterface";
import { postGMOEpsilonPayment } from "utils/api/GMOEpsilon/GMOEpsilon";
import { getOrderCompletedMailBody } from "./getOrderCompletedMailBody";
import { postDm } from "utils/api/dm/dm";

export interface OrderPhoto {
  order_id?: string;
  user_id?: string;
  school_id?: string;
  order_status?: string;
  taxed_price?: string;
  order_price?: string;
  amount?: string;
  shipping_method?: string;
  commission?: string;
  postage?: string;
  delivery_class?: string;
  order_details?: {
    order_id?: string;
    photo_id?: string;
    select_size?: string;
    amount?: string;
    wholesale_price?: string;
    unit_price?: string;
    count?: number;
  }[];
  order_date?: string;
  del_flg?: string;
  payment?: string;
  conveni_code?: string;
}

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      marginRight: "48px",
      fontSize: "12px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    bold: {
      fontWeight: 700,
    },
    ordererUl: {
      padding: 0,
      margin: 0,
    },
    ordererLi: {
      fontWeight: 700,
      listStyleType: "none",
      "& + $ordererLi": {
        marginTop: "1em",
      },
    },
    formControlLabel: {
      fontSize: "14px",
      "& label": { fontSize: "14px" },
    },
  });

const OrderCard = (props: any) => {
  const { children } = props;
  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: "20px",
      }}
    >
      <CardContent sx={{ padding: "20px" }}>{children}</CardContent>
    </Card>
  );
};

const Section = (props: any) => {
  const { title, children } = props;
  return (
    <Box>
      <h3>
        <Box sx={{ fontSize: "16px", marginBottom: "12px" }}>{title}</Box>
      </h3>
      <OrderCard>{children}</OrderCard>
    </Box>
  );
};

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDB600",
  color: "#FFFFFF",
  fontSize: "14px",
  borderRadius: "32px",
  height: "56px",
  "&:hover": {
    backgroundColor: "#ffd057",
  },
  "&:active": {
    backgroundColor: "#FDB600",
  },
  "&.Mui-disabled": {
    backgroundColor: "#b1b1b1b3",
    color: "#FFFFFF",
  },
})) as any;

const isNotOnlyDLFromOrder = (orders: ordersReturnValue): boolean => {
  if (orders.order_details) {
    let photoSizeCounts = { "2L": 0, L: 0, DL: 0 };
    for (const order of orders.order_details) {
      if (
        order.select_size &&
        (order.select_size === "2L" ||
          order.select_size === "L" ||
          order.select_size === "DL")
      ) {
        photoSizeCounts[order.select_size]++;
      }
    }
    return photoSizeCounts["2L"] + photoSizeCounts.L > 0;
  } else {
    return false;
  }
};

const OrderCompleted = (props: any) => {
  const { classes } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState<ordersReturnValue>({});
  dispatch({ type: "INIT_CART" });

  const conveniNextstep = searchParams
    .get("conveni_nextstep")
    ?.replace("'", "");
  const receiptNo = searchParams.get("receipt_no");
  const kigyouCode = searchParams.get("kigyou_code");
  const collectFlag = searchParams.get("collect_flag") === "1" ? true : false;

  // 注文完了メール送信対象を取得する
  const dmTargetOrderId: any = useSelector<RootState>(
    (state) => state.orderDMReducer.orderId
  );

  const deleteDmTargetOrderId = () => {
    dispatch({
      type: "DELETE_ORDERID",
    });
  };

  const postDmMutation = useMutation(postDm, {
    onSuccess: async (res) => {
      console.log("DMSuccess");
      // console.log(res.data);
      // 重複送信防止のため、注文完了メール送信対象フラグを削除する
      deleteDmTargetOrderId();
    },
    onError: async (res) => {
      console.log("DMFailure");
    },
    retry: 10,
    retryDelay: 100,
  });

  const getUserMutation = useMutation(getUsersByUserIdMutation, {
    onSuccess: async (res) => {
      // 重複送信防止のため、注文完了メール送信対象フラグとorderIdが一致した場合のみメール送信する
      if (
        dmTargetOrderId &&
        searchParams.get("order_number") === dmTargetOrderId
      ) {
        const user = res[0];
        const dmData = {
          letter_body: getOrderCompletedMailBody(
            order,
            user,
            receiptNo,
            kigyouCode,
            conveniNextstep
          ),
          title: "【ニコスケット】ご注文ありがとうございます",
          user_id: user.user_id,
          // 2023/5 システムメールを履歴に出さないため、school_idは空とする
          // school_id: user.school_id,
        };
        // console.log(dmData);
        postDmMutation.mutate(dmData);
      }
    },
    onError: async (res) => {
      console.log("Failure");
    },
    retry: 10,
    retryDelay: 100,
  });

  const patchOrderMutation = useMutation(patchOrder, {
    onSuccess: async (res) => {
      console.log("Success");
      getUserMutation.mutate([searchParams.get("user_id")]);
    },
    onError: async (res) => {
      console.log("Failure");
    },
    retry: 10,
    retryDelay: 100,
  });

  const getOrderMutation = useMutation(getOrdersMutation, {
    onSuccess: async (res) => {
      console.log("Success");
      setOrder(res[0]);
      const orderData = {
        ...res[0],
        order_status:
          res[0].payment === "card" && res[0].order_status === "手続き中"
            ? isNotOnlyDLFromOrder(res[0])
              ? "入金済み"
              : "入金済みDL"
            : res[0].order_status,
        // res[0].payment === "card" && res[0].order_status === "手続き中"
        //   ? "入金済み"
        //   : res[0].order_status,
      };
      patchOrderMutation.mutate([orderData, res[0].order_id]);
    },
    onError: async (res) => {
      console.log("Failure");
    },
    retry: 10,
    retryDelay: 100,
  });

  useEffect(() => {
    getOrderMutation.mutate([
      null,
      null,
      null,
      searchParams.get("order_number"),
    ]);
  }, []);

  return (
    <div className={classes.wrapper}>
      <h2>ご注文ありがとうございました</h2>
      <Section title="ご注文番号">
        <span className={classes.bold}>{searchParams.get("order_number")}</span>
      </Section>

      {order.payment === "conveni" && (
        <>
          {conveniNextstep && (
            <Section title="コンビニ支払い詳細リンク">
              <a
                href={conveniNextstep}
                className={classes.bold}
                target="_blank"
                rel="noreferrer"
              >
                {conveniNextstep}
              </a>
            </Section>
          )}
          {receiptNo && (
            <Section title="コンビニ支払い受付番号">
              <span className={classes.bold}>{receiptNo}</span>
            </Section>
          )}
          {kigyouCode && (
            <Section title="コンビニ支払い企業コード">
              <span className={classes.bold}>{kigyouCode}</span>
            </Section>
          )}
        </>
      )}
      <NextButton
        fullWidth
        component={Link}
        to={"/order/" + searchParams.get("order_number")}
      >
        注文詳細をみる
      </NextButton>
      <Box sx={{ marginTop: "20px" }}>
        <GrayButton component={Link} to="/albums">
          TOPへ戻る
        </GrayButton>
      </Box>
    </div>
  );
};

export default withStyles(styles)(OrderCompleted);
