import { Suspense } from "react";
import Grid from "@mui/material/Grid";

import GetOrderHistory from "components/CustomTableOrderHistory/GetOrderHistory";
import { ADMINHEAD } from "./OrderHistoryDataSet";

export default function OrderHistoryAdminTable(props: any) {
  // ここを編集
  const filterKey: string = "";
  const sortKey: string = "sales_period_to";
  const filter: string = "";
  const urlKey: string = "school_id";
  const filterArray: string[] = ["すべて"];
  const group_key: string = "school";
  const { query } = props;

  return (
    <Grid
      container
      rowSpacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
    >
      <Suspense fallback={<p>Loading...</p>}>
        <GetOrderHistory
          filterKey={filterKey}
          sortKey={sortKey}
          filter={filter}
          urlKey={urlKey}
          filterArray={filterArray}
          TABLEHEAD={ADMINHEAD}
          query={query}
          group_key={group_key}
        />
      </Suspense>
    </Grid>
  );
}
