import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function UserChildrenView(props: any) {
  const { register, formState } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <ErrorMessage
          errors={formState.errors}
          name="child_name"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomLabel value={"お子様のお名前"} />
        <CustomForm
          placeholder="お子様のお名前をカンマ(,)区切りで入力してください"
          helperlabel="例：太郎,花子"
          register={register("child_name", {
            required: "⚠︎必須項目です",
          })}
        />
      </Grid>
    </Grid>
  );
}
