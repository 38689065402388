import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";

// components
import Box from "@mui/material/Box";

// img
import flyer from "assets/img/UserRegistFlyer.png";

export default function UserRegistFlyer() {
  const school_id = useParams().SchoolId;
  // const url = `http://localhost:3000/user-account-regist/${school_id}`;
  const url = `https://nicosket.link/user-account-regist/${school_id}`;
  console.debug(url);

  return (
    <>
      <Box
        className="main"
        sx={{
          width: { sm: "210mm" },
          height: { sm: "297mm" },
        }}
      >
        <div className="top">
          <img
            src={flyer}
            style={{
              position: "absolute",
              width: "210mm",
              height: "297mm",
              top: "0px",
              left: "0px",
            }}
          />
        </div>
        <div>
          <QRCode
            value={url}
            size={100}
            style={{
              position: "absolute",
              top: "223mm",
              left: "133mm",
            }}
          />
        </div>
      </Box>
    </>
  );
}
