import { useQuery } from "react-query";

// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// custom components
import CustomTable from "components/CustomTable/CustomTable_org";

import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";

export default function SearchResult(props: any) {
  const { query } = props;

  const { isLoading, isError, data, error } = useQuery<
    schoolsReturnValue[],
    Error
  >(["getSchoolsSample", query.school_id, query.school_name], getSchools);

  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  const DATA = data?.map((purchase: schoolsReturnValue) => [
    purchase.school_id,
    purchase.school_name,
    purchase.school_kana,
    purchase.contract_start,
    purchase.update_date,
    purchase.del_flg,
  ]);
  const HEAD = [
    "school_id",
    "school_name",
    "school_kana",
    "contract_start",
    "update_date",
    "del_flg",
  ];

  return (
    <div>
      <Grid md={10}>
        <Card>
          <CardContent>
            <CustomTable tableHead={HEAD} tableData={DATA} />
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}
