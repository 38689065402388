import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { createTheme } from "@mui/material/styles";
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import AdminPrintingTable1 from './AdminPrintingIDTable1';
import AdminPrintingTable2 from './AdminPrintingIDTable2';
import { orderHistory } from "utils/interface/order/orderInterface";
import { getOrderHistory, getOrder } from "utils/api/order/order";
import { patchOrder } from "utils/api/orderhistory/orderhistory";

export const customeTheme = createTheme({
  palette: {
    emerald: {
      main: "#23AA91",
      contrastText: "#fff",
    }
  }
});

declare module "@mui/material/FormControl" {
  interface FormControlPropsColorOverrides {
    emerald: true;
  }
}

export default function AdminPrinting(props: any) {
  const rowsPerPage = 5;
  const [page1, setPage1] = useState(1);
  const [page2, setPage2] = useState(1);

  const [patchList, setPatchList] = useState<any[]>([]);
  const [message, setMessage] = useState<string | undefined>("");

  // APIのパッチ用
  const patchOrderMutation = useMutation(patchOrder, {
    onSuccess: (data, variables, context) => {
      if (data.status===200) {
        console.log("Success patchOrderMutation");
        window.location.reload();
      } else {
        setMessage("Error");
      }
    },
    onError: (error, variables, context) => {
      setMessage("Error: " + error);
    },
  });
  const dmUseForm = useForm<orderHistory>({
    mode: "onBlur",
  });
  const onSubmit: SubmitHandler<any> = () => {
    // パッチ処理
    setMessage("");
    patchList.map((item: any) => patchOrderMutation.mutate(item));
  };

  // APIでorderHistory取得
  const getOrderHistoryResponse = useQuery<orderHistory[], Error>(["getOrderHistory", "photo", "", "", "", "", "", ""],getOrderHistory);
  const getOrderResponse = useQuery<orderHistory[], Error>(["getOrder", "", "", "", "", "", "入金済み,印刷待ち"],getOrder);
  if (getOrderHistoryResponse.isLoading || getOrderResponse.isLoading) {return <span>Loading...</span>;}
  if (getOrderHistoryResponse.isError) {return <span>Error: {getOrderHistoryResponse.error?.message}</span>;}
  if (getOrderResponse.isError) {return <span>Error: {getOrderResponse.error?.message}</span>; }
  const orderResponseData: any = getOrderResponse.data;
  const orderHistoryData: any = getOrderHistoryResponse.data;

  // 個別配送のみにフィルター
  const orderData = orderHistoryData.filter((x: any) => (x.shipping_method === '自宅配送'));
  const orderResponseDataTmp: any = orderResponseData.filter((x: any) => (x.shipping_method === '自宅配送'));

  // ステータスで絞り込み
  const paidData: any = orderData.filter((x: any) => (x.order_status === '入金済み'));
  const printWaitData: any = orderData.filter((x: any) => (x.order_status === '印刷待ち'));
  const patchData: any = orderResponseDataTmp.filter((x: any) => (x.order_status === '入金済み'));

  const HEAD = [{
    'order_status':'ステータス',
    'order_id':'注文番号',
    'order_date':'注文日',
    'user_name':'ご注文者',
    'school_name':'団体名',
    'amount':'注文枚数',
  }];

  return (
    <>
      <form onSubmit={dmUseForm.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="baseline">
          <Grid item xs={12}>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
              <Stack direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
              <CustomButton color="emerald" variant="outlined" href="/admin-printing">
                一括配送
              </CustomButton>
              <CustomButton color="emerald" variant="contained">
                自宅配送
              </CustomButton>
            </Stack>
          </Stack>
          </Grid>
          <Grid item xs={12}>
            <h2>注文履歴の未印刷一覧</h2>
            <AdminPrintingTable1
              tableHead={HEAD}
              tableData={paidData}
              page={page1}
              rowsPerPage={rowsPerPage}
              patchList={patchList}
              setPatchList={setPatchList}
              patchData={patchData}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
              <Stack direction="row" alignItems="baseline" justifyContent="flex-start" spacing={2}>
                { paidData.length ? (
                    <div>
                      全{paidData.length}件中{(page1 - 1) * rowsPerPage + 1}件〜{Math.min(paidData.length, (page1 - 1) * rowsPerPage + rowsPerPage)}件を表示
                    </div>
                  ) : (
                    <div>
                      全0件中0件〜0件を表示
                    </div>
                )}
                <Pagination
                  page={page1}
                  count={Math.ceil(paidData.length/rowsPerPage)}
                  shape='circular'
                  color='emerald'
                  onChange={(e, page) => setPage1(page)}
                />
              </Stack>
              <CustomButton color="emerald" variant="contained" type="submit">
                印刷
              </CustomButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <span>{message}</span>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <h2>印刷待ち一覧</h2>
            <AdminPrintingTable2
              tableHead={HEAD}
              tableData={printWaitData}
              page={page2}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
          <Grid item>
            { printWaitData.length ? (
                <div>
                  全{printWaitData.length}件中{(page2 - 1) * rowsPerPage + 1}件〜{Math.min(printWaitData.length, (page2 - 1) * rowsPerPage + rowsPerPage)}件を表示
                </div>
              ) : (
                <div>
                  全0件中0件〜0件を表示
                </div>
            )}
          </Grid>
          <Grid item>
            <Pagination
              page={page2}
              count={Math.ceil(printWaitData.length/rowsPerPage)}
              shape='circular'
              color='emerald'
              onChange={(e, page) => setPage2(page)}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}