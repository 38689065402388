import axios from "axios";
import { orderHistory } from "utils/interface/order/orderInterface";
import { orderReturnValue } from "utils/interface/order/orderInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getOrderHistory = async (params: any) => {
  const response = await axios.get<orderHistory[]>(BASE_URL + "orderhistory", {
    params: {
      group_key: params.queryKey[1],
      school_id: params.queryKey[2],
      album_id: params.queryKey[3],
      user_id: params.queryKey[4],
      order_status: params.queryKey[5],
      order_year: params.queryKey[6],
      order_month: params.queryKey[7],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getOrder = async (params: any) => {
  const response = await axios.get<orderReturnValue[]>(BASE_URL + "orders", {
    params: {
      user_id: params.queryKey[1],
      order_date_from: params.queryKey[2],
      order_id: params.queryKey[3],
      order_date_to: params.queryKey[4],
      school_id: params.queryKey[5],
      order_status: params.queryKey[6],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};
