import { albumsReturnValueStrict } from "utils/interface/albums/albumsInterface";
import { getTodayDate, getInOneYear } from "utils/function/utilDate";

// アルバムデータのfrom/toを元に、ステータスを挿入する
export const SALES_STATUS: string[] = [
  "すべて",
  "開始前",
  "販売中",
  "延長販売",
  "販売終了",
  "販売前",
];

// アルバムのステータスを作成する
export const addAlbumStatus = (albumData: any): any => {
  const TODAY: any = getTodayDate(new Date());
  for (let i: number = 0; i < albumData.length; i++) {
    if (TODAY < new Date(albumData[i].sales_period_from)) {
      let calcDate: any = new Date(albumData[i].sales_period_from);
      calcDate = (calcDate - TODAY) / (60 * 60 * 1000 * 24);
      albumData[i].sales_status = SALES_STATUS[1];
      albumData[i].days_left = calcDate;
      continue;
    }

    if (!albumData[i].sales_period_from) {
      albumData[i].sales_status = SALES_STATUS[5];
      continue;
    }

    const inOneYear = getInOneYear(new Date(albumData[i].sales_period_to));
    if (inOneYear < TODAY) {
      albumData[i].sales_status = SALES_STATUS[4];
      continue;
    }

    if (TODAY <= new Date(albumData[i].sales_period_to)) {
      albumData[i].sales_status = SALES_STATUS[2];
    } else {
      if (
        albumData[i].shipping_method &&
        albumData[i].shipping_method === "自宅配送"
      ) {
        albumData[i].sales_status = SALES_STATUS[3];
      } else {
        albumData[i].sales_status = SALES_STATUS[4];
      }
    }
  }
  return albumData;
};

// 自分が見れるアルバムだけ表示する
export const filterChildclass = (
  albumData: any,
  childClassArray: string[]
): albumsReturnValueStrict[] => {
  let include_flg: boolean;
  return albumData.filter((data: albumsReturnValueStrict) => {
    include_flg = false;
    const sales_class_array = JSON.parse(
      data.sales_classes.replaceAll("'", '"')
    );
    // console.log(sales_class_array);
    sales_class_array.map((sales_class: string) => {
      // console.log(childClassArray.includes(sales_class));
      include_flg = include_flg || childClassArray.includes(sales_class);
    });
    return include_flg;
  });
};

// removePreparingAlbums
export const removePreparingAlbums = (
  albumData: any
): albumsReturnValueStrict[] => {
  // remove 販売前
  let newAlbumData: albumsReturnValueStrict[] = albumData.filter(
    (data: albumsReturnValueStrict) => data.sales_status !== SALES_STATUS[5]
  );

  return newAlbumData;
};

// 並べ替えロジック
type Order = "asc" | "desc";
export const sortColumnsByKey = (
  o: Order,
  r: any,
  key: string,
  setOrder: any,
  setRows: any
) => {
  console.debug("call!!!");
  const nextOrder = o === "desc" ? "asc" : "desc";
  const sortRule = { asc: [1, -1], desc: [-1, 1] };
  const sortedRows = r.sort((a: any, b: any) => {
    if (a[key] > b[key]) {
      return sortRule[nextOrder][0];
    } else if (a[key] < b[key]) {
      return sortRule[nextOrder][1];
    } else {
      return 0;
    }
  });
  setOrder(nextOrder);
  setRows(sortedRows);
};

export const getChipColor = (filterKey: string): any => {
  if (filterKey === SALES_STATUS[1]) {
    return "orange";
  } else if (filterKey === SALES_STATUS[2]) {
    return "emerald";
  } else if (filterKey === SALES_STATUS[3]) {
    return "lime";
  } else if (filterKey === SALES_STATUS[4]) {
    return "neutral";
  } else {
    return "ruby";
  }
};
