import { AnyAction } from 'redux';
import { OrderActionTypes } from "../actions/OrderActions";

export interface OrderDetails {
  deliveryClassId: string;
  deliveryClassName: string;
  paymentMethod: string;
  conveniCode: string;
  conveniName: string;
};


export const orderReducer = (state: OrderDetails | {} = {paymentMethod : 'card'}, action: AnyAction): OrderDetails | {} => {  
  switch (action.type) {
    case OrderActionTypes.SELECT_DELIVERY_CLASS:
      return { ...state, deliveryClassId : action.payload.deliveryClassId, deliveryClassName : action.payload.deliveryClassName };
    case OrderActionTypes.SELECT_PAYMENT_METHOD:
      return { ...state, paymentMethod : action.payload.paymentMethod };
    case OrderActionTypes.SELECT_CONVENI:
      return { ...state, conveniCode : action.payload.conveniCode, conveniName : action.payload.conveniName };
    case OrderActionTypes.INIT_ORDER:
      return { paymentMethod : 'card' }
    default:
      return state;
  }
};