import React, { useState } from "react";
import { useMutation } from "react-query";

// compornents
import { GroupKeyView } from "./GroupKeyView";
import ContentsFooter from "./ContentsFooter";
import GetAccounts from "./GetAccounts";

import { useForm, SubmitHandler } from "react-hook-form";

// api
import { loginUser } from "utils/api/authentication/authentication";
import { authenticationReturnValue } from "utils/interface/authentication/authenticationInterface";

export default function ContentsViewContainer(props: any) {
  // ログイン可能なユーザ種別
  const { type } = props;
  const [accountId, setAccountId] = useState<string | undefined>("");
  const [message, setMessage] = useState<string | undefined>("");

  const postLoginMutation = useMutation(loginUser, {
    onSuccess: (data, variables, context) => {
      // console.log(data);
      // console.log(variables);

      // 認証結果がtrueならアカウント情報を取得
      if (data.data.result) {
        // console.log("isAuthed");
        setAccountId(variables.account_id);
      } else {
        // console.log("isNotAuthed");
        setMessage("Error: ログインに失敗しました");
      }
    },
    onError: (error, variables, context) => {
      setMessage("Error: " + error);
    },
  });

  const { register, handleSubmit } = useForm<authenticationReturnValue>();

  const onSubmit: SubmitHandler<authenticationReturnValue> = (data) => {
    setAccountId("");
    setMessage("");
    postLoginMutation.mutate(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GroupKeyView register={register} />
        <ContentsFooter />
      </form>
      <span>{message}</span>
      <GetAccounts accountId={accountId} type={type} />
    </>
  );
}
