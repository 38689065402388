import { Link } from "react-router-dom";

// @mui
import Box from "@mui/material/Box";

// components
import ContentsHeader from "./ContentsHeader";
import ContentsViewContainer from "./ContentsViewContainer";

export default function LoginUser() {
  return (
    <>
      <Box
        sx={{
          margin: { xs: "10vh auto", sm: "20vh auto" },
          padding: { xs: "20px", sm: "50px" },
          width: { xs: "90%", sm: "600px" },
          backgroundColor: "#fff",
        }}
      >
        <ContentsHeader />
        <ContentsViewContainer type={"user"} />
        <p style={{ textAlign: "center" }}>
          <Link to="/resetpassword">
            ※パスワードをお忘れの方はこちらをクリック
          </Link>
        </p>
      </Box>
    </>
  );
}
