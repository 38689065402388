// functions
import getSchoolYear from "utils/function/getSchoolYear";

// other
import { classesReturnValue } from "utils/interface/classes/classesInterface";

export default function updateNewClassList(
  class_name: string,
  class_num: Number,
  newClassList: classesReturnValue[],
  setNewClassList: any,
  school_id: string
) {
  setNewClassList(
    newClassList.map((newClass: classesReturnValue, index: Number) =>
      index === class_num
        ? {
            class_name: class_name,
            school_id: school_id,
            year: getSchoolYear(),
          }
        : newClass
    )
  );
}
