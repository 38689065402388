import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

// @mui
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// compornents
import GroupKeyView from "./GroupKeyView";
import ContentsFooter from "./ContentsFooter";
import UserClassView from "./UserClassView";
import UserNameView from "./UserNameView";
import UserPhoneNumberView from "./UserPhoneNumberView";
import UserAddressView from "./UserAddressView";
import UserChildrenView from "./UserChildrenView";

// interface
import { IFormInput } from "../../utils/interface/account/accountInterface";

// APIs
import { createAccount } from "utils/api/authentication/authentication";
import { postAccount } from "utils/api/account/account";
import { postUsers } from "utils/api/users/users";
import { postDm } from "utils/api/dm/dm";
import { useQuery } from "react-query";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getUsersByParams } from "utils/api/users/users";
import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";

// functions
import { mailText } from "./maitText";
import getSchoolYear from "utils/function/getSchoolYear";

const CustomDivider = styled(Divider)({
  marginBottom: "20px",
});

// メールアドレスが既に存在するかどうかを判定する関数
function isUserIdExist(dictionaryArray: any, key: any, value: any): boolean {
  return dictionaryArray.some((dictionary:any) => dictionary[key] === value);
}

export default function ContentsViewContainer() {
  const school_id = useParams().SchoolId;
  let navigate = useNavigate();
  let [accountData, setAccountData] = useState<any>();
  let [userData, setUserData] = useState<any>();
  const [message, setMessage] = useState<string | undefined>("");
  const [messageMail, setMessageMail] = useState<string | undefined>("");
  const [isMailExist, setIsMailExist] = useState<boolean>(false);
  const [existClassList, setExistClassList] = useState<classesReturnValue[]>(
    []
  );

  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getSchools", "", school_id, getSchoolYear()],
    getClasses
  );
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsersByParams", "", school_id, ""],
    getUsersByParams
  );

  const createAccountMutation = useMutation(createAccount, {
    onSuccess: async (res) => {
      console.debug("createAccount Success");
      console.debug(res.status);
      if (res.status == 200) {
        await postAccountMutation.mutate(accountData);
      } else {
        // setMessage(
        //   "Error: 登録に失敗しました。初めからやり直してください。\n" +
        //     res.data.message
        // );
        setMessage("Error: 登録に失敗しました。初めからやり直してください。");
      }
    },
    onError: async (res) => {
      setMessage("Error: 登録に失敗しました。初めからやり直してください。");
    },
  });
  const postAccountMutation = useMutation(postAccount, {
    onSuccess: async (res) => {
      console.debug("postAccount Success");
      console.debug(res.status);
      if (res.status == 200) {
        await postUsersMutation.mutate(userData);
      } else {
        setMessage("Error: 登録に失敗しました。初めからやり直してください。");
      }
    },
    onError: async (res) => {
      setMessage("Error: 登録に失敗しました。初めからやり直してください。");
    },
  });
  const postUsersMutation = useMutation(postUsers, {
    onSuccess: async (res) => {
      console.debug("postUsers Success");
      console.debug(res);
      if (res.status == 200 && (res.data.message ? false : true)) {
        await postDmMutation.mutate({
          user_id: userData.user_id,
          title: "登録完了のお知らせ(ニコスケット)",
          letter_body: mailText(userData),
        });
      } else {
        setMessage(
          "Error: 登録に失敗しました。内容を確認して初めからやり直してください。"
        );
      }
    },
    onError: async (res) => {
      setMessage("Error: 登録に失敗しました。初めからやり直してください。");
    },
  });

  const postDmMutation = useMutation(postDm, {
    onSuccess: async (res) => {
      console.debug("Success");
      console.debug(res.data);
      navigate("/login/");
    },
    onError: async (res: any) => {
      console.debug("Error");
      console.debug(res);
      postDmMutation.mutate(JSON.parse(res.config.data));
    },
  });

  const { register, handleSubmit, getValues, trigger, formState } =
    useForm<IFormInput>({ mode: "onBlur" });

  let classList: string[] = [];
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setMessage("Loading...");
    console.debug(data);
    existClassList.map((existClass) => {
      if (existClass.child_flg && existClass.class_id) {
        classList.push(existClass.class_id);
      }
    });

    delete data.password_confirmation;
    const address = `${data.prefecture} ${data.municipalities} ${data.houseNumber} ${data.buildings}`;
    const user_data = {
      user_id: data.account_id,
      school_id: school_id,
      child_class: JSON.stringify(classList),
      child_name: data.child_name,
      user_name: data.user_name,
      user_kana: data.user_kana,
      phone_number: data.phone_number,
      zip: data.zip,
      address: address,
    };
    delete data.user_name;
    delete data.user_kana;
    delete data.phone_number;
    delete data.zip;
    delete data.prefecture;
    delete data.municipalities;
    delete data.houseNumber;
    delete data.buildings;
    delete data.child_name;

    let account_data = data;
    data = { ...data, email: data.account_id };
    account_data = {
      ...account_data,
      account_type: "user",
      permission: [JSON.stringify({ school_id: school_id })],
    };

    setAccountData(account_data);
    setUserData(user_data);

    console.debug(data);
    console.debug(accountData);
    console.debug(userData);

    await createAccountMutation.mutate(data);

    // 既にメールアドレスが登録してある場合はエラー
    if (isUserIdExist(users, "user_id", data.account_id)){
      setMessageMail(
        "Error: 既に登録されているメールアドレスです。"
      );
      setIsMailExist(true);
    };
  };

  if (getClassesResponse.isLoading || getUsersResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  const classes = getClassesResponse.data ? getClassesResponse.data : {};
  const users = getUsersResponse.data ? getUsersResponse.data : {};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserNameView register={register} formState={formState} />
      <CustomDivider />
      <UserAddressView register={register} formState={formState} />
      <CustomDivider />
      <UserPhoneNumberView register={register} formState={formState} />
      <CustomDivider />
      <UserChildrenView register={register} formState={formState} />
      <CustomDivider />
      <UserClassView
        classes={classes}
        existClassList={existClassList}
        setExistClassList={setExistClassList}
      />
      <CustomDivider />
      <GroupKeyView
        register={register}
        getValues={getValues}
        trigger={trigger}
        formState={formState}
      />
      {isMailExist ? <span>{messageMail}</span> : <span>{message}</span>}
      <ContentsFooter />
    </form>
  );
}