import axios from "axios";
import { dmHistoryReturnValue } from "utils/interface/dmHistory/dmHistoryInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getDmHistory = async (params: any) => {
  const response = await axios.get<dmHistoryReturnValue[]>(BASE_URL + "dmhistory", {
    params: {
      school_id: params.queryKey[1],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};