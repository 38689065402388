import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import iconA from "../../assets/img/lp-icon-a.svg";
import iconQ from "../../assets/img/lp-icon-q.svg";

const faqRows = [
  {
    id: 0,
    que: "導入費用は何もかからないのですか？",
    ans: "一切かかりません。初期費用や毎月の利用料金、解約時の違約金など負担するものは何もありません。お気軽にご利用ください。",
  },
  {
    id: 1,
    que: "システムが導入できない園でも利用できますか？",
    ans: "利用出来ます。公立園などを中心にSDカードを当社に送るだけでインターネット写真販売が出来るプランをご用意しています。",
  },
  {
    id: 2,
    que: "申込後、いつから利用できますか？",
    ans: "お申込み後、最短３日以内にご利用出来ます。",
  },
  {
    id: 3,
    que: "写真代金の支払方法は？",
    ans: "クレジットカード払い、コンビニ払いをご利用いただけます。※配送方法によって集金の場合もございます。",
  },
  {
    id: 4,
    que: "保育園・幼稚園しか使えないシステムですか？",
    ans: "どの団体様でもご利用いただけます。小学校やスポーツクラブ、保護者会など幅広い団体様にご利用いただいております。",
  },
  {
    id: 5,
    que: "販売できる回数や契約期間に制限はありますか？",
    ans: "制限は何もございません。写真販売の時期になりましたらいつでもご利用ください。",
  },
  {
    id: 6,
    que: "写真購入後のキャンセルは出来ますか？",
    ans: "完全受注生産のため、ご注文確定後のキャンセルはお受けすることができません。",
  },
];

export default function FaqTable() {
  return (
    <div>
      <Table className="faq-table">
        <TableBody>
          {faqRows.map((row) => (
            <div>
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    borderBottom: "unset",
                    padding: "32px 6px 12px",
                    verticalAlign: "top",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <img src={iconQ} alt="question" className="faq-icon" />
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "unset",
                    padding: "32px 6px 12px",
                    verticalAlign: "top",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <b>{row.que}</b>
                </TableCell>
              </TableRow>
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    padding: "12px 6px 32px",
                    verticalAlign: "top",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <img src={iconA} alt="answer" className="faq-icon" />
                </TableCell>
                <TableCell
                  sx={{
                    padding: "12px 6px 32px",
                    verticalAlign: "top",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  {row.ans}
                </TableCell>
              </TableRow>
            </div>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
