import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import CustomCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import {StyledTableContainer, TableHeadCell, TableDataCell} from "components/Table/TableComponents";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#FFFBE7",
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)({
  "& .MuiTableSortLabel-icon": {
    color: "#23AA91 !important",
  },
});

export default function AdminSchoolsTable({ ...props }: any) {
  const { tableHead, tableData, page, rowsPerPage, deleteList, setDeleteList } = props;

  const navigate = useNavigate();

  // CheckBoxにチェックされた行データを保持するための関数
  const useRowSelect = (rowIds: string[], initialSelectedRowIds: string[] = []): {
    selectedRowIds: string[];
    isSelected: (rowId: string) => boolean;
    toggleSelected: (id: string) => void;
  } => {
      const [selectedRowIds, setSelectedRowIds] = useState<string[]>(initialSelectedRowIds);
      const isSelected = (rowId: string) => selectedRowIds.includes(rowId);
      const toggleSelected = (rowId: string) => {
        if (isSelected(rowId)){
          setSelectedRowIds(selectedRowIds.filter((selectedId) => selectedId !== rowId))
          setDeleteList(deleteList.filter((item: any) => item !== rowId));
        }
        else{
          setSelectedRowIds([...selectedRowIds, rowId]);
          let tableDataFilter: any = tableData.filter((item: any) => item.school_id === rowId);
          let orderItem: any = tableDataFilter[0].school_id;
          setDeleteList([...deleteList, orderItem]);
        }
      };
      return { selectedRowIds, isSelected, toggleSelected };
  };
  const {selectedRowIds, isSelected, toggleSelected} = useRowSelect(tableData.map((row: any) => row.school_id));

  // ソートに関する関数
  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("asc");
  const [rows, setRows] = useState(tableData);
  useEffect(() => { setRows(tableData); }, [tableData]);
  function handleClickSortColumn() {
    const isDesc = order === "desc";
    const nextOrder = isDesc ? "asc" : "desc";
    const sortRule = { asc: [1, -1], desc: [-1, 1] };
    const sortedRows = rows.sort((a: any, b: any) => {
      if (a.update_date > b.update_date) {
        return sortRule[nextOrder][0];
      } else if (a.update_date < b.update_date) {
        return sortRule[nextOrder][1];
      } else {
        return 0;
      }
    });
    setOrder(nextOrder);
    setRows(sortedRows);
  }

  const viewTableData = rows.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage
  );

  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: "54px" }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align="center">
                    {prop.school_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.phone_number}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.zip}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    {prop.address}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sortDirection={order}>
                    {prop.update_date}
                    <StyledTableSortLabel
                      active={true}
                      direction={order}
                      IconComponent={ArrowDropDownRoundedIcon}
                      onClick={() => handleClickSortColumn()}
                    />
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center">
                    削除
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {viewTableData.map((prop: any, key: any) => {
            const isItemSelected = isSelected(prop.school_id);
            return (
              <StyledTableRow>
                <TableDataCell key={key} align="center" sx={{ width: "20%" }} onClick={() => { navigate("./" + prop.school_id); }}>
                  {prop.school_name}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }} onClick={() => { navigate("./" + prop.school_id); }}>
                  {prop.phone_number}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }} onClick={() => { navigate("./" + prop.school_id); }}>
                  {prop.zip}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "30%" }} onClick={() => { navigate("./" + prop.school_id); }}>
                  {prop.address}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }} onClick={() => { navigate("./" + prop.school_id); }}>
                  {prop.update_date}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "5%" }}>
                  <CustomCheckBox checked={isItemSelected} onClick={() => { toggleSelected(prop.school_id); }}/>
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
