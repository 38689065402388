import * as React from "react";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FolderIcon from "@mui/icons-material/Folder";

import FolderViewButton from "./FolderViewButton";

const folderRows = [
  {
    id: 0,
    mainFolder: "うめ組の様子",
    subFolder: ["4月のうめ組", "5月のうめ組"],
    number: 250,
  },
  {
    id: 1,
    mainFolder: "ひまわり組の様子",
    subFolder: [],
    number: 150,
  },
  {
    id: 2,
    mainFolder: "お誕生日会の様子",
    subFolder: [],
    number: 55,
  },
  {
    id: 3,
    mainFolder: "お散歩の様子",
    subFolder: [],
    number: 86,
  },
];

export default function UploadedFolderTable() {
  return (
    <div>
      <Table className="folder-table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: "364px",
                borderBottom: "2px solid #E5E5E5",
                padding: "16px 24px",
                fontSize: "12px",
              }}
            >
              写真グループ名
            </TableCell>
            <TableCell
              sx={{
                width: "65px",
                borderBottom: "2px solid #E5E5E5",
                padding: "16px 0px",
                textAlign: "right",
                fontSize: "12px",
              }}
            >
              写真枚数
            </TableCell>
            <TableCell sx={{ borderBottom: "2px solid #E5E5E5" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {folderRows.map((row) => (
            <React.Fragment>
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    borderBottom: "unset",
                    padding: "16px 24px",
                    fontSize: "14px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={1}>
                      <FolderIcon
                        sx={{
                          color: "#EDA605",
                          width: "24px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={11} sx={{ paddingTop: "5px" }}>
                      {row.mainFolder}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "unset",
                    padding: "16px 0px",
                    textAlign: "right",
                    fontSize: "14px",
                  }}
                >
                  {row.number}枚
                </TableCell>
                <TableCell sx={{ textAlign: "right", borderBottom: "unset" }}>
                  <FolderViewButton />
                </TableCell>
              </TableRow>
              {row.subFolder.map((sub) => (
                <TableRow key={sub}>
                  <TableCell
                    sx={{
                      borderBottom: "unset",
                      padding: "0px 24px 16px 56px",
                      fontSize: "14px",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={1.1}>
                        <FolderIcon
                          sx={{
                            color: "#EDA605",
                            width: "24px",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10.9} sx={{ paddingTop: "5px" }}>
                        {sub}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "unset",
                      padding: "0px 0px 16px 0px",
                      textAlign: "right",
                      fontSize: "14px",
                    }}
                  >
                    125枚
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={{ padding: "0px" }} />
                <TableCell sx={{ padding: "0px" }} />
                <TableCell sx={{ padding: "0px" }} />
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
