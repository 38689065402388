import { Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const GrayButton = (props: any) => {
  const {children, nextArrow, ...buttonProps} = props;
  return nextArrow ? (
    <Button sx={{
      backgroundColor: '#F5F5F5',
      color: '#424242',
      fontSize: '12px',
      borderRadius: '32px',
      minWidth: '120px',
      height: '44px',
      border: '1px solid #E5E5E5',
      '&:hover': {
      border: '1px solid #EFEFEF',
      backgroundColor: '#E5E5E5',
      },
      '&:active': {
      border: '1px solid #E5E5E5',
      backgroundColor: '#F5F5F5',
      },
    }} variant="outlined" endIcon={<ArrowForwardIosIcon sx={{color: '#00000066', fontSize: '16px'}}/>} {...buttonProps}
    >
      {children}
    </Button>
  ) : (
    <Button sx={{
      backgroundColor: '#F5F5F5',
      color: '#424242',
      fontSize: '12px',
      borderRadius: '32px',
      minWidth: '120px',
      height: '44px',
      border: '1px solid #E5E5E5',
      '&:hover': {
      border: '1px solid #EFEFEF',
      backgroundColor: '#E5E5E5',
      },
      '&:active': {
      border: '1px solid #E5E5E5',
      backgroundColor: '#F5F5F5',
      },
    }} variant="outlined" startIcon={<ArrowBackIosNewIcon sx={{color: '#00000066', fontSize: '16px'}}/>} {...buttonProps}
    >
      {children}
    </Button>
    );
}