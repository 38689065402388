import * as React from "react";

// @mui
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// compornents

const DesignedButton = styled(Button)({
  fontSize: "14px",
  fontWeight: "bold",
  width: "112px",
  height: "36px",
  borderWidth: "2px",
  boxShadow: "none",
  marginBottom: "16px",
});

export default function InfoEditCustomButton(props: any) {
  const {
    color,
    variant,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    ...rest
  } = props;
  return (
    <DesignedButton
      {...rest}
      color={color}
      variant={variant}
      disabled={disabled}
    >
      {children}
    </DesignedButton>
  );
}
