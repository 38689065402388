import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

import { combineReducers, createStore } from "redux";
import { Provider } from "react-redux";
// redux-persist add takata  2022/05/13
import { PersistGate } from "redux-persist/integration/react";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// react-query add aska 2022/04/26
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// template
import { browserRoute } from "./routes";
import { customeTheme } from "./components/_CustomTheme/_CustomTheme";

import ScrollToTop from "components/ScrollToTop/ScrollToTop";

import { accountReducer } from "./redux/reducers/accountReducer";
import { orderReducer } from "redux/reducers/OrderReducer";
import { cartReducer } from "./redux/reducers/cartReducer";
import { photoGroupReducer } from "./redux/reducers/photoGroupReducer";
import { dmHistoryReducer } from "./redux/reducers/dmHistoryReducer";
import { orderDMReducer } from "./redux/reducers/orderDMReducer";

const queryClient = new QueryClient();

const rootReducer = combineReducers({
  accountReducer,
  cartReducer,
  orderReducer,
  photoGroupReducer,
  dmHistoryReducer,
  orderDMReducer,
});

// 永続化の設定
const persistConfig = {
  key: "root", // Storageに保存されるキー名を指定する
  storage, // 保存先としてlocalStorageがここで設定される
  // whitelist: ["xxxReducer"], // Stateは`xxxReducer`のみStorageに保存する
  // blacklist: ['yyyReducer'] // `yyyReducer`は保存しない
};
// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <ThemeProvider theme={customeTheme}>
            <BrowserRouter>
              <ScrollToTop />
              {browserRoute}
            </BrowserRouter>
          </ThemeProvider>
        </React.StrictMode>
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
