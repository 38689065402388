import * as React from "react";
import { RootState } from "../..";
import { useSelector } from "react-redux";

import Header from "../Header/Header";
import { Footer, LandingFooter } from "../Footer/Footer";
import { AdminNav, SchoolNav, ClientNav, LandingNav } from "../Nav/Nav";

import { useQuery } from "react-query";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getUsersByUserId } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";

type Props = {
  children?: React.ReactNode;
};

export const AdminTemplate: React.FC<Props> = ({ children }) => {
  return (
    <div className="wrapper">
      <Header school_name="管理者画面" />
      <AdminNav />

      {/* import main component */}
      <main>{children}</main>

      <Footer />
    </div>
  );
};

export const SchoolTemplate: React.FC<Props> = ({ children }) => {
  // storeからユーザ情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const account_type: string = loginAccount.accountType;
  const account_id: string = loginAccount.accountId;
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", account_id],
    getSchools
  );
  const school_name =
    getSchoolsResponse.data && account_type === "organization"
      ? getSchoolsResponse.data[0].school_name
      : "";
  return (
    <div className="wrapper">
      <Header
        school_name={
          account_type === "organization" ? school_name : "管理者画面"
        }
      />
      {account_type === "admin" ? <AdminNav /> : <SchoolNav />}

      {/* import main component */}
      <main>{children}</main>

      <Footer account_type="organization" />
    </div>
  );
};

export const ClientTemplate: React.FC<Props> = ({ children }) => {
  // storeからユーザ情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const account_type: string = loginAccount.accountType;
  const account_id: string = loginAccount.accountId;
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsersByUserId", account_id],
    getUsersByUserId
  );
  const school_id =
    getUsersResponse.data && account_type === "user"
      ? getUsersResponse.data[0].school_id
      : "dummy";
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", school_id],
    getSchools
  );
  let school_name: string | undefined = "";
  if (
    !getSchoolsResponse.isLoading &&
    !getSchoolsResponse.isError &&
    school_id != "dummy"
  ) {
    school_name = getSchoolsResponse.data
      ? getSchoolsResponse.data[0].school_name
      : "dummy";
  }
  return (
    <div className="wrapper">
      <Header
        school_name={account_type === "user" ? school_name : "管理者画面"}
      />
      {account_type === "admin" ? <AdminNav /> : <ClientNav />}

      {/* import main component */}
      <main>{children}</main>

      <Footer account_type="user" />
    </div>
  );
};

export const LandingTemplate: React.FC<Props> = ({ children }) => {
  return (
    <div className="lp-out-wrapper">
      <div className="lp-wrapper">
        <LandingNav />

        {/* import main component */}
        {children}
      </div>
      <LandingFooter />
    </div>
  );
};

export const LandingNonheaderTemplate: React.FC<Props> = ({ children }) => {
  return (
    <div className="lp-out-wrapper">
      <div className="lp-wrapper">
        {/* import main component */}
        {children}
      </div>
      <LandingFooter />
    </div>
  );
};
