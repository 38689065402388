// @mui
import { FormControl, TextField, Grid, Typography } from "@mui/material";

import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

// components
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

import { formatDate } from "./getDatetime";

type Props = {
  register: any;
  getValues: any;
  currentDate: string;
  isEditable: boolean;
  accountType: string;
};

export const SalesPeriodView: React.FC<Props> = ({
  register,
  getValues,
  ...prop
}) => {
  // フォームが空か判定する。空の場合はInvalid DateのDate型となり!で判定できないためisNaNで判定
  // フォームのデフォルト値はstring（react hook formの仕様）、変更後はDate型
  const isInvalidDate = (date: Date | string) => {
    return typeof date === "string" ? !date : Number.isNaN(date.getDate());
  };

  // 販売開始日は当日以降とする
  // 販売開始日がないとき、編集不可のとき、管理者は何日でもOK
  const isAfterToday = (date: Date | string) => {
    if (
      isInvalidDate(date) ||
      !prop.isEditable ||
      prop.accountType === "admin"
    ) {
      return true;
    } else {
      return typeof date === "string"
        ? date >= prop.currentDate
        : formatDate(date, "-") >= prop.currentDate;
    }
  };

  // 販売終了日は開始日の翌日以降とする
  // 販売開始日, 終了日がないときは何日でもOK
  const isAfterSalesDateFrom = (date: Date | string) => {
    const salesPeriodFrom = getValues("salesPeriodFrom");

    if (isInvalidDate(date) || isInvalidDate(salesPeriodFrom)) {
      return true;
    } else {
      const from =
        typeof salesPeriodFrom === "string"
          ? salesPeriodFrom
          : formatDate(salesPeriodFrom, "-");
      const to = typeof date === "string" ? date : formatDate(date, "-");
      return to > from;
    }
  };

  return (
    <Grid container>
      <Grid item className="row-name" xs={2}>
        <CustomLabel value="販売期間" />
      </Grid>
      <Grid item className="row-content" xs={10}>
        <FormControl>
          <TextField
            id="date-start"
            className="datetime-picker"
            type="date"
            {...register("salesPeriodFrom", {
              valueAsDate: true,
              validate: isAfterToday,
            })}
          />
        </FormControl>
        <HorizontalRuleIcon className="hr-icon" />
        <FormControl>
          <TextField
            id="date-end"
            className="datetime-picker"
            type="date"
            {...register("salesPeriodTo", {
              valueAsDate: true,
              validate: isAfterSalesDateFrom,
            })}
          />
        </FormControl>
        <Typography variant="body2" className="salesperiod-helperlabel">
          ※保存ボタンでは販売期間は設定されません
        </Typography>
      </Grid>
    </Grid>
  );
};
