import React, { useState, useEffect} from "react";
import { useQuery, useMutation } from "react-query";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';
import AdminUsersTable from './AdminUsersTable';
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getUsers, deleteUser } from "utils/api/users/users";
import { getSchools } from "utils/api/schools/schools";
import { DeleteDialog, DeleteDialogProps } from 'components/DeleteDialog/DeleteDialog';

export default function AdminUsers(props: any) {
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [message, setMessage] = useState<string | undefined>("");
  const [modalConfig, setModalConfig] = useState<DeleteDialogProps | undefined>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [searchResult, setSearchResult] = useState<any[]>([]);

  // API:DELETE
  const deleteListMutation = useMutation(deleteUser, {
  onSuccess: (data, variables, context) => {
      if (data.status===200) {
          console.log("Success deleteListMutation");
          window.location.reload();
      } else {
          setMessage("Error");
      }
  },
  onError: (error, variables, context) => {
      setMessage("Error: " + error);
  },
  });

  // APIからユーザーとクラスの情報を取得
  const getUsersResponse = useQuery<usersReturnValue[], Error>(["getUsers"], getUsers);
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(["getClasses","","",""], getSchools);
  if (getSchoolsResponse.isLoading || getUsersResponse.isLoading) { return <span>Loading...</span>; }
  if (getUsersResponse.isError) { return <span>Error: {getUsersResponse.error?.message}</span>; }
  if (getSchoolsResponse.isError) { return <span>Error: {getSchoolsResponse.error?.message}</span>; }
  const getUsersData: any = getUsersResponse.data;
  const schoolsData: any = getSchoolsResponse.data;

  // ソート
  // let userData = getUsersData!.sort((n1: any, n2: any) => {
  //   if (n1.update_date > n2.update_date) { return -1; }
  //   if (n1.update_date < n2.update_date) { return 1; }
  //   return 0;
  // });

  // ユーザーのデータに団体のデータをschool_idで結合する
  var displayData: any[] = [];
  for (var i=0; i<getUsersData.length; i++) {
    for (var j=0; j<schoolsData.length; j++) {
      if (getUsersData[i].school_id === schoolsData[j].school_id) {
        displayData.push({
          user_id: getUsersData[i].user_id,
          user_name: getUsersData[i].user_name,
          phone_number: getUsersData[i].phone_number,
          zip: getUsersData[i].zip,
          address: getUsersData[i].address,
          school_name: schoolsData[j].school_name,
          update_date: getUsersData[i].update_date,
        });
      }
    }
  }

  // 削除ボタンが押された時の関数
  const handleDeleteClick = async () => {
    const ret = await new Promise<string>((resolve) => {
      setModalConfig({
        onClose: resolve,
        title: '削除します。よろしいですか?',
        message: '削除すると元に戻せません。'
      })
    })
    setModalConfig(undefined)
    if (ret==="ok"){
      deleteList?.map((item: any) => deleteListMutation.mutate(item));
    }
  }

  // 検索欄への入力値をハンドリング
  const searchHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSearchResult(search(e.target.value, displayData));
    setIsFirstTime(false);
    setPage(1);
  };

  // 検索する関数
  type userInfo = {
    address: string;
    phone_number: string;
    school_name: string;
    update_date: string;
    user_id: string;
    user_name: string;
    zip: string;
  };
  const search = (value: string, data:any[]) => {
    if (value !== "") {
      const filteredList = data?.filter((userInfoList: userInfo) =>
        Object.values(userInfoList).some((item: string) =>
          item !== undefined &&
          item !== null &&
          item?.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1)
      );
      return filteredList;
    }
    return data;
  };

  // テーブルヘッドの定義
  const HEAD = [{
    'user_name':'ユーザー名',
    'phone_number':'電話番号',
    'zip':'郵便番号',
    'address':'住所',
    'school_name':'団体名',
    'update_date':'更新日時',
  }];

  return (
    <>
      <Grid container rowSpacing={2} direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={12}>
          <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={2}>
            <h2>会員一覧</h2>
            <CustomButton color="emerald" variant="contained" onClick={handleDeleteClick}>
              削除
            </CustomButton>
          </Stack>
          {modalConfig && <DeleteDialog {...modalConfig} />}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="検索"
            variant="outlined"
            size="small"
            color="emerald"
            value={searchValue}
            onChange={searchHandleChange}
            sx={{background:"#FFFFFF", select:{'&:before':{backgroundColor:"#23AA91" },'&:after':{backgroundColor:"#23AA91"}},"&:hover":{"&& fieldset":{border:"2px solid #23AA91"}}}}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <span>{message}</span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isFirstTime
            ? <AdminUsersTable tableHead={HEAD} tableData={displayData} page={page} rowsPerPage={rowsPerPage} deleteList={deleteList} setDeleteList={setDeleteList}/>
            : <AdminUsersTable tableHead={HEAD} tableData={searchResult} page={page} rowsPerPage={rowsPerPage} deleteList={deleteList} setDeleteList={setDeleteList}/>
          }
        </Grid>
        <Grid item>
          {isFirstTime
            ? (displayData.length
              ? <div>全{displayData?.length}件中{(page - 1) * rowsPerPage + 1}件〜{Math.min(displayData?.length, (page - 1) * rowsPerPage + rowsPerPage)}件を表示</div>
              : <div>全0件中0件〜0件を表示</div>
            )
            : (searchResult.length
              ? <div>全{searchResult?.length}件中{(page - 1) * rowsPerPage + 1}件〜{Math.min(searchResult?.length, (page - 1) * rowsPerPage + rowsPerPage)}件を表示</div>
              : <div>全0件中0件〜0件を表示</div>
            )
          }
        </Grid>
        <Grid item>
          {isFirstTime
            ? <Pagination page={page} count={Math.ceil(displayData?.length/rowsPerPage)} shape='circular' color='emerald' onChange={(e, page) => setPage(page)}/>
            : <Pagination page={page} count={Math.ceil(searchResult?.length/rowsPerPage)} shape='circular' color='emerald' onChange={(e, page) => setPage(page)}/>
          }
        </Grid>
      </Grid>
    </>
  );
}