import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import {
  StyledTableContainer,
  StyledTableRow,
  TableHeadCell,
  TableDataCell,
  StyledChip,
  StyledTypography,
} from "components/Table/TableComponents";
import CustomCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import { SortTableHead } from "components/Table/SortTable";
import { orderHistory } from "utils/interface/order/orderInterface";

import { OrderHistoryContext } from "store/OrderHistoryContext";

export default function OrderHistoryTable({ ...props }: any) {
  const {
    TABLEHEAD,
    tableData,
    sortKey,
    filterKey,
    urlKey,
    group_key,
    chipFn,
    deleteList,
    setDeleteList
  } = props;

  const { setGroup_key, setQuery, setSchoolId, schoolId, setAlbumId, albumId } =
    useContext(OrderHistoryContext);

  // 2022/02/27 西村追記
  // 各テーブル行にpathを追加する関数
  // 2022/04/20 高田追記 /school-album/AlbumIdに変更が必要
  const navigate = useNavigate();
  const goPath = (urlString: string) => {
    navigate("./" + urlString);

    if (group_key) {
      setGroup_key(group_key);

      if (group_key === "school" || group_key === "organization") {
        setSchoolId(urlString);
      } else if (group_key === "user") {
        setAlbumId(urlString);
      }

      setQuery([group_key, schoolId, albumId, ""]);
    }
  };
  // ここまで

  // ソートに関する設定。SortTableHead componentに代入している
  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("desc");
  const [rows, setRows] = useState<orderHistory[]>(tableData);

  // CheckBoxにチェックされた行データを保持するための関数
  const useRowSelect = (rowIds: string[], initialSelectedRowIds: string[] = []): {
    selectedRowIds: string[];
    isSelected: (rowId: string) => boolean;
    toggleSelected: (id: string) => void;
  } => {
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>(initialSelectedRowIds);
    const isSelected = (rowId: string) => selectedRowIds.includes(rowId);
    const toggleSelected = (rowId: string) => {
      if (isSelected(rowId)){
        setSelectedRowIds(selectedRowIds.filter((selectedId) => selectedId !== rowId))
        setDeleteList(deleteList.filter((item: any) => item !== rowId));
      }
      else{
        setSelectedRowIds([...selectedRowIds, rowId]);
        let tableDataFilter: any = tableData.filter((item: any) => item.album_id === rowId);
        let orderItem: any = tableDataFilter[0].album_id;
        setDeleteList([...deleteList, orderItem]);
      }
    };
    return { selectedRowIds, isSelected, toggleSelected };
  };
  const {selectedRowIds, isSelected, toggleSelected} = useRowSelect(tableData.map((row: any) => row.album_id));

  useEffect(() => { setRows(tableData); }, [tableData]);

  console.log(tableData);

  return (
    <Grid item xs={12}>
      <StyledTableContainer>
        <Table>
          <TableHead sx={{ height: "54px" }}>
            {TABLEHEAD.map((props: any, key: number) => {
              return (
                <TableRow key={key}>
                  {Object.keys(props).map((k: any) => {
                    if (k === sortKey) {
                      return (
                        <SortTableHead
                          headline={props[k]}
                          order={order}
                          rows={rows}
                          sortKey={sortKey}
                          setOrder={setOrder}
                          setRows={setRows}
                          key={props[k]}
                        />
                      );
                    } else {
                      return (
                        <TableHeadCell key={props[k]}>{props[k]}</TableHeadCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {rows?.map((tableCell: any, key: number) => {
              const isItemSelected = isSelected(tableCell.album_id);
              return (
                <StyledTableRow
                  key={key}
                  sx={{ "&:hover": { backgroundColor: "#FFFBE7" } }}
                >
                  {TABLEHEAD.map((props: any) => {
                    // tableDataCellで囲みたいが、syntax error。誰か教えて by aska 2022/05/11
                    return Object.keys(props).map((k: any, key: number) => {
                      if (k === filterKey) {
                        return (
                          <TableDataCell key={key} onClick={() => { if (urlKey) goPath(tableCell[urlKey]); }}>
                            <StyledChip
                              label={
                                <StyledTypography>
                                  {tableCell[k]}
                                </StyledTypography>
                              }
                              color={chipFn(tableCell[k])}
                            />
                          </TableDataCell>
                        );
                      } else if (k === "subtotal") {
                        return (
                          <TableDataCell className="alignRight" key={key} onClick={() => { if (urlKey) goPath(tableCell[urlKey]); }}>
                            {Number(tableCell[k]).toLocaleString()}円
                          </TableDataCell>
                        );
                      } else if (k === "records") {
                        return (
                          <TableDataCell className="alignRight" key={key} onClick={() => { if (urlKey) goPath(tableCell[urlKey]); }}>
                            {Number(tableCell[k]).toLocaleString()}件
                          </TableDataCell>
                        );
                      } else if (
                        ["amount_photo", "amount"].some((e) => e === k)
                      ) {
                        return (
                          <TableDataCell className="alignRight" key={key} onClick={() => { if (urlKey) goPath(tableCell[urlKey]); }}>
                            {Number(tableCell[k]).toLocaleString()}枚
                          </TableDataCell>
                        );
                      } else if (((tableCell.sales_status==="販売前")||(tableCell.sales_status==="開始前"))&&(k === "delete_flag")) {
                        return (
                          <TableDataCell key={key}>
                            <CustomCheckBox checked={isItemSelected} onClick={() => { toggleSelected(tableCell.album_id); }}/>
                          </TableDataCell>
                        );
                      } else {
                        return (
                          <TableDataCell key={key} onClick={() => { if (urlKey) goPath(tableCell[urlKey]); }}>
                            {tableCell[k]}
                          </TableDataCell>
                        );
                      }
                    });
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Grid>
  );
}
