import { useState, useContext } from "react";
import { useQuery } from "react-query";

// components
import CustomTable from "components/CustomTable/CustomTable_dmHistory";
import CustomTableFilter from "components/CustomTable/CustomTableFilter";
import CustomTablePagenation from "components/CustomTable/CustomTablePagenation";

// interface
import { mailsInfoReturnValue } from "utils/interface/mailsInfo/mailsInfoInterface";
import { usersReturnValue } from "utils/interface/users/usersInterface";

// api
import { getMailsInfo } from "utils/api/mailsInfo/mailsInfo";
import { getUsersByParams } from "utils/api/users/users";

export function GetReadStatus(props: any) {
  const {
    filter,
    filterKey,
    filterArray,
    sortKey,
    TABLEHEAD,
    chipFn,
    schoolId,
    mailId,
    className,
  } = props;

  const [filterStatus, setFilterStatus] = useState(filterArray[0]);
  const [page, setPage] = useState(1);
  // const ROWSPERPAGE = 10;

  // API GET
  const getMailsInfoResponse = useQuery<mailsInfoReturnValue[], Error>(
    ["getMailsInfo", mailId],
    getMailsInfo,
    { enabled: !!mailId }
  );
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsersByParams", "", schoolId, ""],
    getUsersByParams,
    { enabled: !!schoolId }
  );
  if (getMailsInfoResponse.isLoading || getUsersResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getMailsInfoResponse.isError) {
    return <span>Error: {getMailsInfoResponse.error?.message}</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  const mailsInfoData = getMailsInfoResponse.data
    ? getMailsInfoResponse.data
    : [];
  const usersData = getUsersResponse.data ? getUsersResponse.data : [];

  // console.log(mailsInfoData);
  // console.log(usersData);

  // 既読ステータス情報とユーザ情報を結合
  let mailsInfoList = new Array();
  for (let i = 0; i < mailsInfoData.length; i++) {
    for (let j = 0; j < usersData.length; j++) {
      if (mailsInfoData[i].user_id === usersData[j].user_id) {
        const mailsInfo = {
          user_name: usersData[j].user_name,
          user_id: usersData[j].user_id,
          child_name: usersData[j].child_name,
          class_name: className,
          mail_status: mailsInfoData[i].mail_status,
        };
        mailsInfoList.push(mailsInfo);
        break;
      }
    }
  }

  const viewTableData = mailsInfoList.filter(
    (x: any) => filterStatus === filterArray[0] || filterStatus === x[filterKey]
  );

  // const viewTableData = albumsFilterData.slice(
  //   (page - 1) * ROWSPERPAGE,
  //   (page - 1) * ROWSPERPAGE + ROWSPERPAGE
  // );

  return (
    <>
      {filter ? (
        <CustomTableFilter
          filterArray={filterArray}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          setPage={setPage}
          filter={filter}
        />
      ) : null}

      <CustomTable
        TABLEHEAD={TABLEHEAD}
        tableData={viewTableData}
        sortKey={sortKey}
        filterKey={filterKey}
        chipFn={chipFn}
      />

      {/* <CustomTablePagenation
        tableData={albumsFilterData}
        page={page}
        setPage={setPage}
        ROWSPERPAGE={ROWSPERPAGE}
        filterKey={filterKey}
        filterStatus={filterStatus}
        filterArray={filterArray}
      /> */}
    </>
  );
}
