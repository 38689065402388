import { useParams } from "react-router-dom";

// components
import ContentsHeader from "./ContentsHeader";
import ContentsFooter from "./ContentsFooter";
import ContentsViewContainer from "./ContentsViewContainer";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../..";

export default function Schools() {
  // storeからログイン中のユーザ情報、school_idを取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  // console.debug(loginAccount);

  const account_type: string = loginAccount.accountType;
  const school_id: string = loginAccount.schoolId;

  return (
    <div className="wrapper">
      <ContentsHeader school_id={school_id} />
      <ContentsViewContainer
        school_id={school_id}
        account_type={account_type}
      />
      <ContentsFooter school_id={school_id} />
    </div>
  );
}
