import axios from "axios";
import { ordersReturnValue, ordersPostValue, postOrderReturnValue } from "utils/interface/orders/ordersInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getOrders = async (params: any) => {
  
  const response = await axios.get<ordersReturnValue[]>(BASE_URL + "orders", {
    params: {
      user_id: params.queryKey[0],
      order_date_from: params.queryKey[1],
      order_date_to: params.queryKey[2],
      order_id: params.queryKey[3],
      school_id: params.queryKey[4],
      order_status: params.queryKey[5],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getOrdersMutation = async (params: any) => {
  const response = await axios.get<ordersReturnValue[]>(BASE_URL + "orders", {
    params: {
      user_id: params[0],
      order_date_from: params[1],
      order_date_to: params[2],
      order_id: params[3],
      school_id: params[4],
      order_status: params[5],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const postOrder = async (params: any) => {
  const data = JSON.stringify(params);
  console.log(data);

  return await axios.post<postOrderReturnValue>(BASE_URL + "order", data, {
    headers: { "content-type": "application/json" },
  });
};

export const patchOrder = async (params: any) => {
  const data = JSON.stringify(params[0]);
  console.log(data);

  return await axios.patch(BASE_URL + "order/" + params[1], data, {
    headers: { "content-type": "application/json" },
  });
};