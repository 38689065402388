import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

import { getChipColor } from "utils/function/shapeAlbumData";

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  width: "320px",
  height: "408px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "16px",
  "&:hover": {
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.16)",
  },
});

const StyledChip = styled(Chip)({
  width: "82px",
  height: "22px",
  borderRadius: "12px",
});

const ChipTypography = styled(Typography)({
  width: "62px",
  height: "12px",
  fontFamily: "Hiragino Kaku Gothic ProN",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  color: "#FFFFFF",
  flex: "none",
});

const TitleTypography = styled(Typography)({
  fontFamily: "Zen Maru Gothic",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "18px",
  lineHeight: "150%",
  letterSpacing: "0.1em",
  color: "#455A64",
  flex: "none",
  order: 1,
  alignSelf: "stretch",
  flexGrow: 0,
  margin: "8px 0px",
});

export default function AlbumsCard({ ...props }: any) {
  const { albumsData } = props;

  const onClick = (e: any, isRight?: boolean) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      {albumsData.map((prop: any, index: number) => (
        <Grid item key={index}>
          <StyledCard>
            <CardActionArea
              component={Link}
              to={"/photos/group/" + prop.album_id}
              sx={{ height: "100%" }}
            >
              <CardMedia
                className="photoGuard"
                component="img"
                height="180"
                image={prop.thumbnail}
                onContextMenu={(e: any) => onClick(e, true)}
              />
              <CardContent sx={{ height: "100%" }}>
                <StyledChip
                  label={<ChipTypography>{prop.sales_status}</ChipTypography>}
                  color={getChipColor(prop.sales_status)}
                />
                <TitleTypography>{prop.album_name}</TitleTypography>
                <Divider
                  variant="fullWidth"
                  sx={{ alignSelf: "stretch", margin: "16px 0px" }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Typography color="#37474F" sx={{ fontSize: "14px" }}>
                    販売期間
                  </Typography>
                  <Typography color="#37474F" sx={{ fontSize: "14px" }}>
                    {prop.sales_period_from}〜{prop.sales_period_to}
                  </Typography>
                </Stack>
                {prop.days_left ? (
                  <Typography
                    color="#DE5F5F"
                    align="right"
                    sx={{ fontSize: "14px", margin: "8px 0px" }}
                  >
                    終了まであと{prop.days_left}日
                  </Typography>
                ) : (
                  ""
                )}
              </CardContent>
            </CardActionArea>
          </StyledCard>
        </Grid>
      ))}
    </>
  );
}
