// other
import { classesReturnValue } from "utils/interface/classes/classesInterface";

export default function setDmTargetClassList(
  class_num: Number,
  targetClassList: classesReturnValue[],
  setDmTargetClass: any
) {
  setDmTargetClass(
    targetClassList.map((targetClass: classesReturnValue, index: Number) =>
      index === class_num
        ? {
            class_id: targetClass.class_id,
            class_name: targetClass.class_name,
            school_id: targetClass.school_id,
            year: targetClass.year,
            dm_flg:
              targetClass.dm_flg === undefined ? true : !targetClass.dm_flg,
          }
        : {
            class_id: targetClass.class_id,
            class_name: targetClass.class_name,
            school_id: targetClass.school_id,
            year: targetClass.year,
            dm_flg:
              targetClass.dm_flg === undefined ? false : targetClass.dm_flg,
          }
    )
  );
}
