import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../..";

// @mui
import { Grid, Stack, Pagination } from "@mui/material";

// components
import SchoolDmHistoryTable from "./SchoolDmHistoryTable";
import SchoolDmHistoryDetail from "./SchoolDmHistoryDetail";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

// interface
import { dmHistoryReturnValue } from "utils/interface/dmHistory/dmHistoryInterface";
import { classesReturnValue } from "utils/interface/classes/classesInterface";

// api
import { getDmHistory } from "utils/api/dmHistory/dmHistory";
import { getClasses } from "utils/api/classes/classes";

// functions
import getSchoolYear from "utils/function/getSchoolYear";

export default function SchoolDmHistory(props: any) {
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  // アカウント情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // ローカルストレージのDM履歴を取得、値があれば履歴詳細を表示する
  const selectedDMHistory: any = useSelector<RootState>(
    (state) => state.dmHistoryReducer
  );

  // DM履歴とクラス情報を取得
  const getDmHistoryResponse = useQuery<dmHistoryReturnValue[], Error>(
    ["getDmHistory", loginAccount.accountId],
    getDmHistory
  );
  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getClasses", "", loginAccount.accountId, getSchoolYear()],
    getClasses
  );
  if (getDmHistoryResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getDmHistoryResponse.isError) {
    return <span>Error: {getDmHistoryResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }
  const dmData: any = getDmHistoryResponse.data;
  const classData: any = getClassesResponse.data;

  // クラス名を記号から文字に変換
  // 個別送信の場合は"個別送信"固定値とする
  let newClassName: String[] = [];
  let classIdList: String[] = [];
  let classId: string = "";
  for (let i = 0; i < dmData.length; i++) {
    newClassName = [];
    classId = dmData[i].class_id.replace("[", "").replace("]", "");
    classIdList = classId.split(",");
    if (classIdList && classIdList[0].length > 2) {
      for (let j = 0; j < classIdList.length; j++) {
        for (let k = 0; k < classData.length; k++) {
          if (classData[k].class_id === classIdList[j].slice(1).slice(0, -1)) {
            newClassName.push(classData[k].class_name);
          }
        }
      }
    } else if (classIdList) {
      newClassName.push("個別送信");
    }
    dmData[i].child_class_name = newClassName;
  }

  // 送信日時でソート
  const mailData = dmData!.sort((n1: any, n2: any) => {
    if (n1.send_date > n2.send_date) {
      return -1;
    }
    if (n1.send_date < n2.send_date) {
      return 1;
    }
    return 0;
  });

  const HEAD = [
    {
      class_name: "送信先",
      title: "タイトル",
      send_date: "送信日時",
    },
  ];

  console.log(mailData);

  return (
    <>
      {!selectedDMHistory.schoolId && (
        <Grid
          container
          rowSpacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <h2>メール履歴</h2>
              <CustomButton
                color="emerald"
                variant="outlined"
                href="/school-dm"
              >
                戻る
              </CustomButton>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <SchoolDmHistoryTable
              tableHead={HEAD}
              tableData={mailData}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Grid>
          <Grid item>
            {mailData.length ? (
              <div>
                全{mailData.length}件中{(page - 1) * rowsPerPage + 1}件〜
                {Math.min(
                  mailData.length,
                  (page - 1) * rowsPerPage + rowsPerPage
                )}
                件を表示
              </div>
            ) : (
              <div>全0件中0件〜0件を表示</div>
            )}
          </Grid>
          <Grid item>
            <Pagination
              page={page}
              count={Math.ceil(mailData.length / rowsPerPage)}
              shape="circular"
              color="emerald"
              onChange={(e, page) => setPage(page)}
            />
          </Grid>
        </Grid>
      )}
      {selectedDMHistory.schoolId && <SchoolDmHistoryDetail />}
    </>
  );
}
