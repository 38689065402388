import { useEffect, useState } from "react";

// @mui
import Grid from "@mui/material/Grid";
import { FormControl, OutlinedInput } from "@mui/material/";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// compornents
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

// other
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import updateExistClassList from "utils/function/updateExistClassList";
import updateNewClassList from "utils/function/updateNewClassList";

const DesignedTextField = styled(OutlinedInput)({
  background: "#FAFAFA",
  border: "1px",
  boxSizing: "border-box",
  borderRadius: "4px",
  height: "36px",
  marginTop: "16px",
  marginBottom: "16px",
});

const DesignedButton = styled(Button)({
  fontSize: "14px",
  fontWeight: "bold",
  height: "36px",
  borderWidth: "2px",
  boxShadow: "none",
  marginTop: "16px",
  marginBottom: "20px",
});

export default function GroupClassInfoView(props: any) {
  const {
    school_id,
    classes,
    existClassList,
    setExistClassList,
    newClassList,
    setNewClassList,
  } = props;

  useEffect(() => {
//    classes.sort(function (a: any, b: any) {
//      if (a.class_id < b.class_id) return -1;
//      if (a.class_id > b.class_id) return 1;
//      return 0;
//    });
    setExistClassList(classes);
  }, []);

  const [newRows, setNewRows] = useState<String[]>([]);

  const addNewRow = () => {
    setNewRows((prevRows) => [...prevRows, ""]);
  };

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="クラス" />
      </Grid>
      <Grid item xs={10}>
        {existClassList.map((row: classesReturnValue, index: Number) => (
          <Grid container direction="row" alignItems="center" columnSpacing={2}>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <DesignedTextField
                  inputProps={{
                    value: row.class_name,
                    onChange: (event: any) =>
                      updateExistClassList(
                        event.target.value,
                        index,
                        existClassList,
                        setExistClassList
                      ),
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={1}>
              <DesignedButton color="ruby" variant="outlined" fullWidth>
                削除
              </DesignedButton>
            </Grid> */}
          </Grid>
        ))}
        {newRows.map((row, key) => (
          <Grid container direction="row" alignItems="center" columnSpacing={2}>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <DesignedTextField
                  placeholder="クラス名を入力してください"
                  inputProps={{
                    onChange: (event: any) =>
                      updateNewClassList(
                        event.target.value,
                        key,
                        newClassList,
                        setNewClassList,
                        school_id
                      ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <DesignedButton
            color="emerald"
            variant="outlined"
            onClick={function (e) {
              addNewRow();
              setNewClassList([...newClassList, {}]);
            }}
          >
            ＋
          </DesignedButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
