import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

// other
import PrefMaster from "../../assets/PrefMaster";

const prefectureList = PrefMaster.PREF_OPTIONS.map((pref) => {
  return { value: pref, label: pref };
});

export default function UserAddressView(props: any) {
  const { data } = props;
  const userUseForm = useFormContext();

  useEffect(() => {
    userUseForm.setValue("zip", data ? data.zip : "");
    userUseForm.setValue(
      "prefecture",
      data && data.address ? data.address.split(" ")[0] : ""
    );
    userUseForm.setValue(
      "municipalities",
      data && data.address ? data.address.split(" ")[1] : ""
    );
    userUseForm.setValue(
      "houseNumber",
      data && data.address ? data.address.split(" ")[2] : ""
    );
    userUseForm.setValue(
      "buildings",
      data && data.address ? data.address.split(" ")[3] : ""
    );
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <ErrorMessage
              errors={userUseForm.formState.errors}
              name="zip"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <CustomLabel value={"郵便番号"} />
            <CustomForm
              helperlabel="例：000-0000"
              register={userUseForm.register("zip", {
                required: "⚠︎必須項目です",
                pattern: {
                  value: /^[0-9]{3}-[0-9]{4}$/,
                  message: "⚠︎郵便番号の形式が不正です",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ErrorMessage
              errors={userUseForm.formState.errors}
              name="prefecture"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <CustomLabel value={"都道府県"} />
            <CustomSelector
              items={prefectureList}
              defaultValue={
                data && data.address ? data.address.split(" ")[0] : ""
              }
              register={userUseForm.register("prefecture", {
                required: "⚠︎必須項目です",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ErrorMessage
              errors={userUseForm.formState.errors}
              name="municipalities"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <CustomLabel value={"市区町村"} />
            <CustomForm
              placeholder="市区町村を入力してください"
              helperlabel="例：〇〇市〇〇町"
              register={userUseForm.register("municipalities", {
                required: "⚠︎必須項目です",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <ErrorMessage
              errors={userUseForm.formState.errors}
              name="houseNumber"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <CustomLabel value={"番地"} />
            <CustomForm
              placeholder="番地を入力してください"
              helperlabel="例：1-2-3"
              register={userUseForm.register("houseNumber", {
                required: "⚠︎必須項目です",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <CustomLabel value={"建物名・部屋番号"} />
            <CustomForm
              placeholder="建物名・部屋番号を入力してください"
              helperlabel="例：〇〇マンション101"
              register={userUseForm.register("buildings")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
