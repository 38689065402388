import { useState } from "react";
import { useMutation } from "react-query";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../..";
import { useSelector } from "react-redux";

// components
import ContentsHeader from "./ContentsHeader";
import ContentsFooter from "./ContentsFooter";
import ContentsViewContainer from "./ContentsViewContainer";

// APIs
import { postSchool, patchSchool } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { postClasses, patchClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import {
  changeUserPassword,
  createAccount,
} from "utils/api/authentication/authentication";
import { authenticationReturnValue } from "utils/interface/authentication/authenticationInterface";
import { postAccount, patchAccount } from "utils/api/account/account";

export default function Schools() {
  const school_id = useParams().SchoolId;

  // storeからユーザ情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  // console.debug(loginAccount);
  const account_type: string = loginAccount.accountType;
  // console.debug(account_type);

  // テスト用
  // const account_type = "admin";
  // const account_type = "organization";

  const [existClassList, setExistClassList] = useState<classesReturnValue[]>(
    []
  );
  const [newClassList, setNewClassList] = useState<classesReturnValue[]>([]);
  const [newPassword, setNewPassword] = useState<string | undefined>("");

  const postSchoolInfoMutation = useMutation(postSchool, {
    onSuccess: async (res) => {
      console.debug("Success postSchool");
      console.debug(res.data);

      // パスワード登録
      await createAccountMutation.mutate({
        account_id: res.data.school_id,
        password: newPassword,
        // emailどうするか決める
        email: "hello@nicoandpeace.net",
      });

      // クラス登録
      await newClassList.map((newClass) => {
        if (account_type === "admin") {
          postClassInfoMutation.mutate({
            ...newClass,
            school_id: res.data.school_id,
          });
        }
      });

      await postAccountMutation.mutate({
        account_id: res.data.school_id,
        account_type: "organization",
        password: newPassword,
        permission: [],
      });
    },
  });
  const patchSchoolInfoMutation = useMutation(patchSchool, {
    onSuccess: async (res) => {
      console.debug("Success");
      console.debug(res.data);
      await newClassList.map((newClass) => {
        if (newClass["class_name"]) {
          postClassInfoMutation.mutate({
            ...newClass,
            school_id: res.data.school_id,
          });
        }
      });

      await existClassList.map((existClass) => {
        patchClassInfoMutation.mutate(existClass);
      });
      if (account_type === "admin") {
        navigate("/admin-schools/");
      } else if (account_type === "organization") {
        navigate("/schools/");
      }
    },
  });
  const postClassInfoMutation = useMutation(postClasses, {
    onSuccess: async (res) => {
      console.debug("postClassInfo Success");
      console.debug(res.data);
    },
  });
  const patchClassInfoMutation = useMutation(patchClasses, {
    onSuccess: async (res) => {
      if (account_type === "admin") {
        window.location.reload();
        navigate("/admin-schools/");
      } else if (account_type === "organization") {
        window.location.reload();
        navigate("/schools/");
      }
    },
  });
  const changeUserPasswordMutation = useMutation(changeUserPassword, {
    onSuccess: async (res) => {
      console.debug("Success");
      console.debug(res.data);
      await patchAccountMutation.mutate({
        account_id: school_id,
        account_type: "organization",
        password: newPassword,
        permission: [],
      });
    },
  });
  const postAccountMutation = useMutation(postAccount, {
    onSuccess: async (res) => {
      console.debug("Success postAccount");
      console.debug(res.data);
      navigate("/admin-schools");
      // window.location.reload();
    },
  });
  const patchAccountMutation = useMutation(patchAccount, {
    onSuccess: async (res) => {
      console.debug("Success patchAccount");
      // window.location.reload();
      console.debug(res.data);
    },
  });
  const createAccountMutation = useMutation(createAccount, {
    onSuccess: async (res) => {
      console.debug("Success createAccount");
      console.debug(res.data);
    },
  });

  const schoolUseForm = useForm<schoolsReturnValue>({
    mode: "onBlur",
  });

  let navigate = useNavigate();

  const onSubmitPost: SubmitHandler<schoolsReturnValue> = async (data) => {
    const address = `${data.prefecture} ${data.municipalities} ${data.houseNumber} ${data.buildings}`;
    data.address = address;
    data.sale_price_l = Number(data.wholesale_price_l);
    data.sale_price_2l = Number(data.wholesale_price_2l);
    data.sale_price_dl = Number(data.wholesale_price_dl);
    data.issales_lsize = "True";
    data.issales_2lsize = "True";
    data.issales_dl = "True";
    delete data.prefecture;
    delete data.municipalities;
    delete data.houseNumber;
    delete data.buildings;
    delete data.mail_confirmation;

    setNewPassword(data.new_password);
    delete data.new_password;

    await postSchoolInfoMutation.mutate(data);
  };

  const onSubmitPatch: SubmitHandler<schoolsReturnValue> = async (data) => {
    data.school_id = school_id;
    const address = `${data.prefecture} ${data.municipalities} ${data.houseNumber} ${data.buildings}`;
    data.address = address;
    delete data.prefecture;
    delete data.municipalities;
    delete data.houseNumber;
    delete data.buildings;
    delete data.mail_confirmation;

    // パスワード更新
    const postAccountData = {
      account_id: school_id,
      password: data.old_password,
      new_password: data.new_password,
    };
    setNewPassword(data.new_password);
    delete data.old_password;
    delete data.new_password;
    console.debug(postAccountData);
    await changeUserPasswordMutation.mutate(postAccountData);

    // console.debug(data);
    await patchSchoolInfoMutation.mutate(data);
  };

  return (
    <FormProvider {...schoolUseForm}>
      {school_id === "new" ? (
        <form onSubmit={schoolUseForm.handleSubmit(onSubmitPost)}>
          <ContentsHeader school_id={school_id} account_type={account_type} />
          <ContentsViewContainer
            school_id={school_id}
            account_type={account_type}
            existClassList={existClassList}
            setExistClassList={setExistClassList}
            newClassList={newClassList}
            setNewClassList={setNewClassList}
          />
          <ContentsFooter school_id={school_id} account_type={account_type} />
        </form>
      ) : (
        <form onSubmit={schoolUseForm.handleSubmit(onSubmitPatch)}>
          <ContentsHeader school_id={school_id} account_type={account_type} />
          <ContentsViewContainer
            school_id={school_id}
            account_type={account_type}
            existClassList={existClassList}
            setExistClassList={setExistClassList}
            newClassList={newClassList}
            setNewClassList={setNewClassList}
          />
          <ContentsFooter school_id={school_id} account_type={account_type} />
        </form>
      )}
    </FormProvider>
  );
}
