import { styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const DesignedBreadcrumbs = styled(Breadcrumbs)({
  fontSize: "small",
  color: "#00000066",
});

export default function CustomBreadcrumbs(props: any) {
  const {
    children,
    classes,
    component,
    expandText,
    itemsAfterCollapse,
    itemsBeforeCollapse,
    maxItems,
    separator,
    sx,
    className,
    muiClasses,
    ...rest
  } = props;
  return (
    <DesignedBreadcrumbs
      separator={<NavigateNextIcon />}
      aria-label="breadcrumb"
    >
      {children}
    </DesignedBreadcrumbs>
  );
}
