import { useSelector } from "react-redux";
import { RootState } from "index";

import MediaQuery from "react-responsive";
import AlbumsCard from "./AlbumsCard";
import Grid from "@mui/material/Grid";

import {
  removePreparingAlbums,
  filterChildclass,
  addAlbumStatus,
} from "utils/function/shapeAlbumData";

import { useAlubms, useUsers, useSchools } from "./AlubmsApis";

export default function ConsumerAlbums(props: any) {
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // get childclass from api
  const useInfo = useUsers(loginAccount.accountId);

  // get albumdata from api
  const school_id = useInfo === true ? "loading" : useInfo[0].school_id;
  let albumData = useAlubms(school_id);

  // get shippingMethod from api
  const schoolData = useSchools(school_id);
  if (schoolData && schoolData[0] && schoolData[0].shipping_method) {
    for (let i = 0; i < albumData.length; i++) {
      albumData[i].shipping_method = schoolData[0].shipping_method;
    }
  }

  if (useInfo === true || albumData === true || schoolData === true) {
    return <span>Loading...</span>;
  }

  const useInfoArray =
    useInfo && useInfo[0].child_class
      ? JSON.parse(useInfo[0].child_class.replaceAll("'", '"'))
      : [];

  let ALBUMS = addAlbumStatus(albumData);
  ALBUMS = filterChildclass(ALBUMS, useInfoArray);
  ALBUMS = removePreparingAlbums(ALBUMS);

  return (
    <>
      <h2>販売中のアルバム</h2>
      <MediaQuery query="(min-width: 600px)">
        <Grid container rowSpacing={"48px"} columnSpacing={"40px"}>
          <AlbumsCard albumsData={ALBUMS} />
        </Grid>
      </MediaQuery>
      <MediaQuery query="(max-width: 600px)">
        <Grid
          container
          rowSpacing={"20px"}
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <AlbumsCard albumsData={ALBUMS} />
        </Grid>
      </MediaQuery>
    </>
  );
}
