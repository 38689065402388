import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

type Props = {
  register: any;
};

export const GroupNameView: React.FC<Props> = ({ register }) => {
  return (
    <Grid container direction="row" alignItems="flex-start">
      {/* <Grid item xs={2}>
        <CustomLabel value="基本情報" />
      </Grid> */}
      <Grid item xs={12}>
        <CustomForm
          labelname="団体名"
          placeholder="団体名を入力してください"
          helperlabel="例：〇〇保育園"
          register={register("name")}
        />
        <CustomForm
          labelname="フリガナ"
          placeholder="フリガナを入力してください"
          helperlabel="例：マルマル保育園"
          register={register("kana")}
        />
        <CustomForm
          labelname="担当者"
          placeholder="担当者名を入力して下さい"
          helperlabel="例：NICOSKET"
          register={register("client")}
        />
      </Grid>
    </Grid>
  );
};
