import * as React from "react";

export default function InfoEditCustomFormHelperLabel(props: any) {
  const { value } = props;
  return (
    <div
      style={{
        fontSize: "12px",
        fontWeight: "normal",
        color: "#9E9E9E",
        lineHeight: "16px",
        marginBottom: "20px",
      }}
    >
      {value}
    </div>
  );
}
