import * as React from "react";
import { useState, useEffect } from "react";

// @mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";

// components
import InfoEditCustomeCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:MuiTableRow-hover": {
    backgroundColor: "primary",
  },
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
});

export default function GroupMailTable({ ...props }: any) {
  const { tableData, group, sendType, dmUseForm } = props;
  const [isCheck, setIsCheck] = useState([""]);

  const viewTableData = tableData.filter(
    // (x: any) => group === "default" || x.group_id === group
    (x: any) =>
      group === "default" ||
      x.child_class == "" ||
      (x.child_class != null && JSON.parse(x.child_class).includes(group))
  );

  // フィルタ切替時、送信先の選択状態をリセットする
  useEffect(() => {
    setIsCheck([]);
    dmUseForm.setValue("user_id", []);
  }, [group]);

  // 送信先のチェックを切り替えた時の処理
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      // 個別のcheck, valueをセットする
      setIsCheck([...isCheck, value]);
      dmUseForm.setValue("user_id", isCheck);
    } else {
      // 個別のcheck, valueを空にする
      setIsCheck(isCheck.filter((item) => item !== value));
      dmUseForm.setValue("user_id", isCheck);
    }
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      variant="outlined"
      sx={{ borderRadius: "16px" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {sendType === "person" && (
              <TableHeadCell align="center">送信</TableHeadCell>
            )}
            <TableHeadCell align="center">保護者名</TableHeadCell>
            <TableHeadCell align="center">お子様名</TableHeadCell>
            <TableHeadCell align="center">メールアドレス</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sendType === "class" &&
            viewTableData?.map((prop: any, key: any) => {
              return (
                <StyledTableRow key={key}>
                  <TableDataCell key={key} align="center" sx={{ width: "33%" }}>
                    {prop.user_name}
                  </TableDataCell>
                  <TableDataCell key={key} align="center" sx={{ width: "33%" }}>
                    {prop.child_name?.replace(/"/g, "")}
                  </TableDataCell>
                  <TableDataCell key={key} align="center">
                    {prop.user_id}
                  </TableDataCell>
                </StyledTableRow>
              );
            })}
          {sendType === "person" &&
            viewTableData?.map((prop: any, key: any) => {
              return (
                <StyledTableRow key={key}>
                  <TableDataCell key={key} align="center">
                    <InfoEditCustomeCheckBox
                      register={dmUseForm.register("user_id", {
                        required: "⚠︎送信先を選択してください",
                      })}
                      value={prop.user_id}
                      checked={isCheck.includes(prop.user_id)}
                      onClick={handleClick}
                    />
                  </TableDataCell>
                  <TableDataCell key={key} align="center" sx={{ width: "33%" }}>
                    {prop.user_name}
                  </TableDataCell>
                  <TableDataCell key={key} align="center" sx={{ width: "33%" }}>
                    {prop.child_name?.replace(/"/g, "")}
                  </TableDataCell>
                  <TableDataCell key={key} align="center">
                    {prop.user_id}
                  </TableDataCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
