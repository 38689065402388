import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay, virtualize } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import slide1 from "../../assets/img/lp-slide1.svg";
import slide2 from "../../assets/img/lp-slide2.svg";
import slide3 from "../../assets/img/lp-slide3.svg";
import slide4 from "../../assets/img/lp-slide4.svg";
import slide5 from "../../assets/img/lp-slide5.svg";
import slide1Mb from "../../assets/img/lp-slide1-mb.svg";
import slide2Mb from "../../assets/img/lp-slide2-mb.svg";
import slide3Mb from "../../assets/img/lp-slide3-mb.svg";
import slide4Mb from "../../assets/img/lp-slide4-mb.svg";
import slide5Mb from "../../assets/img/lp-slide5-mb.svg";
import Box from "@mui/material/Box";

const EnhancedSwipeableViews = autoPlay(virtualize(SwipeableViews));

const styles = {
  slide: {
    width: "100%",
    verticalAlign: "top",
  },
};

function slideRenderer(params: { index: any; key: any }) {
  const { index, key } = params;

  switch (mod(index, 5)) {
    case 0:
      return (
        <div key={key} className="swiper">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={slide1} alt="" style={Object.assign({}, styles.slide)} />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={slide1Mb}
              alt=""
              style={Object.assign({}, styles.slide)}
            />
          </Box>
        </div>
      );

    case 1:
      return (
        <div key={key} className="swiper">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={slide2} alt="" style={Object.assign({}, styles.slide)} />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={slide2Mb}
              alt=""
              style={Object.assign({}, styles.slide)}
            />
          </Box>
        </div>
      );

    case 2:
      return (
        <div key={key} className="swiper">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={slide3} alt="" style={Object.assign({}, styles.slide)} />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={slide3Mb}
              alt=""
              style={Object.assign({}, styles.slide)}
            />
          </Box>
        </div>
      );

    case 3:
      return (
        <div key={key} className="swiper">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={slide4} alt="" style={Object.assign({}, styles.slide)} />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={slide4Mb}
              alt=""
              style={Object.assign({}, styles.slide)}
            />
          </Box>
        </div>
      );

    case 4:
      return (
        <div key={key} className="swiper">
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img src={slide5} alt="" style={Object.assign({}, styles.slide)} />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <img
              src={slide5Mb}
              alt=""
              style={Object.assign({}, styles.slide)}
            />
          </Box>
        </div>
      );

    default:
      return null;
  }
}

function PicSwiper() {
  const [activeStep, setActiveStep] = useState<number>(0);

  return (
    <EnhancedSwipeableViews
      slideRenderer={slideRenderer}
      enableMouseEvents
      index={activeStep}
      onChangeIndex={(step) => setActiveStep(step)}
    />
  );
}

export default PicSwiper;
