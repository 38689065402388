const displayNameMap = new Map([
  ["L", "Lサイズ"],
  // ['LL', 'LLサイズ'],
  ["2L", "2Lサイズ"],
  // ["XL", "XLサイズ"],
  ["DL", "ダウンロード"],
]);

const sizeDetailMap = new Map([
  ["L", "89×127mm"],
  // ['LL', '127×178mm'],
  ["2L", "127×178mm"],
  // ["XL", "127×178mm"],
  ["DL", ""],
]);

const wholesaleMap = new Map([
  ["L", "wholesale_price_l"],
  // ['LL', 'wholesale_price_ll'],
  ["2L", "wholesale_price_2l"],
  // ["XL", "wholesale_price_xl"],
  ["DL", "wholesale_price_dl"],
]);

const maxCountMap = new Map([["DL", 1]]);

export const getDisplayNameAndSizeDetail = (
  sizeName: string,
  wholesale_prices?: any
) => {
  const wholesalePriceEntry = wholesaleMap.get(sizeName);
  return {
    displayName: displayNameMap.get(sizeName),
    sizeDetail: sizeDetailMap.get(sizeName),
    wholesalePrice:
      wholesale_prices && wholesalePriceEntry
        ? wholesale_prices[wholesalePriceEntry]
        : undefined,
    maxCount: maxCountMap.get(sizeName),
  };
};
