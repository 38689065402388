import { useState } from "react";

// @mui
import { Tabs, Tab, Box } from "@mui/material";

// components
import { TabPanel, a11yProps } from "./TabPanel";
import SchoolDmClass from "./SchoolDmClass";
import SchoolDmPerson from "./SchoolDmPerson";

export default function SchoolDm(props: any) {
  const [sendType, setSendType] = useState("class");

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    setSendType(newValue);
  };

  return (
    <>
      <h2>新規メール作成</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={sendType}
          onChange={handleChange}
          aria-label="DM send type Tabs"
        >
          <Tab label="クラス単位に送信" {...a11yProps("class")} />
          <Tab label="個別に送信" {...a11yProps("person")} />
        </Tabs>
      </Box>
      <TabPanel group_key={sendType} index="class">
        <SchoolDmClass />
      </TabPanel>
      <TabPanel group_key={sendType} index="person">
        <SchoolDmPerson />
      </TabPanel>
    </>
  );
}
