// @mui
import Grid from "@mui/material/Grid";

import logo from "../../assets/logo.svg";

export default function ContentsHeader() {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img src={logo} alt="NICOSKET" />
      </div>
      <h2 style={{ textAlign: "center" }}>ログイン</h2>
    </>
  );
}
