// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsFooter(props: any) {
  const { school_id, account_type } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      marginTop="20px"
    >
      <Grid item xs={3}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton type="submit" color="emerald" variant="contained">
              保存
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="emerald"
              variant="outlined"
              href={account_type === "admin" ? `/admin-schools` : `/schools`}
            >
              キャンセル
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
