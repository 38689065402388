import * as React from "react";

// @mui
import Box from "@mui/material/Box";

// components
import ContentsHeader from "./ContentsHeader";
import ContentsViewContainer from "./ContentsViewContainer";

export default function LoginAdmin() {
  return (
    <>
      <Box
        sx={{
          margin: { xs: "10vh auto", sm: "20vh auto" },
          padding: { xs: "20px", sm: "50px" },
          width: { xs: "90%", sm: "600px" },
          backgroundColor: "#fff",
        }}
      >
        <ContentsHeader />
        <ContentsViewContainer type={"admin"} />
      </Box>
    </>
  );
}
