// other
import { classesReturnValue } from "utils/interface/classes/classesInterface";

export default function setChildrenClassList(
  class_num: Number,
  existClassList: classesReturnValue[],
  setExistClassList: any
) {
  setExistClassList(
    existClassList.map((existClass: classesReturnValue, index: Number) =>
      index === class_num
        ? {
            class_id: existClass.class_id,
            class_name: existClass.class_name,
            school_id: existClass.school_id,
            year: existClass.year,
            child_flg: !existClass.child_flg,
          }
        : existClass
    )
  );
}
