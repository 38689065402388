import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useNavigate, useParams } from "react-router-dom";

// @mui
import { Divider, Grid, Card } from "@mui/material";

// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// components
import BreadCrumbs from "./BreadCrumbs";
import PhotoGroupBreadCrumbs from "./PhotoGroupBreadCrumbs";
import PhotoGroupNameModal from "./PhotoGroupNameModal";
import PhotoGrid from "./PhotoGrid";
import PhotoPagenation from "./PhotoPagenation";
import UploadedPhotoGroup from "../AlbumEdit/UploadedPhotoGroup";
import { PhotoUploader } from "../AlbumEdit/PhotoUploader";

// apis
import { useQuery } from "react-query";
import { getAlbums } from "utils/api/albums/albums";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";
import { getPhotoGroups } from "utils/api/photoGroup/photoGroup";
import { photoGroupReturnValue } from "utils/interface/photoGroup/photoGroupInterface";
import { getPhotos } from "utils/api/photo/photo";
import { photosReturnValue } from "utils/interface/photos/photosInterface";

import "./school-photo.scss";

export default function PhotoListSchool() {
  //ページネーション用
  const NUMSPERPAGE = 50;
  const [page, setPage] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  // storeからユーザ情報, school_idを取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  const accountType: string = loginAccount.accountType;
  const schoolId: string = loginAccount.schoolId;

  /* 2022/5/14 albumId, groupNameをURLに持たせる実装に変更  */
  /* storeに入れる場合の実装 */
  // const photoGroup: any = useSelector<RootState>(
  //   (state) => state.photoGroupReducer
  // );
  // const albumId: string = photoGroup.albumId;
  // const albumName: string = photoGroup.albumName;
  // const groupName: string = photoGroup.groupName;
  /* ここまで*/

  // URLからalbumId, 選択中の写真グループ情報を取得
  const albumId: string = useParams().albumId || "";
  const groupName: string = useParams().groupName || "";
  const groupPath: string[] = groupName?.split("/");

  const navigate = useNavigate();

  // albumId,groupPathがなければアルバム一覧に遷移
  if (!albumId || !groupPath) navigate("/school-albums");

  const getAlbumsResponse = useQuery<albumsReturnValue[], Error>(
    ["getAlbums", albumId],
    getAlbums,
    { enabled: !!albumId }
  );
  const getPhotosResponse = useQuery<photosReturnValue[], Error>(
    ["getPhotosResponse", "", albumId],
    getPhotos,
    { enabled: !!albumId }
  );
  const getPhotoGroupsResponse = useQuery<photoGroupReturnValue[], Error>(
    ["getPhotoGroups", albumId],
    getPhotoGroups,
    { enabled: !!albumId }
  );

  if (
    getAlbumsResponse.isLoading ||
    getPhotosResponse.isLoading ||
    getPhotoGroupsResponse.isLoading
  ) {
    return <span>Loading...</span>;
  }
  if (getAlbumsResponse.isError) {
    return <span>Error: {getAlbumsResponse.error?.message}</span>;
  }
  if (getPhotosResponse.isError) {
    return <span>Error: {getPhotosResponse.error?.message}</span>;
  }
  if (getPhotoGroupsResponse.isError) {
    return <span>Error: {getPhotoGroupsResponse.error?.message}</span>;
  }

  const albumData = getAlbumsResponse.data ? getAlbumsResponse.data[0] : {};
  const photosData = getPhotosResponse.data ? getPhotosResponse.data : [];
  const photoGroupData = getPhotoGroupsResponse.data
    ? getPhotoGroupsResponse.data
    : [];

  // albumid, groupNameが有効なものかAPI結果で判定
  const existGroupNames = photoGroupData.map((data) => data.group_name);
  // console.log("existGroupNames ");
  // console.log(existGroupNames);

  // アルバムが存在しない場合はアルバム一覧にリダイレクト
  if (!albumData) {
    navigate("/school-albums");
  } else if (!existGroupNames.includes(groupName)) {
    // 写真グループが存在しない場合はアルバム編集にリダイレクト
    navigate("/school-albums/" + albumId);
  } else if (
    accountType === "organization" &&
    schoolId !== albumData.school_id
  ) {
    // 団体かつschool_idがアカウントとアルバムで一致しない場合はアルバム一覧にリダイレクト
    navigate("/school-albums");
  }

  const albumName: string = albumData
    ? albumData.album_name
      ? albumData.album_name
      : ""
    : "";

  // photosDataをgroupNameでフィルタ
  const filteredPhotos = photosData.filter(
    (prop: any) => prop.group_name === groupName
  );

  const viewPhotos = filteredPhotos.slice(
    (page - 1) * NUMSPERPAGE,
    (page - 1) * NUMSPERPAGE + NUMSPERPAGE
  );

  return (
    <>
      <BreadCrumbs
        albumId={albumId}
        albumName={albumName}
        groupName1={groupPath[0]}
      />
      <Grid container sx={{ paddingTop: "24px", minWidth: "1040px" }}>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ borderRadius: "16px" }}>
            {/* folder name */}
            <Grid
              container
              justifyContent="space-between"
              sx={{ padding: "24px" }}
            >
              <Grid item>
                <PhotoGroupBreadCrumbs
                  groupName1={groupPath[0]}
                  groupName2={groupPath[1] || ""}
                />
              </Grid>
              <Grid item>
                <PhotoGroupNameModal
                  albumId={albumId}
                  groupName={groupName}
                  groupName1={groupPath[0]}
                  groupName2={groupPath[1] || ""}
                />
              </Grid>
            </Grid>
            <Divider />

            {/* photo display, delete */}
            <PhotoGrid photos={viewPhotos} page={page} />
          </Card>
          <PhotoPagenation
            photos={filteredPhotos}
            page={page}
            setPage={setPage}
            NUMSPERPAGE={NUMSPERPAGE}
          />
        </Grid>
      </Grid>

      {/* photo upload */}
      <UploadedPhotoGroup
        albumId={albumId}
        albumName={albumName}
        photoGroupData={photoGroupData}
      />
      <PhotoUploader
        isSchoolPhoto={true}
        schoolId={schoolId}
        albumId={albumId}
        groupName={groupName}
      />
    </>
  );
}
