import React from "react";
import { createStyles, withStyles } from "@mui/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Watermark from "react-awesome-watermark";
import cover from "../../assets/img/cover2.png";

const styles = createStyles({
  thumbnailWrapper: {
    position: "relative",
    "&::before": {
      display: "block",
      "padding-top": "100%",
      content: '""',
    },
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    border: "solid 1px",
    "border-color": "#E5E5E5",
    "border-radius": "6px",
    "object-fit": "contain",
    pointerEvents: "none",
  },
});

function SquareThumbnail({ ...props }: any) {
  const { photoSrc, classes } = props;

  const onClick = (e: any, isRight?: boolean) => {
    e.preventDefault();
    return false;
  };

  return (
    <div
      className={`${classes.thumbnailWrapper} photoGuard`}
      onContextMenu={(e) => onClick(e, true)}
    >
      <img src={photoSrc} className={classes.thumbnail} width="100%" />
      <img
        src={cover}
        className={classes.thumbnail}
        width="75%"
        style={{ position: "absolute" }}
      />
    </div>
  );
}

export default withStyles(styles)(SquareThumbnail);
