// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsHeader(props: any) {
  const { school_id, account_type } = props;

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={7}>
        <h2>団体登録情報</h2>
      </Grid>
      <Grid item xs={5}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <a href={`/user-regist-flyer/${school_id}`} target="_blank">
              保護者登録用チラシを表示
            </a>
          </Grid>
          <Grid item>
            <CustomButton type="submit" color="emerald" variant="contained">
              保存
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              color="emerald"
              variant="outlined"
              href={account_type === "admin" ? `/admin-schools` : `/schools`}
            >
              キャンセル
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
