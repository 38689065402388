// @mui
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// compornents
import UserNameView from "./UserNameView";
import UserAddressView from "./UserAddressView";
import UserPhoneNumberView from "./UserPhoneNumberView";
import UserMailAddressView from "./UserMailAddressView";
import UserClassView from "./UserClassView";
import UserChangeLoginView from "./UserChangeLoginView";
import UserChildrenView from "./UserChildrenView";

// APIs
import { useQuery } from "react-query";
import { getUsersByUserId } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";

import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
// functions
import getSchoolYear from "utils/function/getSchoolYear";

const CustomDivider = styled(Divider)({
  marginBottom: "20px",
});

export default function ContentsViewContainer(props: any) {
  const { user_id, existClassList, setExistClassList } = props;

  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsersByUserId", user_id],
    getUsersByUserId
  );
  const data = getUsersResponse.data ? getUsersResponse.data[0] : {};
  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getUsersByUserId", "", data.school_id, getSchoolYear()],
    getClasses
  );
  const classes = getClassesResponse.data ? getClassesResponse.data : [];

  if (getUsersResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }

  return (
    <div className="content">
      <form>
        <UserNameView data={data} />
        <CustomDivider />
        <UserAddressView data={data} />
        <CustomDivider />
        <UserPhoneNumberView data={data} />
        <CustomDivider />
        {/* userId = メールアドレスのため現状変更不可 */}
        {/* <UserMailAddressView data={data} />
        <CustomDivider /> */}
        <UserClassView
          data={data}
          classes={classes}
          existClassList={existClassList}
          setExistClassList={setExistClassList}
        />
        <CustomDivider />
        <UserChildrenView data={data} />
        <CustomDivider />
        <UserChangeLoginView />
      </form>
    </div>
  );
}
