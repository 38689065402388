import { useState, useEffect } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import OrderHistoryConsumerTable from "./organizm/OrderHistoryConsumerTable";
import OrderHistoryAdminTable from "./organizm/OrderHistoryAdminTable";
import OrderHistorySchoolTable from "./organizm/OrderHistorySchoolTable";
import { TabPanel, a11yProps } from "./organizm/TabPanel";
import { useParams, useNavigate } from "react-router-dom";

import "./OrderHistory.scss";

import { OrderHistoryContext } from "store/OrderHistoryContext";

export default function OrderHistoryConsumer(props: any) {
  // 以下が正しいが、特に連動させる必要を感じないため、静的に宣言 by aska 2022/05/26
  // const [group_key, setGroup_key] = useState(loginAccount.accountType);
  const [group_key, setGroup_key] = useState("admin");

  const { school_id, album_id } = useParams();
  const [schoolId, setSchoolId] = useState(school_id);
  const [albumId, setAlbumId] = useState(album_id);
  const [userId, setUserId] = useState("");
  const [query, setQuery] = useState([group_key, school_id, album_id, userId]);
  const navigate = useNavigate();

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    console.debug("handleChange");
    // これを消さないと、tabsの遷移はできない（なぜか変なuriが代入される） 2022/05/15 by aska
    navigate(-1);

    setGroup_key(newValue);
    if (newValue === "admin") setQuery([newValue, "", "", ""]);
    else if (newValue === "admin-school")
      setQuery([newValue, school_id, "", ""]);
    else setQuery([newValue, school_id, album_id, userId]);
  };

  const handleChange2 = (newValue: string) => {
    console.debug("handleChange2");

    setGroup_key(newValue);
    if (newValue === "admin") setQuery([newValue, "", "", ""]);
    else if (newValue === "admin-school")
      setQuery([newValue, schoolId, "", ""]);
    else setQuery([newValue, schoolId, albumId, userId]);
  };

  useEffect(() => {
    if (school_id) {
      setSchoolId(school_id);
      handleChange2("admin-school");
    }
  }, [school_id]);

  useEffect(() => {
    if (album_id) {
      setAlbumId(album_id);
      handleChange2("user");
    }
  }, [album_id]);

  return (
    <OrderHistoryContext.Provider
      value={{
        setGroup_key,
        setQuery,
        setSchoolId,
        schoolId,
        setAlbumId,
        albumId,
      }}
    >
      <h2>注文一覧</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={group_key}
          onChange={handleChange}
          aria-label="Order Group Tabs"
        >
          {group_key === "admin-school" ? (
            <Tab label="団体単位の履歴" {...a11yProps("admin")} />
          ) : (
            <Tab label="団体単位の履歴" {...a11yProps("admin")} disabled />
          )}
          {group_key === "user" ? (
            <Tab label="アルバム単位の履歴" {...a11yProps("admin-school")} />
          ) : (
            <Tab
              label="アルバム単位の履歴"
              {...a11yProps("admin-school")}
              disabled
            />
          )}
          <Tab label="保護者単位の履歴" {...a11yProps("user")} disabled />
        </Tabs>
      </Box>
      <TabPanel group_key={group_key} index="admin">
        <OrderHistoryAdminTable query={query} />
      </TabPanel>
      <TabPanel group_key={group_key} index="admin-school">
        <OrderHistorySchoolTable query={query} />
      </TabPanel>
      <TabPanel group_key={group_key} index="user">
        <OrderHistoryConsumerTable query={query} />
      </TabPanel>
    </OrderHistoryContext.Provider>
  );
}
