import { getDisplayNameAndSizeDetail } from "utils/function/getDisplayNameAndSizeDetail";
import { ordersReturnValue } from "utils/interface/orders/ordersInterface";
import { usersReturnValue } from "utils/interface/users/usersInterface";

const conveniMap = new Map([
    ["11", "セブンイレブン"],
    ["21", "ファミリーマート"],
    ["31", "ローソン"],
    ["32", "セイコーマート"],
    ["33", "ミニストップ"],
]);

const getOrderCountsByPicSize = (orderDetails: ordersReturnValue["order_details"]) => {
    const photoSizes = orderDetails?.reduce((keys: any, orderDetail) => {
        const keysNew = {...keys};
        if (!orderDetail.select_size) return keysNew;
        const selectSize = orderDetail.select_size;
        const key = keysNew[selectSize] || {amount: 0};
        keysNew[selectSize] = {
            name: selectSize,
            displayName: getDisplayNameAndSizeDetail(selectSize).displayName,
            amount: key.amount + (orderDetail.amount ? parseInt(orderDetail.amount) : 0),
        };
        return keysNew;
    }, {}) || {};
    return Object.values(photoSizes);
};

const generateOrderDetailsText = (orderDetails: ordersReturnValue["order_details"]) => {
    const orderCountsByPicSize = getOrderCountsByPicSize(orderDetails);
    return orderCountsByPicSize.reduce((str: string, count: any) => (
        str + `${count.displayName}${count.amount}枚 `
    ), "");
};

export const getOrderCompletedMailBody = (order: ordersReturnValue, user: usersReturnValue, receiptNo: string | null, kigyouCode: string| null, conveniNextstep?: string) => {
    return ( '<html><body>' + `
${user.user_name}様
この度は、ニコスケットを利用いただき
ありがとうございます。
ご注文を以下の通り承りました。

---------------
ご注文日時: ${order.order_date}
ご注文番号: ${order.order_id}
ご注文内容: ${generateOrderDetailsText(order.order_details)}
商品代金: ${order.taxed_price}円
---------------

お支払方法
${order.shipping_method === "一括配送集金" ?
    "現金集金" :
        order.payment === "card" ?
            "クレジットカード" :
            "コンビニ払い"
}

${ order.payment === "conveni" ?
    (order.conveni_code ? ("お支払いコンビニ\n" + conveniMap.get(order.conveni_code) + "\n\n") : "") +
    (conveniNextstep ? ('コンビニ支払い詳細リンク\n' + conveniNextstep + "\n\n") : "") +
    ((receiptNo !== null && receiptNo !== "") ? ('コンビニ支払い受付番号\n' + receiptNo + "\n\n") : "") +
    ((kigyouCode !== null && kigyouCode !== "") ? ('コンビニ支払い企業コード\n' + kigyouCode + "\n\n") : "")
: ""
}お届け先
${order.shipping_method === "自宅配送" ?
    "ご自宅へ配送します。" :
    "所属の団体様へ配送します。"
}

ご注文明細
マイページ内よりご確認ください。
※ダウンロードをご購入の場合、マイページの注文履歴から端末へ保存してください。

■ご注意
完全受注生産の為、お客様都合による返品・キャンセルはお受けすることができません。予めご了承ください。

※このメールは送信専用アドレスから送られています。

〈運営会社〉
株式会社ニコ&ピース
TEL 049-277-5363(平日10時-17時)
FAX 049-277-5362
HP https://nicosket.link
`.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/`/g, '&#x60;').replace(/\n/g, "<br/>") + '</body></html>'
    )
};