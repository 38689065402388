// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupFaxNumberView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="FAX番号" />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={4}>
            <CustomForm
              placeholder="FAX番号を入力してください"
              helperlabel="例：00-1234-5678"
              value={data.fax_number}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
