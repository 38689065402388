// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

const categoryList = [
  {
    value: "保育園",
    label: "保育園",
  },
  {
    value: "幼稚園",
    label: "幼稚園",
  },
  {
    value: "こども園",
    label: "こども園",
  },
  {
    value: "小学校",
    label: "小学校",
  },
  {
    value: "スポーツクラブ",
    label: "スポーツクラブ",
  },
];

const establishmentClassificationList = [
  {
    value: "私立",
    label: "私立",
  },
  {
    value: "公立",
    label: "公立",
  },
];

const authorizationClassificationList = [
  {
    value: "認可",
    label: "認可",
  },
  {
    value: "認可外",
    label: "認可外",
  },
  {
    value: "小規模",
    label: "小規模",
  },
];

const shippingList = [
  {
    value: "自宅配送",
    label: "自宅配送",
  },
  {
    value: "一括配送前払い",
    label: "一括配送前払い",
  },
  {
    value: "一括配送集金",
    label: "一括配送集金",
  },
];

export default function GroupLoginView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="団体概要" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={4}>
            <CustomSelector
              labelname="カテゴリ"
              items={categoryList}
              value={data.category}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="設立区分"
              items={establishmentClassificationList}
              value={data.corporate_type}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="認可区分"
              items={authorizationClassificationList}
              value={data.sub_category}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="契約開始日"
              type="date"
              value={data.contract_start}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="配送方法"
              items={shippingList}
              value={data.shipping_method}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomForm labelname="備考" value={data.remarks} disabled />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
