import { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";

type Props = {
  register: any;
};

export default function GroupKeyView(props: any) {
  const { register, getValues, trigger, formState } = props;

  const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false);
  const togglePassword = () => {
    setIsRevealPassword((prevState: boolean) => !prevState);
  };

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <ErrorMessage
          errors={formState.errors}
          name="account_id"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="メールアドレス"
          placeholder="メールアドレスを入力してください"
          register={register("account_id", {
            required: "⚠︎必須項目です",
            maxLength: 100,
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "⚠︎メールアドレスの形式が不正です",
            },
          })}
        />
        <ErrorMessage
          errors={formState.errors}
          name="password"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="パスワード(アルファベット大文字、小文字、数字が使用可能)"
          placeholder="パスワードを入力してください"
          type={isRevealPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                edge="end"
              >
                {isRevealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          register={register("password", {
            required: "⚠︎必須項目です",
            maxLength: 100,
            minLength: {
              value: 8,
              message: "パスワードは8文字以上で入力してください",
            },
            pattern: {
              value: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])^[0-9a-zA-Z]*$/,
              message:
                "⚠︎パスワードは大文字、小文字、数字を少なくとも1つ使用してください",
            },
            onBlur: () => {
              if (getValues("password_confirmation")) {
                trigger("password_confirmation");
              }
            },
          })}
        />
        <ErrorMessage
          errors={formState.errors}
          name="password_confirmation"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="パスワード確認"
          placeholder="パスワードを再入力してください"
          type={isRevealPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                edge="end"
              >
                {isRevealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          register={register("password_confirmation", {
            required: "⚠パスワードを再入力してください",
            maxLength: 100,
            validate: (value: any) => {
              return (
                value === getValues("password") || "⚠︎パスワードが一致しません"
              );
            },
          })}
        />
      </Grid>
    </Grid>
  );
}
