import CustomTable from "components/CustomTable/CustomTable";
import CustomTableFilter from "components/CustomTable/CustomTableFilter";
import CustomTablePagenation from "components/CustomTable/CustomTablePagenation";

export function GetSchoolAlbums(props: any) {
  const {
    filter,
    filterKey,
    filterArray,
    sortKey,
    urlKey,
    TABLEHEAD,
    group_key,
    chipFn,
    deleteList,
    setDeleteList,
    page,
    setPage,
    albumsData,
    filterStatus,
    setFilterStatus,
  } = props;

  const ROWSPERPAGE = 10;

  // フィルターをかけた
  const albumsFilterData = albumsData.filter(
    (x: any) => filterStatus === filterArray[0] || filterStatus === x[filterKey]
  );

  // 1ページ内に表示するテーブルデータ
  const viewTableData = albumsFilterData.slice(
    (page - 1) * ROWSPERPAGE,
    (page - 1) * ROWSPERPAGE + ROWSPERPAGE
  );

  return (
    <>
      {filter ? (
        <CustomTableFilter
          filterArray={filterArray}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          setPage={setPage}
          filter={filter}
        />
      ) : null}

      <CustomTable
        TABLEHEAD={TABLEHEAD}
        tableData={viewTableData}
        sortKey={sortKey}
        filterKey={filterKey}
        urlKey={urlKey}
        group_key={group_key}
        chipFn={chipFn}
        deleteList={deleteList}
        setDeleteList={setDeleteList}
      />

      <CustomTablePagenation
        tableData={albumsFilterData}
        page={page}
        setPage={setPage}
        ROWSPERPAGE={ROWSPERPAGE}
        filterKey={filterKey}
        filterStatus={filterStatus}
        filterArray={filterArray}
      />
    </>
  );
}
