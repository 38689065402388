import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

export default function CustomTablePagenation(props: any) {
  const {
    tableData,
    page,
    setPage,
    ROWSPERPAGE,
    filterStatus,
    filterArray,
    filterKey,
    size,
  } = props;

  return (
    <>
      <Grid item>
        { tableData.length ? (
          <div>
            全{tableData?.length}件中{(page - 1) * ROWSPERPAGE + 1}件〜
            {Math.min(tableData?.length, (page - 1) * ROWSPERPAGE + ROWSPERPAGE)}
            件を表示
          </div>
          ) : (
          <div>
            全0件中0件〜0件を表示
          </div>
        )}
      </Grid>
      <Grid item>
        <Pagination
          page={page}
          count={Math.ceil(
            tableData?.filter(
              (x: any) =>
                filterStatus === filterArray[0] || filterStatus === x[filterKey]
            ).length / ROWSPERPAGE
          )}
          shape="circular"
          size={size || "medium"}
          color="emerald"
          onChange={(e, page) => setPage(page)}
        />
      </Grid>
    </>
  );
}
