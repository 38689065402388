import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";

// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// custome components
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import CustomForm from "components/InfoEditCustomForm/InfoEditCustomForm";

// api
import { postSchool } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";

export default function GetSample() {
  const postSampleMutation = useMutation(postSchool);

  const { register, handleSubmit } = useForm<schoolsReturnValue>();

  const onSubmit: SubmitHandler<schoolsReturnValue> = (data) => {
    const restData = {
      name: "APIテスト",
      mail: "email@school.co.jp",
      contract_start: "2022/04/02",
      zip: "999-6666",
      address: "沖縄県 XXXX市 55-55-999",
      phone_number: "0809119111",
      fax_number: "081363684300",
      shipping_method: "郵便",
      issales_lsize: "True",
      issales_2lsize: "True",
      issales_dl: "False",
      wholesale_price_l: "50",
      wholesale_price_2l: "150",
      wholesale_price_dl: null,
      sale_price_l: "300",
      sale_price_2l: "500",
      sale_price_dl: null,
      postage: "100",
      commission: "150",
      remarks:
        "これはテストデータです。123456789 abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ @#$%^&*?!]",
      auto_correct: "False",
      bank_code: "1234",
      bank_name: "テスト銀行",
      branch_number: "123",
      branch_name: "テスト支店",
      bank_type: "無利息型普通預金",
      bank_account: "1234567",
      bank_account_name: "テスト口座",
    };

    const postData = { ...data, ...restData };
    console.log(postData);

    postSampleMutation.mutate(postData);
  };

  return (
    <div className="mt-2 mb-2">
      <Grid md={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <h2>PostSample</h2>
              <p>情報を入力してください</p>
              <CustomForm
                type="text"
                label="school_name"
                placeholder="school_name"
                register={register("school_name")}
              />
              <CustomForm
                type="text"
                label="school_kana"
                placeholder="school_kana"
                register={register("school_kana")}
              />
            </CardContent>
            <Grid container direction="row" justifyContent="center">
              <CustomButton type="submit" color="emerald" variant="contained">
                学校を登録
              </CustomButton>
            </Grid>
          </Card>
        </form>
      </Grid>
    </div>
  );
}
