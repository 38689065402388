import axios from "axios";
import { orderHistory } from "utils/interface/order/orderInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getOrderHistory = async (params: any) => {
  const response = await axios.get<orderHistory[]>(BASE_URL + "orderhistory", {
    params: {
      school_id: params.queryKey.school_id,
      album_id: params.queryKey.album_id,
      user_id: params.queryKey.user_id,
      order_status: params.queryKey.order_status,
      group_key: params.queryKey.group_key,
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const patchOrder = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.patch(BASE_URL + "order/" + params.order_id, data, {
    headers: { "content-type": "application/json" },
  });
};