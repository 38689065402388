import * as React from "react";
import { useState, useRef, useCallback, useMemo } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

// @mui
import { Card, Grid, Link } from "@mui/material";

// components
import { PhotoUploadModal } from "./PhotoUploadModal";

import "./album-edit.scss";

// ToDo: モーダル制御

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    directory?: string; // remember to make these attributes optional....
    webkitdirectory?: string;
  }
}

// react-dropzoneのstyle設定
const baseStyle = {
  display: "flex",
  width: "100%",
  padding: "53px 0",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
  alignItems: "center",
  borderWidth: "2px",
  borderStyle: "dashed",
  borderColor: "#cccccc",
};

const acceptStyle = {
  borderColor: "#23aa91",
  backgroundColor: "rgba(35, 170, 145, 0.1)",
};

const rejectStyle = {
  borderColor: "#cccccc",
  backgroundColor: "#ffffff",
};

// S3アップロード用
const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME || "bucketName";
const creds = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "accessKeyId",
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "secretAccessKey",
};

const s3 = new S3Client({
  region: "ap-northeast-1",
  credentials: creds,
});

const uploadFile = async (
  file: any,
  schoolId: string,
  albumId: string,
  filePath: string
) => {
  try {
    // console.log(BUCKET_NAME);
    // console.log(creds);
    // console.log(filePath);

    const uploadParams = {
      Bucket: BUCKET_NAME,
      Key: schoolId + "/" + albumId + "/" + filePath,
      Body: file,
    };

    try {
      const data = await s3.send(new PutObjectCommand(uploadParams));
      console.log("Successfully uploaded photo.");
      return data;
    } catch (err) {
      console.log(err);
      return console.log("There was an error uploading your photo.");
    }
  } catch (err) {
    console.log(err);
  }
};

export const PhotoUploader = (props: any) => {
  const { isSchoolPhoto, schoolId, albumId, groupName } = props;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [dispCount, setDispCount] = React.useState(0);

  const folderInput = React.useRef(null);

  // 全てのファイルに対する処理（成功/エラー問わず）完了後にリロードする
  const checkUploadStatus = async (count: number, filesLength: number) => {
    // console.log("count: " + count);
    // プログレスバー表示用
    setDispCount((count / filesLength) * 100);
    if (count >= filesLength) {
      // console.log("finished: " + count);
      setMessage("アップロード完了");
      // DB登録を待つ（登録されたタイミングが取れないため一定時間待つ）
      await new Promise((s) => setTimeout(s, 5000));
      // リロード
      window.location.reload();
    }
  };

  // upload by select file
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files != null) {
      setMessage("アップロード中...");
      setOpen(true);

      const files = event.currentTarget.files;
      const filesLength = files.length;
      let count = 0;

      Array.from(files).map(async (file) => {
        const fileName = file.name;
        const filePath = groupName + "/" + fileName;

        try {
          await uploadFile(file, schoolId, albumId, filePath);
          count++;
          checkUploadStatus(count, filesLength);
          return file;
        } catch (err) {
          count++;
          checkUploadStatus(count, filesLength);
          return null;
        }
      });
    }
  };

  // upload by select folder
  const handleChangeFolder = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files != null) {
      setMessage("アップロード中...");
      setOpen(true);

      const files = event.currentTarget.files;
      const filesLength = files.length;
      let count = 0;

      Array.from(files).map(async (file) => {
        // 取得されるファイルパスは先頭の/なし（例：1階層目のフォルダ名/2階層目のフォルダ名/ファイル名）
        const uploadedFilePath = file.webkitRelativePath;
        // console.log(uploadedFilePath);

        // 3階層より下のフォルダ配下はアップロードしない
        const filePathAry = uploadedFilePath.split("/");
        // console.log(filePathAry);

        // 全てのファイルに対する処理（成功/エラー問わず）完了後にリロードする
        if (filePathAry.length < 4) {
          const filePath = isSchoolPhoto
            ? groupName + "/" + file.name
            : uploadedFilePath;

          try {
            await uploadFile(file, schoolId, albumId, filePath);
            count++;
            checkUploadStatus(count, filesLength);
            return file;
          } catch (err) {
            count++;
            checkUploadStatus(count, filesLength);
            return null;
          }
        } else {
          count++;
          checkUploadStatus(count, filesLength);
        }
      });
    }
  };

  // upload by drag & drop
  const onDrop = useCallback(
    (acceptedFiles) => {
      setMessage(
        isSchoolPhoto
          ? "アップロード中..."
          : "処理実行中... （フォルダのみアップロード）"
      );
      setOpen(true);
      const filesLength = acceptedFiles.length;
      let count = 0;

      acceptedFiles.forEach(async (file: FileWithPath) => {
        // 取得されるファイルパスは先頭の/あり（例：/1階層目のフォルダ名/2階層目のフォルダ名/ファイル名）
        // ファイルの場合は先頭の/なし（例：ファイル名）
        const uploadedFilePath = file.path;
        // console.log(uploadedFilePath);

        if (uploadedFilePath != null) {
          // setOpen(true);

          const filePathAry = uploadedFilePath.split("/");
          const minFilePathLength = isSchoolPhoto ? 1 : 3;
          // console.log("minFilePathLength" + minFilePathLength);
          // console.log("filePathAry.length" + filePathAry.length);

          // 写真一覧のみファイルアップロード可能
          // 3階層より下のフォルダ配下はアップロードしない
          if (
            minFilePathLength <= filePathAry.length &&
            filePathAry.length < 5
          ) {
            // console.log("uploadFile");

            const filePath = isSchoolPhoto
              ? groupName + "/" + file.name
              : uploadedFilePath.replace("/", "");

            // 全てのファイルに対する処理（成功/エラー問わず）完了後にリロードする
            try {
              await uploadFile(file, schoolId, albumId, filePath);
              count++;
              checkUploadStatus(count, filesLength);
              return file;
            } catch (err) {
              count++;
              checkUploadStatus(count, filesLength);
              return null;
            }
          } else {
            count++;
            checkUploadStatus(count, filesLength);
          }
        }
      });
    },
    [albumId, groupName, isSchoolPhoto, schoolId]
  );

  // react-dropzoneの設定
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  return (
    <>
      <div className="drop-container">
        <div {...getRootProps({ style, className: "drop-zone" })}>
          <input {...getInputProps()} />
          <div className="drop-text">
            フォルダ{isSchoolPhoto && "・ファイル"}をドラッグ&ドロップ または{" "}
            <label htmlFor="folder_upload">
              フォルダを選択
              <input
                id="folder_upload"
                type="file"
                onChange={handleChangeFolder}
                // 拡張子を制限する場合
                // accept=".png, .jpg, .jpeg"
                multiple={true}
                directory=""
                webkitdirectory=""
                ref={folderInput}
              />
            </label>
            {/* 写真一覧のみファイルアップロード可能 */}
            {isSchoolPhoto && (
              <>
                {"  "}
                <label htmlFor="file_upload">
                  ファイルを選択
                  <input
                    id="file_upload"
                    type="file"
                    onChange={handleChangeFile}
                    // 拡張子を制限する場合
                    // accept=".png, .jpg, .jpeg"
                    multiple={true}
                  />
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <PhotoUploadModal open={open} message={message} dispCount={dispCount} />
    </>
  );
};
