import axios from "axios";
import { usersReturnValue } from "utils/interface/users/usersInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getUsers = async (params: any) => {
  const response = await axios.get<usersReturnValue[]>(BASE_URL + "users", {
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getUsersByUserId = async (params: any) => {
  const response = await axios.get<usersReturnValue[]>(BASE_URL + "users", {
    params: {
      user_id: params.queryKey[1],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getUsersByUserIdMutation = async (params: any) => {
  const response = await axios.get<usersReturnValue[]>(BASE_URL + "users", {
    params: {
      user_id: params[0],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const getUsersByParams = async (params: any) => {
  const response = await axios.get<usersReturnValue[]>(BASE_URL + "users", {
    params: {
      user_id: params.queryKey[1],
      school_id: params.queryKey[2],
      user_name: params.queryKey[3],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const postUsers = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "user", data, {
    headers: { "content-type": "application/json" },
  });
};

export const patchUsers = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.patch(BASE_URL + "user/" + params.user_id, data, {
    headers: { "content-type": "application/json" },
  });
};

export const deleteUser = async (params: any) => {
  const response = await axios.delete(BASE_URL + "user/" + params);
  return response;
};