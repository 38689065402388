import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

export default function PhotoPagenation(props: any) {
  const { photos, page, setPage, NUMSPERPAGE } = props;

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        sx={{ paddingTop: "16px" }}
      >
        <Grid item>
          {photos.length ? (
            <div>
              全{photos?.length}件中{(page - 1) * NUMSPERPAGE + 1}件〜
              {Math.min(photos?.length, (page - 1) * NUMSPERPAGE + NUMSPERPAGE)}
              件を表示
            </div>
          ) : (
            <div>全0件中0件〜0件を表示</div>
          )}
        </Grid>
        <Grid item>
          <Pagination
            page={page}
            count={Math.ceil(photos?.length / NUMSPERPAGE)}
            shape="circular"
            color="emerald"
            onChange={(e, page) => setPage(page)}
          />
        </Grid>
      </Grid>
    </>
  );
}
