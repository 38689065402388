import React, { useState } from "react";

// core components
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";

// custom components
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import CustomForm from "components/InfoEditCustomForm/InfoEditCustomForm";
import CustomCheckbox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomBreadcrumbs from "components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomChip from "components/CustomChip/CustomChip";

export default function Components() {
  // for radio button
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };

  // for breadcrumbs
  const breadcrumbs = [
    <CustomChip label="Chip Outlined" color="emerald" />,
    <CustomChip label="Chip2 Outlined" variant="outlined" />,
    <CustomChip label="Chip3 Filled" />,
  ];

  return (
    <div>
      <h2>components</h2>
      <h3>button</h3>
      <Grid container>
        <Grid item md={3}>
          <CustomButton color="emerald" variant="contained">
            emerald
          </CustomButton>
        </Grid>
        <Grid item md={3}>
          <CustomButton color="orange" variant="contained">
            orange
          </CustomButton>
        </Grid>
        <Grid item md={3}>
          <CustomButton color="emerald" variant="outlined">
            キャンセル
          </CustomButton>
        </Grid>
        <Grid item md={3}>
          <CustomButton color="error" variant="outlined">
            削除
          </CustomButton>
        </Grid>
      </Grid>

      <h3>form items</h3>
      <Grid container>
        <Grid item md={4}>
          <CustomForm />
        </Grid>
        <Grid item md={2}>
          <CustomCheckbox />
          <CustomCheckbox defaultChecked />
        </Grid>
        <Grid item md={2}>
          <Radio
            checked={selectedValue === "a"}
            onChange={handleChange}
            value="a"
            color="emerald"
          />
          <Radio
            checked={selectedValue === "b"}
            onChange={handleChange}
            value="b"
            color="emerald"
          />
        </Grid>
      </Grid>

      <h3>Breadcrumbs</h3>
      <CustomBreadcrumbs>{breadcrumbs}</CustomBreadcrumbs>
    </div>
  );
}
