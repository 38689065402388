// @mui
import Grid from "@mui/material/Grid";

// compornents
import GroupCommissionSetting from "./GroupCommissionSetting";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupCommissionView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value={"送料・手数料"} />
      </Grid>
      <Grid item xs={10}>
        <GroupCommissionSetting data={data} />
      </Grid>
    </Grid>
  );
}
