import { orderHistory } from "utils/interface/order/orderInterface";

export const CONSUMERHEAD: orderHistory[] = [
  {
    order_id: "注文番号",
    user_name: "ユーザー名",
    order_status: "ステータス",
    order_date: "注文日",
    amount: "購入枚数",
    subtotal: "販売金額",
  },
];

export const ORDERSTATUS: string[] = [
  "すべて",
  "発送済み",
  "入金済み",
  "入金済みDL",
  "未入金",
];

export const getChipColorByOrderstate = (filterKey: string): any => {
  if (filterKey === "発送済み") {
    return "lime";
  } else if (filterKey === "入金済み" || filterKey === "入金済みDL") {
    return "cyan";
  } else if (filterKey === "団体入金完了") {
    return "cyan";
  } else if (filterKey === "手続き中") {
    return "orange";
  } else if (filterKey === "未入金") {
    return "ruby";
  } else {
    return "emerald";
  }
};

export const SCHOOLHEAD: orderHistory[] = [
  {
    album_name: "アルバム名",
    amount_photo: "写真枚数",
    sales_period_from: "販売開始日",
    sales_period_to: "販売終了日",
    records: "注文件数",
    subtotal: "販売金額",
  },
];

export const ADMINHEAD: orderHistory[] = [
  {
    school_id: "団体ID",
    school_name: "団体名",
    records: "注文件数",
    subtotal: "販売金額",
  },
];
