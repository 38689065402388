import * as React from "react";

import "./footer.scss";

import footLogo from "assets/footer_logo.svg";
import termsPdf from "assets/terms.pdf";
import Terms from "views/Terms/Terms";
import privacyPolicyPdf from "assets/privacy-policy.pdf";
import organizationTermsPdf from "assets/OrganizationTerms.pdf";
import userTermsPdf from "assets/UserTerms.pdf";

// @mui
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const CustomDivider = styled(Divider)({
  marginBottom: "24px",
});

export function Footer(props: any) {
  const { account_type } = props;

  return (
    <footer>
      <div className="footerContent">
        <div className="clear">
          <ul>
            <li>
              <a href={privacyPolicyPdf} target="_blank">
                プライバシーポリシー
              </a>
            </li>
            <li>
              <a href={termsPdf} target="_blank">
                特定商取引法に基づく表示
              </a>
            </li>
            {account_type === "user" ? (
              <li>
                <a href={userTermsPdf} target="_blank">
                  利用規約
                </a>
              </li>
            ) : (
              <li>
                <a href={organizationTermsPdf} target="_blank">
                  利用規約
                </a>
              </li>
            )}
          </ul>
        </div>
        <CustomDivider />
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            <h3>
              <img src={footLogo} alt="Nice&Peace" />
            </h3>
          </Grid>
          <Grid item xs={12} sm={7}>
            <p>〒350-1131 埼玉県川越市岸町1-46-30</p>
            <p>TEL: 049-277-5363（営業時間: 平日10:00 - 17:00）</p>
            <p>FAX: 049-277-5362 Mail: hello[@]nicoandpeace.net</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <p className="aligned">Copyright Nico&Peace All Rights Reserved.</p>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}

export function LandingFooter() {
  return (
    <footer>
      <div className="footerContentLP">
        <div className="clear">
          <ul>
            <li>
              <a href={privacyPolicyPdf} target="_blank">
                プライバシーポリシー
              </a>
            </li>
          </ul>
        </div>
        <CustomDivider />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <h3>
              <img src={footLogo} alt="Nice&Peace" />
            </h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>〒350-1131 埼玉県川越市岸町1-46-30</p>
            <p>TEL: 049-277-5363（営業時間: 平日10:00 - 17:00）</p>
            <p>FAX: 049-277-5362 Mail: hello[@]nicoandpeace.net</p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <p className="aligned">Copyright Nico&Peace All Rights Reserved.</p>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
}
