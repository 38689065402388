import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { useNavigate } from "react-router-dom";

import { getAccounts } from "utils/api/accounts/accounts";
import { accountsReturnValue } from "utils/interface/accounts/accountsInterface";

export default function GetAccounts(props: any) {
  const { accountId, type } = props;

  const dispatch = useDispatch();

  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  const getAccountsResponse = useQuery<accountsReturnValue[], Error>(
    ["getAccounts", accountId],
    getAccounts,
    {
      // accountIdが存在する場合のみこのクエリを実行する
      enabled: !!accountId,
    }
  );
  if (getAccountsResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getAccountsResponse.isError) {
    return <span>Error: {getAccountsResponse.error?.message}</span>;
  }
  const resData = getAccountsResponse.data ? getAccountsResponse.data[0] : {};

  console.log("GetAccounts");
  console.log(resData);

  // 検索キーのIDと取得データのIDが一致、画面種別と取得データのユーザ種別が一致しているか判定
  // ログイン可能なユーザ種別の場合、認証フラグとアカウント情報をstoreに格納してログイン後画面に遷移
  if (resData && resData.account_id && resData.account_type) {
    if (resData.account_id === accountId && resData.account_type === type) {
      // console.log("store");
      // console.log(loginAccount);

      // 団体の場合、account_id = school_id
      let schoolId = "";
      if (type === "organization")
        schoolId = resData.account_id ? resData.account_id : "";

      dispatch({
        type: "LOGIN",
        payload: {
          resData,
          schoolId,
        },
      });

      // console.log("dispatch");
      // console.log(loginAccount);

      if (loginAccount.isAuthenticated) {
        // navigate("/albums"); でページ遷移するとAPIが再実行され無限ループになる
        if (type === "admin") {
          window.location.href = "/admin";
        } else if (type === "organization") {
          window.location.href = "/school-albums";
        } else if (type === "user") {
          window.location.href = "/albums";
        }
      }
    } else {
      // console.log("account type error");
      // このコンポーネント配下で表示を変えるとAPIが再実行され、ログイン成功/種別が違う場合に無限ループになる
      // alert("account type error");

      // そもそも使うべきログイン画面が違うためLPに遷移させる
      window.location.href = "/";
    }
  }

  return <></>;
}
