import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useNavigate } from "react-router-dom";

// components
import ContentsViewContainer from "./ContentsViewContainer";
import Breadcrumbs from "./BreadCrumbs";

// apis
import { useQuery } from "react-query";
import { getAlbums } from "utils/api/albums/albums";
import { albumsReturnValue } from "utils/interface/albums/albumsInterface";
import { getPhotoGroups } from "utils/api/photoGroup/photoGroup";
import { photoGroupReturnValue } from "utils/interface/photoGroup/photoGroupInterface";

// functions
import { getCurrentDate } from "./getDatetime";

import "./album-edit.scss";

export default function AlbumEdit(props: any) {
  // URLからalbumIdを取得
  const albumId: string = useParams().albumId || "";
  let schoolId: string = useParams().SchoolId || "";

  // storeからログイン中のユーザ情報、school_idを取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  const accountType: string = loginAccount.accountType;

  if (accountType !== "admin") {
    schoolId = loginAccount.schoolId;
  }

  // console.log(accountType);
  // console.log(schoolId);

  const navigate = useNavigate();

  const getAlbumsResponse = useQuery<albumsReturnValue[], Error>(
    ["getAlbums", albumId],
    getAlbums,
    { enabled: !!albumId }
  );
  const getPhotoGroupsResponse = useQuery<photoGroupReturnValue[], Error>(
    ["getPhotoGroups", albumId],
    getPhotoGroups,
    { enabled: !!albumId }
  );

  if (getAlbumsResponse.isLoading || getPhotoGroupsResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getAlbumsResponse.isError) {
    return <span>Error: {getAlbumsResponse.error?.message}</span>;
  }
  if (getPhotoGroupsResponse.isError) {
    return <span>Error: {getPhotoGroupsResponse.error?.message}</span>;
  }

  const albumData = getAlbumsResponse.data ? getAlbumsResponse.data[0] : {};
  const photoGroupData = getPhotoGroupsResponse.data
    ? getPhotoGroupsResponse.data
    : {};

  console.log("albumData:" + albumData);

  // console.log(albumData);
  // console.log(photoGroupData);

  // 現在の日付を取得
  const currentDate: string = getCurrentDate();
  // 2022/6/19 日付のデフォルト値をnullにするため1か月後の日付は不要
  // const oneMonthLaterDate: string = getOneMonthLaterDate();

  // 初期値
  let album = {
    albumId: albumId,
    schoolId: schoolId,
    albumName: "",
    salesPeriodFrom: "",
    salesPeriodTo: "",
    // salesPeriodFrom: currentDate,
    // salesPeriodTo: oneMonthLaterDate,
    priceList: { L: 0, "2L": 0, DL: 0 },
    sizeList: [],
    salesClasses: [],
    thumbnail: "",
  };

  // 新規/編集、販売開始による編集可否判定用
  let isNewAlbum: boolean = true;
  let isEditable: boolean = true;

  // APIの戻り値で新規/編集を判定、販売開始日と権限で編集可否を判定
  if (albumData && albumData.album_id) {
    // console.log("編集");
    isNewAlbum = false;

    // 団体かつschool_idがアカウントとアルバムで一致しない場合はアルバム一覧にリダイレクト
    if (accountType === "organization" && schoolId !== albumData.school_id) {
      navigate("/school-albums");
    }

    // 団体かつ販売開始日が本日含む過去日で登録済の場合は一部編集不可
    if (
      accountType === "organization" &&
      albumData.sales_period_from &&
      albumData.sales_period_from.replace(/\//g, "-") <= currentDate
    ) {
      // console.log("一部編集不可");
      isEditable = false;
    }

    // APIの取得結果をセット
    album.albumName = albumData.album_name ? albumData.album_name : "";
    album.schoolId = albumData.school_id ? albumData.school_id : schoolId;
    album.salesPeriodFrom = albumData.sales_period_from
      ? albumData.sales_period_from.replace(/\//g, "-")
      : "";
    // : currentDate;
    album.salesPeriodTo = albumData.sales_period_to
      ? albumData.sales_period_to.replace(/\//g, "-")
      : "";
    // : oneMonthLaterDate;

    if (albumData.price_list) {
      const price_list = JSON.parse(albumData.price_list);
      const priceL = price_list.L | 0;
      const price2L = price_list["2L"] | 0;
      const priceDL = price_list.DL | 0;
      album.priceList = { L: priceL, "2L": price2L, DL: priceDL };
    }

    album.sizeList = albumData.size_list ? JSON.parse(albumData.size_list) : [];

    album.salesClasses = albumData.sales_classes
      ? JSON.parse(albumData.sales_classes)
      : [];

    console.log("salesClassJson:" + album.salesClasses);
  } else {
    // console.log("新規");
    // 管理者かつ新規の場合はアルバム一覧にリダイレクト
    // if (accountType === "admin") {
    //   navigate("/school-albums");
    // }
  }

  return (
    <>
      <Breadcrumbs isNewAlbum={isNewAlbum} dfAlbumValue={album} />
      <ContentsViewContainer
        isNewAlbum={isNewAlbum}
        isEditable={isEditable}
        schoolId={album.schoolId}
        dfAlbumValue={album}
        photoGroupData={photoGroupData}
        accountType={accountType}
        currentDate={currentDate}
      />
    </>
  );
}
