export default function convertStrToBool(str: string) {
  if (typeof str != "string") {
    return Boolean(str);
  }
  try {
    let obj = JSON.parse(str.toLowerCase());
    return obj == true;
  } catch (e) {
    return str != "";
  }
}
