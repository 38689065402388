import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../..";

// components
import ContentsHeader from "./ContentsHeader";
import ContentsFooter from "./ContentsFooter";
import ContentsViewContainer from "./ContentsViewContainer";

// APIs
import { patchUsers } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { changeUserPassword } from "utils/api/authentication/authentication";
import { patchAccount } from "utils/api/account/account";

export default function UserInfoEdit() {
  // storeからユーザ情報を取得
  let user_id = useParams().userId || "";
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  console.log(loginAccount.accountId);
  if (loginAccount.accountType === "user") {
    user_id = loginAccount.accountId;
  }
  const account_type: string = loginAccount.accountType;
  // 試験的にダミーデータ
  // const user_id = "taka5216@gmail.com";
  // const user_id = "test2467@gmail.com";

  let navigate = useNavigate();

  const [existClassList, setExistClassList] = useState<classesReturnValue[]>(
    []
  );
  const [newPassword, setNewPassword] = useState<string | undefined>("");

  const patchUserMutations = useMutation(patchUsers, {
    onSuccess: async () => {
      console.debug("Success");
      if (account_type === "admin") {
        navigate("/admin-users/");
        window.location.reload();
      } else if (account_type === "user") {
        navigate("/albums/");
      }
    },
  });
  const changeUserPasswordMutation = useMutation(changeUserPassword, {
    onSuccess: async (res) => {
      console.debug("Success");
      console.debug(res.data);
      if (res.data.result) {
        await patchAccountMutation.mutate({
          account_id: user_id,
          account_type: "user",
          password: newPassword,
          permission: [],
        });
      }
    },
  });
  const patchAccountMutation = useMutation(patchAccount, {
    onSuccess: async (res) => {
      console.debug("Success patchAccount");
    },
  });

  const userUseForm = useForm<usersReturnValue>({
    mode: "onBlur",
  });

  let classList: string[] = [];
  const onSubmit: SubmitHandler<usersReturnValue> = async (data) => {
    data.user_id = user_id;
    const address = `${data.prefecture} ${data.municipalities} ${data.houseNumber} ${data.buildings}`;
    data.address = address;
    delete data.prefecture;
    delete data.municipalities;
    delete data.houseNumber;
    delete data.buildings;

    // パスワード更新
    const postAccountData = {
      account_id: user_id,
      password: data.old_password,
      new_password: data.new_password,
    };
    setNewPassword(data.new_password);
    delete data.old_password;
    delete data.new_password;
    console.debug(postAccountData);
    await changeUserPasswordMutation.mutate(postAccountData);

    existClassList.map((existClass) => {
      if (existClass.child_flg && existClass.class_id) {
        classList.push(existClass.class_id);
      }
    });
    // data.child_class = classList.join(",");
    data.child_class = JSON.stringify(classList);
    console.debug(data.child_name);
    const child_name_tmp = data.child_name
      ? JSON.stringify(data.child_name).replace(" ", "")
      : "";
    data.child_name = child_name_tmp;

    console.debug(data);
    await patchUserMutations.mutate(data);
  };

  return (
    <FormProvider {...userUseForm}>
      <form onSubmit={userUseForm.handleSubmit(onSubmit)}>
        <ContentsHeader />
        <ContentsViewContainer
          user_id={user_id}
          existClassList={existClassList}
          setExistClassList={setExistClassList}
        />
        <ContentsFooter />
      </form>
    </FormProvider>
  );
}
