import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function UserPhoneNumberView(props: any) {
  const { data } = props;
  const userUseForm = useFormContext();

  useEffect(() => {
    userUseForm.setValue("phone_number", data.phone_number);
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={4}>
            <CustomLabel value={"電話番号"} />
            <ErrorMessage
              errors={userUseForm.formState.errors}
              name="phone_number"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <CustomForm
              placeholder="電話番号を入力してください"
              helperlabel="例：00-1234-5678"
              register={userUseForm.register("phone_number", {
                required: "⚠︎必須項目です",
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
