// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsFooter() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      marginTop="20px"
      justifyContent="center"
    >
      <CustomButton type="submit" color="neutral" variant="contained">
        登録
      </CustomButton>
    </Grid>
  );
}
