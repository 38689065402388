import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupChangeLoginView(props: any) {
  const schoolUseForm = useFormContext();

  const { school_id } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="パスワード変更" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={4}>
            <CustomForm labelname="ログインID" value={school_id} />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={12}>
            <ErrorMessage
              errors={schoolUseForm.formState.errors}
              name="old_password"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="現在のパスワード"
              placeholder="現在のパスワードを入力してください"
              helperlabel="Passw0rdOld"
              type="text"
              register={schoolUseForm.register("old_password", {
                // required: "⚠︎必須項目です",
                maxLength: 100,
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上で入力してください",
                },
                pattern: {
                  value: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])^[0-9a-zA-Z]*$/,
                  message:
                    "⚠︎パスワードは大文字、小文字、数字を少なくとも1つ使用してください",
                },
              })}
            />
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={12}>
            <ErrorMessage
              errors={schoolUseForm.formState.errors}
              name="new_password"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="新パスワード"
              placeholder="新パスワードを入力してください"
              helperlabel="Passw0rdNew"
              type="text"
              register={schoolUseForm.register("new_password", {
                // required: "⚠︎必須項目です",
                maxLength: 100,
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上で入力してください",
                },
                pattern: {
                  value: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]/,
                  message:
                    "⚠︎パスワードは大文字、小文字、数字を少なくとも1つ使用してください",
                },
              })}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
