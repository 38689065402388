// 保育園用views
import SchoolAlbum from "views/SchoolAlbums/SchoolAlbums";
import SchoolUsers from "views/SchoolUsers/SchoolUsers";
import UserInfoEdit from "views/UserInfoEdit/UserInfoEdit";
import SchoolDm from "views/SchoolDm/SchoolDm";
import SchoolDmHistory from "views/SchoolDmHistory/SchoolDmHistory";
import OrderHistorySchool from "views/OrderHistory/OrderHistorySchool";
import Schools from "views/Schools/Schools";
import SchoolsView from "views/SchoolsView/SchoolsView";
import AlbumEdit from "views/AlbumEdit/AlbumEdit";
import PhotoListSchool from "views/SchoolPhoto/PhotoListSchool";
import OrderDetailsSchool from "views/Order/OrderDetailsSchool";
import Logout from "views/Logout/Logout";
import UserRegistFlyer from "views/UserRegistFlyer/UserRegistFlyer";

export const schoolRouteArray = [
  {
    id: "20",
    path: "/school-login",
    element: "",
  },
  {
    id: "21",
    path: "/school-albums",
    element: <SchoolAlbum />,
  },
  {
    id: "22",
    path: "/school-album/:albumId",
    element: <AlbumEdit />,
  },
  // aska add
  {
    id: "22A",
    path: "/school-albums/:albumId",
    element: <AlbumEdit />,
  },
  {
    id: "22B",
    path: "/school-photos/:albumId/:groupName",
    element: <PhotoListSchool />,
  },
  /* 2022/5/14 URLに持たせる実装に変更 */
  // {
  //   id: "22A",
  //   path: "/school-photos",
  //   element: <PhotoListSchool />,
  // },
  {
    id: "23",
    path: "/school-dm",
    element: <SchoolDm />,
  },
  {
    id: "24",
    path: "/school-dm-history",
    element: <SchoolDmHistory />,
  },
  {
    id: "25",
    path: "/schools/",
    element: <SchoolsView />,
  },
  {
    id: "26",
    path: "/schools-edit/:SchoolId",
    element: <Schools />,
  },
  {
    id: "27A",
    path: "/school-order-history",
    element: <OrderHistorySchool />,
  },
  {
    id: "27B",
    path: "/school-order-history/:album_id",
    element: <OrderHistorySchool />,
  },
  {
    id: "27C",
    path: "/school-order-history/:album_id/:orderId",
    element: <OrderDetailsSchool />,
  },
  {
    id: "28",
    path: "/user-regist-flyer/:SchoolId",
    element: <UserRegistFlyer />,
  },
  {
    id: "29",
    path: "/school-users",
    element: <SchoolUsers />,
  },
  {
    id: "98",
    path: "/school-logout",
    element: <Logout accountType="organization" />,
  },
];
