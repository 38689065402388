import axios from "axios";

export const postGMO = async (params: any) => {
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(
    "https://beta.epsilon.jp/cgi-bin/order/receive_order3.cgi",
    data
    // {
    //   headers: { "content-type": "application/json" },
    // }
  );
};
