import * as React from "react";

// @mui
import { Grid } from "@mui/material";

import FolderIcon from "@mui/icons-material/Folder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function PhotoGroupBreadCrumbs(props: any) {
  const { groupName1, groupName2 } = props;

  return (
    <>
      <Grid container spacing="12px">
        <Grid item>
          <Grid container alignItems="center">
            <FolderIcon sx={{ color: "orange.main", marginRight: "6px" }} />
            <b>{groupName1}</b>
          </Grid>
        </Grid>
        {/* 下位フォルダがある場合のみ表示 */}
        {groupName2 && (
          <>
            <Grid item>
              <Grid container alignItems="center">
                <NavigateNextIcon
                  sx={{
                    fontSize: "24px",
                    color: "rgba(0,0,0,.4)",
                    marginLeft: "12px",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" sx={{ paddingBottom: "8px" }}>
                <FolderIcon sx={{ color: "orange.main", marginRight: "6px" }} />
                <b>{groupName2}</b>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
