import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const DesignedChip = styled(Chip)({
  borderRadius: "4px",
  fontSize: "12px",
  margin: "0 5px",
});

export default function CustomChip(props: any) {
  const {
    avatar,
    children,
    classes,
    clickable,
    color,
    component,
    deleteIcon,
    disabled,
    icon,
    label,
    onDelete,
    size,
    sx,
    className,
    muiClasses,
    variant,
    ...rest
  } = props;
  return (
    <DesignedChip
      label={label}
      color={color}
      variant={variant}
      clickable={clickable}
      component={component}
      {...rest}
    >
      {children}
    </DesignedChip>
  );
}
