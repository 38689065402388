import axios from "axios";
import { dmReturnValue } from "utils/interface/dm/dmInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const postDm = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "dm", data, {
    headers: { "content-type": "application/json" },
  });
};
