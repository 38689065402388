import * as React from "react";
import MediaQuery from "react-responsive";
import Grid from '@mui/material/Grid';
import FolderImage from "./FolderImage"

export default function FolderList(props: any) {
  const { albumId, PhotoList, folderFlag, groupName } = props;
  return (
    <>
      <MediaQuery query="(min-width: 600px)">
        <Grid container rowSpacing={5} direction="row" justifyContent="flex-start" alignItems="baseline">
          {PhotoList.map((prop: any, key: any) => (
            <Grid item>
              <FolderImage albumId={albumId} title={prop} flag={folderFlag[key]} groupName={groupName}/>
            </Grid>
          ))}
        </Grid>
      </MediaQuery>
      <MediaQuery query="(max-width: 600px)">
        <Grid container rowSpacing={5} direction="column" justifyContent="flex-start" alignItems="center" sx={{ paddingLeft: "-24px",}}>
          {PhotoList.map((prop: any, key: any) => (
            <Grid item>
              <FolderImage albumId={albumId} title={prop} flag={folderFlag[key]} groupName={groupName}/>
            </Grid>
          ))}
        </Grid>
      </MediaQuery>
    </>
  );
}