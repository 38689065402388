import * as React from "react";

// @mui
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";

// compornents

const BpIcon = styled("span")(({ theme }) => ({
  border: "1px solid #CCCCCC",
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: "#FAFAFA",
}));

const BpCheckedIcon = styled(BpIcon)({
  border: "1px solid #23AA91",
  backgroundColor: "rgba(35, 170, 145, 0.1)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%2323AA91'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

// Inspired by blueprintjs
export default function InfoEditCustomeCheckBox(props: any) {
  const {
    disabled,
    checked,
    register,
    defaultChecked,
    value,
    onChange,
    onClick,
  } = props;
  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      defaultChecked={defaultChecked}
      value={value}
      onChange={onChange}
      onClick={onClick}
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...register}
    />
  );
}
