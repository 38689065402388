import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "index";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import OrderHistoryConsumerTable from "./organizm/OrderHistoryConsumerTable";
import OrderHistorySchoolTable from "./organizm/OrderHistorySchoolTable";
import { TabPanel, a11yProps } from "./organizm/TabPanel";
import { useParams, useNavigate } from "react-router-dom";

import "./OrderHistory.scss";

import { OrderHistoryContext } from "store/OrderHistoryContext";

export default function OrderHistoryConsumer(props: any) {
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  // Sdummyが入っているため、動作確認したいのであればコメントを変更すること by aska 2022/05/26
  const [schoolId, setSchoolId] = useState(loginAccount.schoolId);

  const { album_id } = useParams();
  const [albumId, setAlbumId] = useState(album_id);

  // 以下が正しいが、accountType = organizationなので改修が必要 by aska 2022/05/26
  // const [group_key, setGroup_key] = useState(loginAccount.accountType);
  const [group_key, setGroup_key] = useState("school");
  const [userId, setUserId] = useState("");
  const [query, setQuery] = useState([group_key, schoolId, album_id, userId]);
  const navigate = useNavigate();

  const handleChange = (e: React.SyntheticEvent, newValue: string) => {
    console.debug("handleChange");
    navigate(-1);
    setGroup_key(newValue);
    if (newValue === "admin") setQuery([newValue, "", "", ""]);
    else if (newValue === "school") setQuery([newValue, schoolId, "", ""]);
    else setQuery([newValue, schoolId, albumId, userId]);
  };

  const handleChange2 = (newValue: string) => {
    console.debug("handleChange2");
    setGroup_key(newValue);
    if (newValue === "admin") setQuery([newValue, "", "", ""]);
    else if (newValue === "school") setQuery([newValue, schoolId, "", ""]);
    else setQuery([newValue, schoolId, albumId, userId]);
  };

  useEffect(() => {
    if (album_id) {
      setAlbumId(album_id);
      handleChange2("user");
    }
  }, [album_id]);

  return (
    <OrderHistoryContext.Provider
      value={{
        setGroup_key,
        setQuery,
        setSchoolId,
        schoolId,
        setAlbumId,
        albumId,
      }}
    >
      <h2>注文一覧</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={group_key}
          onChange={handleChange}
          aria-label="Order Group Tabs"
        >
          <Tab label="団体単位の履歴" {...a11yProps("admin")} disabled />
          {group_key === "user" || "organization" ? (
            <Tab label="アルバム単位の履歴" {...a11yProps("school")} />
          ) : (
            <Tab label="アルバム単位の履歴" {...a11yProps("school")} disabled />
          )}
          <Tab label="保護者単位の履歴" {...a11yProps("user")} disabled />
        </Tabs>
      </Box>
      <TabPanel group_key={group_key} index="admin">
        お客様の権限では閲覧できません
      </TabPanel>
      <TabPanel group_key={group_key} index="school">
        <OrderHistorySchoolTable query={query} />
      </TabPanel>
      <TabPanel group_key={group_key} index="user">
        <OrderHistoryConsumerTable query={query} />
      </TabPanel>
    </OrderHistoryContext.Provider>
  );
}
