import axios from "axios";
import { mailsInfoReturnValue } from "utils/interface/mailsInfo/mailsInfoInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getMailsInfo = async (params: any) => {
  const response = await axios.get<mailsInfoReturnValue[]>(
    BASE_URL + "mailsinfo",
    {
      params: {
        mail_id: params.queryKey[1],
        user_id: params.queryKey[2],
        mail_status: params.queryKey[3],
        send_date_from: params.queryKey[4],
        send_date_to: params.queryKey[5],
      },
      headers: { "content-type": "application/json" },
    }
  );
  return response.data;
};