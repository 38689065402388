import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

export function EmptyCartCard({ ...props }: any) {
  return (
    <Card sx={{
        background: '#FFFFFF',
        border: '1px solid #E7E7E7',
        boxShadow: 'none',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: 0,
        marginBottom: '24px'
    }}>
      <CardContent>
        <Grid
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', color: '#9E9E9E' }}
        >

          <Grid item xs={3} >
            <ShoppingCartOutlinedIcon fontSize="inherit" sx={{ fontSize: '64px' }}/>
          </Grid>
          <Grid item xs={3}>
            カート内に商品がありません
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}