import { PhoneNumberUtil, PhoneNumber, PhoneNumberFormat } from 'google-libphonenumber';

export default function formatPhoneNumber(phoneNumber?: string) {
  // 日本の国コード
  const region = 'JP';

  const util:PhoneNumberUtil = PhoneNumberUtil.getInstance();

  if (!phoneNumber) return undefined;

  // 番号と地域を設定
  const number:PhoneNumber = util.parseAndKeepRawInput(phoneNumber, region);

  // 電話番号の有効性チェック
  if (!util.isValidNumberForRegion(number, region)) {
    return phoneNumber;
  }

  // ハイフン付きの形式で返却
  return util.format(number, PhoneNumberFormat.NATIONAL);
}
