// @mui
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// compornents
import GroupNameView from "./GroupNameView";
import GroupAddressView from "./GroupAddressView";
import GroupPhoneNumberView from "./GroupPhoneNumberView";
import GroupMailAddressView from "./GroupMailAddressView";
import GroupPhotoPriceSettingView from "./GroupPhotoPriceSettingView";
import GroupAccountInfoView from "./GroupAccountInfoView";
import GroupClassInfoView from "./GroupClassInfoView";
import GroupPersonInChangeView from "./GroupPersonInChangeView";
import GroupLoginView from "./GroupLoginView";
import GroupOverviewView from "./GroupOverviewView";
import GroupFaxNumberView from "./GroupFaxNumberView";
import AdminPhotoPriceSettingView from "./AdminPhotoPriceSettingView";
import GroupCommissionView from "./GroupCommissionSettingView";

// APIs
import { useQuery } from "react-query";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";

// functions
import getSchoolYear from "utils/function/getSchoolYear";

const CustomDivider = styled(Divider)({
  marginBottom: "20px",
});

export default function ContentsViewContainer(props: any) {
  const { school_id, account_type } = props;

  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", school_id],
    getSchools
  );

  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getSchools", "", school_id, getSchoolYear()],
    getClasses
  );

  if (getSchoolsResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getSchoolsResponse.isError) {
    return <span>Error: {getSchoolsResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }
  const data = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};
  const classes = getClassesResponse.data ? getClassesResponse.data : {};

  return (
    <div className="content">
      <form>
        <GroupNameView data={data} />
        <CustomDivider />
        <GroupAddressView data={data} />
        <CustomDivider />
        <GroupPhoneNumberView data={data} />
        <CustomDivider />
        <GroupFaxNumberView data={data} />
        <CustomDivider />
        <GroupMailAddressView data={data} />
        <CustomDivider />
        <GroupClassInfoView classes={classes} />
        {account_type == "organization" ? (
          <>
            <CustomDivider />
            <GroupPhotoPriceSettingView data={data} />
            <CustomDivider />
            <GroupAccountInfoView data={data} />
          </>
        ) : (
          <></>
        )}
        {account_type == "admin" ? (
          <>
            <CustomDivider />
            {/* <GroupLoginView />
            <CustomDivider /> */}
            <GroupPersonInChangeView data={data} />
            <CustomDivider />
            <GroupOverviewView data={data} />
            <CustomDivider />
            <GroupCommissionView data={data} />
            <CustomDivider />
            <AdminPhotoPriceSettingView data={data} />
          </>
        ) : (
          <></>
        )}
        <CustomDivider />
      </form>
    </div>
  );
}
