import axios from "axios";
import { photoGroupReturnValue } from "utils/interface/photoGroup/photoGroupInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getPhotoGroups = async (params: any) => {
  const response = await axios.get<photoGroupReturnValue[]>(
    BASE_URL + "photogroup",
    {
      params: {
        album_id: params.queryKey[1],
      },
      headers: { "content-type": "application/json" },
    }
  );
  return response.data;
};

export const patchPhotoGroup = async (params: any) => {
  const data = JSON.stringify(params);
  // console.debug(data);

  return await axios.patch(BASE_URL + "photogroup/" + params.album_id, data, {
    headers: { "content-type": "application/json" },
  });
};
