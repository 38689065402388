import { AnyAction } from "redux";
import { AccountActionTypes } from "../actions/AccountActions";

export interface LoginAccount {
  isAuthenticated: boolean;
  accountId: string; // user_id, school_idと同じ値
  accountType: string;
  permission: string[];
  schoolId: string; // アルバム新規作成用 団体はaccountIdと同じ、管理者は画面で選択
}

const initialState = {
  isAuthenticated: false,
  accountId: "",
  accountType: "",
  permission: [],
  schoolId: "",
};

export const accountReducer = (
  state: LoginAccount = initialState,
  action: AnyAction
): LoginAccount => {
  switch (action.type) {
    case AccountActionTypes.LOGIN:
      state = {
        isAuthenticated: true,
        accountId: action.payload.resData.account_id,
        accountType: action.payload.resData.account_type,
        permission: action.payload.resData.permission,
        schoolId: action.payload.schoolId,
      };
      return state;
    case AccountActionTypes.LOGOUT:
      state = initialState;
      return state;
    case AccountActionTypes.ADD_PERMISSION:
      return { ...state, permission: action.payload.newPermission };
    default:
      return state;
  }
};
