import { withStyles, createStyles } from '@mui/styles';
import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { Photo, PhotoSize } from "./Cart";

const styles = createStyles({
  countSpan: {
    margin: '0 0.5em',
    color: '#000000'
  }
});

function NumberChangeButtons (props: any) {
  const { photoId, sizeName, classes } = props;
  const dispatch = useDispatch();
  const count = useSelector<RootState, any>(state => state.cartReducer.filter(
    (photo) => photo.id === photoId
    )[0].photoSizes.filter(
      (size: PhotoSize) => size.name === sizeName
    )[0].count);
  const maxCount = useSelector<RootState, any>(state => state.cartReducer.filter(
    (photo) => photo.id === photoId
    )[0].photoSizes.filter(
      (size: PhotoSize) => size.name === sizeName
    )[0].maxCount);
  // const count = 0;
  // const maxCount = 3;
  const isPositive = count > 0;
  const isMax = typeof maxCount !== 'undefined' && count >= maxCount;

  const handlePlusButton = () => {
    dispatch({
      type: 'INCREMENT_COUNT',
      payload: {
        photoId,
        sizeName,
      }
    });
  };
  const handleMinusButton = () => {
    dispatch({
      type: 'DECREMENT_COUNT',
      payload: {
        photoId,
        sizeName,
      }
    });
  };

  const buttonStyle = {
      minWidth: '28px',
      maxWidth: '28px',
      minHeight: '36px',
      maxHeight: '36px',
      backgroundColor: '#23AA91',
      border: '1px solid #23AA91',
      color: '#FFFFFF',
  };
  const disabledButtonStyle = {
      ...buttonStyle,
      backgroundColor: '#23AA9188',
  };
  const CountButtonStyle = {
      ...buttonStyle,
      backgroundColor: '#FFFFFF',
      color: '#424242'
  };

  return (
    <ButtonGroup sx={{margin: '0 0.5em'}}>{
        isPositive ? 
          <Button size="small" style={buttonStyle} variant="outlined" onClick={event => handleMinusButton()} > <RemoveIcon sx={{ fontSize: 16 }} /> </Button> :
          <Button size="small" variant="outlined" style={disabledButtonStyle} disabled> <RemoveIcon sx={{ fontSize: 16 }}/> </Button>
      }<Button style={CountButtonStyle} disabled>{count}</Button>{
        isMax ?
          <Button size="small" variant="outlined" style={disabledButtonStyle} disabled> <AddIcon sx={{ fontSize: 16 }}/> </Button> :
          <Button size="small" style={buttonStyle} variant="outlined" onClick={event => handlePlusButton()} > <AddIcon sx={{ fontSize: 16 }} /> </Button>
    }</ButtonGroup>
  );
}

export default withStyles(styles)(NumberChangeButtons);