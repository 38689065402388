import Grid from "@mui/material/Grid";
import ContactButton from "./ContactButton";
import LoginButton from "./LoginButton";

export default function FloatButtons() {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: "fixed",
        bottom: 0,
        display: { xs: "flex", sm: "none" },
        background: "linear-gradient(transparent, gray)",
      }}
    >
      <Grid item>
        <ContactButton />
        <LoginButton />
      </Grid>
    </Grid>
  );
}
