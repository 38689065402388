const date: Date = new Date();

const OneMonthLaterDate: Date = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  date.getDate()
);

// format date to YYYY-MM-DD, YYYY/MM/DD
export const formatDate = (date: Date, delimiter: string): string => {
  let formattedDate: string = 
    date.getFullYear() +
    delimiter +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    delimiter +
    ("00" + date.getDate()).slice(-2);
  return formattedDate;
};

export const getCurrentDate = (): string => {
  return formatDate(date, "-");
};

export const getOneMonthLaterDate = (): string => {
  return formatDate(OneMonthLaterDate, "-");
};
