import { useState, useEffect } from "react";
import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";
import { saveAs } from "file-saver";

export default function DownloadPaymentInfo() {
  const [objectList, setObjectList] = useState<any>([]);

  useEffect(() => {
    listObjects();
  }, []);

  // S3設定用
  const BUCKET_NAME =
    process.env.REACT_APP_PAYMENTINFO_BUCKET_NAME || "bucketName";
  const creds = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "accessKeyId",
    secretAccessKey:
      process.env.REACT_APP_SECRET_ACCESS_KEY || "secretAccessKey",
  };

  const s3 = new S3Client({
    region: "ap-northeast-1",
    credentials: creds,
  });

  // S3 バケット内のオブジェクトの一覧を取得する
  async function listObjects() {
    try {
      const output = await s3.send(
        new ListObjectsV2Command({
          Bucket: BUCKET_NAME,
          MaxKeys: 10, // 最大10件まで取得
        })
      );
      setObjectList(output.Contents || []);
      for (const obj of output.Contents || []) {
      }
    } catch (err) {
      console.debug("ERROR:", err);
    }
  }

  // ダウンロード
  const handleClickDownload = async (fileName: string) => {
    const path = `https://${BUCKET_NAME}.s3.ap-northeast-1.amazonaws.com/${fileName}`;

    const response = await fetch(path);
    const blob = await response.blob();

    saveAs(blob, fileName);
  };

  return (
    <>
      {objectList.map((obj: any) => (
        <div>
          <button
            onClick={() => {
              console.debug(obj.Key);
              handleClickDownload(obj.Key);
            }}
          >
            {obj.Key}
          </button>
        </div>
      ))}
    </>
  );
}
