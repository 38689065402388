import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function ContentsFooter(props: any) {
  const { school_id } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      marginTop="20px"
    >
      <Grid item xs={3}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <CustomButton
              color="emerald"
              variant="contained"
              href={`/schools-edit/${school_id}`}
            >
              編集
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
