import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";
import CustomSelector from "../../components/InfoEditCustomSelector/InfoEditCustomSelector";

const categoryList = [
  {
    value: "保育園",
    label: "保育園",
  },
  {
    value: "幼稚園",
    label: "幼稚園",
  },
  {
    value: "こども園",
    label: "こども園",
  },
  {
    value: "小学校",
    label: "小学校",
  },
  {
    value: "スポーツクラブ",
    label: "スポーツクラブ",
  },
];

const establishmentClassificationList = [
  {
    value: "私立",
    label: "私立",
  },
  {
    value: "公立",
    label: "公立",
  },
];

const authorizationClassificationList = [
  {
    value: "認可",
    label: "認可",
  },
  {
    value: "認可外",
    label: "認可外",
  },
  {
    value: "小規模",
    label: "小規模",
  },
];

const shippingList = [
  {
    value: "自宅配送",
    label: "自宅配送",
  },
  {
    value: "一括配送前払い",
    label: "一括配送前払い",
  },
  {
    value: "一括配送集金",
    label: "一括配送集金",
  },
];

export default function GroupLoginView(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue("category", data ? data.category : "");
    schoolUseForm.setValue("corporate_type", data ? data.corporate_type : "");
    schoolUseForm.setValue("sub_category", data ? data.sub_category : "");
    schoolUseForm.setValue("contract_start", data ? data.contract_start : "");
    schoolUseForm.setValue("shipping_method", data ? data.shipping_method : "");
    schoolUseForm.setValue(
      "postage",
      data && data.postage ? data.postage : "0"
    );
    schoolUseForm.setValue(
      "commission",
      data && data.commission ? data.commission : "0"
    );
    schoolUseForm.setValue("remarks", data ? data.remarks : "");
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="団体概要" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          columnSpacing={2}
        >
          <Grid item xs={4}>
            <CustomSelector
              labelname="カテゴリ"
              items={categoryList}
              defaultValue={data && data.category ? data.category : ""}
              register={schoolUseForm.register("category")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="設立区分"
              items={establishmentClassificationList}
              defaultValue={
                data && data.corporate_type ? data.corporate_type : ""
              }
              register={schoolUseForm.register("corporate_type")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="認可区分"
              items={authorizationClassificationList}
              defaultValue={data && data.sub_category ? data.sub_category : ""}
              register={schoolUseForm.register("sub_category")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomForm
              labelname="契約開始日"
              type="date"
              register={schoolUseForm.register("contract_start")}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomSelector
              labelname="配送方法"
              items={shippingList}
              defaultValue={
                data && data.shipping_method ? data.shipping_method : ""
              }
              register={schoolUseForm.register("shipping_method")}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomForm
              labelname="備考"
              register={schoolUseForm.register("remarks")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
