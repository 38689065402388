import { useState, useContext } from "react";
import { useQuery } from "react-query";

import { getOrderHistory } from "utils/api/order/order";
import CustomCardList from "components/CustomCardList/CustomCardList";
import CustomTableFilter from "components/CustomTable/CustomTableFilter";
import CustomTablePagenation from "components/CustomTable/CustomTablePagenation";
import { addAlbumStatus } from "utils/function/shapeAlbumData";
import { orderHistory } from "utils/interface/order/orderInterface";

export default function GetOrderHistorySP(props: any) {
  const {
    filter,
    filterKey,
    filterArray,
    sortKey,
    urlKey,
    TABLEHEAD,
    query,
    group_key,
    chipFn,
  } = props;

  const ROWSPERPAGE = 10;
  const [filterStatus, setFilterStatus] = useState(filterArray[0]);
  const [page, setPage] = useState(1);

  // ログインしたユーザーのアカウント情報をStoreから取ってくる
  const { isLoading, isError, data, error } = useQuery<orderHistory[], Error>(
    ["getOrderHistory"].concat(query),
    getOrderHistory
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>Error: {error?.message}</span>;
  }

  const albumsData = addAlbumStatus(data);

  const viewTableData = albumsData
    .filter(
      (x: any) =>
        filterStatus === filterArray[0] || filterStatus === x[filterKey]
    )
    .slice((page - 1) * ROWSPERPAGE, (page - 1) * ROWSPERPAGE + ROWSPERPAGE);

  return (
    <>
      {filter ? (
        <CustomTableFilter
          filterArray={filterArray}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
          setPage={setPage}
          filter={filter}
        />
      ) : null}

      <CustomCardList
        TABLEHEAD={TABLEHEAD}
        tableData={viewTableData}
        sortKey={sortKey}
        filterKey={filterKey}
        urlKey={urlKey}
        group_key={group_key}
        chipFn={chipFn}
      />

      <CustomTablePagenation
        tableData={albumsData}
        page={page}
        setPage={setPage}
        ROWSPERPAGE={ROWSPERPAGE}
        filterKey={filterKey}
        filterStatus={filterStatus}
        filterArray={filterArray}
      />
    </>
  );
}
