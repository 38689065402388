import React from 'react';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export function BreadCrumbs(props: any) {
  const { breadcrumbs } = props;

  return (
    <Box sx={{ marginTop: { xs: '0px', md: '-30px'}, marginBottom: {xs: '30px', md: '10px'}}}>
        <Breadcrumbs
          separator={<ArrowForwardIosIcon sx={{ 'fontSize': '16px', 'color': 'rgba(0,0,0,.4)', 'marginTop': '4px'}}/>}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((breadcrumb: any, key: any) => (
            <Chip
              key={key}
              sx={{ borderRadius: "4px", fontSize: "12px", height: "20px" }}
              { ...breadcrumb }
            />
          ))}
        </Breadcrumbs>
    </Box>
  );
}
