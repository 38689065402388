// // @material-ui/core-element
import { Route, Routes } from "react-router-dom";

// component
import PrivateRoute from "components/Route/PrivateRoute";
import LogoutRoute from "components/Route/LogoutRoute";

// template
import {
  LandingTemplate,
  ClientTemplate,
  SchoolTemplate,
  AdminTemplate,
} from "components/_Template/_Template";

// data
import { logoutRouteArray } from "utils/route/logoutRouteArray";
import { clientRouteArray } from "utils/route/clientRouteArray";
import { schoolRouteArray } from "utils/route/schoolRouteArray";
import { adminRouteArray } from "utils/route/adminRouteArray";

export const browserRoute = (
  <Routes>
    {logoutRouteArray.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={<LogoutRoute routeType="logout" children={prop.element} />}
          key={prop.id}
        />
      );
      // return <Route path={prop.path} element={prop.element} key={prop.id} />;
    })}

    {clientRouteArray.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={
            <PrivateRoute
              routeType="user"
              children={<ClientTemplate children={prop.element} />}
            />
          }
          key={prop.id}
        />
        // <Route
        //   path={prop.path}
        //   element={<ClientTemplate children={prop.element} />}
        //   key={prop.id}
        // />
      );
    })}

    {schoolRouteArray.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={
            <PrivateRoute
              routeType="organization"
              children={
                prop.id !== "28" ? (
                  <SchoolTemplate children={prop.element} />
                ) : (
                  prop.element
                )
              }
            />
          }
          key={prop.id}
        />
        // <Route
        //   path={prop.path}
        //   element={<SchoolTemplate children={prop.element} />}
        //   key={prop.id}
        // />
      );
    })}

    {adminRouteArray.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          element={
            <PrivateRoute
              routeType="admin"
              children={
                prop.id !== "46" ? (
                  <AdminTemplate children={prop.element} />
                ) : (
                  prop.element
                )
              }
            />
          }
          key={prop.id}
        />
        // <Route
        //   path={prop.path}
        //   element={<AdminTemplate children={prop.element} />}
        //   key={key}
        // />
      );
    })}
  </Routes>
);
