import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// components
import Box from "@mui/material/Box";

// img
import deliverySlip from "assets/img/DeliverySlip.png";

// APIs
import { useQuery } from "react-query";
import { getOrder } from "utils/api/order/order";
import {
  orderReturnValue,
  orderDetail,
} from "utils/interface/order/orderInterface";
import { getUsersByUserId } from "utils/api/users/users";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";

export default function UserRegistFlyer() {
  const order_id = useParams().OrderId;

  const [schoolId, setSchoolId] = useState<string | undefined>("");
  const [userId, setUserId] = useState<string | undefined>("");
  const [updFlg, setUpdFlg] = useState<boolean>(false);

  const getOrderResponse = useQuery<orderReturnValue[], Error>(
    ["getOrder", "", "", order_id, "", "", ""],
    getOrder
  );
  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", schoolId, ""],
    getSchools
  );
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsers", userId],
    getUsersByUserId
  );
  useEffect(() => {
    getSchoolsResponse.refetch();
    getUsersResponse.refetch();
  }, [userId]);

  if (
    getOrderResponse.isLoading ||
    getUsersResponse.isLoading ||
    getSchoolsResponse.isLoading
  ) {
    return <span>Loading...</span>;
  }
  if (getOrderResponse.isError) {
    return <span>Error: {getOrderResponse.error?.message}</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  if (getSchoolsResponse.isError) {
    return <span>Error: {getSchoolsResponse.error?.message}</span>;
  }
  const orderData = getOrderResponse.data ? getOrderResponse.data[0] : {};
  const school_id = orderData.school_id;
  const user_id = orderData.user_id;
  if (!updFlg) {
    setSchoolId(school_id);
    setUserId(user_id);
    setUpdFlg(true);
  }
  const userData = getUsersResponse.data ? getUsersResponse.data[0] : {};
  const schoolData = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};

  let countL = 0;
  let count2L = 0;
  let countDL = 0;
  let priceL = 0;
  let price2L = 0;
  let priceDL = 0;
  let unitPriceL = -9999;
  let unitPrice2L = -9999;
  let unitPriceDL = -9999;

  if (orderData.order_details) {
    orderData.order_details.map((order: orderDetail) => {
      if (order.select_size == "L") {
        countL += Number(order.amount);
        priceL += Number(order.unit_price) * Number(order.amount);
        unitPriceL = Number(order.unit_price);
        console.debug(priceL);
      } else if (order.select_size == "2L") {
        count2L += Number(order.amount);
        price2L += Number(order.unit_price) * Number(order.amount);
        unitPrice2L = Number(order.unit_price);
      } else if (order.select_size == "DL") {
        countDL += Number(order.amount);
        priceDL += Number(order.unit_price) * Number(order.amount);
        unitPriceDL = Number(order.unit_price);
      }
    });
  }
  console.debug(orderData);

  return (
    <>
      <Box
        className="main"
        sx={{
          width: { sm: "210mm" },
          height: { sm: "148mm" },
        }}
      >
        <div className="top">
          <img
            src={deliverySlip}
            style={{
              position: "absolute",
              width: "210mm",
              height: "148mm",
              top: "0px",
              left: "0px",
            }}
          />
        </div>

        <div
          style={{
            position: "absolute",
            top: "25mm",
            left: "20mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            〒{userData.zip}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "30mm",
            left: "20mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {userData.address}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "40mm",
            left: "20mm",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
            }}
          >
            {userData.user_name}　様
          </h4>
        </div>
        <div
          style={{
            position: "absolute",
            top: "84mm",
            left: "37mm",
          }}
        >
          <h3>{schoolData.school_name}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "96mm",
            left: "37mm",
          }}
        >
          <h3>{userData.child_name}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "108.5mm",
            left: "37mm",
          }}
        >
          <h3>{orderData.delivery_class}</h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "95mm",
            left: "136mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {countL}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "95mm",
            left: "170mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {priceL}円
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "101.5mm",
            left: "136mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {count2L}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "101.5mm",
            left: "170mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {price2L}円
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "108.5mm",
            left: "136mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {countDL}
          </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "108.5mm",
            left: "170mm",
          }}
        >
          <h3
            style={{
              fontWeight: "normal",
            }}
          >
            {priceDL}円
          </h3>
        </div>
      </Box>
    </>
  );
}
