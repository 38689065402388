import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";

// @mui
import { Grid, Stack, Card, CardContent, styled } from "@mui/material";

// components
import { GetReadStatus } from "components/CustomTableSchoolDmHistory/GetReadStatus";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

// functions
import { MAIL_STATUS } from "utils/function/shapeSchoolDｍHistoryData";
import { getChipColor } from "utils/function/shapeSchoolDｍHistoryData";

const StyledCard = styled(Card)({
  background: "#FFFFFF",
  border: "1px solid #E5E5E5",
  boxSizing: "border-box",
  borderRadius: "16px",
  padding: "16px 16px",
  margin: "16px 0px 32px",
});

export default function SchoolDmHistoryDetail(props: any) {
  const dispatch = useDispatch();

  // ローカルストレージのDM履歴を取得
  const selectedDMHistory: any = useSelector<RootState>(
    (state) => state.dmHistoryReducer
  );

  // 表示文面作成用
  const getLetterBodyArray = (letterBody: string) => {
    return letterBody
      .replace(/<html>|<body>|<\/html>|<\/body>/g, "")
      .split("<br/>");
  };

  // DM履歴の選択解除用
  const deselectDM = () => {
    dispatch({
      type: "DESELECT_DM",
    });
  };

  const HEAD = [
    {
      user_name: "保護者名",
      user_id: "メールアドレス",
      child_name: "お子様名",
      class_name: "クラス",
      mail_status: "ステータス",
    },
  ];

  const filter: string = "ステータス";
  const filterKey: string = "mail_status";
  const filterArray: string[] = MAIL_STATUS;
  const sortKey: string = "user_name";

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <h2>メール履歴詳細</h2>
            <CustomButton
              color="emerald"
              variant="outlined"
              onClick={() => {
                deselectDM();
              }}
            >
              戻る
            </CustomButton>
          </Stack>
          <StyledCard variant="outlined">
            <CardContent>
              <Grid
                container
                rowSpacing={4}
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
              >
                <Grid item xs={2}>
                  <b>タイトル</b>
                </Grid>
                <Grid item xs={10}>
                  {selectedDMHistory.title}
                </Grid>
                <Grid item xs={2}>
                  <b>送信日時</b>
                </Grid>
                <Grid item xs={10}>
                  {selectedDMHistory.sendDate}
                </Grid>
                <Grid item xs={12}>
                  {getLetterBodyArray(selectedDMHistory.letterBody).map(
                    (prop: any, key: any) => {
                      return (
                        <>
                          {prop}
                          <br />
                        </>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
        <GetReadStatus
          filter={filter}
          filterKey={filterKey}
          filterArray={filterArray}
          sortKey={sortKey}
          TABLEHEAD={HEAD}
          // query={query}
          chipFn={getChipColor}
          schoolId={selectedDMHistory.schoolId}
          mailId={selectedDMHistory.mailId}
          className={selectedDMHistory.className}
        />
      </Grid>
    </>
  );
}
