import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";

// @mui
import { Grid, Box, Button, Modal } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";
import CustomForm from "components/InfoEditCustomForm/InfoEditCustomForm";

// interface
import { IFormInputPhotoGroupName } from "./INTERFACES";

// api
import { patchPhotoGroup } from "utils/api/photoGroup/photoGroup";

// 正規表現 \/:*?"<>|を含まない
const fileNameRegExp: RegExp = /^(?!.*(\\|\/|:|\*|\?|"|<|>|\|)).*$/;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PhotoGroupNameModal(props: any) {
  const { albumId, groupName, groupName1, groupName2 } = props;
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string | undefined>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 下位フォルダの存在判定
  const dfName = groupName2 ? groupName2 : groupName1;

  const { register, handleSubmit } = useForm<IFormInputPhotoGroupName>({
    defaultValues: {
      groupName: dfName,
    },
  });

  // DBの最大桁数30に合わせる
  const isValidLength = (name: string) => {
    if (groupName2) {
      return name.length + groupName1.length + 1 <= 30;
    } else {
      return name.length <= 30;
    }
  };

  const patchPhotoGroupMutation = useMutation(patchPhotoGroup, {
    onSuccess: (res) => {
      // console.log("Success patchPhotoGroupMutation");
      // console.log(res.data ? res.data : "nodata");

      /* 2022/5/14 albumId, groupNameをURLに持たせる実装に変更  */
      /* storeに入れる場合の実装 */
      // // 更新後の写真グループ名をglobal storeに入れる
      // const groupName = res.data.group_name;
      // dispatch({
      //   type: "REGIST_PHOTOGROUP",
      //   payload: {
      //     groupName,
      //   },
      // });
      /* ここまで*/

      // 成功時はアルバムIDに紐づく写真グループ名のリストが返る
      if (res.data && res.data[0].album_id) {
        // アルバム編集画面に遷移する
        window.location.href = "/school-album/" + albumId;

        /* 更新後の写真グループ名に紐づく写真一覧画面に遷移する場合はres.dataの絞り込みが必要 */
        // window.location.href =
        //   "/school-photos/" +
        //   albumId +
        //   "/" +
        //   encodeURIComponent(res.data.group_name);
        /* ここまで */
      } else {
        setMessage(
          "Error: 更新に失敗しました。初めからやり直してください。\n" +
            res.data.message
        );
      }
    },
  });

  const onSubmit: SubmitHandler<IFormInputPhotoGroupName> = (data) => {
    // console.log(data);
    setMessage("");
    const newName = groupName2
      ? groupName1 + "/" + data.groupName
      : data.groupName;
    // console.log("newName: " + newName);
    patchPhotoGroupMutation.mutate({
      album_id: albumId,
      org_group_name: groupName,
      new_group_name: newName,
    });
  };

  return (
    <>
      <Button
        startIcon={<EditIcon />}
        size="small"
        sx={{
          padding: "4px 10px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          borderRadius: "18px",
          fontSize: "12px",
          color: "#424242",
          fontWeight: 700,
        }}
        onClick={handleOpen}
      >
        フォルダ名変更
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomForm
              labelname="変更後のフォルダ名"
              register={register("groupName", {
                required: true,
                // maxLength: 15,
                validate: isValidLength,
                pattern: fileNameRegExp,
              })}
            />
            <span>{message}</span>
            <Grid
              container
              spacing="12px"
              direction="row"
              alignItems="center"
              marginTop="20px"
              justifyContent="center"
            >
              <Grid item>
                <CustomButton type="submit" color="emerald" variant="contained">
                  保存
                </CustomButton>
              </Grid>
              <Grid item>
                <CustomButton
                  color="emerald"
                  variant="outlined"
                  onClick={handleClose}
                >
                  キャンセル
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
