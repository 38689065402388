import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const createAccount = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "create_account", data, {
    headers: { "content-type": "application/json" },
  });
};

export const changeUserPassword = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "password_change", data, {
    headers: { "content-type": "application/json" },
  });
};

export const loginUser = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "login", data, {
    headers: { "content-type": "application/json" },
  });
};
