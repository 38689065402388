import { useFormContext } from "react-hook-form";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupPersonInChangeView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="担当者名" />
      </Grid>
      <Grid item xs={10}>
        <CustomForm
          labelname="担当者名"
          placeholder="担当者名を入力してください"
          helperlabel="例：山田太郎"
          value={data.name}
          disabled
        />
      </Grid>
    </Grid>
  );
}
