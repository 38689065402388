import { ErrorMessage } from "@hookform/error-message";
// @mui
import Grid from "@mui/material/Grid";
// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

export default function RenderRequestCodeForm(props: any) {
  const { handleSubmit, handleSendCodeClick, register, formState } = props;
  return (
    <form onSubmit={handleSubmit(handleSendCodeClick)}>
      <Grid
        container
        direction="row"
        alignItems="center"
        marginTop="20px"
        justifyContent="center"
      >
        <ErrorMessage
          errors={formState.errors}
          name="email"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="確認コードを送信します。メールアドレスを入力してください。"
          placeholder="メールアドレスを入力してください"
          register={register("email", {
            required: "⚠︎必須項目です",
            maxLength: 100,
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "⚠︎メールアドレスの形式が不正です",
            },
          })}
        />
        <CustomButton type="submit" color="neutral" variant="contained">
          コード送信
        </CustomButton>
      </Grid>
    </form>
  );
}
