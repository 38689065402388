import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material'
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { deleteSchool } from "utils/api/schools/adminSchools";

export type ConfirmDialogProps = {
    onClose: (value: string) => void
    title?: string
    message?: string
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const { onClose, title, message } = props

    return (
        <Dialog open onClose={() => onClose('close')}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CustomButton color="emerald" variant="contained" type="submit" onClick={() => onClose('ok')}>
                    はい
                </CustomButton>
                <CustomButton color="emerald" variant="contained" onClick={() => onClose('cancel')} autoFocus>
                    いいえ
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}
