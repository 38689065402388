// @mui
import Grid from "@mui/material/Grid";

// components
import AlbumSaveButton from "./AlbumSaveButton";
import SalesStartButton from "./SalesStartButton";

export default function ContentsHeader(props: any) {
  const { photoGroupData, isEditable, setIsStartSale } = props;
  // console.log(photoGroupData);
  return (
    <Grid container alignItems="center" sx={{ padding: "24px 0" }}>
      <Grid item xs={6}>
        <h2>アルバム登録/編集</h2>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <AlbumSaveButton setIsStartSale={setIsStartSale} />
          </Grid>

          {/* 写真グループがない場合は表示しない */}
          {/* 一部編集不可（販売中かつ管理者でない）のアルバムは販売開始ボタンを押せない */}
          {/* 管理者は日付を登録するために押す必要あり */}
          {photoGroupData.length > 0 && (
            <Grid item>
              <SalesStartButton
                isEditable={isEditable}
                setIsStartSale={setIsStartSale}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
