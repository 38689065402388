import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import PhotoPriceSetting from "./PhotoPriceSetting";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupPhotoPriceSettingView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value={"販売価格"} />
        <CustomLabel value={"基本設定"} />
      </Grid>
      <Grid item xs={10}>
        <PhotoPriceSetting data={data} />
      </Grid>
    </Grid>
  );
}
