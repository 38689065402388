import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";

import {
  StyledTableContainer,
  StyledTableRowNoPointer,
  TableHeadCell,
  TableDataCell,
  StyledChip,
  StyledTypography,
} from "components/Table/TableComponents";
import { SortTableHead } from "components/Table/SortTable";
import { orderHistory } from "utils/interface/order/orderInterface";

export default function CustomTable({ ...props }: any) {
  const { TABLEHEAD, tableData, sortKey, filterKey, chipFn } = props;

  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("desc");
  const [rows, setRows] = useState<orderHistory[]>(tableData);

  useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  return (
    <Grid item xs={12}>
      <StyledTableContainer>
        <Table>
          <TableHead sx={{ height: "54px" }}>
            {TABLEHEAD.map((props: any, key: number) => {
              return (
                <TableRow key={key}>
                  {Object.keys(props).map((k: any) => {
                    if (k === sortKey) {
                      return (
                        <SortTableHead
                          headline={props[k]}
                          order={order}
                          rows={rows}
                          sortKey={sortKey}
                          setOrder={setOrder}
                          setRows={setRows}
                          key={props[k]}
                        />
                      );
                    } else {
                      return (
                        <TableHeadCell key={props[k]}>{props[k]}</TableHeadCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {rows?.map((tableCell: any, key: number) => {
              return (
                <StyledTableRowNoPointer key={key}>
                  {TABLEHEAD.map((props: any) => {
                    return Object.keys(props).map((k: any, key: number) => {
                      if (k === filterKey) {
                        return (
                          <TableDataCell key={key}>
                            <StyledChip
                              label={
                                <StyledTypography>
                                  {tableCell[k]}
                                </StyledTypography>
                              }
                              color={chipFn(tableCell[k])}
                            />
                          </TableDataCell>
                        );
                      } else {
                        return (
                          <TableDataCell key={key}>
                            {tableCell[k]}
                          </TableDataCell>
                        );
                      }
                    });
                  })}
                </StyledTableRowNoPointer>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Grid>
  );
}
