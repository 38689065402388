import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// compornents
import CustomPriceForm from "../../components/InfoEditCustomPriceForm/InfoEditCustomPriceForm";

function createData(name: any, price: any, tableMargin: any) {
  return { name, price, tableMargin };
}

export default function PhotoPriceSetting(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue(
      "postage",
      data && data.postage ? data.postage : "250"
    );
    schoolUseForm.setValue(
      "commission",
      data && data.commission ? data.commission : "200"
    );
  }, []);

  const rows = [
    createData(
      "送料",
      <CustomPriceForm
        register={schoolUseForm.register("postage")}
        placeholder="40"
      />,
      ""
    ),
    createData(
      "手数料",
      <CustomPriceForm
        register={schoolUseForm.register("commission")}
        placeholder="130"
      />,
      ""
    ),
  ];

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 150 }}></TableCell>
            <TableCell style={{ width: 90 }} align="right">
              金額（税込）
            </TableCell>
            <TableCell style={{ width: 490 }} align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="left">{row.tableMargin}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
