import * as React from "react";

export default function Header(props: any) {
  const { school_name } = props;
  return (
    <header>
      <h1>{school_name}</h1>
    </header>
  );
}
