// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function AlbumSaveButton(props: any) {
  const { setIsStartSale } = props;

  return (
    <CustomButton
      onClick={() => {
        setIsStartSale(false);
      }}
      color="emerald"
      variant="contained"
      type="submit"
    >
      保存
    </CustomButton>
  );
}
