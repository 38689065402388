// @mui
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

// components
import FolderEditButton from "./FolderEditButton";

import FolderIcon from "@mui/icons-material/Folder";

type Props = {
  albumId: string;
  albumName: string;
  photoGroupData: GroupName[];
};

type GroupName = {
  album_id: string;
  group_name: string;
  counts: number;
};

type SubGroup = {
  subName: string;
  subCounts: number;
};

type GroupRow = {
  mainName: string;
  subGroup: SubGroup[];
  mainCounts: number;
};

// APIの取得結果をフォルダ階層に変換する
const formatGroupRows = (list: GroupName[]): GroupRow[] => {
  let rows: GroupRow[] = [];

  list.map((groupName: GroupName) => {
    const names: string[] = groupName.group_name.split("/");

    // rowsに存在したか判定
    let isUpdated: boolean = false;

    rows.map((row: GroupRow) => {
      if (names[0] === row.mainName) {
        // 1段目のフォルダ名がmainNameに入っている場合、更新
        if (names.length === 1) {
          // 1段目のみ
          row.mainCounts += groupName.counts;
        } else {
          // 2段目あり
          row.mainCounts += groupName.counts;

          const newSub: SubGroup = {
            subName: names[1],
            subCounts: groupName.counts,
          };
          row.subGroup.push(newSub);
        }
        isUpdated = true;
      }
    });

    if (!isUpdated) {
      // 1段目のフォルダ名がmainNameに入っていない場合、追加
      const newRow: GroupRow = {
        mainName: "",
        subGroup: [],
        mainCounts: 0,
      };

      if (names.length === 1) {
        // 1段目のみ
        newRow.mainName = names[0];
        newRow.mainCounts = groupName.counts;
      } else {
        // 2段目あり
        newRow.mainName = names[0];
        newRow.mainCounts = groupName.counts;

        const newSub: SubGroup = {
          subName: names[1],
          subCounts: groupName.counts,
        };
        newRow.subGroup.push(newSub);
      }
      rows.push(newRow);
    }
  });
  return rows;
};

export default function UploadedPhotoGroup(props: Props) {
  const { albumId, albumName, photoGroupData } = props;
  const groupRows: GroupRow[] = formatGroupRows(photoGroupData);

  return (
    <Grid container sx={{ padding: "24px 0" }}>
      <Grid item xs={12}>
        <h3>アップロードした写真グループ</h3>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "8px" }}>
        <Card variant="outlined" sx={{ borderRadius: "16px" }}>
          <Table className="folder-table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: "364px",
                    borderBottom: "2px solid #E5E5E5",
                    padding: "16px 24px",
                    fontSize: "12px",
                  }}
                >
                  写真グループ名
                </TableCell>
                <TableCell
                  sx={{
                    width: "65px",
                    borderBottom: "2px solid #E5E5E5",
                    padding: "16px 0px",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                >
                  写真枚数
                </TableCell>
                <TableCell sx={{ borderBottom: "2px solid #E5E5E5" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {groupRows.map((row) => (
                <>
                  <TableRow key={row.mainName}>
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                        padding: "16px 24px",
                        fontSize: "14px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <FolderIcon
                            sx={{
                              color: "#EDA605",
                              width: "24px",
                            }}
                          />
                        </Grid>
                        <Grid item xs={11} sx={{ paddingTop: "5px" }}>
                          {row.mainName}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "unset",
                        padding: "16px 0px",
                        textAlign: "right",
                        fontSize: "14px",
                      }}
                    >
                      {/* 配下フォルダが存在する場合は枚数表示しない */}
                      {row.subGroup.length === 0 && row.mainCounts + "枚"}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "right", borderBottom: "unset" }}
                    >
                      {/* 配下フォルダが存在する場合は写真グループ表示ボタンを出さない */}
                      {row.subGroup.length === 0 && (
                        <FolderEditButton
                          albumId={albumId}
                          albumName={albumName}
                          groupName={row.mainName}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  {row.subGroup.map((sub) => (
                    <TableRow key={sub.subName}>
                      <TableCell
                        sx={{
                          borderBottom: "unset",
                          padding: "8px 24px 8px 56px",
                          fontSize: "14px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={1}>
                            <FolderIcon
                              sx={{
                                color: "#EDA605",
                                width: "24px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={11} sx={{ paddingTop: "5px" }}>
                            {sub.subName}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "unset",
                          padding: "8px 0px 8px 0px",
                          textAlign: "right",
                          fontSize: "14px",
                        }}
                      >
                        {sub.subCounts}枚
                      </TableCell>
                      <TableCell
                        sx={{ textAlign: "right", borderBottom: "unset" }}
                      >
                        <FolderEditButton
                          albumId={albumId}
                          albumName={albumName}
                          groupName={row.mainName + "/" + sub.subName}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell sx={{ padding: "0px" }} />
                    <TableCell sx={{ padding: "0px" }} />
                    <TableCell sx={{ padding: "0px" }} />
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
}
