// compornents
import CustomButton from "../../components/InfoEditCustomButton/InfoEditCustomButton";

export default function SalesStartButton(props: any) {
  const { isEditable, setIsStartSale } = props;

  return (
    <CustomButton
      onClick={() => {
        setIsStartSale(true);
      }}
      disabled={!isEditable}
      color="orange"
      variant="contained"
      type="submit"
    >
      販売開始
    </CustomButton>
  );
}
