import Button from "@mui/material/Button";

export default function LoginButton() {
  return (
    <Button
      href="/login"
      color="orange"
      variant="contained"
      sx={{
        borderRadius: 28,
        margin: "6px",
      }}
    >
      ログイン
    </Button>
  );
}
