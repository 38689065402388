import { Children, useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function UserChildrenView(props: any) {
  const { data } = props;
  const userUseForm = useFormContext();

  console.debug('"' + data.child_name + '"');

  useEffect(() => {
    let child_name_with_comma =
      data.child_name && data.child_name.indexOf('"') == -1
        ? '"' + data.child_name + '"'
        : data.child_name;
    userUseForm.setValue(
      "child_name",
      data.child_name
        ? JSON.parse(child_name_with_comma.replaceAll("'", '"'))
        : ""
    );
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12}>
        <CustomLabel value={"お子様のお名前"} />
        <CustomForm
          placeholder="お子様のお名前をカンマ(,)区切りで入力してください"
          helperlabel="例：太郎,花子"
          register={userUseForm.register("child_name")}
        />
      </Grid>
    </Grid>
  );
}
