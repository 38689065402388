import { createTheme } from "@mui/material/styles";

export const customeTheme = createTheme({
  palette: {
    emerald: {
      main: "#23AA91",
      contrastText: "#fff",
    },
    ruby: {
      main: "#DE5F5F",
      contrastText: "#fff",
    },
    lime: {
      main: "#A5B91A",
      contrastText: "#fff",
    },
    neutral: {
      main: "#8C8C8C",
      contrastText: "#fff",
    },
    orange: {
      main: "#EDA605",
      contrastText: "#fff",
    },
    cyan: {
      main: "#00BCD4",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Hiragino Kaku Gothic ProN", "Gothic ProN"].join(","),
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    emerald: Palette["primary"];
    ruby: Palette["primary"];
    lime: Palette["primary"];
    neutral: Palette["primary"];
    orange: Palette["primary"];
    cyan: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    emerald?: PaletteOptions["primary"];
    ruby?: PaletteOptions["primary"];
    lime?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
    orange?: PaletteOptions["primary"];
    cyan?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
    cyan: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    emerald: true;
  }
}

declare module "@mui/material/OutlinedInput" {
  interface OutlinedInputPropsColorOverrides {
    emerald: true;
  }
}

declare module "@mui/material/Radio" {
  interface RadioPropsColorOverrides {
    emerald: true;
  }
}

declare module "@mui/material/Pagination" {
  interface PaginationPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
  }
}

declare module "@mui/material/TableCell" {
  interface TableCellPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}

declare module "@mui/material/PaginationItem" {
  interface PaginationItemPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    emerald: true;
    ruby: true;
    lime: true;
    neutral: true;
    orange: true;
  }
}
