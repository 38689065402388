import axios from "axios";
import { photosReturnValue } from "utils/interface/photos/photosInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getPhotos = async (params: any) => {
  const response = await axios.get<photosReturnValue[]>(BASE_URL + "photos", {
    params: {
      photo_id: params.queryKey[1],
      album_id: params.queryKey[2],
      path: params.queryKey[3],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const postPhoto = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "photos", data, {
    headers: { "content-type": "application/json" },
  });
};
