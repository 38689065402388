import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// @mui
import { Card, Divider } from "@mui/material";

// components
import ContentsHeader from "./ContentsHeader";
import { AlbumTitleView } from "./AlbumTitleView";
import { SalesPeriodView } from "./SalesPeriodView";
import { TargetClassView } from "./TargetClassView";
// import PrintDateView from "./PrintDateView";
import { PriceSelectView } from "./PriceSelectView";
import UploadedPhotoGroup from "./UploadedPhotoGroup";
import { PhotoUploader } from "./PhotoUploader";
import ContentsFooter from "./ContentsFooter";

// interface
import { IFormInput } from "./INTERFACES";

// api
import { useQuery } from "react-query";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { postAlbum, patchAlbum } from "utils/api/albums/albums";

// functions
import { formatDate } from "./getDatetime";

import uneditableImg from "../../assets/img/ae-uneditable.svg";

export default function ContentsViewContainer(props: any) {
  const {
    isNewAlbum,
    isEditable,
    schoolId,
    dfAlbumValue,
    photoGroupData,
    accountType,
  } = props;
  // console.log("dfAlbumValue", dfAlbumValue);

  let navigate = useNavigate();

  // 保存・販売開始ボタンのどちらが押下されたか判定用
  const [isStartSale, setIsStartSale] = useState<boolean>(false);

  const { register, handleSubmit, getValues } = useForm<IFormInput>({
    defaultValues: {
      albumName: dfAlbumValue.albumName,
      salesPeriodFrom: dfAlbumValue.salesPeriodFrom,
      salesPeriodTo: dfAlbumValue.salesPeriodTo,
    },
  });

  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", schoolId],
    getSchools,
    { enabled: !!schoolId }
  );
  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getClasses", "", schoolId],
    getClasses,
    { enabled: !!schoolId }
  );

  // schoolIdから団体の価格設定を取得
  // 団体の価格設定がない場合は団体の卸値を設定する
  // 団体の卸値もない場合はデフォルト値 Lサイズ：33円、2Lサイズ：130円、ダウンロード：140円を設定する
  const schoolData = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};
  const dfPriceL = dfAlbumValue.priceList.L
    ? dfAlbumValue.priceList.L
    : schoolData &&
      schoolData.sale_price_l &&
      Number(schoolData.sale_price_l) !== 0
    ? schoolData.sale_price_l
    : schoolData &&
      schoolData.wholesale_price_l &&
      schoolData.wholesale_price_l !== "0"
    ? schoolData.wholesale_price_l
    : 33;
  const dfPrice2L = dfAlbumValue.priceList["2L"]
    ? dfAlbumValue.priceList["2L"]
    : schoolData &&
      schoolData.sale_price_2l &&
      Number(schoolData.sale_price_2l) !== 0
    ? schoolData.sale_price_2l
    : schoolData &&
      schoolData.wholesale_price_2l &&
      schoolData.wholesale_price_2l !== "0"
    ? schoolData.wholesale_price_2l
    : 130;
  const dfPriceDL = dfAlbumValue.priceList.DL
    ? dfAlbumValue.priceList.DL
    : schoolData &&
      schoolData.sale_price_dl &&
      Number(schoolData.sale_price_dl) !== 0
    ? schoolData.sale_price_dl
    : schoolData &&
      schoolData.wholesale_price_dl &&
      schoolData.wholesale_price_dl !== "0"
    ? schoolData.wholesale_price_dl
    : 140;
  const dfPriceList = { L: dfPriceL, "2L": dfPrice2L, DL: dfPriceDL };
  // schoolIdから販売対象クラスを取得
  const classData =
    getClassesResponse && getClassesResponse.data
      ? getClassesResponse.data
      : [];

  // アルバム登録用
  const postAlbumMutation = useMutation(postAlbum, {
    onSuccess: async (res) => {
      // console.log("Success postAlbumMutation");
      // console.log(res.data);

      // アルバム一覧に遷移する
      navigate("/school-albums");
    },
  });

  // アルバム更新用
  const patchAlbumMutation = useMutation(patchAlbum, {
    onSuccess: async (res) => {
      // console.log("Success patchAlbumMutation");
      // console.log(res.data);

      // アルバム一覧に遷移する
      // navigateではアルバム一覧から再度詳細画面表示した際に更新前の値をデフォルト値として設定してしまうためリロード
      window.location.href = "/school-albums";
    },
  });

  if (getSchoolsResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getSchoolsResponse.isError) {
    return <span>Error: {getSchoolsResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    // console.log(isStartSale);
    // console.log(data);

    const salesPeriodFrom: string | null = !Number.isNaN(
      data.salesPeriodFrom.getTime()
    )
      ? formatDate(data.salesPeriodFrom, "/")
      : null;

    const salesPeriodTo: string | null = !Number.isNaN(
      data.salesPeriodTo.getTime()
    )
      ? formatDate(data.salesPeriodTo, "/")
      : null;

    const priceL: number = data.priceL ? Number(data.priceL) : 0;
    const price2L: number = data.price2L ? Number(data.price2L) : 0;
    const priceDL: number = data.priceDL ? Number(data.priceDL) : 0;
    const priceList = { L: priceL, "2L": price2L, DL: priceDL };

    let sizeList: string[] = [];
    if (data.sizeL) sizeList.push("L");
    if (data.size2L) sizeList.push("2L");
    if (data.sizeDL) sizeList.push("DL");

    let salesClasses: string[] = [];
    for (let key in data.salesClasses) {
      if (data.salesClasses[key]) salesClasses.push(key);
    }

    let postData = {
      school_id: dfAlbumValue.schoolId,
      album_name: data.albumName,
      price_list: JSON.stringify(priceList),
      size_list: JSON.stringify(sizeList),
      sales_classes: JSON.stringify(salesClasses),
      camera_man_flg: JSON.stringify(data.cameraManFlg),
    };

    const timeData = {
      sales_period_from: salesPeriodFrom,
      sales_period_to: salesPeriodTo,
    };

    // 販売開始ボタンでのみ日付を登録/更新する
    if (isStartSale) postData = { ...postData, ...timeData };

    if (isNewAlbum) {
      // 登録
      if (accountType === "admin") postData.camera_man_flg = "1";
      await postAlbumMutation.mutate(postData);
    } else {
      // 更新
      delete postData.school_id;
      await patchAlbumMutation.mutate({
        album_id: dfAlbumValue.albumId,
        ...postData,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentsHeader
        photoGroupData={photoGroupData}
        isEditable={isEditable}
        setIsStartSale={setIsStartSale}
      />

      <Card variant="outlined" sx={{ borderRadius: "16px" }}>
        <AlbumTitleView register={register} />
        <Divider />

        {/* 販売中のアルバムは変更不可 */}
        <div className="uneditable-area">
          {!isEditable && (
            <>
              <img className="uneditable-img" src={uneditableImg} alt="" />
              <div className="uneditable-text">
                販売中のアルバムは変更できません
              </div>
            </>
          )}
          {/* 写真グループがない場合（登録済みの写真が0枚）は変更不可 */}
          <div className="uneditable-area">
            {photoGroupData.length === 0 && isEditable && (
              <>
                <img className="uneditable-img" src={uneditableImg} alt="" />
                <div className="uneditable-text">
                  写真をアップロードした後に、販売期間が設定できます
                </div>
              </>
            )}
            <SalesPeriodView
              register={register}
              getValues={getValues}
              {...props}
            />
          </div>
          <Divider />
          <TargetClassView
            register={register}
            classData={classData}
            {...props}
          />
          <Divider />

          {/* 撮影日印字はPh1では出さない */}
          {/* <PrintDateView />
          <Divider /> */}

          <PriceSelectView
            register={register}
            dfPriceList={dfPriceList}
            dfSizeList={dfAlbumValue.sizeList}
            schoolData={schoolData}
          />
        </div>
      </Card>

      {/* 新規作成では表示しない */}
      {!isNewAlbum && (
        <>
          <UploadedPhotoGroup
            albumId={dfAlbumValue.albumId}
            albumName={dfAlbumValue.albumName}
            photoGroupData={photoGroupData}
          />
          <PhotoUploader
            isSchoolPhoto={false}
            schoolId={dfAlbumValue.schoolId}
            albumId={dfAlbumValue.albumId}
            groupName={""}
          />
          <ContentsFooter
            photoGroupData={photoGroupData}
            isEditable={isEditable}
            setIsStartSale={setIsStartSale}
          />
        </>
      )}
    </form>
  );
}
