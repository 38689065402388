import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupMailAddressView(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue("mail", data ? data.mail : "");
    schoolUseForm.setValue("mail_confirmation", data ? data.mail : "");
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="メールアドレス" />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" alignItems="flex-start">
          <ErrorMessage
            errors={schoolUseForm.formState.errors}
            name="mail"
            render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
          />
          <CustomForm
            labelname="メールアドレス"
            placeholder="メールアドレスを入力してください"
            helperlabel="例：xxx@example.com"
            register={schoolUseForm.register("mail", {
              onBlur: () => {
                if (schoolUseForm.getValues("mail_confirmation")) {
                  schoolUseForm.trigger("mail_confirmation");
                }
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: "⚠︎メールアドレスの形式が不正です",
              },
            })}
          />
          <ErrorMessage
            errors={schoolUseForm.formState.errors}
            name="mail_confirmation"
            render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
          />
          <CustomForm
            labelname="メールアドレス確認"
            placeholder="メールアドレスを入力してください"
            register={schoolUseForm.register("mail_confirmation", {
              validate: (value) => {
                return (
                  value === schoolUseForm.getValues("mail") ||
                  "⚠︎メールアドレスが一致しません"
                );
              },
            })}
          />
          {/* メアドvalidationの参考url */}
          {/* https://hirakublog.com/react-hook-form_re-enter-validation/ */}
        </Grid>
      </Grid>
    </Grid>
  );
}
