import axios from "axios";
import { classesReturnValue } from "utils/interface/classes/classesInterface";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getClasses = async (params: any) => {
  const response = await axios.get<classesReturnValue[]>(BASE_URL + "classes", {
    params: {
      class_id: params.queryKey[1],
      school_id: params.queryKey[2],
      year: params.queryKey[3],
    },
    headers: { "content-type": "application/json" },
  });
  return response.data;
};

export const postClasses = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.post(BASE_URL + "class", data, {
    headers: { "content-type": "application/json" },
  });
};

export const patchClasses = async (params: any) => {
  const data = JSON.stringify(params);
  console.debug(data);

  return await axios.patch(BASE_URL + "class/" + params.class_id, data, {
    headers: { "content-type": "application/json" },
  });
};
