type returnValue = {
  album_id?: string;
  school_id?: string;
  album_name?: string;
  school_name?: string;
  sales_status?: string;
  sales_period_from?: string;
  sales_period_to?: string;
  price_list?: string;
  size_list?: string;
  sales_classes?: string;
  photo_counts?: number | string;
  thumbnail?: string;
  days_left?: string | Date;
  delete_flag?: string;
};

export const TABLEHEAD: returnValue[] = [
  {
    sales_status: "販売状況",
    album_name: "アルバム名",
    school_name: "団体名",
    photo_counts: "写真枚数",
    sales_period_from: "販売開始日",
    sales_period_to: "販売終了日",
    delete_flag: "削除",
  },
];
