import Button from "@mui/material/Button";

export default function ContactButton() {
  return (
    <Button
      href="/form"
      color="emerald"
      variant="contained"
      sx={{
        borderRadius: 28,
        margin: "6px",
      }}
    >
      資料請求・お問い合わせ
    </Button>
  );
}
