import React from "react";
import { Dialog, DialogContent, Button, Grid, Box } from "@mui/material";
import SquareThumbnail from "../Cart/SquareThumbnail";
import CloseIcon from "@mui/icons-material/Close";
import Watermark from "react-awesome-watermark";

export const PhotoMordal = (props: any) => {
  const { url, open, handleClose } = props;

  const onClick = (e: any, isRight?: boolean) => {
    e.preventDefault();
    return false;
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogContent>
          <Grid container justifyContent="center">
            <img
              src={url}
              width="100%"
              onContextMenu={(e) => onClick(e, true)}
            />
            <Button
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
              sx={{ marginTop: "1em" }}
            >
              画像を閉じる
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
