import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";
import { FormControl, OutlinedInput } from "@mui/material/";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

// compornents
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

// other
import { classesReturnValue } from "utils/interface/classes/classesInterface";

const DesignedTextField = styled(OutlinedInput)({
  background: "#FAFAFA",
  border: "1px",
  boxSizing: "border-box",
  borderRadius: "4px",
  height: "36px",
  marginTop: "16px",
  marginBottom: "16px",
});

export default function GroupClassInfoView(props: any) {
  const { classes } = props;

  classes.sort(function (a: any, b: any) {
    if (a.class_id < b.class_id) return -1;
    if (a.class_id > b.class_id) return 1;
    return 0;
  });

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="クラス" />
      </Grid>
      <Grid item xs={10}>
        {classes.map((row: classesReturnValue) => (
          <Grid container direction="row" alignItems="center" columnSpacing={2}>
            <Grid item xs={7}>
              <FormControl fullWidth>
                <DesignedTextField
                  inputProps={{
                    value: row.class_name,
                  }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
