import React from 'react';
import { Divider, Grid, Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { numberToWithComma } from './PicSizeSelector';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: '12px',
    lineHeight: '1em',
  },
  sizeDetails: {
    fontSize: '10px',
    color: '#9E9E9E',
    marginTop: '4px',
  },
});

function OrderCountsByPicSize({ ...props }: any) {
  const {
    orderCountsByPicSize,
    classes
  } = props;
  return (
    <div className={classes.root}>
      { orderCountsByPicSize.map((prop: any, index: number) => (
        <div className={classes.row} key={index}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    {prop.displayName}
                  </Grid>
                  <Grid item>
                    <div className={classes.sizeDetails}>
                      {prop.sizeDetail}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>{String(prop.count) + '点'}</Grid>
            <Grid item>{'¥' + numberToWithComma(prop.subtotal)}</Grid>
          </Grid>
          {index === orderCountsByPicSize.length - 1 ? null : <Divider sx={{margin: '1.25rem 0'}}/>}
        </div>
      ))}
    </div>
  );
}

export default withStyles(styles)(OrderCountsByPicSize);