import { ErrorMessage } from "@hookform/error-message";
// @mui
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

export default function renderConfirmationForm(props: any) {
  const {
    handleSubmit,
    handleConfirmClick,
    formState,
    isRevealPassword,
    togglePassword,
    register,
    getValues,
    trigger,
  } = props;
  return (
    <form onSubmit={handleSubmit(handleConfirmClick)}>
      <Grid
        container
        direction="row"
        alignItems="center"
        marginTop="20px"
        justifyContent="center"
      >
        <ErrorMessage
          errors={formState.errors}
          name="code"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="検証コード"
          placeholder="検証コード(6桁)を入力してください"
          register={register("code", {
            required: "⚠︎必須項目です",
          })}
        />
        <ErrorMessage
          errors={formState.errors}
          name="password"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="新パスワード(アルファベット大文字、小文字、数字が使用可能)"
          placeholder="新パスワードを入力してください"
          type={isRevealPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                edge="end"
              >
                {isRevealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          register={register("password", {
            required: "⚠︎必須項目です",
            maxLength: 100,
            minLength: {
              value: 8,
              message: "パスワードは8文字以上で入力してください",
            },
            pattern: {
              value: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])^[0-9a-zA-Z]*$/,
              message:
                "⚠︎パスワードは大文字、小文字、数字を少なくとも1つ使用してください",
            },
            onBlur: () => {
              if (getValues("password_confirmation")) {
                trigger("password_confirmation");
              }
            },
          })}
        />
        <ErrorMessage
          errors={formState.errors}
          name="password_confirmation"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomForm
          labelname="新パスワード確認"
          placeholder="新パスワードを再入力してください"
          type={isRevealPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                edge="end"
              >
                {isRevealPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          register={register("password_confirmation", {
            required: "⚠パスワードを再入力してください",
            maxLength: 100,
            validate: (value: any) => {
              return (
                value === getValues("password") || "⚠︎パスワードが一致しません"
              );
            },
          })}
        />
        <CustomButton
          type="submit"
          color="neutral"
          variant="contained"
          style={{ width: "130px" }}
        >
          変更を保存する
        </CustomButton>
      </Grid>
    </form>
  );
}
