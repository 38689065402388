import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { orderHistory } from "utils/interface/order/orderInterface";
import { getOrder } from "utils/api/order/order";

const getChipColor = (key: string): any => {
  if (key === "印刷待ち") {
    return "orange";
  } else if (key === "未入金" || key === "取り消し") {
    return "ruby";
  } else if (key === "発送済み") {
    return "lime";
  } else if (key === "発送待ち") {
    return "neutral";
  } else if (key === "入金済み" || key === "入金済みDL") {
    return "cyan";
  } else {
    return "emerald";
  }
};

const StyledChip = styled(Chip)({
  width: "82px",
  height: "22px",
  borderRadius: "12px",
});

const ChipTypography = styled(Typography)({
  width: "62px",
  height: "12px",
  fontFamily: "Hiragino Kaku Gothic ProN",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  color: "#FFFFFF",
  flex: "none",
});

const StyledTableContainer = styled(TableContainer)({
  background: "#FFFFFF",
  border: "1px solid #E7E7E7",
  boxSizing: "border-box",
  borderRadius: "16px",
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "54px",
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableHeadCell = styled(TableCell)({
  color: "#23AA91",
  borderBottom: "2px solid #E6E6E6",
  padding: "0px 40px",
});

const TableDataCell = styled(TableCell)({
  color: "#37474F",
  padding: "0px 40px",
});

export default function MonthTable({ ...props }: any) {
  const { tableHead, UsersData, day } = props;

  // その日の注文履歴をAPIで取得
  const getOrderResponse = useQuery<orderHistory[], Error>(
    ["getOrder", "", day, "", day, "", ""],
    getOrder
  );
  if (getOrderResponse.isLoading) { return <span>Loading...</span>; }
  if (getOrderResponse.isError) return <span>Error: {getOrderResponse.error?.message}</span>;
  const tableData: any = getOrderResponse.data;

  // ユーザー名を記号から文字に変換
  let newUserName: String[] = [];
  let tmpList: string = "";
  for (let i = 0; i < tableData.length; i++) {
    newUserName = [];
    tmpList = String(tableData[i].user_id);
    if (tmpList) {
      for (let k = 0; k < UsersData.length; k++) {
        if (UsersData[k].user_id === tmpList) {
          newUserName.push(UsersData[k].user_name);
        }
      }
    }
    tableData[i].user_name = newUserName;
  }

  return (
    <StyledTableContainer>
      <Table>
        {tableHead !== undefined ? (
          <TableHead sx={{ height: "54px" }}>
            {tableHead.map((prop: any, key: any) => {
              return (
                <TableRow>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    {prop.user_name}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    {prop.order_id}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    {prop.order_status}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    {prop.amount}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    {prop.subtotal}
                  </TableHeadCell>
                  <TableHeadCell key={key} align="center" sx={{ width: "15%" }}>
                    注文詳細
                  </TableHeadCell>
                </TableRow>
              );
            })}
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((prop: any, key: any) => {
            return (
              <StyledTableRow key={key} onClick={() => {}}>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }}>
                  {prop.user_name}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }}>
                  {prop.order_id}
                </TableDataCell>
                <TableDataCell key={key} align="center" sx={{ width: "15%" }}>
                  <StyledChip
                    label={<ChipTypography>{prop.order_status}</ChipTypography>}
                    color={getChipColor(prop.order_status)}
                  />
                </TableDataCell>
                <TableDataCell key={key} align="right" sx={{ width: "15%" }}>
                  {Number(prop.amount).toLocaleString()}枚
                </TableDataCell>
                <TableDataCell key={key} align="right" sx={{ width: "15%" }}>
                  {Number(prop.taxed_price).toLocaleString()}円
                </TableDataCell>
                <TableDataCell key={key} align="center">
                  <Link
                    to={"/admin-order/" + prop.order_id}
                    style={{ color: "#23AA91" }}
                  >
                    確認する
                  </Link>
                </TableDataCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
