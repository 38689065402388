import React, { Suspense, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// custome components
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";
import CustomForm from "components/InfoEditCustomForm/InfoEditCustomForm";

// api
import SearchResult from "./GetSampleSearchResutl";
import { schoolsSearchValues } from "utils/interface/schools/schoolsInterface";

export default function GetSample() {
  const [query, setQuery] = useState<schoolsSearchValues>({});

  const { register, handleSubmit } = useForm<schoolsSearchValues>();

  const onSubmit: SubmitHandler<schoolsSearchValues> = (data) => {
    setQuery(data);
    console.debug(query);
  };

  return (
    <div>
      <Grid md={10}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <h2>GetSample</h2>
              <p>検索条件を入力してください</p>
              <CustomForm
                type="text"
                label="school_id"
                placeholder="school_id"
                register={register("school_id")}
              />
              <CustomForm
                type="text"
                label="school_name"
                placeholder="school_name"
                register={register("school_name")}
              />
            </CardContent>
            <Grid container direction="row" justifyContent="center">
              <CustomButton type="submit" color="emerald" variant="contained">
                学校を検索
              </CustomButton>
            </Grid>
          </Card>
        </form>
      </Grid>

      {/* get function */}
      {/* もし初期値で検索を走らせたくないのであれば、!queryとかで制御してください */}
      <Suspense fallback={<p>Loading...</p>}>
        <SearchResult query={query} />
      </Suspense>
    </div>
  );
}
