import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const USER = "user";
const ORG = "organization";
const ADMIN = "admin";

export default function Logout(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch({ type: "LOGOUT" });

  console.log(props.accountType);

  useEffect(() => {
    // Update the document title using the browser API
    if (props.accountType === USER) {
      navigate("/login");
    } else if (props.accountType === ORG) {
      navigate("/school/login");
    } else if (props.accountType === ADMIN) {
      navigate("/admin/login");
    }
  });

  return <>ログアウト処理</>;
}
