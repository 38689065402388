// @mui
import Grid from "@mui/material/Grid";

import logo from "../../assets/logo.svg";

export default function ContentsHeader() {
  return (
    <>
      <div className="center">
        <img src={logo} alt="NICOSKET" />
      </div>
      <h2 className="center">新規アカウント登録</h2>
    </>
  );
}
