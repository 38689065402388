import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// @mui
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

// compornents
import CustomeCheckBox from "../../components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomPriceForm from "../../components/InfoEditCustomPriceForm/InfoEditCustomPriceForm";

// functions
import convertStrToBool from "utils/function/convertStrToBool";

function createData(
  photoSize: any,
  wholePrice: any,
  salesPrice: any,
  tableMargin: any
) {
  return { photoSize, wholePrice, salesPrice, tableMargin };
}

export default function PhotoPriceSetting(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue(
      "sale_price_l",
      data.sale_price_l ? data.sale_price_l : "0"
    );
    schoolUseForm.setValue(
      "sale_price_2l",
      data.sale_price_2l ? data.sale_price_2l : "0"
    );
    schoolUseForm.setValue(
      "sale_price_dl",
      data.sale_price_dl ? data.sale_price_dl : "0"
    );
    schoolUseForm.setValue(
      "issales_lsize",
      convertStrToBool(data.issales_lsize)
    );
    schoolUseForm.setValue(
      "issales_2lsize",
      convertStrToBool(data.issales_2lsize)
    );
    schoolUseForm.setValue("issales_dl", convertStrToBool(data.issales_dl));
  }, []);

  const rows = [
    createData(
      <FormControlLabel
        control={
          <CustomeCheckBox
            register={schoolUseForm.register("issales_lsize")}
            defaultChecked={convertStrToBool(data.issales_lsize)}
          />
        }
        label={<Typography sx={{ fontSize: 14 }}>Lサイズ</Typography>}
      />,
      (data.wholesale_price_l ? data.wholesale_price_l : "0") + "円",
      <>
        <ErrorMessage
          errors={schoolUseForm.formState.errors}
          name="sale_price_l"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomPriceForm
          register={schoolUseForm.register("sale_price_l", {
            min: {
              value: data.wholesale_price_l,
              message: "卸値以上の値を入力してください",
            },
          })}
          placeholder="40"
        />
      </>,
      ""
    ),
    createData(
      <FormControlLabel
        control={
          <CustomeCheckBox
            register={schoolUseForm.register("issales_2lsize")}
            defaultChecked={convertStrToBool(data.issales_2lsize)}
          />
        }
        label={<Typography sx={{ fontSize: 14 }}>2Lサイズ</Typography>}
      />,
      (data.wholesale_price_2l ? data.wholesale_price_2l : "0") + "円",
      <>
        <ErrorMessage
          errors={schoolUseForm.formState.errors}
          name="sale_price_2l"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomPriceForm
          register={schoolUseForm.register("sale_price_2l", {
            min: {
              value: data.wholesale_price_2l,
              message: "卸値以上の値を入力してください",
            },
          })}
          placeholder="130"
        />
      </>,
      ""
    ),
    createData(
      <FormControlLabel
        control={
          <CustomeCheckBox
            register={schoolUseForm.register("issales_dl")}
            defaultChecked={convertStrToBool(data.issales_dl)}
          />
        }
        label={<Typography sx={{ fontSize: 14 }}>ダウンロード</Typography>}
      />,
      (data.wholesale_price_dl ? data.wholesale_price_dl : "0") + "円",
      <>
        <ErrorMessage
          errors={schoolUseForm.formState.errors}
          name="sale_price_dl"
          render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
        />
        <CustomPriceForm
          register={schoolUseForm.register("sale_price_dl", {
            min: {
              value: data.wholesale_price_dl,
              message: "卸値以上の値を入力してください",
            },
          })}
          placeholder="140"
        />
      </>,
      ""
    ),
  ];

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 150 }}>写真サイズ</TableCell>
            <TableCell style={{ width: 200 }} align="right">
              卸値（税込）
            </TableCell>
            <TableCell style={{ width: 200 }} align="right">
              販売価格（税込）
            </TableCell>
            <TableCell style={{ width: 100 }} align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.photoSize}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.photoSize}
              </TableCell>
              <TableCell align="right">{row.wholePrice}</TableCell>
              <TableCell align="right">{row.salesPrice}</TableCell>
              <TableCell align="left">{row.tableMargin}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
