import * as React from "react";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupMailAddressView(props: any) {
  const { data } = props;

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="メールアドレス" />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" alignItems="flex-start">
          <CustomForm
            labelname="メールアドレス"
            placeholder="メールアドレスを入力してください"
            helperlabel="例：xxx@example.com"
            value={data.mail}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
