export default function getSchoolYear() {
  const date = new Date();
  const year = date.getFullYear() * 1e4; // 1e4 gives us the the other digits to be filled later, so 20210000.
  const month = (date.getMonth() + 1) * 100; // months are numbered 0-11 in JavaScript, * 100 to move two digits to the left. 20210011 => 20211100
  const day = date.getDate(); // 20211100 => 20211124
  const result = year + month + day + ""; // `+ ''` to convert to string from number, 20211124 => "20211124"
  // console.debug(result);

  let output;
  try {
    var y = Number(result.substr(0, 4));
    var m = Number(result.substr(4, 2));
    var d = Number(result.substr(6, 2));
    var dt = new Date(y, m - 1, d);
    if (dt.getFullYear() == y && dt.getMonth() == m - 1 && dt.getDate() == d) {
      if (m < 4) {
        //4月はじまり
        output = y - 1;
      } else {
        output = y;
      }
    }
    return String(output);
  } catch (ex) {
    return "日付文字列が不正です。";
  }
}
