import { VFC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { Navigate } from "react-router-dom";

const USER = "user";
const ORG = "organization";
const ADMIN = "admin";

type Props = {
  children: ReactNode;
  routeType: string;
};

const LogoutRoute: VFC<Props> = (props) => {
  const { children, routeType } = props;

  // ログイン状態、権限情報を取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );

  // ログインしている場合はログイン後ページにリダイレクト
  if (
    loginAccount &&
    loginAccount.isAuthenticated &&
    loginAccount.accountType
  ) {
    // console.log("route login");
    if (loginAccount.accountType === ADMIN) {
      return <Navigate to="/admin" />;
    } else if (loginAccount.accountType === USER) {
      return <Navigate to="/albums" />;
    } else if (loginAccount.accountType === ORG) {
      return <Navigate to="/school-albums" />;
    } else {
      return <>{children}</>;
    }
  } else {
    // console.log("route logout");
    return <>{children}</>;
  }
};

export default LogoutRoute;
