import { useMutation } from "react-query";

// core components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// custome components
import CustomButton from "components/InfoEditCustomButton/InfoEditCustomButton";

// API
import { postGMO } from "utils/api/GMO/gmo";

export default function GMOSample() {
  const postGMOMutation = useMutation(postGMO, {
    onError: async (res) => {
      console.debug(res);
    },
  });

  const onSubmit = () => {
    const submit_data = {
      version: "1",
      contract_code: "72081500",
      user_id: "taka5216",
      user_name: "大和田　陽介",
      user_mail_add: "test2467@gmail.com",
      item_code: "S0000000042",
      item_name: "ニコスケ テスト53のお写真",
      order_number: "test",
      st_code: "00100-0000-00000-00000-00000-00000-00000",
      mission_code: "1",
      item_price: "250",
      process_code: "1",
      xml: "1",
      character_code: "UTF8",
    };

    // console.debug(submit_data);

    postGMOMutation.mutate(submit_data);
  };

  return (
    <div>
      <Grid md={10}>
        <form>
          <Card>
            <CardContent>
              <h2>GMOSample</h2>
            </CardContent>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <CustomButton
                  color="emerald"
                  variant="contained"
                  onClick={onSubmit}
                >
                  購入確定
                </CustomButton>
              </Grid>
            </Grid>
          </Card>
        </form>
      </Grid>
    </div>
  );
}
