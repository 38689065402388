import React from 'react';
import { Box, Divider, Grid, Theme } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import { numberToWithComma } from '../Cart/PicSizeSelector';
import { Photo } from '../Cart/Cart';
import DownloadIcon from '@mui/icons-material/Download';

const DOWNLOAD_NAME = 'DL';
const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: '12px',
    lineHeight: '1em',
  },
  sizeDetails: {
    fontSize: '10px',
    color: '#9E9E9E',
    marginTop: '4px',
  },
  a: {
    color: theme.palette.emerald.main,
    fontWeight: 700,
    textDecoration: 'underline #23AA91'
  },
  thumbnail: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
    'transition': 'box-shadow .1s ease',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.28)',
    }
  }
});

const getDownlaods = ((photos: Photo[]) => {
  return photos.map((photo: Photo) => {
    for (const photoSize of photo.photoSizes) {
      if (photoSize.name === DOWNLOAD_NAME) {
        return {
          url: photo.url,
          count: photoSize.count,
          sizeDetail: photoSize.sizeDetail,
          displayName: photoSize.displayName,
          subtotal: photoSize.unitPrice * (photoSize.count ? photoSize.count : 0),    
        }
      }
    }
    return {};
  }).filter(
    (prop:any) => (prop.count ? prop.count > 0 : false)
  )
});

function OrderCountsByPicSize({ ...props }: any) {
  const {
    orderCountsByPicSize,
    downloads,
    classes
  } = props;
  const list = orderCountsByPicSize.filter(
      (prop: any) => prop.name !== DOWNLOAD_NAME
    ).concat(getDownlaods(downloads));
  return (
    <div className={classes.root}>
      { list.map((prop: any, index: number) => (
            <div className={classes.row} key={index}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container direction="column">
                      <Grid item>{
                        typeof prop.url === 'string' ?
                        <a href={prop.url} download className={classes.a}>
                        {prop.displayName}
                        </a>
                        : <>{prop.displayName}</>
                      }</Grid>
                      <Grid item>
                        <div className={classes.sizeDetails}>
                          {prop.sizeDetail}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>{String(prop.count) + '点'}</Grid>
                  <Grid item xs={4}>{
                    typeof prop.url === 'string' ?
                    <Box sx={{width: {xs: '62px', sm: '100%'}}}>
                      <a href={prop.url} download> <img src={prop.url} alt={prop.displayName} width="100%" className={classes.thumbnail}/> </a>
                    </Box>
                    : null
                  }</Grid>
                <Grid item>{'¥' + numberToWithComma(prop.subtotal)}</Grid>
              </Grid>
              {index === list.length - 1 ? null : <Divider sx={{margin: '1.25rem 0'}}/>}
            </div>
      ))}
    </div>
  );
}

export default withStyles(styles)(OrderCountsByPicSize);