// @mui
import Grid from "@mui/material/Grid";

export default function ContentsHeader() {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: 2 }}
    >
      <h2 className="center">資料請求・お問い合わせ</h2>
    </Grid>
  );
}
