import * as React from "react";

// @mui
import { Grid } from "@mui/material";

// components
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

type Props = {
  register: any;
};

export const AlbumTitleView: React.FC<Props> = ({ register }) => {
  return (
    <Grid container>
      <Grid item className="row-name" xs={2}>
        <CustomLabel value="タイトル" />
      </Grid>
      <Grid item className="row-content" xs={10}>
        <CustomForm
          placeholder="タイトルを入力してください"
          register={register("albumName", {
            maxLength: 100,
          })}
        />
      </Grid>
    </Grid>
  );
};
