import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// @mui
import Grid from "@mui/material/Grid";

// compornents
import CustomForm from "../../components/InfoEditCustomForm/InfoEditCustomForm";
import CustomLabel from "../../components/InfoEditCustomLabel/InfoEditCustomLabel";

export default function GroupNameView(props: any) {
  const { data } = props;
  const schoolUseForm = useFormContext();

  useEffect(() => {
    schoolUseForm.setValue("school_name", data ? data.school_name : "");
    schoolUseForm.setValue("school_kana", data ? data.school_kana : "");
  }, []);

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={2}>
        <CustomLabel value="団体名" />
      </Grid>
      <Grid item xs={10}>
        <CustomForm
          labelname="団体名"
          placeholder="団体名を入力してください"
          helperlabel="例：〇〇保育園"
          register={schoolUseForm.register("school_name")}
        />
        <CustomForm
          labelname="フリガナ"
          placeholder="フリガナを入力してください"
          helperlabel="例：マルマルホイクエン"
          register={schoolUseForm.register("school_kana")}
        />
      </Grid>
    </Grid>
  );
}
