// @mui
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";

// compornents
import GroupNameView from "./GroupNameView";
import GroupAddressView from "./GroupAddressView";
import GroupPhoneNumberView from "./GroupPhoneNumberView";
import GroupMailAddressView from "./GroupMailAddressView";
import GroupPhotoPriceSettingView from "./GroupPhotoPriceSettingView";
import GroupAccountInfoView from "./GroupAccountInfoView";
import GroupClassInfoView from "./GroupClassInfoView";
import GroupPersonInChangeView from "./GroupPersonInChangeView";
import GroupChangeLoginView from "./GroupChangeLoginView";
import GroupNewLoginView from "./GroupNewLoginView";
import GroupOverviewView from "./GroupOverviewView";
import GroupFaxNumberView from "./GroupFaxNumberView";
import AdminPhotoPriceSettingView from "./AdminPhotoPriceSettingView";
import GroupCommissionView from "./GroupCommissionSettingView";

// APIs
import { useQuery } from "react-query";
import { getSchools } from "utils/api/schools/schools";
import { schoolsReturnValue } from "utils/interface/schools/schoolsInterface";
import { getClasses } from "utils/api/classes/classes";
import { classesReturnValue } from "utils/interface/classes/classesInterface";

// functions
import getSchoolYear from "utils/function/getSchoolYear";

const CustomDivider = styled(Divider)({
  marginBottom: "20px",
});

export default function ContentsViewContainer(props: any) {
  const {
    school_id,
    account_type,
    existClassList,
    setExistClassList,
    newClassList,
    setNewClassList,
    accountId,
    setAccountId,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
  } = props;

  const getSchoolsResponse = useQuery<schoolsReturnValue[], Error>(
    ["getSchools", school_id],
    getSchools
  );

  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getClasses", "", school_id, getSchoolYear()],
    getClasses
  );

  if (getSchoolsResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getSchoolsResponse.isError) {
    return <span>Error: {getSchoolsResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }
  const data = getSchoolsResponse.data ? getSchoolsResponse.data[0] : {};
  const classes = getClassesResponse.data ? getClassesResponse.data : [];

  return (
    <div className="content">
      <form>
        <GroupNameView data={data} />
        <CustomDivider />
        <GroupAddressView data={data} />
        <CustomDivider />
        <GroupPhoneNumberView data={data} />
        <CustomDivider />
        <GroupFaxNumberView data={data} />
        <CustomDivider />
        <GroupMailAddressView data={data} />
        <CustomDivider />
        <GroupClassInfoView
          school_id={school_id}
          classes={classes}
          existClassList={existClassList}
          setExistClassList={setExistClassList}
          newClassList={newClassList}
          setNewClassList={setNewClassList}
        />
        {account_type == "organization" ? (
          <>
            <CustomDivider />
            <GroupPhotoPriceSettingView data={data} />
            <CustomDivider />
            <GroupAccountInfoView data={data} />
          </>
        ) : (
          <></>
        )}
        {account_type == "admin" ? (
          <>
            <CustomDivider />
            {school_id === "new" ? (
              <GroupNewLoginView />
            ) : (
              <GroupChangeLoginView school_id={school_id} />
            )}

            <CustomDivider />
            <GroupPersonInChangeView data={data} />
            <CustomDivider />
            <GroupOverviewView data={data} />
            <CustomDivider />
            <GroupCommissionView data={data} />
            <CustomDivider />
            <AdminPhotoPriceSettingView data={data} />
          </>
        ) : (
          <></>
        )}
        <CustomDivider />
      </form>
    </div>
  );
}
