import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery } from "react-query";
import Card from "@mui/material/Card";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CurrencyYenRoundedIcon from "@mui/icons-material/CurrencyYenRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { orderHistory } from "utils/interface/order/orderInterface";
import { getOrderHistory, getOrder } from "utils/api/order/order";
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { getUsers } from "utils/api/users/users";

import MonthTable from "./MonthTable";
import DayTable from "./DayTable";
import DayDetailTable from "./DayDetailTable";

const MonthHead: any[] = [
  { month: "月", sales: "売り上げ", records: "注文件数" },
];

const DayHead: any[] = [{ day: "日", sales: "売り上げ", records: "注文件数" }];

const DayDetailHead: any[] = [
  {
    user_name: "保護者名",
    order_id: "注文番号",
    order_status: "ステータス",
    amount: "購入枚数",
    subtotal: "購入金額",
  },
];

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  minWidth: "320px",
  width: "640px",
  height: "130px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  borderRadius: "16px",
});

export default function Admin(props: any) {
  // 年で絞り込むために年を管理
  const date = new Date();
  const thisYear = date.getFullYear();
  const [year, setYear] = useState(String(thisYear));
  const handleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  // 月で絞り込むために月を管理
  const [month, setMonth] = useState<string>("");

  // 日で絞り込むために日を管理
  const [day, setDay] = useState<string>("");
  const [isSetDay, setIsSetDay] = useState<boolean>(false);

  // API
  const getOrderHistoryYearResponse = useQuery<orderHistory[], Error>(
    ["getOrderHistory", "year", "", "", "", "", "", ""],
    getOrderHistory
  );
  const getOrderHistoryMonthResponse = useQuery<orderHistory[], Error>(
    ["getOrderHistory", "month", "", "", "", "", "", ""],
    getOrderHistory
  );
  const getOrderResponse = useQuery<orderHistory[], Error>(
    ["getOrder", "", "", "", "", "", "入金済み,印刷待ち,発送待ち"],
    getOrder
  );
  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsers"],
    getUsers
  );
  if (
    getOrderHistoryYearResponse.isLoading ||
    getOrderHistoryMonthResponse.isLoading ||
    getOrderResponse.isLoading ||
    getUsersResponse.isLoading
  ) {
    return <span>Loading...</span>;
  }
  if (getOrderHistoryYearResponse.isError) {
    return <span>Error: {getOrderHistoryYearResponse.error?.message}</span>;
  }
  if (getOrderHistoryMonthResponse.isError) {
    return <span>Error: {getOrderHistoryMonthResponse.error?.message}</span>;
  }
  if (getOrderResponse.isError) {
    return <span>Error: {getOrderResponse.error?.message}</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  const DayData: any = getOrderHistoryYearResponse.data;
  const MonthData: any = getOrderHistoryMonthResponse.data;
  const orderData: any = getOrderResponse.data;
  const UsersData: any = getUsersResponse.data;

  // 年の候補を取得
  const yearListTmp: string[] = [];
  MonthData?.map((props: any) => {
    yearListTmp.push(props.order_year);
  });
  const yearList = Array.from(new Set(yearListTmp));

  // 今月の売り上げと注文件数を取得
  const thisMonth = date.getMonth() + 1;
  const thisMonthSubtotal = MonthData.filter(
    (x: any) => (x.order_month === ('00' + String(thisMonth)).slice(-2))&&(x.order_year === String(thisYear))
  )[0]?.subtotal;
  const thisMonthAmount = MonthData.filter(
    (x: any) => (x.order_month === ('00' + String(thisMonth)).slice(-2))&&(x.order_year === String(thisYear))
  )[0]?.records;

  return (
    <>
      <Grid
        container
        spacing={4}
        direction="row"
        justifyContent="flex-center"
        alignItems="baseline"
      >
        <Grid item xs={12}>
          <Stack direction="row" spacing={3}>
            <StyledCard>
              <CardContent>
                <ListItem alignItems="center">
                  <ListItemIcon sx={{ mr: "30px" }}>
                    <CurrencyYenRoundedIcon
                      sx={{ fontSize: 70, color: "#23AA91" }}
                    />
                  </ListItemIcon>
                  <div>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      今月の売り上げ
                    </Typography>
                    {thisMonthSubtotal ? (
                      <Typography variant="h4">
                        {Number(thisMonthSubtotal).toLocaleString()}円
                      </Typography>
                    ) : (
                      <Typography variant="h4">0円</Typography>
                    )}
                  </div>
                </ListItem>
              </CardContent>
            </StyledCard>
            <StyledCard>
              <CardContent>
                <ListItem alignItems="center">
                  <ListItemIcon sx={{ mr: "30px" }}>
                    <TrendingUpRoundedIcon
                      sx={{ fontSize: 70, color: "#23AA91" }}
                    />
                  </ListItemIcon>
                  <div>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      今月の注文件数
                    </Typography>
                    {thisMonthSubtotal ? (
                      <Typography variant="h4">
                        {Number(thisMonthAmount).toLocaleString()}件
                      </Typography>
                    ) : (
                      <Typography variant="h4">0件</Typography>
                    )}
                  </div>
                </ListItem>
              </CardContent>
            </StyledCard>
            <StyledCard>
              <CardContent>
                <ListItem alignItems="center">
                  <ListItemIcon sx={{ mr: "30px" }}>
                    <PrintRoundedIcon sx={{ fontSize: 70, color: "#23AA91" }} />
                  </ListItemIcon>
                  <div>
                    <Typography
                      sx={{ fontSize: 16 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      未発送の注文件数
                    </Typography>
                    {thisMonthSubtotal ? (
                      <Typography variant="h4">
                        {Number(orderData.length).toLocaleString()}件
                      </Typography>
                    ) : (
                      <Typography variant="h4">0件</Typography>
                    )}
                  </div>
                </ListItem>
              </CardContent>
            </StyledCard>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormControl size="small" color="emerald">
            <InputLabel id="label" sx={{ color: "#23AA91" }}>
              年
            </InputLabel>
            <Select
              value={year}
              label="年"
              labelId="label"
              onChange={handleChange}
              sx={{
                width: "500px",
                background: "#FFFFFF",
                select: {
                  "&:before": { backgroundColor: "#23AA91" },
                  "&:after": { backgroundColor: "#23AA91" },
                },
                "&:hover": { "&& fieldset": { border: "2px solid #23AA91" } },
              }}
            >
              {yearList.map((prop: any, key: number) => {
                return (
                  <MenuItem value={prop} key={key}>
                    {prop}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <MonthTable
            tableHead={MonthHead}
            tableData={MonthData.filter((x: any) => x.order_year === year)}
            setMonth={setMonth}
          />
        </Grid>
        <Grid item xs={6}>
          <DayTable
            tableHead={DayHead}
            tableData={DayData.filter(
              (x: any) => x.order_year === year && x.order_month === month
            )}
            setDay={setDay}
            setIsSetDay={setIsSetDay}
          />
        </Grid>
        <Grid item xs={12}>
          {isSetDay
            ? <DayDetailTable tableHead={DayDetailHead} UsersData={UsersData} day={day}/>
            : <div></div>
          }
        </Grid>
      </Grid>
    </>
  );
}
