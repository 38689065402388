import { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

// @mui
import {
  Grid,
  Stack,
  Card,
  CardContent,
  Button,
  FormControlLabel,
  styled,
} from "@mui/material";

// components
import GroupMailTable from "./SchoolDmTable";
import InfoEditCustomeCheckBox from "components/InfoEditCustomCheckBox/InfoEditCustomCheckBox";
import CustomForm from "components/InfoEditCustomForm/InfoEditCustomForm";
import CustomMailForm from "components/InfoEditCustomForm/InfoEditCustomMailForm";

// interface
import { usersReturnValue } from "utils/interface/users/usersInterface";
import { classesReturnValue } from "utils/interface/classes/classesInterface";
import { dmReturnValue } from "utils/interface/dm/dmInterface";

// APIs
import { getUsersByParams } from "utils/api/users/users";
import { getClasses } from "utils/api/classes/classes";
import { postDm } from "utils/api/dm/dm";

// functions
import getSchoolYear from "utils/function/getSchoolYear";
import setDmTargetClassList from "utils/function/setDmTargetClassList";
import { getCurrentDate } from "utils/function/utilDate";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { exit } from "process";

const StyledButton = styled(Button)({
  padding: "5px 10px",
  width: "82px",
  height: "22px",
  borderRadius: "12px",
  margin: "0px 4px",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "12px",
  textAlign: "center",
  "&:hover": {
    backgroundColor: "#23AA91",
    color: "white",
  },
});

const StyledCard = styled(Card)({
  background: "#FFFFFF",
  border: "1px solid #E5E5E5",
  boxSizing: "border-box",
  borderRadius: "16px",
  padding: "16px 16px",
});

export default function SchoolDmClass(props: any) {
  const [group, setGroup] = useState("default");
  const [dmTargetClass, setDmTargetClass] = useState<classesReturnValue[]>([]);
  let classes: classesReturnValue[] = [];
  const [isUpdated, setIsUpdated] = useState(false);
  const [attachedFilepath, setAttachedFilepath] = useState<string>("");
  const [message, setMessage] = useState<string | undefined>("");
  const [checkboxMessage, setCheckboxMessage] = useState<string | undefined>(
    ""
  );

  const postDmMutation = useMutation(postDm, {
    onSuccess: async (res: any) => {
      console.debug("Success");
      console.debug(res);
      navigate("/school-dm-history/");
      setMessage("送信成功");
    },
    onError: async (res: any) => {
      console.debug("Error");
      console.debug(res);
      postDmMutation.mutate(JSON.parse(res.config.data));
      // postDmMutation.mutate(JSON.parse(res.config.data));
      // setMessage(
      //   "エラーが発生しました。時間を空けてからもう一度お試しください。"
      // );
    },
  });

  const dmUseForm = useForm<dmReturnValue>({
    mode: "onBlur",
  });

  let navigate = useNavigate();

  // storeからログイン中のユーザ情報、school_idを取得
  const loginAccount: any = useSelector<RootState>(
    (state) => state.accountReducer
  );
  // console.debug(loginAccount);

  const account_type: string = loginAccount.accountType;
  const school_id: string = loginAccount.schoolId;

  const getUsersResponse = useQuery<usersReturnValue[], Error>(
    ["getUsers", "", school_id, ""],
    getUsersByParams
  );
  const getClassesResponse = useQuery<classesReturnValue[], Error>(
    ["getClasses", "", school_id, getSchoolYear()],
    getClasses
  );

  useEffect(() => {
    if (!isUpdated && classes.length != 0) {
      setDmTargetClass(classes);
      setIsUpdated(true);
    }
  }, [classes]);

  if (getUsersResponse.isLoading || getClassesResponse.isLoading) {
    return <span>Loading...</span>;
  }
  if (getUsersResponse.isError) {
    return <span>Error: {getUsersResponse.error?.message}</span>;
  }
  if (getClassesResponse.isError) {
    return <span>Error: {getClassesResponse.error?.message}</span>;
  }
  const users = getUsersResponse.data ? getUsersResponse.data : [];
  classes = getClassesResponse.data ? getClassesResponse.data : [];

  const getVariant = (onSelectGroupNumber: string) =>
    group === onSelectGroupNumber ? "contained" : "text";

  let dmTargetClassListString: string[] = [];
  
  const onSubmit: SubmitHandler<dmReturnValue> = (data) => {
    setCheckboxMessage("");
    data.school_id = school_id;
    data.letter_body =
      "<html><body>" +
      data.letter_body.replace(/\n/g, "<br/>") +
      "</body></html>";
    dmTargetClass.map((dmTarget) => {
      if (dmTarget.dm_flg) {
        dmTargetClassListString.push(dmTarget.class_id);
      }
    });
    if (dmTargetClassListString.length == 0) {
      setCheckboxMessage("送信先クラスを選択してください");
      return;
    }
    data.class_id = `['${dmTargetClassListString.join("','")}']`;
    data.user_id = "";
    // 試験用
    // data.user_id = "taka5216@gmail.com";
    data.attached_file = attachedFilepath;

    setMessage("送信中...");
    postDmMutation.mutate(data);
  };

  // S3アップロード用
  const BUCKET_NAME = process.env.REACT_APP_MAIL_BUCKET_NAME || "bucketName";
  const creds = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "accessKeyId",
    secretAccessKey:
      process.env.REACT_APP_SECRET_ACCESS_KEY || "secretAccessKey",
  };

  const s3 = new S3Client({
    region: "ap-northeast-1",
    credentials: creds,
  });

  const uploadFile = async (event: any) => {
    try {
      console.debug(BUCKET_NAME);
      console.debug(creds);

      const file = event.currentTarget.files;
      const filePath = school_id + "/" + getCurrentDate() + "/" + file[0].name;

      const uploadParams = {
        Bucket: BUCKET_NAME,
        Key: filePath,
        Body: file[0],
      };
      console.debug(uploadParams);

      try {
        const data = await s3.send(new PutObjectCommand(uploadParams));
        console.debug("Successfully uploaded.");
        setAttachedFilepath(filePath);
        console.debug(filePath);
        return data;
      } catch (err) {
        console.debug(err);
        return console.debug("There was an error.");
      }
    } catch (err) {
      console.debug(err);
    }
  };

  return (
    <>
      <form onSubmit={dmUseForm.handleSubmit(onSubmit)}>
        <StyledCard variant="outlined">
          <CardContent>
            <Grid
              container
              rowSpacing={4}
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
            >
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="baseline"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <b>クラスリスト</b>
                  <Button
                    disableElevation
                    href="/school-dm-history"
                    color="emerald"
                    variant="outlined"
                    sx={{
                      borderRadius: "4px",
                      width: "112px",
                      height: "36px",
                      fontWeight: "bold",
                      background: "#FFFFFF",
                      borderWidth: "2px",
                      ":hover": { backgroundColor: "#23AA91", color: "white" },
                    }}
                  >
                    送信履歴
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {classes.map((prop: any, key: any) => {
                  return (
                    <StyledButton
                      disableElevation
                      variant={getVariant(prop.class_id)}
                      color="emerald"
                      onClick={() => setGroup(prop.class_id)}
                    >
                      {prop.class_name}
                    </StyledButton>
                  );
                })}
              </Grid>
              <Grid item xs={12}>
                <GroupMailTable
                  tableData={users}
                  group={group}
                  sendType={"class"}
                  dmUseForm={dmUseForm}
                />
              </Grid>
              <Grid item xs={2}>
                <b>送信先</b>
              </Grid>
              <Grid item xs={10}>
                <p style={{ color: "red" }}>{checkboxMessage}</p>
                {classes.map((prop: any, key: Number) => {
                  return (
                    <FormControlLabel
                      control={
                        <InfoEditCustomeCheckBox
                          onChange={function (e: any) {
                            setDmTargetClassList(
                              key,
                              dmTargetClass,
                              setDmTargetClass
                            );
                          }}
                        />
                      }
                      label={prop.class_name}
                    />
                  );
                })}
              </Grid>
              <Grid item xs={2}>
                <b>タイトル</b>
              </Grid>
              <Grid item xs={10}>
                <ErrorMessage
                  errors={dmUseForm.formState.errors}
                  name="title"
                  render={({ message }) => (
                    <p style={{ color: "red" }}>{message}</p>
                  )}
                />
                <CustomForm
                  fullWidth
                  placeholder="タイトルを入力してください"
                  id="outlined-basic"
                  variant="outlined"
                  color="emerald"
                  register={dmUseForm.register("title", {
                    required: "⚠︎必須項目です",
                  })}
                />
              </Grid>
              <Grid item xs={2}>
                <b>添付ファイル</b>
              </Grid>
              <Grid item xs={10}>
                <input type="file" onChange={uploadFile} />
              </Grid>
              <Grid item xs={2}>
                <b>本文</b>
              </Grid>
              <Grid item xs={10}>
                <ErrorMessage
                  errors={dmUseForm.formState.errors}
                  name="letter_body"
                  render={({ message }) => (
                    <p style={{ color: "red" }}>{message}</p>
                  )}
                />
                <CustomMailForm
                  fullWidth
                  placeholder="本文を入力してください"
                  multiline
                  id="outlined-multiline-static"
                  register={dmUseForm.register("letter_body", {
                    required: "⚠︎必須項目です",
                  })}
                  variant="outlined"
                  color="emerald"
                  minRows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems="center">
                  <Button
                    variant="contained"
                    color="orange"
                    type="submit"
                    disableElevation
                    sx={{ height: "32px", borderRadius: "4px" }}
                  >
                    送信
                  </Button>
                  <br />
                  <span>{message}</span>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </form>
    </>
  );
}
